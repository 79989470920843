import { ConfiguratinDetailsModel } from "../../models/ConfigurationDetails/ConfigurationDetails.model";

export class ApplicationCodes {
    //buyer serach table names
    CMNCountriesSearchTable: string = "CMN_Countries";
    SalesAgentNamesSearchTable: string = "PROC_SalesAgentNames";
    ResSalesAgentNamesSearchTable: string = "RES_SalesAgentNames1";
    OrderTakerNamesSearchTable: string = "RES_SalesAgentNames2";
    SalesRegionSearchTable: string = "PROC_SalesRegion";
    BuyerSearchTable: string = "PROC_BuyerNames";
    VendorSearchTable: string = "PROC_VendorNames";
    CollectionAgentTable: string = "PROC_CollectionAgentNames"
    ProductDiscountGroup: string = "PROC_ProductGroupDiscountSetup"
    GlobalTaxGroup: string = "CFN_TaxRateMaster";
    LocalLanguages: string = "CMN_LocalLanguages";
    UnitOfMeasurement: string = "PROC_UnitsOfMeasurement";
    GodownTableName: string = "PROC_GoDownNames";
    ProductionOrder: string = "PROD_ProductionOrder";
    ProductionReceipt: string = "PROD_ProductionReceipt";
    MaterialReceipt: string = "PROD_MaterialReceipt";
    UOMGroupTableName: string = "PROC_UOMGroupNames";
    ProductCardNames: string = "PROC_ProductCardNames";
    RoutingTable: string = "PROD_Routing"
    ProductCardCategories: string = "PROC_ProductCardMaster";
    PrinterGroupTableName: string = "POS_PrinterGroupNames";
    ResPrinterGroupTableName: string = "Res_PrinterGroupNames";
    CountersTable: string = "POS_Counters";
    ResCountersTable: string = "Res_Counters";
    ResArea: string = "Res_Area";
    BuyerGroupsTable: string = "PROC_BuyerGroupNames";
    PriceSetupTable: string = "PROC_PriceSetupNames";
    BarcodeLabelSetup: string = "PROC_BarcodeLabelSetupNames"
    CollectionAgentNamesSearchTable: string = "PROC_CollectionAgentNames";
    TaxesNameTable: string = "CFN_Taxes";
    TaxesGroupNameTable: string = "CFN_TaxGroupNames";
    CardsTable: string = "PROC_Cards";
    BanksTable: string = "PROC_Banks";
    PaymentTerm: string = "PROC_PaymentTermNames";
    Currencies: string = "CFN_Currencies";
    PLBSClassNames: string = "FA_PLBSClassNames";
    CommissionSetupNames: string = "PROC_CommissionSetupNames";
    ExpenseTypes: string = "FA_ExpenseTypes";
    ProductGroups: string = "PROC_SalesGroupsNames";
    ActiveBuyers: string = "PROC_BuyerNames_Active";
    ActiveVendors: string = "PROC_VendorNames_Active";
    PaymentMethods: string = 'PROC_PaymentMethod'
    BannerPromotionNames: string = "Ecomm_BannerPromotionNames";

    RiderNamesSearchTable: string = "EComm_RiderNames";
    StoreSearchTable: string = "EComm_Stores";
    ShipmentsNamesSearchTable: string = "EComm_ShipmentsNames";
    RiderNamesTable: string = "EComm_RiderNames";

    LoyaltyNamesTable: string = "PROC_LoyaltyNames";
    DeliveryChargesTable: string = "EComm_DeliveryCharges";

    ResourceUOMTable: string = "PROD_ResourceUOM";
    ResourceMasterTable: string = "PROD_ResourceMasterNames";
    WorkCenterTable: string = "PROD_WorkCenterNames";
    ResourceCostTable: string = "PROD_ResourceCost";
    BillOfMaterialTable: string = "PROD_BOMProductNames";
    TaskTypesTable: string = "HR_TaskTypes";

    Branches: string = "HR_Branches";
    Departments: string = "HR_Departments";
    Designations: string = "HR_Designations";
    Cities: string = "HR_City";
    Nationalities: string = "HR_Nationalities";
    Grades: string = "HR_Grades";
    PayGroups: string = "HR_PayGroups";
    Religions: string = "HR_Religions";
    Employees: string = "HR_EmployeeNames";
    Projects: string = "HR_ProjectNames";
    AssetTypes: string = "HR_AssetTypes";
    AssetsEntry: string = "HR_AssetsEntry";
    WeekendStructures: string = "HR_WeekendStructureNames";
    DeductionCategories: string = "HR_DeductionCategoryNames";
    LeaveTypes: string = "HR_LeavesType";
    EOSBReasons: string = "HR_EOSBReason";
    Areas: string = "HR_Area";
    LetterHead: string = "HR_LetterHead";
    ChannelPrices: string = "PROC_ChannelPrices";
    AnalyticGroups: string = "FA_AnalyticGroups";
    AnalyticTags: string = "FA_AnalyticTagsNames";
    AddOnGroup: string = "PROC_AddOnGroup";
    TaskStatus: string = "HR_TaskStatus";
}

export class CommonConstant {
    LoggedInCompanyID: string = "LoggedInCompanyID";
    FinancialManagerSig: string = "FinancialManagerSig";
    GeneralManagerSig: string = "GeneralManagerSig";
    AccountantSig: string = "AccountantSig";
    FinancialrefernceSig: string = "FinancialrefernceSig";
    CompanyName: string = "CompanyName";
    ZipCode: string = "ZipCode";
    MobileNo: string = "MobileNo";
    CompanyVatNumber: string = "ComponyVatNumbar";
    CompanyLogo: string = "ComponyLogo";
    CompanyStamp: string = "CompanyStamp";
    ShowCompanyLogo: string = "showCompanyLogo";
    CCRNumber: string = "CCRNumber";
    Address: string = "Address";
    Email: string = "Email";
    Fax: string = "Fax";
    ContactNumber: string = "";
    AmountFormat: string = "AmountFormat";
    DecimalsInAmount: string = "DecimalsInAmount";
    DecimalsInPrice: string = "DecimalsInPrice";
    DecimalsInQuantity: string = "DecimalsInQuantity";
    DefaultPrintStyle: string = "DefaultPrintStyle";
    UnPostedPOSInvoices: string = "UnPostedPOSInvoices";    
    UnPostedNotificationRead: string = "UnPostedNotificationRead";

    NewFundsTransferred: string = "NewFundsTransferred";
    IsShowNewFundsTransferred: string = "IsShowNewFundsTransferred";

    IsProductOnLowQuantity: string = "IsProductOnLowQuantity";
    ReportSignature: boolean = false;
    ReportHeadInformation: string = "reportHeadInformation";
    ReportParams: string = "reportParams";
    ReportStoreProcedure: string = "reportStoreProcedure";
    BackPagePath: string = "backPagePath";
    ReportName: string = "reportName";
    QueryParameters: string = "queryParams";
    HttpMethod: string = "httpMethod";
    CompanyEmail: string = "CompanyEmail";
    CompanyFax: string = "CompanyFax";
    CompanyAddress: string = "CompanyAddress";
    CompanyHeadNew: string = 'CompanyHeadNew';
    CompanyHeadParams: string = 'CompanyHeadParams';
    CompanyCCRNumber: string = "CompanyCCRNumber";
    CompanyContactNumber: string = "CompanyContactNumber";
    CompanyTermsConditions: string = "CompanyTermsConditions";
    CurrentFiscalSpanID: string = "CurrentFiscalSpanID";
    CurrentFiscalSpanDates: string = "CurrentFiscalSpanDates";
    UserRoleID: string = "userRoleID";
    UserName: string = "userName";
    DaysLeft: string = "DaysLeft";
    ShowLicenseAlert: string = "ShowLicenceAlert";
    CanAccessDashboard: string = "CanAccessDashboard";
    UserRoleName: string = "roleName";
    RoleOperationsPermission = "roleOperationsPermission";
    AllowedMenus = "allowedMenus";
    CompanyCurrencyCode: string = "CompanyCurrencyCode";
    CompanyCurrencySymbol: string = "CompanyCurrencySymbol";
    IsRestaurantClient: string = "IsRestaurantClient";
    AllowedToChangeDateOnPurchaseInvoice = "allowedToChangeDateOnPurchaseInvoice";
    DisplayLastCostPriceButton = "displayLastCostPriceButton";
    AllowedToChangeDateOnSalesInvoice = "allowedToChangeDateOnSalesInvoice";
    DisplayLastSalePriceButton = "displayLastSalePriceButton";
    DisplayCostPriceButton = "displayCostPriceButton";
    IsCostPriceDisplayOnStockTransfer = "isCostPriceDisplayOnStockTransfer";
    DisplayProductCreationButtonOnPurchase = "DisplayProductCreationButtonOnPurchase";
    DisplayProductCreationButtonOnSales = "DisplayProductCreationButtonOnSales";

    STRINGS_TRANSALTION = 'stringsTranslationObj';
    SESSION_TIME = 'SessionTime';
    SERVER_SESSION_TIME = 'ServerSessionTime';
    PrintStockInformation = "printStockInformation";
    SelectionType = "selectionType";   
    BankDetail = "bankDetail";
    IsEcommerceClient : string = "IsEcommerceClient";
    IsDemoClient : string = "IsDemoClient";
    ShowFiscalspanNote : string = "ShowFiscalspanNote";
    SalesBillTitleEn : string = "SalesBillTitleEn";
    GoodDeliveryNoteTitleEn : string = "GoodDeliveryNoteTitleEn";
    // Kitchen OR Customer Display
    Last_Order_Time_Customer:string = "LastOrderTimeCustomer";    
    Last_Order_Time_Kitchen:string = "LastOrderTimeKitchen";
    Last_Pending_Order_Time_kitchen:string = "LastPendingOrderTimeKitchen";
    Kitchen_Notifications_List:string = "KitchenNotificationsList";

    //BRIDGE SERVER
    IsBridgeServerRequired:string = "IsBridgeServerRequired";

    //Auto Projection
    IsAutoPurchaseOrder:string = "IsAutoPurchaseOrder";

    EnableSalePriceAddOnPurchaseBill = 'EnableSalePriceAddOnPurchaseBill';
    EnableBonusQuantity = 'EnableBonusQuantity';
}
// USING FOR AUTO NUMBER SETUP NAMES 
export const STARTING_NUMBER_SETUP_NAMES = {
    EXPENSE_ENTRY: 'HRExpenses',
    TRAVEL_TRAINING_EXPENSES: 'HRTravelExpenses',
    EXPLOYEE_CUSTODY: 'EmployeeCustody',
    FINANCE_EXPENSES: 'Expenses',
    DEDUCTIONS_CATEGORY: 'HRDeductionCategory',
    ALLOWANCE_CATEGORY: 'HRAllowanceCategory',
    RESOURCE_UOM: 'ResourceUOM',
    RESOURCE_COST: 'ResourceCost',
    BILL_OF_MATERIAL: 'BillOfMaterial',
    RESOURCE_MASTER: 'ResourceMaster',
    ROUTING: 'Routing',
    WORK_CENTER: 'WorkCenter',
    MATERIAL_DAMAGE: 'MaterialDamage',
    WASTE_MANAGEMENT: 'ScrapManagement',
    MATERIAL_RECEIPT: 'MaterialReceipt',
    MATERIAL_RECEIPT_REFUND: 'MaterialReceiptRefund',
    PRODUCTION_RECEIPT_REFUND: 'ProductionReceiptRefund',
    PRODUCTION_RECEIPT: 'ProductionReceipt',
    LOYALTY: 'Loyalty',
    EmployeeTask: 'Tasks',
    PROJECTS: 'Projects',
    PAYMENTMETHOD: 'PaymentMethod',
    DELIVERYCHARGES: 'DeliveryCharges',
    SHIPMENT: "Shipments",
    DISCOUNTANDPROMOTION: "DiscountAndPromotion",
    BANNERPROMOTION: "BannerPromotion"
}

export const ApplicationConstants = {
    IsAnalyticTagCodeRequired: 'AnalyticTagCodeIsRequired',
    IsAllowSaleOnPriceLessThanRetailPrice: 'AllowSaleOnPriceLessThanRetailPrice',
    IsAutoPurchaseOrder: 'IsAutoPurchaseOrder',

}