import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IEOSBRepository } from "./IEOSBRepository";
import { EOSB } from "../../../models/HR/EOSB/eosb.model";

@Injectable()
export class EOSBRepository extends GenericRepository<EOSB> implements IEOSBRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/EOSB', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: EOSB): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectEOSBByEmployee(employeeCode: string): Observable<ResponseModel> {
        return this.GetById('/SelectEOSBByEmployee/' + employeeCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    CalculationGratuity(employeeCode: string, eosbReasonCode: string, dateOfJoin: string, dateOfRetire: string, totalAmount: number): Observable<ResponseModel> {
        dateOfJoin = dateOfJoin.split('/').join('~');
        dateOfRetire = dateOfRetire.split('/').join('~');       
        return this.GetById('/CalculationGratuity/' + '/' + employeeCode + '/' + eosbReasonCode + '/' + dateOfJoin + '/' + dateOfRetire + '/' + totalAmount).pipe(map(this.extractData), catchError(this.handleError));
    }
}