import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { ILoyaltyTransactionRepository } from "./ILoyaltyTransactionRepository";
import { LoyaltyTransaction } from "src/app/models/LoyaltyManagement/loyalty-transaction.model";

@Injectable({ providedIn: 'root' })
export class LoyaltyTransactionRepository extends GenericRepository<any> implements ILoyaltyTransactionRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/LoyaltyTransaction', environment.urlAddress);
    }

    GetRec(code: number): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<ResponseModel> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }

    SaveRec(item: LoyaltyTransaction): Observable<ResponseModel> { 
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: number): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }
    
}