import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { IOrderTakerRepository } from "./IOrderTakerRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { ResSalesAgent } from "src/app/models/Restaurant/res-sales-agent.model";

@Injectable()
export class OrderTakerRepository extends GenericRepository<ResSalesAgent> implements IOrderTakerRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/OrderTaker', environment.urlAddress);
    }

    GetAll(skip: number, next: number, filterText: string): Observable<any> {

        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/OrderTaker/SelectAll' + '/' + skip + '/' + next+ '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    LoadRec(restaurantSalesAgentCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/OrderTaker/GetRec' + '/' + restaurantSalesAgentCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(restaurantSalesAgentCode: string) {
        return this._http.delete(this.url + 'api/OrderTaker/DeleteRec/' + restaurantSalesAgentCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: ResSalesAgent) {
        return this._http.post(this.url + 'api/OrderTaker/SaveRec', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
