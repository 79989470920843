import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { ITransactionViewerRepository } from "./ITransactionViewerRepository";
import { JournalVoucher } from "../../../models/Finance/JournalVoucher/journal-voucher.model";
import { TransactionSearchModel } from "src/app/models/common/transaction-search-model";


@Injectable()
export class TransactionViewerRepository extends GenericRepository<JournalVoucher> implements ITransactionViewerRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/transactionviewer', environment.urlAddress);
  }   
   
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectVoucherTypes(isHidden: boolean = true): Observable<any> {
        return this.GetById('/SelectVoucherTypes/' + isHidden);
    }

    SelectTransactionsOnLoad(): Observable<any> {
        return this.GetById('/SelectTransactionsOnLoad');
    }

    SelectTransactions(searchObj:TransactionSearchModel):Observable<ResponseModel>{
        return this._http.post(this.url + 'api/transactionviewer/SelectTransactions', searchObj, this.httpOptions)
        .pipe(map(this.extractData), catchError(this.handleError));
    }  

    SelectTransactionDetails(transactionNumber:number):Observable<any>{
        return this.GetById('SelectTransactionDetails/' + transactionNumber);
    }

    SelectTransactionListDetails(transactionNumbers:string):Observable<any>{
        return this.GetById('SelectTransactionListDetails/' + transactionNumbers);
    }

    SelectTransactionsByCommaSeparatedNumbers(transactionNumber:string):Observable<any>{
        return this.GetById('SelectTransactionsByCommaSeparatedNumbers/' + transactionNumber);
    }
    
}