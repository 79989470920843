import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { ChequesModel } from "src/app/models/Finance/Cheques/ChequeRegister/cheque.model";
import { IChequesProcessRepository } from "./IChequesProcessRepository";
import { ChequeSearchModel } from "src/app/models/Finance/Cheques/ChequeProcess/cheque-search.model";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class ChequesProcessRepository extends GenericRepository<any> implements IChequesProcessRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ChequeProcess', environment.urlAddress);
    }

    GetAllRec(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }

    
    searchProcessedCheques(searchKey: string, processType: number, chequeType: number, searchProcessed: boolean = true): Observable<any> {
        return this.GetById('/SearchProcessedCheques/' + searchKey + '/' + processType + '/' + chequeType + '/' + searchProcessed);
    }

    searchByDocumentNumber(docNumber:string,processType: string, searchProcessed: boolean = true): Observable<any> {
        return this.GetById('/SearchByDocumentNumber/' + docNumber + '/' + processType + '/' + searchProcessed);
    }

    SearchCheques(item: ChequeSearchModel): Observable<any> {
        return this.PostData('/SearchCheques', item);
    }

    SearchAllCheques(item: ChequeSearchModel): Observable<any> {
        return this.PostData('/SearchAllCheques', item);
    }
    ProcessCheques(item: Array<ChequesModel>) {
        return this.PostData('/ProcessCheques', item);
    }

}
