import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";

@Injectable({providedIn:'root'})
export class HrCommonRepository extends GenericRepository<ResponseModel>  {
 
  url: string = environment.urlAddress;
  constructor(protected http: HttpClient) {
      super(http, 'api/Common', environment.urlAddress);
    }

    selectDepartmentsByBranch(branchCodes: string): Observable<ResponseModel> {
        return this.GetById('/SelectDepartmentsByBranch/' + branchCodes).pipe(map(this.extractData), catchError(this.handleError));
    }
    selectDesignationsByDepartment(departmentCodes: string): Observable<ResponseModel> {
        return this.GetById('/SelectDesignationsByDepartment/' + departmentCodes).pipe(map(this.extractData), catchError(this.handleError));
    }
    areaSelectByCity(cityCode: string): Observable<ResponseModel> {
        return this.GetById('/AreaSelectByCity/' + cityCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    selectAllEmployees(employeeCodes: string): Observable<ResponseModel> {
        return this.GetById('/SelectAllEmployees/' + employeeCodes).pipe(map(this.extractData), catchError(this.handleError));
    }
    selectWeekendDays(): Observable<ResponseModel> {
        return this.GetById('/SelectWeekendDays/').pipe(map(this.extractData), catchError(this.handleError));
    }
    selectCategories(designationCode: string, departmentCode: string, paygroupCode: string): Observable<ResponseModel> {
        return this.GetById('SelectCategories/' + designationCode + '/' + departmentCode + '/' + paygroupCode)
    }
}