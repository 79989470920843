import { Component, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-payment',
  templateUrl: "./payment.component.html"

})
export class PaymentComponent implements AfterViewInit{
  DidChangeIcon: boolean = true;
  constructor() {

  }
  ngOnInit() {
  
  }
  ngAfterViewInit(): void {
    //$('.pipeline-header').click(function () {
    //  $(this).children('.os-dropdown-trigger').children('.os-icon').toggleClass(' os-icon-ui-22 os-icon-ui-23');
    //});
  }
}
