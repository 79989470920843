import { Injectable } from "@angular/core";

declare var $: any;

@Injectable()
export class TopToolBarOptions {
    isPrimaryKeyAuto?= true;
    screenType: any;
    // 1 is StandardForm, 2 is GridForm, 3 is UpdateOnly like setups, 4 is master screens with levels like account master
    loggingEnabled: boolean = true;
    formContainer: any = $('.content-i');             // form container element added.

    //navFuncPath: string = "";                       // navigation function path will be called when we click on navigation buttons.
    navParentContainer?: any = $(".ActionContainer");

    enableCB: Function = null;                      // will enable disable fields

    preAddCB?: Function = null;                       /*might needed eg. should u add or not. Must return true or false*/
    postAddCB?: Function = null;                      /*for specifying the save type*/

    preUpdateCB?: Function = null;                    /*might needed eg. should u update or not. Must return true or false*/
    postUpdateCB?: Function = null;                   /*for specifying the save type*/

    preDeleteCB?: Function = null;                    /*might needed eg. should u delete or not. Must return true or false*/
    deleteCB?: Function = null;
    postDeleteCB?: Function = null;                   /*eg Refresing UI*/

    preSaveCB?: Function = null;                      /*For validations. Must return true or false*/
    saveAddUpdateCB?: Function = null;                /*called when recording is updating or inserting*/
    postSaveCB?: Function = null;                      /*Any UI refresh*/

    loadNavRec?: Function = null;
    postNavLoad?: Function = null;

    loadCB?: Function = null;
    postDataLoadCB?: Function = null;

    preViewCB?: Function = null;
    postViewCB?: Function = null;
    cancelLoadCB?: Function = null;
    cancelAddUpdateCB?: Function = null;

    getEmailAddress?:Function = null; // return email to show in modal
    getEmailAddressFromDB?:Function = null; // use this to get email from Database
    sendEmailCB?:Function = null; // this will send email .
    
    printPath?: string = '';                         // txtPrimaryKey must have the recordNumber to be printed
    printRecCB?: Function = null;
    printPageID?: number;
    isAutoPrint?: boolean = false;
}

