import { Component, ElementRef, OnDestroy } from "@angular/core";
import { IHeaderParams, IAfterGuiAttachedParams } from "ag-grid-community";
import { IHeaderAngularComp } from "ag-grid-angular";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-add-new-button-sales-target',
  templateUrl: './add-new-button-sales-target.component.html'  
})
export class AddNewButtonSalesTargetComponent implements OnDestroy, IHeaderAngularComp {
  
  addNewAllowed:boolean=true;
  public params: any;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef,private route: ActivatedRoute) {
    this.elementRef = elementRef;
    route.data.subscribe(data => {
      this.setOperationsPermission(data["PageID"]);
   })
  }
    refresh(params: IHeaderParams): boolean {
        throw new Error("Method not implemented.");
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error("Method not implemented.");
    }

  agInit(params: any): void {
    this.params = params;
  }

  ngOnDestroy() {
    console.log(`Destroying HeaderComponent`);
  }

  onAddClick($event) {
    if (this.params.onAddClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        params: this.params
        //rowData: this.params.node.data
        // ...something
      }
      this.params.onAddClick(params);

    }
  }
  private setOperationsPermission(pageID):void{

    if(!pageID) return ;

   let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission')|| '[]')
   if(rolepermissionObject.length > 0){
    let permissions = rolepermissionObject.filter(x=>x.PageID == pageID)
    if(permissions.length > 0){
        // console.log(permissions)
        if(permissions[0].PermissionObj && permissions[0].PermissionObj.length > 0)
        {
            let addNeweObj = permissions[0].PermissionObj.filter(x=>x.OperationID == pageID+'01');
            if(addNeweObj && addNeweObj.length > 0)
              this.addNewAllowed = addNeweObj[0].IsAllowed;
              else
              this.addNewAllowed = true;          
          
        }
        else{
            console.log("Operation not found");
            console.log(permissions);
        }
    }
    else{
        console.log(pageID +" : id not found" )
    }
   }
   

}

  invokeSaveMethod() {
    var newRecord = { SalesAgentTargetID: this.params.ID };
    var res = this.params.api.updateRowData({ add: [newRecord] });
    var count = this.params.api.getDisplayedRowCount();    
    this.params.api.setFocusedCell(count - 1, this.params.colKey);
    this.params.api.startEditingCell({
      rowIndex: count - 1,
      colKey: this.params.colKey
    });
  };
}
