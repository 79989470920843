import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-save-button',
  templateUrl: './grid-save-button.component.html'  
})
export class GridSaveButtonComponent implements ICellRendererAngularComp {
  public params: any;  
  public refresh(params: any): boolean {
    return false;
  }
  constructor() {

  }
  agInit(params: any): void {
    this.params = params;
  }

  onSaveClick($event) {
    if (this.params.onSaveClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        params: this.params,
        rowData: this.params.node.data,
        IsEditable:true
        // ...something
      }
      
      this.params.onSaveClick(params);

    }
  }

  onCancelClick($event) {
    if (this.params.onCancelClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowIndex: this.params.node.rowIndex,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onCancelClick(params);
    }
  }

}
