/*
 * added by Yameen 19-12-2019
 * For sharing common directives, pipes
 * import the created PipesModule module into the modules where your pipe is going to be used, by adding it to the imports array
 */ 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumericPipe } from './numeric.pipe';
import { NumericSanitizerPipe } from './numeric-sanitizer.pipe';
import { NumericService } from '../services/numeric/numeric-service';
import { IntegerInputDirective } from '../directives/integer-input.directive';
import { NumericInputDirective } from '../directives/numeric-input.directive';
import { AmountInputDirective } from '../directives/amount-input.directive';
import { PriceInputDirective } from '../directives/price-input.directive';
import { QuantityInputDirective } from '../directives/quantity-input.directive';
import { CurrencyInputDirective } from '../directives/currency-input.directive';
import { ResizeColumnDirective } from '../directives/resize-column.directive';
import { TaxInputDirective } from '../directives/tax-input.directive';
import { NullableNumericPipe } from './nullable-numeric.pipe';
import { NullableNumericSanitizerPipe } from './nullable-numeric-sanitizer.pipe';
import { NullableQuantityInputDirective } from '../directives/nullable-quantity-input.directive';
import { VarDirective } from '../directives/ng-var.directive';
import { NullableAmountInputDirective } from '../directives/nullable-amount-input.directive';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { StyleFormatPipe } from './style-format.pipe';
import { CounterFomaterPipe } from './counter-fomater.pipe';
import { NoSpecialCharacterInputDirective } from '../directives/no-special-charecter-input.directive';
import { PhoneNumberValidatorDirective } from '../directives/phone-number.directive';
import { EmailValidatorDirective } from '../directives/email-input.directive';
import { DebitCreditPipe } from './debit-credit.pipe';
@NgModule({
    imports: [
        CommonModule,        
    ],
    declarations: [NumericPipe, NumericSanitizerPipe, NullableNumericPipe, NullableNumericSanitizerPipe, IntegerInputDirective, NumericInputDirective, AmountInputDirective, PriceInputDirective, QuantityInputDirective, CurrencyInputDirective, ResizeColumnDirective, TaxInputDirective, NullableQuantityInputDirective, VarDirective, NoSpecialCharacterInputDirective,PhoneNumberValidatorDirective,EmailValidatorDirective,NullableAmountInputDirective, NumberToWordsPipe, StyleFormatPipe, CounterFomaterPipe, DebitCreditPipe],
    exports: [NumericPipe, NumericSanitizerPipe, NullableNumericPipe, NullableNumericSanitizerPipe, IntegerInputDirective, NumericInputDirective, AmountInputDirective, PriceInputDirective, QuantityInputDirective, CurrencyInputDirective, ResizeColumnDirective, TaxInputDirective, NullableQuantityInputDirective, VarDirective,NoSpecialCharacterInputDirective,PhoneNumberValidatorDirective,EmailValidatorDirective, NullableAmountInputDirective, NumberToWordsPipe, StyleFormatPipe, CounterFomaterPipe,DebitCreditPipe],
    providers: [NumericService, NumericPipe, NumericSanitizerPipe, NullableNumericPipe, NullableNumericSanitizerPipe, NumberToWordsPipe, StyleFormatPipe,DebitCreditPipe]
})
export class PipesModule { }
