
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Login } from "../../models/Login/Login.model";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { RolesModel } from "src/app/models/Roles/roles.model";
import { Observable } from "rxjs";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { IRolesRepository } from "./IRolesRepository";

@Injectable(
  {
    providedIn: "root"
  }
)

export class RolesRepository extends GenericRepository<RolesModel> implements IRolesRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/Role', environment.urlAddress);
  }

  SaveRec(item: RolesModel): Observable<ResponseModel> {
    return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
  }

  LoadRoles(): Observable<ResponseModel> {
    return this.GetById('/SelectAll/').pipe(map(this.extractData), catchError(this.handleError));
  }

  DeleteRec(code: string): Observable<ResponseModel> {
    return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
  }
}
