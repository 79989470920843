import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IProjectsRepository } from "./IProjectsRepository";
import { Projects } from "src/app/models/HR/Projects/projects";

@Injectable()
export class ProjectsRepository extends GenericRepository<any> implements IProjectsRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Projects', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText)
    }
  
    SaveRec(item: Projects): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('NavRec/' + navType + '/' + currentIndex);
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('GetRec/' + code);
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code);
    }
    
}