import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { ToastrService } from 'ngx-toastr';
import { UploadDocumentModel } from '../../models/common/document-upload.model';
import { LoaderService } from '../../services/loader/LoaderService';
declare var $: any;

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html'
})
export class UploadDocumentComponent implements OnInit {

  private _documentsList = new Array<UploadDocumentModel>();

  get documentsList(): Array<UploadDocumentModel> {
      return this._documentsList;
  }

  set documentsList(obj: Array<UploadDocumentModel>) {
    this._documentsList = obj ? obj : []
    }
    public numberOfDocs: number = 2;
    public pdfFileAllowed: boolean= true;

    public allowImage: boolean = true;

  
    constructor(private loaderService: LoaderService, private translate: TranslatePipe, private show: ToastrService) {

    }

  ngOnInit() {
      
  }
  // Image Upload Events -- Start

  handleImageInput(file: FileList) {

    // this.fileToUpload.push(file.item(0));
    this.loaderService.display(true);
    if (!this.validateFile(file.item(0))) {
        this.loaderService.display(false);
      return false;
    }
    
    let media = new UploadDocumentModel();
    media.ContentType = file.item(0).type;
    media.MediaName = file.item(0).name;
    media.IsSavedImage = false;
    media.MediaIndex = this.documentsList.length + 1;

    this.documentsList.push(media);
    // console.log(this.documentsList);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      if (event.target.result) {
        this.documentsList[this.documentsList.length - 1].MediaContent = event.target.result;
        this.loaderService.display(false);
      }
    }
    reader.readAsDataURL(file.item(0));
  }

  deleteImage(imageId: number) {

    this.documentsList = this.documentsList.filter(x => x.MediaIndex !== imageId);
  }
  downloadPdf(fileIndex: number) {
    
    let file = this.documentsList.find(x => x.MediaIndex == fileIndex);
    const linkSource = file.MediaContent;
    const downloadLink = document.createElement("a");
    const fileName = file.MediaName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    }

  validateFile(file: File) {

      if ((file.type.toLowerCase().includes('image')   && this.allowImage) || (file.type.toLowerCase() == 'application/pdf' && this.pdfFileAllowed)) {

      if ((file.type.toLowerCase() == 'application/pdf' && file.size / 1024) > 500) {
        this.show.info(this.translate.transform('2339'));
        return false;
      }

      if ((file.type.toLowerCase().includes('image') && file.size / 1024) > 200) {
        this.show.info(this.translate.transform('2343'));
        return false;
      }
      return true;
    }

    this.show.error(this.translate.transform('2342'));
    return false;
  }

  // Image Upload Events -- End 
}


