import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ag-grid-user-action-buttons',
    templateUrl: './ag-grid-user-action-buttons.component.html'
})
export class AgGridUserActionButtonsComponent implements ICellRendererAngularComp {

    params;
    label: string;
    propertyName: string;
    graphAccess: boolean;

    updateAllowed:boolean=true;
    changePassAllowed:boolean=true;
    deleteAllowed:boolean=true;
  
    constructor(route:ActivatedRoute) { 

        route.data.subscribe(data => {
         this.setOperationAccess(data["PageID"])});
        
    }

    private setOperationAccess(pageID):void{

        if(!pageID) return ;
    
       let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission')|| '[]')
       if(rolepermissionObject.length > 0){
        let permissions = rolepermissionObject.filter(x=>x.PageID == pageID)
        if(permissions.length > 0){
            console.log(permissions)
            if(permissions[0].PermissionObj && permissions[0].PermissionObj.length > 0)
            {
                let addNeweObj = permissions[0].PermissionObj.filter(x=>x.OperationID == pageID+'10');
                if(addNeweObj && addNeweObj.length > 0)
                  this.changePassAllowed = addNeweObj[0].IsAllowed;
                  else
                  this.changePassAllowed = true;
                
                let updateObj = permissions[0].PermissionObj.filter(x=>x.OperationID == pageID+'02');
                if(updateObj && updateObj.length > 0)
                this.updateAllowed = updateObj[0].IsAllowed;
                else
                this.updateAllowed = true;
    
                let deleteObj = permissions[0].PermissionObj.filter(x=>x.OperationID == pageID+'03');
                if(deleteObj && deleteObj.length > 0)
                  this.deleteAllowed = deleteObj[0].IsAllowed;
                  else
                  this.deleteAllowed = true;
            }
            else{
                console.log("Operation not found");
                console.log(permissions);
            }
        }
        else{
            console.log(pageID +" : id not found" )
        }
       }
       
    
    }
    

    public refresh(params: any): boolean {
        return false;
    }

    agInit(params: any): void {

        this.params = params;
        this.label = this.params.label || null;
        this.propertyName = this.params.colDef.field;
        this.graphAccess = params.data.DashboardAccess;        
    }
   
    onDeleteButtonClick($event) {
        if (this.params.onDeleteButtonClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data
                // ...something
            }
            this.params.onDeleteButtonClick(params);
        }
    }

    onEditButtonClick($event) {
        if (this.params.onEditButtonClick instanceof Function) {            
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,                
            }
            this.params.IsEditable = true;
            this.params.onEditButtonClick(params);
        }
    }

    onChangePasswordButtonClick($event) {

        if (this.params.onChangePasswordButtonClick instanceof Function) {            
            const params = {
                event: $event,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data                
            }
            this.params.onChangePasswordButtonClick(params);
        }
    }


    onNotificationButtonClick($event) {

        if (this.params.onNotificationButtonClick instanceof Function) {            
            const params = {
                event: $event,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data
            }
            this.params.onNotificationButtonClick(params);
        }
    }

    onGraphButtonClick($event) {

        if (this.params.onGraphButtonClick instanceof Function) {            
            const params = {
                event: $event,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data
            }
            this.params.onGraphButtonClick(params);
        }
    }
}
