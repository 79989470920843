/*
 * added by Yameen 19-12-2019
 * For integer input
 */
import { Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[integerInput]',
    host: {
        '(keydown)': 'onKeyDown($event)',
        '(change)': 'onChange($event)'
    },
})
export class IntegerInputDirective {

    /**
     *
     */
    constructor(private elementRef: ElementRef) {        
        
    }

    onKeyDown = (event) => {
        let e = <KeyboardEvent>event;
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            //(e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();

        }

        if(this.elementRef.nativeElement.max && Number(this.elementRef.nativeElement.max) > 0 &&  Number(this.elementRef.nativeElement.value) > Number(this.elementRef.nativeElement.max)){
            this.elementRef.nativeElement.value = 1000;
        }        
    }

    onChange(){
        if(!Number(this.elementRef.nativeElement.value)){
            this.elementRef.nativeElement.value = 0;
        }

        if(this.elementRef.nativeElement.max && Number(this.elementRef.nativeElement.max) > 0 &&  Number(this.elementRef.nativeElement.value) > Number(this.elementRef.nativeElement.max)){
            this.elementRef.nativeElement.value = 1000;
        }  
    }

}
