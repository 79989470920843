import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ApplicationCodes } from '../../constent/applicationcodes';
import { SmartAppUtilities } from '../../../models/common/getjsonfrom-controls-service-model';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/environments/Globals';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/ngx-bootstrap-typeahead';
declare var $: any;
@Component({
    selector: 'app-address',
    templateUrl: "./address.component.html"
})
export class AddressComponent implements AfterViewInit {

    applicationCodes = new ApplicationCodes();
    CountryKeyword = 'Name';
    @Input()
    type: string;
    @Input()
    code: string = "";
    @Input()
    Countries: any = [];
    @Input()
    user: string;
    AddressDivId: string;    
    CountryCodeValue: string;
    CountryCode:string;
    collapse: string = "closed";
    @Output() emitAddressType = new EventEmitter<string>();
    @Output() emitValue: EventEmitter<string> = new EventEmitter<string>();
    smartUtilities = new SmartAppUtilities(this.toastrService);
    constructor(private toastrService: ToastrService, private globals: Globals) {
    }
    ngOnInit() {
        this.AddressDivId = this.type;
        this.globals.ClearaddressFields();
    }
    GetAddressTypeValue(type: string) 
    {
        if (type === "Home")
            document.getElementById('IsHomeAddressRecordInView').setAttribute('value', 'true');
        if (type === "Work")
            document.getElementById('IsWorkAddressRecordInView').setAttribute('value', 'true');

        if (this.code != null && this.code != undefined) {
            if (type === "Home" && localStorage.getItem("HomeAddress") == undefined && localStorage.getItem("HomeAddress") == null) {
                this.emitAddressType.emit(type);
              
            }
            else if (type === "Work" && localStorage.getItem("WorkAddress") == undefined && localStorage.getItem("WorkAddress") == null) {
                this.emitAddressType.emit(type);
                
            }
        }
    }

    ngAfterViewInit(): void {
    }
    onAccountCodeSelect(event: TypeaheadMatch) {
        this.emitValue.emit(event.item.code);
    }
    onCountrySelectBlur(event) {
        var selectedItem = this.Countries.find(x => x.name == event.target.value);
        if (selectedItem == null) {
            $("#" + event.target.id).val("");
        }
    }
}
