import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { SalesRegion } from "src/app/models/SalesRegion/sales-region.model";
import { ISalesRegionRepository } from "./ISalesRegionRepository";

@Injectable()
export class SalesRegionRepository extends GenericRepository<SalesRegion> implements ISalesRegionRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/salesregion', environment.urlAddress);
  }
  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
    .get(this.url + 'api/salesregion/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(code: string): Observable<any> {
    return this._http
      .delete(this.url + 'api/salesregion/DeleteRec' + '/' + code, this.httpOptions)
      .pipe(map(this.extractData), catchError(this.handleError));
  }
}
