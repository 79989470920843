import { GenericRepository } from "../generic-repository/GenericRepository";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IZIDRepository } from "./IZIDRepository";
import { Zid } from "../../models/Zid/zid.model";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { map, catchError } from "rxjs/operators";
import { CompanyProfile } from "src/app/models/CompanyProfile/company-profile.model";

@Injectable()
export class ZIDRepository extends GenericRepository<Zid> implements IZIDRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Zid/', environment.urlAddress);
    }

    SyncProducts(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncProducts', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

    SyncCategories(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncCategories', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

    SyncStores(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncStores', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

    SyncUOM(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncUOM', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

    SyncOrder(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncOrder', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

    SyncBuyers(zid: Zid): Observable<ResponseModel> {
        return this.PostData('SyncBuyers', zid).pipe(map(this.extractData), catchError(this.handleError));
    }

}