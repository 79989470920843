import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { HandStyle } from "../../models/HandStyle/handstyle.model";
import { IHandStyleRepository } from "./IHandStyleRepository";

@Injectable()
export class HandStyleRepository extends GenericRepository<HandStyle> implements IHandStyleRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/HandStyle', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/HandStyle/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(HandStyleCode: string) {
        return this._http.delete(this.url + 'api/HandStyle/DeleteRec/' + HandStyleCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveStyleNames(item: HandStyle) {
        return this._http.post(this.url + 'api/HandStyle/SaveStyleNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
