import { ProductCardVarientsModel } from "./product-card-varients.model";

export class ProductCardMaster {
    NamesString:string;    
    IsParent:boolean;    
    ProductCardDetails:ProductCardDetail;
    ProductCardSalesData:ProductCardSalesData;
    ProductCardPurchaseData:ProductCardPurchaseData;
    ProductCardEquivalentProducts:Array<ProductCardEquivalentProducts>;
    ProductCardMedia:Array<ProductCardMedia>;
    ProductCardUnits:Array<ProductCardUnits>;
    ProductIngredients:Array<ProductIngredients>;
    IsInserting: boolean;
    IsUnitsDataRetrieved: boolean;
    IsEqProductsDataRetrieved: boolean;
    IsMediaDataRetrieved: boolean;
    IsNorcotic: boolean;
    IsShort: boolean;
    ProductCompanyCode: string;
    // AlertOnQuantity: number;
    constructor() {
    }
}


export class ProductCardDetail{    
    ProductCode:string;    
    ProductType:number;
    Description:string;
    IsStockMaintained:boolean;
    IsAutoNumbering:boolean;
    IsWarrantyRequired:boolean;
    IsBatchRequired:boolean;
    IsManufactureDateRequired:boolean;
    IsExpiryDateRequired:boolean;
    IsSerialNumberRequired:boolean;
    DoNotApplyDiscount:boolean;
    IsVarient:boolean;    
    IsMeasurementRequired:boolean;
    MeasurementType:number;
    ExpiryType:number;
    UOMGroupCode:string;   
    ProductCategory:string; 

    ProductAvailability: number;  
    EstimatedDays: number;  
    StockInQuantity: number;
    StockOutQuantity: number;

    LocationCode: string;
    ProductCompanyCode: string;

}
export class ProductIngredients {
    ProductCode: boolean;    
    ProductBarCode: string;
    CategoryCode: string;
    CategoryIngredientCode: number;   
}

export class ProductCardUnits{
    RowNumber:number;
    ProductCode:string;
    ProductBarCode:string;
    UnitNumber:number;
    UOMCode: string;
    AddonGroupCode: string;
    UnitFragment:number;
    UnitWeight:number;
    SalesPrice:number;
    AverageCostPrice: number;
    WholeSalePrice: number;
    DistributorPrice: number;
    ProductCardUnitImages: Array<ProductCardUnitImages>;
}
export class ProductCardSalesData{
    ProductCode:string;
    SalePrice:number;
    TaxGroupCode:string;
    HoldFromSale:boolean;
    DisableDiscountGroups: boolean;
    CanNotReturn: boolean;
}
export class ProductCardPurchaseData{
    ProductCode:string;
    CostPrice:number;
    AverageCostPrice:number;
    TaxGroupCode:string;
    VendorCode:string;
    HoldFromPurchase:boolean;
}
export class ProductCardEquivalentProducts{
    RowNumber:number;
    EquiProductCode: string;
    Name: string;
    Description:string;
}

export class ProductCardMedia{    
    MediaNo:number;
    MediaName:string
    MediaContentType:string
    Description:string;
    MediaGuid:string;
    MediaContent:string;    
    IsSavedImage:boolean;
}

export class ProductCardSalesTax {    
    TaxGroupCode: string;
    ProductCodes: string;
}

export class ProductCategories {
    ProductCategory: string;
    ProductType: number;
    ProductSearch: boolean;
    CallFromDiscountPermotion:boolean;
}

export class ProductCardUnitImages {
    MediaNo: number;
    ProductCode: string;
    ProductBarCode: string;
    UOMCode: string;
    Image: string;
    Type: string;
    IsSavedImage: boolean;
}


export class ProductImport {
    Srno: number;
    ProductCode: string;
    ProductNameinEnglish: string;
    ProductNameinArabic: string;
    ProductDescription: string;
    ProductUnitType: string;
    ProductUnitFragment: number;
    ProductCategory: string;
    ProductCostPrice: number;
    ProductSalePrice: number;
    ProductPurchaseTax: string;
    ProductSaleTax: string;
    ProductBarcode: string;
    ProductType: number = 1;
    IsAutoCodeEntry: boolean = false;
    IsAutoBarcodeEntry: boolean = false;
    UOMName: string;
    WholeSalePrice: number;
    ProductCompany: string;
    ProductCompanyCode: string;
    ProductVendor: string;
    EnableBatch: boolean = false;
    EnableManufacturing: boolean = false;
    EnableExpiry: boolean = false;
    EnableDimensionType: boolean = false;
    DimensionType: string;
    IsNorcotic: boolean = false;

}


export class ProductCardNames{
    ProductCode: string;
    Name: string;
    UName: string;
} 
export class ProductBarcodes{
    ProductCardBarCode: string;
    ProductCode: string;
    UnitType: number;
} 
export class ProductsImport{
    ProductCardDetail = new Array<ProductCardDetail>();
    ProductName = new Array<ProductCardNames>();
    ProductCardUnits = new Array<ProductCardUnits>();
    ProductBarcodes = new Array<ProductBarcodes>();
    ProductCardPurchaseData = new Array<ProductCardPurchaseData>();
    ProductCardSalesData = new Array<ProductCardSalesData>();
}
// used for variation
export class ProductCardMasterModel {
    ProductCard: ProductCardMaster
    ProductCardVarients: Array<ProductCardVarientsModel>
    
    constructor() {
        this.ProductCard = new ProductCardMaster();
        this.ProductCardVarients = new Array<ProductCardVarientsModel>();
        
    }
}

export class ProductsDetail{
  ProductCode: number;
  ProductType: string;
  ProductName: string;
  UOM: String;
  CostPrice: number;
  SalesPrice: number;
  WholeSalePrice: number;
  TotalStock: number;
  Incoming: number;
  Outgoing:Number
}