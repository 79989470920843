import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessServices } from "../../../services/singleton/business-services";
import { Login } from "../../../models/Login/Login.model";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../authentication-helpers/auth.service";
import { LoaderService } from "src/app/services/loader/LoaderService";
import { Globals } from "src/environments/Globals";
import { formatDate } from '@angular/common';
import { ApplicationConstants, CommonConstant } from "../../../shared/constent/applicationcodes";
import { TranslateServiceLocal } from "src/app/services/translateservice/translate.service.local";
import { UserModel } from "src/app/models/ErpUser/user-model";
declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    commonconstant = new CommonConstant();
    login = new Login();
    currentDate: string = "";
    counter: number = 0;
    showMainContent: Boolean = true;
    nameForget: string = "";
    mobile: string = "";
    isUserAuthenticated: boolean = false;
    selectionType:string = '1';
    email:string = '';   
    postEmailFix:string = '';
    phone:string = '';
    users: UserModel = new UserModel();
    inputEmail:string = '';
    inputPhone:string = '';
    chkKeepSignedOn: boolean = false;
    isVisible: boolean = false;
    hidePassword: boolean = true;

    constructor(private loaderService: LoaderService, private transService: TranslateServiceLocal, private route: Router, private loginBusinessServices: BusinessServices, private activatedRoute: ActivatedRoute,
        private touster: ToastrService, private authService: AuthService, private globals: Globals) {
        this.currentDate = formatDate(new Date(), 'dd/MM/yyyy HH:mm', 'en');
        
    }
    ngOnInit() {   
        // $('.loginslider').slick({ 
        //     dots: false,
        //     arrows: false,
        //     autoplay: true, 
        //     infinite: true,  
        //     speed: 300,
        //     slidesToShow: 3, 
        //     slidesToScroll: 1,
        //     rtl: true,  
        //     responsive: [ 
        //         {
        //             breakpoint: 1440,
        //             settings: {
        //                 slidesToShow: 3,  
        //                 slidesToScroll: 1,
        //             }
        //         },
        //         {
        //             breakpoint: 1199,
        //             settings: {
        //                 slidesToShow: 2, 
        //                 slidesToScroll: 1,  
        //             }
        //         },
        //         {
        //             breakpoint: 600,
        //             settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1
        //             }
        //         }
        //     ]
        // });

        // // animate login button
        // setTimeout(function(){     
        //     document.getElementById('elementToMove').style.bottom = '15px';  
        // }, 1000); 

        if (localStorage.getItem('IsLoggingOut') === "true") {
            this.touster.error("Session has been expired");
            this.authService.logout();
        }
        $("body").addClass("loginbody");

        $("html").removeAttr("dir");
        //const username: string = this.activatedRoute.snapshot.queryParams.userName;
        //const password: string = this.activatedRoute.snapshot.queryParams.password;
        //if (username && password) {
        //    this.counter = 1;
        //    this.onSubmit(username, password);
        //}

        let userName = this.GetCookie("bnodyName");
        let passWord = this.GetCookie("bnodyPassword");
        if (userName && passWord) {
            this.onSubmit(userName, passWord);
        }

        //document.getElementById('currentDate1').innerHTML = formatDate(new Date(), 'yyyy', 'en');
        
    }
    ShowHideButton() {
        this.showMainContent = !this.showMainContent;
    }

    dispalyGrid() {

        if (this.isUserAuthenticated)
            {
                this.isUserAuthenticated = false;
                this.inputEmail = ''
                this.inputPhone = ''
            }
        else
            {
                this.showMainContent = true;
                this.nameForget = ''
            }

    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    onSubmit(userName: string, password: string) {
        this.loaderService.display(true);
        this.login.Username = userName;
        this.login.Password = password;
        try {
            this.loginBusinessServices.loginService.Authentication(this.login).subscribe((data: any) => {
                this.loaderService.display(false);
                if (data && data._obj) {
                    if (data._statusCode === 200) {
                        if (data._obj) {
                            if (window.location.hostname !== data._obj.CompanyDomain.toLocaleLowerCase() && window.location.hostname !== 'localhost') {
                                this.touster.error("Username or Password is wrong.");
                                //this.route.ngOnDestroy();
                                //this.loaderService.display(false);
                                //window.location.href = ('http://' + data._obj.CompanyDomain + '/Login?userName=' + userName + '&password=' + password + '');
                                return;
                            }

                        }
                        //this.translateService.use(data._obj.UserInformation.CultureCode);
                        // this.globals.SetCultureCode(data._obj.UserInformation.CultureCode);
                        this.globals.resetSessionTimeOut();
                        this.globals.resetServerSessionTimeOut();
                        this.globals.SetCultureCode(data._obj.CompanyInformation.CultureCode);
                        this.transService.setStringData(data._obj.AppStrings);
                        this.touster.success(data._message);
                        localStorage.setItem('isLoggedIn', "true");
                        localStorage.setItem('token', data._obj.LoginTicket);
                        this.globals.setDataInLocalStorage(this.commonconstant.LoggedInCompanyID, data._obj.LoggedInCompanyID);
                        // this.translateService.use(data._obj.UserInformation.CultureCode)                                                 
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyLogo,
                            data._obj.CompanyInformation.MediaContentType +
                            "," +
                            data._obj.CompanyInformation.MediaContents);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyStamp,
                            data._obj.CompanyInformation.StampMediaContentType +
                            "," +
                            data._obj.CompanyInformation.StampMediaContents);
                            
                        this.globals.setDataInLocalStorage(this.commonconstant.UnPostedPOSInvoices, data._obj.UnPostedInvoices);

                        this.globals.setDataInLocalStorage(this.commonconstant.UnPostedNotificationRead, Number(data._obj.UnPostedInvoices) == 0);
                        
                        this.globals.setDataInLocalStorage(this.commonconstant.NewFundsTransferred, data._obj.NewFundsTransferred);
                        this.globals.setDataInLocalStorage(this.commonconstant.IsShowNewFundsTransferred, data._obj.IsShowNewFundsTransferred);
                        
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyEmail, data._obj.CompanyInformation.Email);
                        this.globals.setDataInLocalStorage(this.commonconstant.ContactNumber, data._obj.CompanyInformation.ContactNumber);
                        this.globals.setDataInLocalStorage(this.commonconstant.AmountFormat, data._obj.CompanyInformation.AmountFormat);

                        this.globals.setDataInLocalStorage(this.commonconstant.DefaultPrintStyle, data._obj.CompanyInformation.DefaultPrintStyle);

                        this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInAmount, data._obj.CompanyInformation.DecimalsInAmount);
                        this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInPrice, data._obj.CompanyInformation.DecimalsInPrice);
                        this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInQuantity, data._obj.CompanyInformation.DecimalsInQuantity);


                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyFax, data._obj.CompanyInformation.Fax);
                        this.globals.setDataInLocalStorage(this.commonconstant.MobileNo, data._obj.CompanyInformation.MobileNo);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyCCRNumber, data._obj.CompanyInformation.CCRNumber);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyAddress, data._obj.CompanyInformation.Address);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyName, data._obj.CompanyInformation.CompanyName);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyVatNumber, data._obj.CompanyInformation.VATNumber);
                        this.globals.setDataInLocalStorage(this.commonconstant.ZipCode, data._obj.CompanyInformation.ZipCode);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyCurrencyCode, data._obj.CompanyInformation.DefaultCurrency);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyCurrencySymbol, data._obj.CompanyInformation.CurrencySymbol);
                        this.globals.setDataInLocalStorage(this.commonconstant.CompanyTermsConditions, data._obj.CompanyInformation.TermsAndConditions);
                        
                        this.globals.setDataInLocalStorage(this.commonconstant.SelectionType, data._obj.CompanyInformation.SelectionType)
                        this.globals.setDataInLocalStorage(this.commonconstant.DaysLeft, data._obj.CompanyInformation.DaysLeft);
                        this.globals.setDataInLocalStorage(this.commonconstant.ShowLicenseAlert, true);

                        this.globals.setDataInLocalStorage(this.commonconstant.CurrentFiscalSpanID, data._obj.CompanyInformation.FiscalSpanID);
                        this.globals.setDataInLocalStorage(this.commonconstant.CurrentFiscalSpanDates, data._obj.CompanyInformation.FiscalYearStartDate + " - "
                            + data._obj.CompanyInformation.FiscalYearEndDate);

                        this.globals.setDataInLocalStorage(this.commonconstant.UserRoleID, data._obj.UserInformation.RoleID);
                        this.globals.setDataInLocalStorage(this.commonconstant.UserRoleName, data._obj.UserInformation.RoleName);
                        this.globals.setDataInLocalStorage(this.commonconstant.UserName, data._obj.UserInformation.FullName);
                        this.globals.setDataInLocalStorage(this.commonconstant.CanAccessDashboard, data._obj.UserInformation.CanAccessDashboard);
                        this.globals.setDataInLocalStorage(this.commonconstant.PrintStockInformation, data._obj.CompanyInformation.PrintStockInformation);
                        this.globals.setDataInLocalStorage(this.commonconstant.BankDetail, data._obj.CompanyInformation.BankDetail);                        
                        this.globals.setDataInLocalStorage(this.commonconstant.IsEcommerceClient, data._obj.IsEcommerceClient);                        
                        this.globals.setDataInLocalStorage(this.commonconstant.IsRestaurantClient, data._obj.ProductIDs.includes('13'));
                        this.globals.setDataInLocalStorage(this.commonconstant.IsDemoClient, data._obj.IsDemoClient);
                        this.globals.setDataInLocalStorage(this.commonconstant.IsBridgeServerRequired, data._obj.CompanyInformation.IsBridgeServerRequired); 

                        // this.globals.setDataInLocalStorage(this.commonconstant.ShowFiscalspanNote, true);

                        //user settings
                        this.globals.setDataInLocalStorage(this.commonconstant.AllowedToChangeDateOnPurchaseInvoice, data._obj.UserInformation.AllowedToChangeDateOnPurchaseInvoice);
                        this.globals.setDataInLocalStorage(this.commonconstant.DisplayLastCostPriceButton, data._obj.UserInformation.DisplayLastCostPriceButton);
                        this.globals.setDataInLocalStorage(this.commonconstant.AllowedToChangeDateOnSalesInvoice, data._obj.UserInformation.AllowedToChangeDateOnSalesInvoice);
                        this.globals.setDataInLocalStorage(this.commonconstant.DisplayLastSalePriceButton, data._obj.UserInformation.DisplayLastSalePriceButton);
                        this.globals.setDataInLocalStorage(this.commonconstant.DisplayCostPriceButton, data._obj.UserInformation.DisplayCostPriceButton);
                        this.globals.setDataInLocalStorage(this.commonconstant.IsCostPriceDisplayOnStockTransfer, data._obj.UserInformation.IsCostPriceDisplayOnStockTransfer);
                        this.globals.setDataInLocalStorage(this.commonconstant.DisplayProductCreationButtonOnPurchase, data._obj.UserInformation.DisplayProductCreationButtonOnPurchase);
                        this.globals.setDataInLocalStorage(this.commonconstant.DisplayProductCreationButtonOnSales, data._obj.UserInformation.DisplayProductCreationButtonOnSales);

                        //GlobalSetup 
                        // need to update when we show this setup in Page
                        this.globals.setDataInLocalStorage(ApplicationConstants.IsAnalyticTagCodeRequired, data._obj.IsAnalyticCodeRequired);
                        this.globals.setDataInLocalStorage(this.commonconstant.IsProductOnLowQuantity,  data._obj.IsProductOnLowQuantity);
                        this.globals.setDataInLocalStorage(this.commonconstant.IsAutoPurchaseOrder,  data._obj.IsAutoPurchaseOrder);
                        this.globals.setDataInLocalStorage(this.commonconstant.EnableBonusQuantity,  data._obj.EnableBonusQuantity);
                        this.globals.setDataInLocalStorage(this.commonconstant.EnableSalePriceAddOnPurchaseBill,  data._obj.EnableSalePriceAddOnPurchaseBill);


                        // for file
                        this.globals.setDataInLocalStorage('isDownloadingFile', "false");

                        if (data._obj.RolePermissions && data._obj.RolePermissions.Table) {

                            let allowedMenus = data._obj.RolePermissions.Table.filter(x => x.IsAllowed == true);

                            this.globals.setDataInLocalStorage(this.commonconstant.AllowedMenus, JSON.stringify(allowedMenus));

                            let modulePagesObj = data._obj.RolePermissions.Table.filter(x => x.IsParent == false);

                            if (data._obj.RolePermissions.Table1 && data._obj.RolePermissions.Table1.length > 0) {
                                modulePagesObj.forEach((element) => {
                                    element.PermissionObj = data._obj.RolePermissions.Table1.filter(x => x.PageID == element.PageID);
                                });
                            }

                            this.globals.setDataInLocalStorage(this.commonconstant.RoleOperationsPermission, JSON.stringify(modulePagesObj));

                        }
                       
                        if (this.chkKeepSignedOn) {
                            this.CreateCookie(userName, password);
                        }
                        
                        this.touster.success(data._message);

                        if (data._obj.UserInformation.DefaultPage)
                            this.route.navigate([data._obj.UserInformation.DefaultPage]);
                        else
                            this.route.navigate(['Dashboard']);


                    } else
                        this.touster.error(data._message);

                } else
                    this.touster.error(data._message);


            });
        } catch (error) {
            console.log(error);
            this.touster.error(error);
        }
    }

    checkKeepSignedState(event) {
        if (event.target.checked) {
            this.chkKeepSignedOn = true;
        } else {
            this.chkKeepSignedOn = false;
        }
    }

    CreateCookie(userName: string, password: string) {

        let today = new Date();
        let expire = new Date();
        expire.setTime(today.getTime() + 3600000 * 24 * 30);

        userName = window.btoa(userName);
        password = window.btoa(password);
        document.cookie = "bnodyName=" + userName + ";expires=" + expire.toUTCString();
        document.cookie = "bnodyPassword=" + encodeURI(password) + ";expires=" + expire.toUTCString();
    }
    GetCookie(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return window.atob(parts.pop().split(';').shift());
    }
    validateUserInput() {
        if (this.selectionType == '1') {
            if (this.inputEmail == this.users.EmailAddress.split('@')[0]) {
                this.users.SelectionType = '1';
                this.users.Value = this.users.EmailAddress;
                this.resetPassword()
            }
            else {
                this.touster.error('Email is incorrect.')
            }
        } else {
            if (this.inputPhone.length > 5 && this.users.Mobile.includes(this.inputPhone)) {
                this.users.SelectionType = '2';
                this.users.Value = '+966' + this.inputPhone;
                this.resetPassword()
            }
            else {
                this.touster.error('Mobile number is incorrect.')
            }
        }
    }

    resetPassword() {
        this.loaderService.display(true)
        this.loginBusinessServices.loginService.ResetPassword(this.users).subscribe((data: any) => {
            this.loaderService.display(false);
            if (data && data._statusCode === 200) {                                
                this.touster.success(data._message);
                this.dispalyGrid()
                this.dispalyGrid()
                this.loaderService.display(false)
            }
            else {
                this.touster.error(data._message);
            }

        })
    }

    ValidateUser() {
        this.loaderService.display(true)
        this.login.Username = this.nameForget;
        this.loginBusinessServices.loginService.ValidateUser(this.login).subscribe((data: any) => {
            this.loaderService.display(false);
            if (data && data._statusCode === 200) {

                this.users = data._obj;
                this.email = data._obj.EmailAddress;
                this.phone = data._obj.Mobile;

                if (this.email || this.phone) {

                    this.selectionType = this.email ? '1' : '2';

                    if (this.email && this.email.length > 3 && this.email.includes('@')) {
                        this.email = '';
                        for (let index = 0; index < data._obj.EmailAddress.split('@')[0].length; index++) {
                            this.email += (index >= data._obj.EmailAddress.split('@')[0].length - 3) ? data._obj.EmailAddress[index] : '*';
                        }
                        this.email += '@' + data._obj.EmailAddress.split('@')[1];
                        this.postEmailFix = '@' + data._obj.EmailAddress.split('@')[1];
                    }

                    if (this.phone && this.phone.length > 3) {
                        this.phone = '';
                        for (let index = 0; index < data._obj.Mobile.length; index++) {
                            this.phone += (index >= data._obj.Mobile.length - 3) ? data._obj.Mobile[index] : '*';
                        }
                    }

                    this.isUserAuthenticated = true;
                }
                else {
                    this.touster.error('No method available for resetting <b>Password</b>. <br/> Please contact with Administrator.')
                    this.dispalyGrid();
                }
                console.log(data._obj);
                // this.touster.success(data._obj);
            }
            else if (data._statusCode == 403) {
                this.touster.info(data._message);
            }
            else {
                this.touster.error(data._message);
            }

        });
    }

    toggleVisibility() {
    this.isVisible = !this.isVisible;
    }

}


