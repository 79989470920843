import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { IAccountCodeSetupRepository } from "./IAccountCodeSetupRepository";
import { AccountCodeSetup } from "../../../models/Finance/AccountCodeSetup/account-code-setup.model";
import { ResponseModel } from "../../../models/SharedModels/response.model";

@Injectable()
export class AccountCodeSetupRepository extends GenericRepository<AccountCodeSetup> implements IAccountCodeSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/accountcodesetup', environment.urlAddress);
    }

    SaveRec(item: AccountCodeSetup): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetRec(): Observable<ResponseModel> {
        return this.GetById('/GetRec/').pipe(map(this.extractData), catchError(this.handleError));
    }
}