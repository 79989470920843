import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ConfirmationDialogServices } from '../confirmation-dialog/confirmation-dialog.service';
import { Subject } from 'rxjs';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { Globals } from 'src/environments/Globals';
import { style } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
 
@Component({
    selector: 'app-grid-delete-button',
    templateUrl: './grid-delete-button.component.html'
})

export class GridDeleteButtonComponent implements ICellRendererAngularComp {

    updateAllowed: boolean = true;
    deleteAllowed: boolean = true;

    public params: any;
    private isAgGridDelete: Subject<boolean>;
    disabled: boolean = false;
    visibleEdit: string = '';
    visibleDelete: string = '';
    visibleReset: string = 'd-none';
    visibleImage: string = 'd-none';
    visiblelogout: string = 'd-none';
    visiblePasswordChange: string = 'd-none';
    visibleAddUomButton: string = 'd-none';
    visibleIngredientsButton: string = 'd-none';

    public refresh(params: any): boolean {
        return false;
    }
    constructor(private ConfirmationDialogServices: ConfirmationDialogServices, private businessServices: BusinessServices, private globals: Globals, route: ActivatedRoute) {
        route.data.subscribe(data => {
            this.setOperationsPermission(data["PageID"]);
        })

    }
    agInit(params: any): void {
        this.params = params;
        if (this.params.hideEdit) {
            this.visibleEdit = 'd-none';
        }

        if (this.params.hideDelete) {
            this.visibleDelete = 'd-none';
        }

        if (this.params.showPassword) {
            this.visiblePasswordChange = '';
        }
        
        if (this.params.showImage) {
            this.visibleImage = '';
        }

        if (this.params.showIngredientButton) {
            this.visibleIngredientsButton = '';
        }

        if (this.params.showUomButton) {
            this.visibleAddUomButton = '';
        }

        if (this.params.node.data.hasOwnProperty("CounterUniqueKey")
            && this.params.node.data.CounterUniqueKey != ""
            && this.params.node.data.CounterUniqueKey != null
            && this.params.node.data.CounterUniqueKey != undefined) {
            this.visibleReset = "";
        }

        if (this.params.node.data.hasOwnProperty("IsLoggedIn")
            && this.params.node.data.IsLoggedIn == true
            && this.params.node.data.IsLoggedIn != null
            && this.params.node.data.IsLoggedIn != undefined) {
            this.visiblelogout = "";
        }

        // if (!this.params.IsEditable)
        //   this.disabled = true;
        // else
        //   this.disabled = false;    
    }

    onDeleteClick($event) {
        if (this.params.onDeleteClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data
                // ...something
            }
            this.params.onDeleteClick(params);
        }
    }

    onEditClick($event) {
        if (this.params.onEditClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.IsEditable = true;
            this.params.onEditClick(params);
        }
    }

    onResetClick($event) {
        if (this.params.onResetClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onResetClick(params);
        }
    } 

    onImageClick($event) { 
        if (this.params.onImageClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onImageClick(params);            
        }                
    }

    onIngredientsClick($event) {
        if (this.params.onIngredientsClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onIngredientsClick(params);
        }
    }
    

    onAddUomButtonClick($event) {
        if (this.params.onAddUomButtonClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onAddUomButtonClick(params);
        }
    }

    onPasswordChangeClick($event) {
        if (this.params.onPasswordChangeClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onPasswordChangeClick(params);
        }
    }

    onLogoutClick($event) {
        if (this.params.onLogoutClick instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                event: $event,
                rowIndex: this.params.node.rowIndex,
                rowData: this.params.node.data,
                // ...something
            }
            this.params.onLogoutClick(params);
        }
    }

    public invokeDeleteMethod() {
        this.ConfirmationDialogServices.showConfirmationModal(this.params, this.businessServices, true);
    }

    private setOperationsPermission(pageID): void {

        if (!pageID) return;

        let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission') || '[]')
        if (rolepermissionObject.length > 0) {
            let permissions = rolepermissionObject.filter(x => x.PageID == pageID)
            if (permissions.length > 0) {
                // console.log(permissions)
                if (permissions[0].PermissionObj && permissions[0].PermissionObj.length > 0) {

                    let updateObj = permissions[0].PermissionObj.filter(x => x.OperationID == pageID + '02');
                    if (updateObj && updateObj.length > 0)
                        this.updateAllowed = updateObj[0].IsAllowed;
                    else
                        this.updateAllowed = true;

                    let deleteObj = permissions[0].PermissionObj.filter(x => x.OperationID == pageID + '03');
                    if (deleteObj && deleteObj.length > 0)
                        this.deleteAllowed = deleteObj[0].IsAllowed;
                    else
                        this.deleteAllowed = true;
                }
                else {
                    console.log("Operation not found");
                    console.log(permissions);
                }
            }
            else {
                console.log(pageID + " : id not found")
            }
        }


    }

}


