import { Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CodeNameSearchModel } from 'src/app/models/common/code-name-search.model';
import { Globals } from 'src/environments/Globals';
import { ApplicationConstants } from '../constent/applicationcodes';

@Component({
  selector: 'analytic-tags-drop-down',
  templateUrl: './analytic-tags-drop-down.component.html'
})
export class AnalyticTagsDropDownComponent implements OnInit {

  @Input()
  Id: string= '';

  // use for Showing Error
  @Input()
  Name: string = '';

  // this will create dropdown
  @Input()
  DropDownOptions: Array<CodeNameSearchModel>;

  @Input()
  ShowLabel: boolean = true;

  // this will use to update ngModelChildValue
  @Input() 
  ngModelChild: string; //  
  
  // id & name Saparator
  @Input()   
  Separator: string = '_';

  @Output() ngModelChildChange = new EventEmitter();

  change(newValue) {
    console.log('newvalue', newValue)
    this.ngModelChild = newValue;
    this.ngModelChildChange.emit(newValue);
  }

  isAnalyticTagCode:boolean = false;

  constructor(private globals: Globals) {    
    this.isAnalyticTagCode = this.globals.getBoolFromLocalStorage(ApplicationConstants.IsAnalyticTagCodeRequired)
  }

  ngOnInit() {
  }

}
