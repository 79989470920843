﻿/*
 * added by Yameen 19-12-2019
 * Generic pipe to handle Number of decimals in Price, Amount, Quantity and Currency without Comma separator
  * --==========Input Type=============
 * 1 for Price
 * 2 for Amount
 * 3 for Quantity
 * 4 for Currency
 * 5 for Tax
 */
import { Pipe, PipeTransform } from '@angular/core';
import { NumericService } from '../services/numeric/numeric-service';

@Pipe({
    name: 'nullablenumericSanitizerPipe'
})
export class NullableNumericSanitizerPipe implements PipeTransform {

    constructor(private numberService: NumericService) { }

    transform(input: any, inputType: number): any {
        if (!input) return "";
        if (input.toString().startsWith(".")) input = ("0" + input);
        let result: string = "";
        if (inputType === 1)
            result = this.numberService.get(input, 3, 1)
        else if (inputType === 2)
            result = this.numberService.get(input, 2, 3)
        else if (inputType === 3)
            result = this.numberService.get(input, 2, 1)
        else if (inputType === 4)
            result = this.numberService.get(input, 1, 1)
        else if (inputType === 5)
            result = this.numberService.get(input, 4, 1)
        result = result.toString().replace(/,/g, ""); // remove commas
        return Number(result);
    }

}
