/*
 * added by Hamza 
 * For Removing Special Characters
 * Only dash(-) is allowed
 */
import { Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[noSpecialCharacterInput]',
    host: {      
        '(change)': 'onChange($event.target.value)'
    },
})

export class NoSpecialCharacterInputDirective {    

    constructor(private elementRef: ElementRef, private ngControl: NgControl) { }

    ngOnInit(): void {

    }   

    onChange = (value) => {               
        this.elementRef.nativeElement.value = value.replace(/[^A-Za-z0-9-]/g, "");
        this.ngControl.viewToModelUpdate(this.elementRef.nativeElement.value);
    }
}
