import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StockInternalUse } from "../../models/StockInternalUse/stockinternaluse.model";
import { IStockInternalUseRepository } from "./IStockInternalUseRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";
@Injectable()
export class StockInternalUseRepository extends GenericRepository<StockInternalUse> implements IStockInternalUseRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/StockInternalUse/', environment.urlAddress);
    }
    LoadRec(DocumentNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + DocumentNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/StockInternalUse/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(DocumentNumber: string) {
        return this.Delete('/DeleteRec/' + DocumentNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCode: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerialByProduct(inwardDocument: string, productCode: string, godownCode: string, inwardRecordID: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerialByProduct/' + inwardDocument + '/' + productCode + '/' + godownCode + '/' + inwardRecordID).pipe(map(this.extractData), catchError(this.handleError));
    }
}