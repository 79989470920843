import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AnalyticGroups } from "../../models/AnalyticGroups/analytic-groups";
import { IAnalyticGroupsRepository } from "./IAnalyticGroupsRepository";

@Injectable()
export class AnalyticGroupsRepository extends GenericRepository<AnalyticGroups> implements IAnalyticGroupsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/analyticgroups', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage);
    }

    DeleteRec(code: string): Observable<any> {
        return this._http
            .delete(this.url + 'api/analyticgroups/DeleteRec' + '/' + code, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(data: AnalyticGroups) {
        return this.PostData("/SaveRec", data);
    }

    GetAllAnalyticGroups(code: string = ""): Observable<any> {
        return this.Get('/SelectAll' + '/' + code);        
    }
}
