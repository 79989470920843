import { IProductGroupDiscountSetupRepository } from "./IProductGroupDiscountSetupRepository";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { PGDSDetails } from "src/app/models/ProductGroupDiscountSetup/pgdsdetails.model";
import { PGDSMaster } from "src/app/models/ProductGroupDiscountSetup/pgdsmaster.model";

@Injectable()
export class ProductGroupDiscountSetupRepository extends GenericRepository<PGDSMaster> implements IProductGroupDiscountSetupRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/ProductGroupDiscountSetup', environment.urlAddress);
  }  
 
  GetNavRec(navType: number, currentIndex: number): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductGroupDiscountSetup/NavRec'+ '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  LoadRec(ProductDiscountCode: string): Observable<any> {
    return this.GetById('GetRec/' + ProductDiscountCode).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(ProductDiscountCode: string) {
    return this.Delete('DeleteRec/' + ProductDiscountCode).pipe(map(this.extractData), catchError(this.handleError));
  }
}


 
  

