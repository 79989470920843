
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { IRiderRepository } from "./IRider";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Rider } from "../../models/ECommerceModels/Rider/rider-model";

@Injectable()
export class RiderRepository extends GenericRepository<Rider> implements IRiderRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {

        super(_http, 'api/rider/', environment.urlAddress);
    }
    GetRiderAddress(salesAgentCode: string, addressType: string) {
        return this._http.get(this.url + 'api/rider/RiderAddress' + '/' + salesAgentCode + '/' + addressType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/rider/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(salesAgentCode: string, isAddress: boolean, isName: boolean): Observable<any> {
        return this.GetById('/GetRec/' + salesAgentCode + '/' + isAddress + '/' + isName).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(salesAgentCode: string) {
        return this.Delete('/DeleteRec/' + salesAgentCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAll(isActive: boolean): Observable<any> {
        return this._http.get(this.url + 'api/rider/SelectAll' + '/' + isActive, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }        
}
