import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IAllowanceCategoryRepository } from "./IAllowanceCategoryRepository";
import { AllowanceCategory } from "src/app/models/HR/AllowanceCategory/allowance-category";


@Injectable({ providedIn: 'root' })
export class AllowanceCategoryRepository extends GenericRepository<any> implements IAllowanceCategoryRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/AllowanceCategory', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(isStrucure: boolean, currentPage: number, recordsPerPage: number, filterText: string): Observable<ResponseModel> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + isStrucure + '/' + currentPage + '/' + recordsPerPage + '/' + filterText );
    }

    SaveRec(item: AllowanceCategory): Observable<any> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }
    
}