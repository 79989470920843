import { Routes, RouterModule } from '@angular/router';
// import { AdminLayoutComponent } from './layouts/admin/admin.component';
// import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { NgModule } from '@angular/core';
import { P404Component } from './views/error/404.component';
import { AuthGuard } from './views/authentication/authentication-helpers/auth.guard';
import { P401Component } from './views/error/401.component';
// import { POSTailorLayoutComponent } from './layouts/postailor/postailor-layout.component';
import { TestPageComponent } from './views/procurement/test-folder/test-page/test-page.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { POSLayoutComponent } from './layouts/pos/pos-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { POSTailorLayoutComponent } from './layouts/postailor/postailor-layout.component';
import { AdminLayoutComponent } from './layouts/admin/admin.component';
import { ReportsViewerComponent } from './views/procurement/Reports/reports-viewer/reports-viewer.component';
import { CanDeactivateGuard } from './views/authentication/authentication-helpers/auth.can-deactivate-guard';
import { EcommerceClientLayoutComponent } from './layouts/ecommerce-client/ecommerce-client-layout.component';
import { EcommerceAuthLayoutComponent } from './layouts/ecommerce-client-auth/ecommerce-auth-layout.component';
import { EmployeeTaskRatingComponent } from './shared/employee-task-rating/employee-task-rating.component';
import { KitchenComponent } from './views/kitchen/kitchen.component';
import { CustomerDisplayComponent } from './views/customer-display/customer-display.component';
import { ClientSignupComponent } from './views/authentication/client-signup/client-signup.component';


export const AppRoutes: Routes = [
    { path: '', redirectTo: 'Login', pathMatch: 'full' },
    {
        path: 'Login',
        component: LoginComponent,
    },
    {
        path: 'ClientRegister',
        component: ClientSignupComponent,
    },
    {
        path: 'Dashboard',
        component: AdminLayoutComponent,
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        data: {
            breadcrumb: 'Home'
        }
    },
    { 
        path: 'Kitchen',
        component: KitchenComponent
    },
    { 
        path: 'CustomerDisplay',
        component: CustomerDisplayComponent
    },
    {
        path: 'GetServices',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/get-services/get-services.module#GetServicesModule',
        data: {
            breadcrumb: '2784',
            description: 'Get Services'
        }
    },

    //#region Module Routing
    {
        path: 'Configuration/TaxSetup',
        // component: AdminLayoutComponent,
        loadChildren: './views/configuration/tax-setup/tax-setups.module#TaxSetupsModule',
        data: {
            breadcrumb: 'Configuration'
        }
    },
    {
        path: 'Configuration/Company',
        // component: AdminLayoutComponent,
        loadChildren: './views/configuration/fiscal-spans/fiscal-period-updates.module#FiscalPeriodUpdatesModule',
        data: {
            breadcrumb: 'Configuration'
        }
    },
    {
        path: 'Configuration/Management',
        // component: AdminLayoutComponent,
        loadChildren: './views/configuration/management/management.module#ManagementModule',
        data: {
            breadcrumb: 'Configuration'
        }
    },
    {
        path: 'Configuration/Security',
        // component: AdminLayoutComponent,
        loadChildren: './views/configuration/security/security-module.module#SecurityModule',
        data: {
            breadcrumb: 'Configuration'
        }
    },
    //#endregion

    //#Restaurant
    {
        path: 'Restaurant/Core',
        component: AdminLayoutComponent,
        loadChildren: './views/restaurant/core/core.module#CoreModule',
        data: {
            breadcrumb: '230',
            description: 'Restaurant',
        }
    },
    {
        path: 'Restaurant/Settings',
        component: AdminLayoutComponent,
        loadChildren: './views/restaurant/setting/setting.module#SettingModule',
        data: {
            breadcrumb: '2380',
            description: 'App Settings',
        }
    },
    {
        path: 'Restaurant/BillManagement',
        component: AdminLayoutComponent,
        loadChildren: './views/restaurant/order-management/order-management.module#OrderManagementModule',
        data: {
            breadcrumb: '3192',
            description: 'Bill Management',
        }
    },
    {
        path: 'Restaurant/CatalogManagement',
        component: AdminLayoutComponent,
        loadChildren: './views/restaurant/catalog-management/catalog-management.module#CatalogManagementModule',
        data: {
            breadcrumb: '2494',
            description: 'Catalog Management',
        }
    },

    //#Restaurant Ended

    //#region Finance Module Routing
    {
        path: 'Finance/Accounts',
        loadChildren: './views/finance/accounts/accounts.module#AccountsModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Transactions',
        loadChildren: './views/finance/transactions/transactions.module#TransactionsModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Payments',
        loadChildren: './views/finance/payments/payments.module#PaymentsModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Earnings',
        loadChildren: './views/finance/earnings/earnings.module#EarningsModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Expenses',
        loadChildren: './views/finance/expenses/expenses.module#ExpensesModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Notes',
        loadChildren: './views/finance/notes/notes.module#NotesModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Invoicing',
        loadChildren: './views/finance/invoicing/invoicing.module#InvoicingModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Banking',
        loadChildren: './views/finance/banking/banking.module#BankingModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/IncomingCheques',
        loadChildren: './views/finance/cheques/incoming-cheques.module#IncomingChequesModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/OutgoingCheques',
        loadChildren: './views/finance/cheques/incoming-cheques.module#IncomingChequesModule',
        data: {
            breadcrumb: 'Finance'
        }
    },
    {
        path: 'Finance/Setting',
        loadChildren: './views/finance/finance-setup/finance-setup.module#FinanceSetupModule',
        data: {
            breadcrumb: 'Finance'
        }
    },

    //#endregion

    //#region Finance Reports Module
    {
        path: 'Finance/GenericStatements',
        loadChildren: './views/finance/Reports/Ledgers&Statements/generic-statements.module#GenericStatementsModule',
    },
    {
        path: 'Finance/AuditReports',
        loadChildren: './views/finance/Reports/listings/audit-reports.module#AuditReportsModule',
    },
    {
        path: 'Finance/Summary',
        loadChildren: './views/finance/Reports/summary/summary-reports.module#SummaryReportsModule',
    },
    {
        path: 'Finance/AnalyticGroup',
        component: AdminLayoutComponent,
        loadChildren: './views/finance/Reports/analytic-group/analytic-group.module#AnalyticGroupModule',
    },
    //#endregion
    //#region POS Module Routing
    {
        path: 'Pos/Tailor',
        component: AdminLayoutComponent,
        loadChildren: './views/pos/pos.module#PosModule',
        data: {
            breadcrumb: '312',
            description: 'POS'
        }
    },
    {
        path: 'Pos/Settings',
        loadChildren: './views/pos/point-of-sales-setups/point-of-sales-setups.module#PointOfSalesSetupsModule',
        data: {
            breadcrumb: '312',
            description: 'POS'
        }
    },
    // {
    //     path: 'Pos/Restaurant',
    //     component: AdminLayoutComponent,
    //     loadChildren: './views/pos/restaurent/restaurent.module#RestaurentModule',
    //     data: {
    //         breadcrumb: '312',
    //         description: 'POS'
    //     }
    // },
    {
        path: 'Pos/BillManagement',
        component: AdminLayoutComponent,
        loadChildren: './views/pos/order-management/order-management.module#OrderManagementModule',
        data: {
            breadcrumb: '312',
            description: 'POS'
        }
    },

    //#endregion

    //#region Procurement Modules Routing

    // {
    //     path: 'Procurement',
    //     component: AdminLayoutComponent,
    //     canActivate: [AuthGuard],
    //     loadChildren: './views/procurement/procurement.module#ProcurementModule',
    //     data: {
    //         breadcrumb: '1038',
    //         description: 'Procurement',
    //         preload: true // this will used in custom preload stretegy
    //     }
    // },

    {
        path: 'Procurement/Core',
        loadChildren: './views/procurement/master/master.module#MasterModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/Purchasing',
        loadChildren: './views/procurement/purchase/purchase.module#PurchaseModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/Sales',
        loadChildren: './views/procurement/sales/sales.module#SalesModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/LoyaltyManagement',
        component: AdminLayoutComponent,
        loadChildren: './views/procurement/loyalty/loyalty.module#LoyaltyModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/BusinessPartners',
        loadChildren: './views/procurement/business-partners/business-partners.module#BusinessPartnersModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/ProductManagement',
        loadChildren: './views/procurement/stock-pile-management/stock-pile-management.module#StockPileManagementModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/LoyaltyManagement',
        component: AdminLayoutComponent,
        loadChildren: './views/procurement/loyalty/loyalty.module#LoyaltyModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/Setting',
        loadChildren: './views/procurement/setup/procurement-setup.module#ProcurementSetupModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/Labeling',
        loadChildren: './views/procurement/barcode/barcode.module#BarcodeModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/Banking',
        loadChildren: './views/procurement/banking/banking.module#BankingModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/PartnerDirectory',
        loadChildren: './views/procurement/partner-directory/partner-directory.module#PartnerDirectoryModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'Procurement/OffersDiscount',
        loadChildren: './views/procurement/offere-discount/offere-discount.module#OffersDiscountModule',
        data: {
            breadcrumb: '2363',
            description: 'Procurement',
        }
    },

    //#endregion


    //#region Procurement Reports Routing

    {
        path: 'ProReports/Core',
        loadChildren: './views/procurement/Reports/procurment-reports/procurment-reports.module#ProcurmentReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Refunds',
        loadChildren: './views/procurement/Reports/sales-reports/sales-reports.module#SalesReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Estimation',
        loadChildren: './views/procurement/Reports/acquisition/acquisition.module#AcquisitionModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/VatTaxReport',
        loadChildren: './views/procurement/Reports/tax-reports/tax-reports.module#TaxReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Products',
        loadChildren: './views/procurement/Reports/stocks-reports/stocks-reports.module#StocksReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Orders',
        loadChildren: './views/procurement/Reports/order-reports/order-reports.module#OrderReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Bills',
        loadChildren: './views/procurement/Reports/invoices-reports/invoices-reports.module#InvoicesReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },
    {
        path: 'ProReports/Payments',
        loadChildren: './views/procurement/Reports/payments-reports/payments-reports.module#PaymentsReportsModule',
        data: {
            breadcrumb: '1038',
            description: 'Procurement',
        }
    },

    //#endregion

    {
        path: 'POSLogin',
        component: AuthLayoutComponent,
        loadChildren: './views/authentication/poslogin/poslogin.module#POSLoginModule',
    },
    {
        path: 'POSDashboard',
        component: POSLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/posdashboard/posdashboard.module#POSDashboardModule',
        data: {
            breadcrumb: 'Home'
            // ,PageID : 704009
        }
    },
    {
        path: 'POSTDashboard',
        component: POSTailorLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/postdashboard/postdashboard.module#POSTDashboardModule',
        data: {
            breadcrumb: 'Home',
            PageID: 704009
        }
    },

    // {
    //     path: 'Configuration',
    //     component: AdminLayoutComponent,
    //     loadChildren: './views/configuration/configuration.module#ConfigurationModule',
    //     data: {
    //         breadcrumb: '226',
    //         description: 'Configuration'
    //     }
    // },
    // {
    //     path: 'Finance',
    //     component: AdminLayoutComponent,
    //     canActivate: [AuthGuard],
    //     loadChildren: './views/finance/finance.module#FinanceModule',
    //     data: {
    //         breadcrumb: '299',
    //         description: 'Finance'
    //     }
    // },
    {
        path: 'ReportViewer',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                component: ReportsViewerComponent,
                canDeactivate: [CanDeactivateGuard],
                data: {
                    breadcrumb: "1684",
                    description: "Report Viewer"
                }
            },
        ]
    },
    {
        path: 'Zid',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/zid/zid.module#ZidModule',
        data: {
            breadcrumb: '2784',
            description: 'Zid'
        }
    },
    {
        path: 'Production',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/production/production.module#ProductionModule',
        data: {
            breadcrumb: '2002',
            description: 'Production'
        }
    },
    {
        path: 'Production/Reports',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/production/reports/reports.module#ReportsModule',
        data: {
            breadcrumb: '2002',
            description: 'Production Reports'
        }
    },
    //#region Ecommerce Module related Routings

    {
        path: 'Ecommerce/Configuration',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/configuration/configuration.module#ConfigurationModule',
        data: {
            breadcrumb: '2488',
            description: ''
        }
    },

    {
        path: 'Ecommerce/CatalogManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/catalog-management/catalog-management.module#CatalogManagementModule',
        data: {
            breadcrumb: '2494',
            description: ''
        }
    },
    {
        path: 'Ecommerce/Orders',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/orders/orders.module#OrdersModule',
        data: {
            breadcrumb: '330',
            description: ''
        }
    },
    {
        path: 'Ecommerce/BuyerManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/buyer-management/buyer-management.module#BuyerManagementModule',
        data: {
            breadcrumb: '2511',
            description: ''
        }
    },
    {
        path: 'Ecommerce/ContentManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/content-management/content-management.module#ContentManagementModule',
        data: {
            breadcrumb: '1366',
            description: ''
        }
    },
    {
        path: 'Ecommerce/DeliveryManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/delivery-management/delivery-management.module#DeliveryManagementModule',
        data: {
            breadcrumb: '2488',
            description: ''
        }
    },
    {
        path: 'Ecommerce/Promotions',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/promotions/promotions.module#PromotionsModule',
        data: {
            breadcrumb: '2488',
            description: ''
        }
    },
    {
        path: 'Ecommerce/Setting',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/setting/setting.module#SettingModule',
        data: {
            breadcrumb: '242',
            description: ''
        }
    },
    {
        path: 'Ecommerce/DiscountManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/discount-management/discount-management.module#DiscountManagementModule',
        data: {
            breadcrumb: '2488',
            description: ''
        }
    },
    {
        path: 'Ecommerce/CouponManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/ecommerce/coupon-management/coupon-management.module#CouponManagementModule',
        data: {
            breadcrumb: '2488',
            description: ''
        }
    },
    //#endregion 

    // HR PAGES START
    {
        path: 'HR/Configurations',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/configurations/configurations.module#ConfigurationsModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/CustomerRelationManagement',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/customer-relation-management/customer-relation-management.module#CustomerRelationManagementModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/General',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/general/general.module#GeneralModule',
        data: {
          breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/Employees',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/employees/employees.module#EmployeesModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/Payroll',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/payroll/payroll.module#PayrollModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/PayrollSetup',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/payroll-setup/payroll-setup.module#PayrollSetupModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/ServiceSetup',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/service-setup/service-setup.module#ServiceSetupModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/ServiceTransactions',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/service-transactions/service-transactions.module#ServiceTransactionsModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/ServiceLetter',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/service-letter/service-letter.module#ServiceLetterModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/Vacations',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/vacations/vacations.module#VacationsModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HRReports/Employees',
        component: AdminLayoutComponent,
        loadChildren: './views/hr/reports/reports.module#ReportsModule',
        data: {
            breadcrumb: '2813',
            description: 'HR',
        }
    },
    {
        path: 'HR/Directory',
        component: AdminLayoutComponent,
        loadChildren: './views/hr/employee-directory/employee-directory.module#EmployeeDirectoryModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    {
        path: 'HR/Settings',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: './views/hr/setting/setting.module#SettingModule',
        data: {
            breadcrumb: '1336',
            description: 'HR'
        }
    },
    // HR PAGES END


    // Ecommerce PAGES Start 

    {
        path: 'EcommerceClient',
        component: EcommerceClientLayoutComponent,
        //canActivate: [AuthGuard],
        loadChildren: './views/ecommerce-client/ecommerce-client.module#EcommerceClientModule',
        data: {
            breadcrumb: 'EcommerceClient'
        }
    },
    {
        path: 'EcommerceClient/Auth',
        component: EcommerceAuthLayoutComponent,
        //canActivate: [AuthGuard], 
        loadChildren: './views/ecommerce-client/auth/auth.module#AuthModule',
        data: {
            breadcrumb: 'Auth'
        }
    },
    //{
    //    path: 'EcommerceClient/Login',
    //    component: EcommerceAuthLayoutComponent,
    //    //canActivate: [AuthGuard], 
    //    loadChildren: './views/ecommerce-client/auth/auth.module#AuthModule',
    //    data: {
    //        breadcrumb: 'Login'
    //    }
    //},

    // Ecommerce PAGES END

    {
        path: '',
        component: AuthLayoutComponent,
        loadChildren: './views/reportprint/report-print.module#ReportPrintModule',
    },
    {
        path: 'TestPage',
        component: TestPageComponent,
        data: {
            breadcrumb: "Test Events Page"
        }
    }, 
    {
        path: 'EmployeeTaskRating',
        component: EmployeeTaskRatingComponent,
        data: {
            breadcrumb: "EmployeeTaskRating"
        }
    },
    { path: 'NoAccess', component: P401Component },
    { path: '**', component: P404Component }
];

@NgModule({
    // imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: CustomPreLoadStrategyService })],
    //  imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules })],
    imports: [RouterModule.forRoot(AppRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
