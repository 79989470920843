import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IAccountMasterBalanceRepository } from "./IAccountMasterBalanceRepository";
import { AccountMasterBalance } from "../../../models/Finance/AccountMasterBalance/account-master-balance.model";

@Injectable()
export class AccountMasterBalanceRepository extends GenericRepository<AccountMasterBalance> implements IAccountMasterBalanceRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/openingbalance', environment.urlAddress);
    }   
    GetAll(): Observable<any> {       
        return this._http
            .get(this.url + 'api/openingbalance/SelectAll', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadByKey(searchByCode: boolean, txtSearchkey: string): Observable<ResponseModel> {
        return this.GetById('/LoadByKey/' + searchByCode + '/' + txtSearchkey).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    GetNavRec(rowNumber: string, pageSize: string, navType: string,accountType:string=''): Observable<ResponseModel> {
        return this.GetById('/GetNavRec/' + rowNumber + '/' + pageSize + '/' + navType + '/'+ accountType).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    
      
}