import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
declare var $: any;
@Component({
    selector: 'app-ag-grid-dropdown',
    templateUrl: './ag-grid-dropdown.component.html'
})
export class AgGridDropdownComponent implements ICellRendererAngularComp {
    public params: any;
    public Items: any;
    id: string = "";
    selectedDeviceObj: any;
    refresh(params: any): boolean {
        return false;
    }

    constructor() {

    }

    agInit(params: any): void {
        this.params = params;
        this.Items = params.Items;
        this.id = params.ID + "-" + params.rowIndex;
        if (params.value == 0) {
            params.value = this.Items[0].id;
        }

        this.selectedDeviceObj = params.value;

        if (params.ID == "productType" && params.value == 3) {
            setTimeout(() => {
                $("#priceType-" + params.rowIndex).prop("disabled", "disabled");
            }, 100);
        }
    }

    onChange(value) {
        if (this.params.ID == "productType" && value == 3) {
            $("#priceType-" + this.params.rowIndex).prop("disabled", "disabled");
        }
        else if (this.params.ID == "productType") {
            $("#priceType-" + this.params.rowIndex).prop("disabled", false);
        }
        this.selectedDeviceObj = value;
        this.params.node.setDataValue(this.params.colDef, Number(this.selectedDeviceObj));
    }
}
