import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IBuyerGroupSetupRepository } from "./IBuyerGroupSetupRepository";
import { BuyerGroupSetup } from "src/app/models/BuyerGroupSetup/buyergroupsetup.model";

@Injectable()
export class BuyerGroupSetupRepository extends GenericRepository<BuyerGroupSetup> implements IBuyerGroupSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/BuyerGroupSetup', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, isPriceSetup: boolean): Observable<any> {
        return this._http
            .get(this.url + 'api/BuyerGroupSetup/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + isPriceSetup, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(BuyerGroupCode: string) {
        return this._http.delete(this.url + 'api/BuyerGroupSetup/DeleteRec/' + BuyerGroupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveGroupNames(item: BuyerGroupSetup) {
        return this._http.post(this.url + 'api/BuyerGroupSetup/SaveGroupNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(BuyerGroupCode: string): Observable<any> {
        return this.GetById('/GetRec/' + BuyerGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/BuyerGroupSetup/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    
}
