export class ProcurementPostingSetup {
    RecordNumber: number;
    FiscalSpanID: number
    GodownCode: string;
    Procurement_Account: string;
    CostOfGoods_Account: string;
    Adjustment_Account: string;
    InternalUse_Account: string;
    DamagedStock_Account: string;
    ExpiredStock_Account: string;
    SupportingItem_Account: string;

    Procurement_Account_AnalyticTagCode: string;
    CostOfGoods_Account_AnalyticTagCode: string;
    Adjustment_Account_AnalyticTagCode: string;
}
