import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { BuyerGroupSetup } from "src/app/models/BuyerGroupSetup/buyergroupsetup.model";
import { BarcodeLabelSetupModel } from "src/app/models/barcode/barcode-label-setup/barcode-label-setup-model";
import { IBarcodeLableSetupRepository } from "./IBarcodeLableSetupRepository";

@Injectable(
    {
        providedIn:'root'
    }
)
export class BarcodeLableSetupRepository extends GenericRepository<BarcodeLabelSetupModel> implements IBarcodeLableSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/BarcodeLabelSetup', environment.urlAddress);
    }    

    DeleteRec(code: string) {
        return this.Delete('/DeleteRec/' + code)
    }

    SaveRec(item: BarcodeLabelSetupModel) {
        return this.PostData('/SaveRec', item);
    }

    LoadRec(code: string): Observable<any> {
        return this.GetById('/LoadRec/' + code);
    }
    LoadLabelSetupHTML(code: string): Observable<any> {
        return this.GetById('/LoadLabelSetupHTML/' + code);
    }
    
    LoadNavRec(navType: number, currentIndex: number): Observable<any> {
        return this.GetById('/LoadNavRec/' + navType + '/' + currentIndex);
    }
    
}
