import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "checkbox-cell",
  template: `
<div class="form-check d-inline">
<label class="chk">
        <input type="checkbox" class="checkBoxAgGrid" [(ngModel)]="checked"  (change)="onChange()" >
<span class="checkmark"></span>
                                                                                </label>
                                                                            </div>    
    `,
  styles: [
    `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }
            .chk {
              top: -3px;
            }
        `
  ]
})
export class AgGridCheckboxCellComponent implements ICellRendererAngularComp {
  private params: any;

  checked: boolean = false;

  agInit(params: any): void {    
    this.params = params;
    this.checked = this.params.value === true;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange() {

      if (this.params.data.TaxFaction == 2) {
        this.checked = false;
        this.params.node.setDataValue(this.params.colDef.field, false);
      }
      else {

        this.checked = !this.checked;
        this.params.node.setDataValue(this.params.colDef, !this.checked);
      }
    

    this.params.api.refreshCells({ force: true });

    $(".TaxFaction select").attr('disabled', 'disabled');  

    if(this.params.data.TaxFaction == 2)
      {
        $("[row-index=" + this.params.data.rowNum + "]").find(".TaxCalculationID select").attr('disabled', 'disabled');
        $("[row-index=" + this.params.data.rowNum + "]").find(".checkBoxAgGrid").attr('disabled', 'disabled');        
      }
    
  }

  refresh(params: any): boolean {
    return false;
  }
}