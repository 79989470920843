﻿/*
 * added by Yameen 10-11-2019
 * --==========Format values=============
 * 1000 for Eng One Thousand
 * 1000 for Arabic واحد ألف
 *  --=======================
 */
import { Pipe, PipeTransform } from '@angular/core';
import * as Tafgeet from 'tafgeetjs';
@Pipe({
    name: 'numberToWordsPipe'
})
export class NumberToWordsPipe implements PipeTransform {

    transform(input: any, arabicOnly: boolean = false): any {        
        if (!input) return "";
        var currencySymbol = localStorage.getItem("CompanyCurrencyCode");
        var IsdefaultCurrency = true; 
        if(currencySymbol)
        {
            IsdefaultCurrency = !(currencySymbol.indexOf("Q")>-1 || currencySymbol.indexOf("ق")>-1);
        }

        /*if (!arabicOnly) {
            const cultureCode = localStorage.getItem("cultureCode");
            if (cultureCode)
                (cultureCode === "en-US" ? arabicOnly = false : arabicOnly = true);
        }*/
        if (arabicOnly) {
            input = parseFloat(input).toFixed(2);
            let amountInText = new Tafgeet(input, IsdefaultCurrency? 'SAR': 'QAR').parse();
            if(input < 1 && input > 0) // show zero as undefined in translation
            amountInText= IsdefaultCurrency? amountInText.replace('undefined','').replace(' ريال سعودي و',''): amountInText.replace('undefined','').replace(' ريال قطري و','')
            return amountInText;
        }
        else {
            let inputInt = parseInt(input);
            let amountInWords = this.numberToWords(inputInt, arabicOnly);

            if (Number(input) - inputInt != 0)
                amountInWords += IsdefaultCurrency?  ' saudi riyal and ' + this.numberToWords(Number(parseFloat(input).toFixed(2).split('.')[1]), arabicOnly) + ' halala': ' qatari riyal and ' + this.numberToWords(Number(parseFloat(input).toFixed(2).split('.')[1]), arabicOnly) + ' dirham'
            let index = 0;     // capitalize first letter of string
            if(amountInWords.charAt(index) == ' ')
                index = 1
            return (amountInWords.charAt(index).toUpperCase() + amountInWords.slice(index == 0 ? 1 : 2));
        }
    }
    numberToWords(number: number, arabicOnly: boolean = false) {        
        if (number === 0)
            return (arabicOnly ? "صفر" : "zero");

        if (number < 0)
            return (arabicOnly ? "ناقص" : "minus ") + this.numberToWords(Math.abs(number));

        let words = "";

        if (Math.floor(number / 1000000) > 0) {
            words += this.numberToWords(Math.floor(number / 1000000), arabicOnly) + (arabicOnly ? " مليون" : " million ");
            number %= 1000000;
        }

        if (Math.floor(number / 1000) > 0) {
            words += this.numberToWords(Math.floor(number / 1000), arabicOnly) + (arabicOnly ? " ألف" : " thousand ");
            number %= 1000;
        }

        if (Math.floor(number / 100) > 0) {
            words += this.numberToWords(Math.floor(number / 100), arabicOnly) + (arabicOnly ? " مائة" : " hundred ");
            number %= 100;
        }

        if (number > 0) {

            if (arabicOnly) {

                let unitsMap = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
                let tensMap = ["zero", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

                if (arabicOnly) {
                    unitsMap = ["صفر", "واحد", "إثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة", "عشرة", "إحدى عشر", "إثنا عشر", "ثلاثة عشر", "أربعة عشر", "خمسة عشر", "ستة عشر", "سبعة عشر", "ثمانية عشر", "تسعة عشر"];
                    tensMap = ["صفر", "عشرة", "عشرون", "ثلاثون", "أربعون", "خمسون", "ستون", "سبعون", "ثمانون", "تسعون"];
                }
                if (number < 20)
                    words += " " + unitsMap[number];
                else {
                    if (Math.floor(number % 10) > 0) {
                        if (words === "") {
                            words += unitsMap[Math.floor(number % 10)];
                            words += (arabicOnly ? " و" : "and ");
                        } else {
                            words += unitsMap[Math.floor(number % 10)];
                        }
                    }
                    words += " " + tensMap[Math.floor(number / 10)];
                }
            } else {
                if (words !== "")
                    words += (arabicOnly ? " و" : "and ");

                let unitsMap = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
                let tensMap = ["zero", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

                if (arabicOnly) {
                    unitsMap = ["صفر", "واحد", "إثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة", "عشرة", "إحدى عشر", "إثنا عشر", "ثلاثة عشر", "أربعة عشر", "خمسة عشر", "ستة عشر", "سبعة عشر", "ثمانية عشر", "تسعة عشر"];
                    tensMap = ["صفر", "عشرة", "عشرون", "ثلاثون", "أربعون", "خمسون", "ستون", "سبعون", "ثمانون", "تسعون"];
                }
                if (number < 20)
                    words += " " + unitsMap[number];
                else {
                    words += tensMap[Math.floor(number / 10)];
                    if (Math.floor(number % 10) > 0)
                        words += " " + unitsMap[Math.floor(number % 10)];
                }
            }

        }
        return words;
    }
}