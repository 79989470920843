/*
 * added by Yameen 19-12-2019
 * Generic pipe to handle Number of decimals in Price, Amount, Quantity and Currency without Comma separator
  * --==========Input Type=============
 * 1 for Price
 * 2 for Amount
 * 3 for Quantity
 * 4 for Currency
 * 5 for Tax
 * 6 for simple numeric input
 */
import { Pipe, PipeTransform } from '@angular/core';
import { Globals } from '../../environments/Globals';
import { NumericService } from '../services/numeric/numeric-service';
import { CommonConstant } from '../shared/constent/applicationcodes';

@Pipe({
    name: 'numericSanitizerPipe'
})
export class NumericSanitizerPipe implements PipeTransform {
    commonconstant = new CommonConstant();

    constructor(private numberService: NumericService, private globals: Globals) { }

    transform(input: any, inputType: number): number {
        var AmountFormat = this.globals.getDataFromLocalStorage(this.commonconstant.AmountFormat);
        var DecimalsInQuantity = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInQuantity);
        var DecimalsInAmount = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInAmount);
        var DecimalsInPrice = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInPrice);

        AmountFormat = AmountFormat != null ? Number(AmountFormat) : 2;
        DecimalsInQuantity = DecimalsInQuantity != null ? Number(DecimalsInQuantity) : 2;
        DecimalsInAmount = DecimalsInAmount != null ? Number(DecimalsInAmount) : 2;
        DecimalsInPrice = DecimalsInPrice != null ? Number(DecimalsInPrice) : 2;

        if (!input) return 0;
        if (input.toString().startsWith(".")) input = ("0" + input);
        let result: string = "";
        if (inputType === 1)
            result = this.numberService.get(input, DecimalsInPrice, AmountFormat)
        else if (inputType === 2)
            result = this.numberService.get(input, DecimalsInAmount, AmountFormat)
        else if (inputType === 3)
            result = this.numberService.get(input, DecimalsInQuantity, AmountFormat)
        else if (inputType === 4)
            result = this.numberService.get(input, 1, AmountFormat)
        else if (inputType === 5)
            result = this.numberService.get(input, DecimalsInAmount, AmountFormat)
        else if (inputType === 6)
            result = this.numberService.get(input, 4, AmountFormat)
        result = result.toString().replace(/,/g, ""); // remove commas
        return Number(result);
    }

}
