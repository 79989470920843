import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { MultiTablesNamesModalComponent } from "./multi-tables-names.component";
import { BusinessServices } from "src/app/services/singleton/business-services";
@Injectable({
  providedIn: "root"
})
export class MultiTablesNamesServices {

  constructor(private _bsModalService: BsModalService) { }

  public showConfirmationModal(params?:any,services?:any) {
    const modal = this._bsModalService.show(MultiTablesNamesModalComponent, { class: 'modal-dialog-centered' });

    (<MultiTablesNamesModalComponent>modal.content).showConfirmationModal(params,services);

    (<MultiTablesNamesModalComponent>modal.content).onClose.subscribe(result => {
      if (result === true) {
        // when pressed Yes
      } else if (result === false) {
        // when pressed No
      } else {
        // When closing the modal without no or yes
      }
    });
  }

}
