import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IGoodsReceivedReceiptRepository} from "./IGoodsReceivedReceiptRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { GoodsReceivedReceipt } from "src/app/models/goods-received-receipt/goods-received-receipt.model";
import { DocumentSearchViewModel } from "src/app/models/common/document-search-model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class GoodsReceivedReceiptRepository extends GenericRepository<GoodsReceivedReceipt> implements IGoodsReceivedReceiptRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/GoodsReceivedReceipt', environment.urlAddress);
    }
    
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/GoodsReceivedReceipt/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/GoodsReceivedReceipt/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectPostingSetup(postingSetup: any, godownCode: string, paymentType: number, vendorCode: string, isTaxRefundable: boolean, isTaxByVendor: boolean, processNumber?: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode + '/' + paymentType + '/' + vendorCode + '/' + isTaxRefundable + '/' + isTaxByVendor + '/' + processNumber, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchGoodsReceivedReceipt(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/GoodsReceivedReceipt/SearchForGoodsReceivedReceipt', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRecForProcess(GRRNumber: string): Observable<any> {
        return this._http.get(this.url + 'api/GoodsReceivedReceipt/LoadRecForProcess/' + GRRNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllForDashBoard (currentPage: number, recordsPerPage: number, godownCode: string ='', vendorCode:string='', DateFrom:string='',DateTo:string=''): Observable<any>{
        godownCode = godownCode!='' ? godownCode : 'null';
        return this._http
            .get(this.url + 'api/GoodsReceivedReceipt/SelectAllForDashBoard' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode + '/' + DateFrom + '/' + DateTo+ '/' + vendorCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}