﻿/*
 * added by Yameen 30-03-2020
 * For Search In Given Values
 */
import { Component, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
declare var $: any;
@Component({
    selector: 'Dropdown-cell-values',
    template: `
<style>

</style>
<select #input class="form-trans form-control square custom-select no_enable" [(ngModel)]="value" name="DDL">
  <option *ngFor="let item of Items" value="{{item.Id}}">{{item.Name}}</option>  
</select>
`,

})
export class DropdownRenderInGivenValuesComponent implements ICellRendererAngularComp {
    private params: any;
    public value: number;
    public Items: any;
    @ViewChild('input', { read: ViewContainerRef }) public input;

    agInit(params: any): void {

        this.params = params;
        this.value = this.params.value;
        if (params.data.SearchValues)
            this.Items = params.data.SearchValues;
        else
            this.Items = [];

    }
    refresh(params: any): boolean {
        return false;
    }
}