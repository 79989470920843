import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { Globals } from 'src/environments/Globals';
import { MultiTablesNamesServices } from '../multi-tables-names/multi-tables-names.service';

@Component({
  selector: 'app-ag-grid-names-add',
  templateUrl: './ag-grid-names-add.component.html'  
})
export class AgGridNamesAddComponent implements ICellRendererAngularComp {
  private params: any;
  private mood: string;
  public name: string;
  public body: string;
  public title: string;
  public onClose: Subject<boolean>;
  public active: boolean = false;
  constructor(private multiTablesNamesServices: MultiTablesNamesServices, private businessServices: BusinessServices, private globalVariables: Globals) {

  }
  agInit(params: any): void {
    this.params = params;
    this.setMood(params);
    this.onClose = new Subject
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }

  onBlur(event) {
    this.name = event.target.value;
    if (this.params.Table == "PROC_UOMGroupNames")
      this.params.data.GroupName = this.name;
    else
      this.params.data.Name = this.name;

    if (this.params.onTextboxBlur instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {        
        Name: this.name,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onTextboxBlur(params);
    }
    
  }

  private setMood(params) {    
    if (params.value != '' && params.value.includes('<-|->')) {
      // this.mood = params.value;
      let tempString = params.value.split('<-|->');
      for (let i = 0; i < tempString.length; i++) {
        if (tempString[i].includes(this.globalVariables.cultureCode)) {
          this.name = tempString[i].split('-<|>-')[1];
          break;
        }
      }
    }
    else
      this.name = params.value;
  };
  onShowPopup() {
    this.multiTablesNamesServices.showConfirmationModal(this.params, this.businessServices);
  }
}
