import { Component } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';


@Component({
  selector: 'app-ag-grid-checkbox',
  templateUrl: './ag-grid-checkbox.component.html'
})
export class AgGridCheckboxComponent implements AgRendererComponent {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }
  onCheckBoxClick($event) {
    if (this.params.onCheckBoxClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        params: this.params.value
        //rowData: this.params.node.data
        // ...something
      }
      this.params.column.getColDef().field= this.params.value
      this.params.onCheckBoxClick(params);

    }
  }
  refresh(params: any): boolean {
    params.data.amount++;
    this.params.column.getColDef().field= params.value
    console.log(params.value);
    params.api.refreshCells(params);
    return false;
  }
}