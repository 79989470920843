import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { IStockBatchInwardRepository } from "./IStockBatchInwardRepository";
import { StockBatchSerials } from "src/app/models/common/getjsonfrom-controls-service-model";

@Injectable()
export class StockBatchInwardRepository extends GenericRepository<StockBatchSerials> implements IStockBatchInwardRepository {
  
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/StockBatchInward', environment.urlAddress);
  }   
    SaveRec(item: StockBatchSerials): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }   
    LoadBatchRec(productCode: string,  documentNumber: string, goDownCode: string, pageID: number): Observable<ResponseModel> {
        return this.GetById('/GetRecWithProductCode/' + productCode + '/' + documentNumber + '/' + goDownCode + '/' + pageID).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRecWithPageID(code: string, pageID: number): Observable<ResponseModel> {
        return this.GetById('/GetRecWithPageID/' + code + '/' + pageID).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string, pageID: number): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code + '/' + pageID).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCode: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
}