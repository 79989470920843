import { environment } from "src/environments/environment";

var _mode = true;
export const GlobalSetups = {

    IsProcurementAccountLinked: 'IsProcurementAccountLinked',
    IsBuyerAccountAutoCreate: 'IsBuyerAccountAutoCreate',
    IsVendorAccountAutoCreate: 'IsVendorAccountAutoCreate',
    IsCostCenterSupportEnabledAgainstAccountCodeInInventory: 'IsCostCenterSupportEnabledAgainstAccountCodeInInventory',

    //#region SALES SECTION
    AllowSaleOnMinusStock: 'AllowSaleOnMinusStock',
    IsDealWithSubBranches: 'IsDealWithSubBranches',
    AllowSaleOnPriceLessThanCost: 'AllowSaleOnPriceLessThanCost',
    AllowSaleOnPriceLessThanDistributorPrice: 'AllowSaleOnPriceLessThanDistributorPrice',
    AllowSaleOnPriceLessThanRetailPrice: 'AllowSaleOnPriceLessThanRetailPrice',
    AllowSaleOnPriceLessThanWholeSalePrice: 'AllowSaleOnPriceLessThanWholeSalePrice',
    EnableCurrencyOnSalePages: 'IsMultiCurrencyOnSalesProduct',
    EnableDescriptionOnSalePages: 'EnableDescriptionOnSalePages',
    IsBatchSerialEnabledOnSalesInvoice: 'IsBatchSerialEnabledOnSalesInvoice',
    IsBatchSerialEnabledOnSalesReturn: 'IsBatchSerialEnabledOnSalesReturn',
    IsUserAllowedToChangeDateOnSalesInvoice: 'IsUserAllowedToChangeDateOnSalesInvoice',
    ReturnDaysLimit: 'ReturnDaysLimit',
    SalesInvoiceNumberForEachWarehouse: 'SalesInvoiceNumberForEachWarehouse',
    ShowLastSalePriceButton: 'ShowLastSalePriceButton',
    SalesEstimationDefaultTaxGroup: "SalesEstimationDefaultTaxGroup",

    SalesDefaultTaxGroup: 'SalesDefaultTaxGroup',
    IsTaxOnSalesInvoice: 'IsTaxOnSalesInvoice',
    IsTaxOnSalesProduct: 'IsTaxOnSalesProduct',
    IsDiscountOnSalesInvoice: 'IsDiscountOnSalesInvoice',
    IsDiscountOnSalesProduct: 'IsDiscountOnSalesProduct',
    AccreditCardType: 'AccreditCardCharges',
    IsLoyaltyCardAuto: 'IsLoyaltyCardAuto',
    IsChannelPrice: 'IsChannelPrice',
    AllowLoyaltyInvoiceToReturn: 'AllowLoyaltyInvoiceToReturn',
    InventoryCostingMethod: 'InventoryCostingMethod',
    //#endregion


    //#region Finance Invoice
    InvoiceAccreditCardType: 'InvoiceAccreditCardCharges',
    IsDiscountOnInvoiceProduct: 'IsDiscountOnInvoiceProduct',
    IsTaxOnInvoiceProduct: 'IsTaxOnInvoiceProduct',
    IsDiscountOnInvoice: 'IsDiscountOnInvoice',
    IsTaxOnInvoice: 'IsTaxOnInvoice',
    IsMultiCurrencyOn: 'IsMultiCurrencyOn',
    //#endregion

    //#region PURCHASE SECTION

    EnableCurrencyOnPurchasePages: 'IsMultiCurrencyOnPurchaseProduct',
    EnableDescriptionOnPurchasePages: 'EnableDescriptionOnPurchasePages',

    IsBatchSerialEnabledOnPurchaseInvoice: 'IsBatchSerialEnabledOnPurchaseInvoice',
    IsBatchSerialEnabledOnPurchaseReturn: 'IsBatchSerialEnabledOnPurchaseReturn',
    IsUserAllowedToChangeDateOnPurchaseInvoice: 'IsUserAllowedToChangeDateOnPurchaseInvoice',
    OnMinusStockEnablePurchasePriceAsAverageCost: 'OnMinusStockEnablePurchasePriceAsAverageCost',

    ShowCostPriceButton: 'ShowCostPriceButton',
    ShowLastCostPriceButton: 'ShowLastCostPriceButton',


    PurchaseDefaultTaxGroup: 'PurchaseDefaultTaxGroup',
    IsTaxOnPurchaseInvoice: 'IsTaxOnPurchaseInvoice',
    IsTaxOnPurchaseProduct: 'IsTaxOnPurchaseProduct',
    IsDiscountOnPurchaseInvoice: 'IsDiscountOnPurchaseInvoice',
    IsDiscountOnPurchaseProduct: 'IsDiscountOnPurchaseProduct',
    IsShowLargestUOMOnlyInPurchase: 'IsShowLargestUOMOnlyInPurchase',
    IsShowNTNOnPurchasePage: 'IsShowNTNOnPurchasePage',
    StopSMS: 'StopSMS',

    EnableBonusQuantity: 'EnableBonusQuantity',
    EnableSalePriceAddOnPurchaseBill: 'EnableSalePriceAddOnPurchaseBill',
    GRRCreationType: 'GRRCreationType',
    //#endregion



    //#region STOCK SECTION    
    IsAmountShownOnStockTransfer: 'IsAmountShownOnStockTransfer',
    IsCostPriceShownOnStockTransfer: 'IsCostPriceShownOnStockTransfer',
    IsBatchSerialEnabledOnStockAdjustment: 'IsBatchSerialEnabledOnStockAdjustment',
    IsBatchSerialEnabledOnStockDamage: 'IsBatchSerialEnabledOnStockDamage',
    IsBatchSerialEnabledOnStockInternalUse: 'IsBatchSerialEnabledOnStockInternalUse',
    IsBatchSerialEnabledOnStockTransfer: 'IsBatchSerialEnabledOnStockTransfer',
    StopPosPostingOnNoStock: 'StopPosPostingOnNoStock',
    //#endregion




    //#region Product Card Master

    IsBatchNumberEnabledOnItemCardMaster: 'IsBatchNumberEnabledOnItemCardMaster',
    IsSerialNumberEnabledOnItemCardMaster: 'IsSerialNumberEnabledOnItemCardMaster',
    IsMeasurementEnabledOnItemCardMaster: 'IsMeasurementEnabledOnItemCardMaster',
    DefaultBaseUnitDimensionalItem: 'DefaultBaseUnitDimensionalItem',
    DefaultBaseUnitNonDimensionalItem: 'DefaultBaseUnitNonDimensionalItem',
    IsCostCenterSupportEnabledAgainstAccountCodeInFA: 'IsCostCenterSupportEnabledAgainstAccountCodeInFA',

    IsCompanyEnableOnProduct: 'IsCompanyEnableOnProduct',
    IsShortProductEnableOnProduct: 'IsShortProductEnableOnProduct',
    IsNorcoticProductEnableOnProduct: 'IsNorcoticProductEnableOnProduct',

    IsUpdateSalePriceOfSmallUOMByLargeUOM: 'IsUpdateSalePriceOfSmallUOMByLargeUOM',



    //#endregion
};

export function GetSetupBool(object: any, setupName: string, defaultValue = false): boolean {

    if (object && object.length > 0) {

        object = object.find(x => x.SetupName == setupName);

        if (object)
            return object.SetupBool;
        else {
            console.log(object);
            console.log(setupName + ": NOT Found");
        }
    }
    return defaultValue;
}

export function GetSetupString(object: any, setupName: string, defaultValue = ''): string {

    if (object && object.length > 0) {

        object = object.find(x => x.SetupName == setupName);

        if (object)
            return object.SetupString;
        else {
            console.log(object);
            console.log(setupName + ": NOT Found");
        }
    }
    return defaultValue;
}

export function GetSetupInt(object: any, setupName: string, defaultValue = 0): number {

    if (object && object.length > 0) {

        object = object.find(x => x.SetupName == setupName);

        if (object)
            return object.SetupInt;
        else {
            console.log(object);
            console.log(setupName + ": NOT Found");
        }
    }
    return defaultValue;
}

export function writeToLog(txt: any) {//added by yameen //mode = false for production
    if (environment.production)
        console.log(txt);
}
