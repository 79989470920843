import { Injectable } from "@angular/core";
import { ConfirmationModalComponent } from "./confirmation-dialog.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { AnyRecord } from "dns";
import { TranslatePipe } from "../custom-pipes/translate-pipe/translate.pipe";
import { CategoryIngredient } from "src/app/models/CantegoryIngredient/category-ingredient";
@Injectable({
  providedIn: "root"
})
export class ConfirmationDialogServices {
    public onDelete: Subject<boolean>; 
    public taskCode: any = "";
    public notes: any = "";
    public mobile: any = "";
    public rowDataIngredients: Array<CategoryIngredient> = [];
    constructor(private _bsModalService: BsModalService, private translate: TranslatePipe) {
        this.onDelete = new Subject
    }

  public showConfirmationModal(e?: any, prams?: any, isAgGridRecordDelete?: boolean, serviceName?: any, code?: any) {
    const modal = this._bsModalService.show(ConfirmationModalComponent);
    (<ConfirmationModalComponent>modal.content).showConfirmationModal(
      this.translate.transform('2588'),
      this.translate.transform('985'),
      e,
      prams,
      isAgGridRecordDelete,
      serviceName,
      code
    );
      (<ConfirmationModalComponent>modal.content).onClose.subscribe(result => {          
          this.onDelete.next(result);
        if (result === true) {                        
        // when pressed Yes
        } else if (result === false) {            
        // when pressed No
        } else {            
        // When closing the modal without no or yes
      }
    });
  }

  public showConfirmationMsgSendModal(e?: any, taskCode: any = this.taskCode, notes: any = this.notes, mobile: any = this.mobile) { 
    const modal = this._bsModalService.show(ConfirmationModalComponent);
    (<ConfirmationModalComponent>modal.content).showConfirmationMsgSendModal(
      this.translate.transform('2588'),
      this.translate.transform('3570'),
      e,
      taskCode,
      notes,
      mobile
    );
      (<ConfirmationModalComponent>modal.content).onClose.subscribe(result => {      
          this.onDelete.next(result);
          if (result === true) {                        
            // when pressed Yes
            } else if (result === false) {            
            // when pressed No
            } else {            
            // When closing the modal without no or yes
      }
    });
  }

  public showConfirmationUpdateCategoryModal(e?: any, rowDataIngredients: any = this.rowDataIngredients) { 
    const modal = this._bsModalService.show(ConfirmationModalComponent);
    (<ConfirmationModalComponent>modal.content).showConfirmationUpdateCategoryModal(
      this.translate.transform('3463'),
      this.translate.transform('3887'),
      e,
      rowDataIngredients
    );
      (<ConfirmationModalComponent>modal.content).onClose.subscribe(result => {      
          this.onDelete.next(result);
          if (result === true) {                        
            // when pressed Yes
            } else if (result === false) {            
            // when pressed No
            } else {            
            // When closing the modal without no or yes
      }
    });
  }

/**
 * Takes 3 params
 * @service business service object 
 * @code code to Delete 
 * @method (Optional) this is delete method name if any. Default: Send request to Delete Method with single param method
 * @updateStatement (Optional) a method that will call after delete confirm
 * @param1 (Optional) delete params in Order param1,param2 
 */
  public showDeleteConfirmationModal(service:any,code,method:string="",updateStatement:any = null,param1:any=null,param2:any=null) {

    const modal = this._bsModalService.show(ConfirmationModalComponent);
    (<ConfirmationModalComponent>modal.content).showDeleteConfirmationModal(
      this.translate.transform('2588'),
      this.translate.transform('985'),
      service,
      code,
      method,
      updateStatement,
      param1,param2
    );
      (<ConfirmationModalComponent>modal.content).onClose.subscribe(result => {          
          this.onDelete.next(result);
        if (result === true) {                        
        // when pressed Yes
        } else if (result === false) {            
        // when pressed No
        } else {            
        // When closing the modal without no or yes
      }
    });

  }
}
