import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { TaxRateMaster } from "src/app/models/TaxRateMaster/tax-rate-master.model";
import { ITaxRateMasterRepository } from "./ITaxRateMasterRepository";

@Injectable()
export class TaxRateMasterRepository extends GenericRepository<TaxRateMaster> implements ITaxRateMasterRepository {
    GetTaxRatNamesRec(): Observable<any> {
        return this._http
        .get(this.url + 'api/TaxRateMaster/GetTaxRatNamesRec', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/TaxRateMaster', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, taxItemOrGlobal: number, isSetting: boolean, isMethods: boolean, taxType: number): Observable<any> {
        return this._http
            .get(this.url + 'api/TaxRateMaster/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + taxItemOrGlobal + '/' + isSetting + '/' + isMethods + '/' + taxType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(TaxGroupID: string, TaxType: string) {        
        return this._http.delete(this.url + 'api/TaxRateMaster/DeleteRec/' + TaxGroupID + '/' + TaxType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetFilteredTaxRateName(taxItemOrGlobal: number = null, taxType: number=null) {
        return this.Get('GetFilteredTaxRateNames/' + taxItemOrGlobal + '/' + taxType);
    }
}
