import { IStartingNumberSetupRepository } from "./istarting-number-setup-repository";
import { StartingNumberSetup } from "src/app/models/StartingNumberSetup/starting-number-setup.model";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class StartingNumberSetupRepository extends GenericRepository<StartingNumberSetup> implements IStartingNumberSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/StartingNumberSetup', environment.urlAddress);
    }
}
