import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DeliveryCharges } from "../../models/ECommerceModels/delivery-charges";
import { IDeliveryChargesRepository } from "./IDeliveryChargesRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class DeliveryChargesRepository extends GenericRepository<DeliveryCharges> implements IDeliveryChargesRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/deliverycharges', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: DeliveryCharges): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllUnitOfMeasurements(DeliveryChargesCode: string = ""): Observable<any> {
        return this._http
            .get(this.url + 'api/deliverycharges/GetRec' + '/' + DeliveryChargesCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
