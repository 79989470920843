import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SmartAppUtilities } from 'src/app/models/common/getjsonfrom-controls-service-model';
import { ProductCardDetail, ProductCardMaster, ProductCardMasterModel, ProductCardPurchaseData, ProductCardSalesData, ProductCardUnits, ProductImport } from 'src/app/models/ProductCardMaster/ProductMasterCardModel';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { TaxGroupInModel } from 'src/app/models/tax-group/tax-rate-in-model';
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { Globals } from 'src/environments/Globals';
import { ApplicationCodes } from '../constent/applicationcodes';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
declare var $: any;
@Component({
  selector: 'app-add-new-product-slider',
  templateUrl: './add-new-product-slider.component.html'
})
export class AddNewProductSliderComponent implements OnInit {
  @Output() saveRecord = new EventEmitter<any>();
  applicationCodes = new ApplicationCodes();
  smartUtilities = new SmartAppUtilities(this.toastrService);
  IsAutoCode: boolean = true;
  IsPurchaseTax: boolean = false;
  IsSalesTax: boolean = false;
  TaxGroupName: string = "";
  isDescriptionEnabled:boolean=true;
  //Product Popup
  productCategoriesObject = [];
  productmodel: ProductImport = new ProductImport();
  uomObject = [];
  productTaxes: TaxGroupInModel[]; // will use for autcomplete search
  TableName: string = "PROC_ProductCardNames";
  CodeColumn: string = "ProductCode";

  constructor(private businessServices: BusinessServices, private toastrService: ToastrService, private translate: TranslatePipe, private global: Globals,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.display(true);
    this.businessServices.commonService.Get(this.applicationCodes.ProductCardCategories).subscribe((data: any) => {
      this.loaderService.display(false);
      this.productCategoriesObject = data.filter(x => x.TblName == this.applicationCodes.ProductCardCategories);
    });

    this.businessServices.commonService.IsAutoNumber('ProductCardMaster').subscribe(data => {
      
      this.loaderService.display(false);
      if (data) {
        
        this.IsAutoCode = data._obj;
      }
    });


    this.businessServices.unitOfMeasurementService.GetAllUnitOfMeasurements().subscribe((data: ResponseModel) => {
      if (data && data._statusCode == 200 && data._obj) {
        this.uomObject = data._obj;
      }
      else
        console.log(this.translate.transform('1479'));
    })

    this.businessServices.taxGroupRepository.GetAllTaxes().subscribe((data: ResponseModel) => {
      if (data._statusCode == 200 && data._obj) {
        this.productTaxes = data._obj.filter(x => x.TaxLevel == 1);

      } else {
        this.toastrService.error(this.translate.transform(data._message));
      }
    });
  }

  onCancelProduct() {
    this.hideSlider()
    this.toastrService.error(this.translate.transform('1573'));
  }

  showSlider() {
    this.productmodel.ProductBarcode = this.getRandomArbitrary(100000, 999999) + "-" + 1;
    $(".ProductModalForm.slider-box").animate({ width: "toggle" });
  }

  hideSlider() {
    this.productmodel = new ProductImport();
    $('.ProductModalForm.slider-box').hide({ direction: 'right' }, 'slow');
  }

  getRandomArbitrary(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }


  onProductTaxChange(code: string) {

    var selectedItems = this.productTaxes.find(x => x.TaxGroupCode == code);
    if (selectedItems) {
      this.productmodel.ProductPurchaseTax = selectedItems.TaxGroupCode;
      this.TaxGroupName = selectedItems.TaxGroupName;
    }
    else {
      this.productmodel.ProductPurchaseTax = "";
      this.TaxGroupName = "";
    }
  }

  onSalesTaxChange(code: string) {

    var selectedItems = this.productTaxes.find(x => x.TaxGroupCode == code);
    if (selectedItems) {
      this.productmodel.ProductSaleTax = selectedItems.TaxGroupCode;
      this.TaxGroupName = selectedItems.TaxGroupName;
    }
    else {
      this.productmodel.ProductPurchaseTax = "";
      this.TaxGroupName = "";
    }
  }

  createNewProduct() {

    let isValidated = this.smartUtilities.ValidateForm('#ProductModalForm');
    if (isValidated) {
      
      this.productmodel.ProductType = this.productmodel.ProductType ? this.productmodel.ProductType : 1;

      if (this.productmodel.ProductType == 1 && Number(this.productmodel.ProductCostPrice) == 0) {
        this.toastrService.error(this.translate.transform('699') + ' ' + this.translate.transform('2610'))
        return;
      }

      this.loaderService.display(true);
      let ProductCardMasterObect = new ProductCardMasterModel();
      let SalesData = new ProductCardSalesData();
      let PurchaseData = new ProductCardPurchaseData();
      let units = new Array<ProductCardUnits>();


      ProductCardMasterObect.ProductCard.NamesString = $('#MultiNames').val() + "<-|->" + this.global.cultureCode + "-<|>-" + this.productmodel.ProductNameinEnglish;
      ProductCardMasterObect.ProductCard.IsInserting = true;
      ProductCardMasterObect.ProductCard.IsMediaDataRetrieved = false;
      ProductCardMasterObect.ProductCard.IsUnitsDataRetrieved = true;
      ProductCardMasterObect.ProductCard.IsEqProductsDataRetrieved = false;
      ProductCardMasterObect.ProductCard.ProductCardDetails = this.mapToDetailForm();


      if (this.productmodel.ProductPurchaseTax) {
        PurchaseData.TaxGroupCode = this.productmodel.ProductPurchaseTax;
        PurchaseData.HoldFromPurchase = !PurchaseData.HoldFromPurchase;
      }
      else {
        PurchaseData = null;
      }

      if (this.productmodel.ProductSaleTax) {
        SalesData.TaxGroupCode = this.productmodel.ProductSaleTax;
        SalesData.HoldFromSale = false;
        SalesData.DisableDiscountGroups = false;
        SalesData.CanNotReturn = false;

      }
      else {
        SalesData = null;

      }

      let unitObj = new ProductCardUnits();
      unitObj.RowNumber = 1;
      unitObj.UnitNumber = 1;
      unitObj.ProductBarCode = this.productmodel.ProductBarcode;
      unitObj.UOMCode = this.productmodel.ProductUnitType;
      unitObj.UnitFragment = 1;
      unitObj.SalesPrice = this.productmodel.ProductSalePrice;
      unitObj.AverageCostPrice = this.productmodel.ProductCostPrice;
      units.push(unitObj);

      ProductCardMasterObect.ProductCard.ProductCardMedia = null;
      ProductCardMasterObect.ProductCard.ProductCardUnits = units;
      ProductCardMasterObect.ProductCard.ProductIngredients = null;
      ProductCardMasterObect.ProductCard.ProductCardSalesData = SalesData;
      ProductCardMasterObect.ProductCard.ProductCardPurchaseData = PurchaseData;
      ProductCardMasterObect.ProductCard.ProductCardEquivalentProducts = null;
      
      let uom = this.uomObject.find(x => x.UOMCode == this.productmodel.ProductUnitType);
      this.productmodel.UOMName = uom.Name;

      if (ProductCardMasterObect.ProductCard.ProductCardUnits && ProductCardMasterObect.ProductCard.ProductCardUnits[0].ProductBarCode) {
        
        this.businessServices.commonService.IsDuplicated('PROC_ProductCardBarCodes', 'ProductCardBarCode', ProductCardMasterObect.ProductCard.ProductCardUnits[0].ProductBarCode, 'ProductCode', $('.NewProductCode').val()).subscribe(
          (data: ResponseModel) => {
            
            if (data && data._statusCode == 200) {
              let count = ProductCardMasterObect.ProductCard.ProductCardUnits[0][0].RowNumber - 1;
              this.toastrService.error(this.translate.transform('1477'));
              this.productmodel.ProductBarcode = this.getRandomArbitrary(100000, 999999) + "-" + 1;
              this.loaderService.display(false);
            }
            else {
              this.businessServices.productCardRepository.SaveRec(ProductCardMasterObect).subscribe((data: any) => {
                if (data && data._statusCode === 200) {
                  this.toastrService.success(this.translate.transform(data._message));
                  if(data._obj !== "" && data._obj !== null && typeof data._obj !== "undefined")
                        this.productmodel.ProductCode = data._obj;
                  if (this.saveRecord) {
                    this.saveRecord.next(null);
                  }
                }
                else {
                  this.toastrService.error(this.translate.transform(data._message));
                }
                this.loaderService.display(false);
              });
            }
          });
      }



    }
  }

  private mapToDetailForm(): ProductCardDetail {

    let detailObject = new ProductCardDetail();
    detailObject.ProductCode = this.productmodel.ProductCode; // $('#ProductCode').val();
    detailObject.Description = this.productmodel.ProductDescription;
    detailObject.DoNotApplyDiscount = false;
    detailObject.IsBatchRequired = false;
    detailObject.IsExpiryDateRequired = false;
    detailObject.IsManufactureDateRequired = false;
    detailObject.IsMeasurementRequired = false;
    detailObject.IsSerialNumberRequired = false;
    detailObject.ProductCategory = this.productmodel.ProductCategory;
    detailObject.IsStockMaintained = false;//formValues.IsStockMaintained;
    detailObject.IsWarrantyRequired = false;//formValues.IsWarrantyRequired;
    detailObject.IsAutoNumbering = this.IsAutoCode;
    detailObject.MeasurementType = 0;
    detailObject.ProductType = this.productmodel.ProductType ? this.productmodel.ProductType : 1;
    detailObject.ExpiryType = 0;
    detailObject.UOMGroupCode = null;

    return detailObject;
  }

}
