import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { POSSalesInvoiceSelectTableDto } from "src/app/models/POSModels/POSSalesInvoiceSelectTableDto";
import { ResSalesInvoiceSelectTableDto } from "src/app/models/Restaurant/ResSalesInvoiceSelectTableDto";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { IResOrderHistoryRepository } from "./IResOrderHistoryRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class ResOrderHistoryRepository extends GenericRepository<ResSalesInvoiceSelectTableDto> implements IResOrderHistoryRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResOrders', environment.urlAddress);
    }

    LoadRec(code: string, invoiceType: number, billId: string, fiscalSpanID: number): Observable<ResponseModel> {
        return this.GetById('/LoadRec/' + code + '/' + invoiceType + '/' + billId + '/' + fiscalSpanID).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/ResOrders/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveAccountRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveAccountRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    
}