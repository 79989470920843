import { Injectable } from '@angular/core';
import { Globals } from '../../../environments/Globals';
import { CommonConstant } from 'src/app/shared/constent/applicationcodes';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class TranslateServiceLocal {
    private stringsData: any[];

    comStrings = new CommonConstant();

    constructor(private global: Globals, private touster: ToastrService) {
        this.updateStringsObject();
    }

    addNewString(key: any, value: string): void {
        this.stringsData[key] = value;
        this.updateLocalStorage()
    }

    updateString(key: any, value: string): void {
        this.stringsData[key] = value;
        this.updateLocalStorage()
    }

    getItem(key: string): string {
        return this.stringsData[key] || key;
    }

    clearStringsData(): void {
        this.stringsData = [];
    }

    setStringData(obj: any): void {
        if(!obj) {
            this.touster.error('Something went wrong with strings.');
            console.log(obj);
            return;
        }
        this.stringsData = obj[this.global.cultureCode];
        this.updateLocalStorage();
    }

    private updateLocalStorage() {
        localStorage.removeItem(this.comStrings.STRINGS_TRANSALTION)
        localStorage.setItem(this.comStrings.STRINGS_TRANSALTION, this.stringsData ? JSON.stringify(this.stringsData) : null)
    }

    private updateStringsObject() {

        let obj = localStorage.getItem(this.comStrings.STRINGS_TRANSALTION)

        if (obj) {
            this.stringsData = JSON.parse(obj)
        }
        // else
        // {
        //     this.touster.error('Something wrong with translation. <br> Please login again. ','Translation Error',)
        //     setTimeout(() => {
        //         this.route.navigate(['/Login']);
        //     }, 2000);
        // }
    }

}