import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IStockTransferRepository } from "./IStockTransferRepository";
import { StockTransfer } from "src/app/models/StockTransfer/stock-transfer.model";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
@Injectable(
    {
      providedIn: "root"
    }
  )
  
export class StockTransferRepository extends GenericRepository<StockTransfer> implements IStockTransferRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/StockTransfer/', environment.urlAddress);
    }

   SaveRec(item: StockTransfer): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }   
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCodeFrom: string, godownCodeTo: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCodeFrom + '/' + godownCodeTo, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerialByProduct(inwardDocument: string, productCode: string, godownCode: string, inwardRecordID: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerialByProduct/' + inwardDocument + '/' + productCode + '/' + godownCode + '/' + inwardRecordID).pipe(map(this.extractData), catchError(this.handleError));
    }
}