﻿import { Injectable, Injector } from '@angular/core';
import { BranchRepository } from '../../repositories/HR/branch-repository/BranchRepository';
import { DepartmentRepository } from '../../repositories/HR/department-repository/DepartmentRepository';
import { DesignationRepository } from '../../repositories/HR/designation-repository/DesignationRepository';
import { GradeRepository } from '../../repositories/HR/grade-repository/GradeRepository';
import { NationalityRepository } from '../../repositories/HR/nationality-repository/NationalityRepository';
import { ReligionRepository } from '../../repositories/HR/religion-repository/ReligionRepository';
import { CityRepository } from '../../repositories/HR/city-repository/CityRepository';
import { AreaRepository } from '../../repositories/HR/area-repository/AreaRepository';
import { AssetTypeRepository } from '../../repositories/HR/asset-type-repository/AssetTypeRepository';
import { InsuranceRepository } from '../../repositories/HR/insurance-repository/InsuranceRepository';
import { OfficialDocumentRepository } from '../../repositories/HR/official-document-repository/OfficialDocumentRepository';
import { EmployeeRepository } from '../../repositories/HR/employee-repository/EmployeeRepository';
import { HrCommonRepository } from '../../repositories/HR/hr-common-repository/HrCommonRepository';
import { EmployeeCustodyRepository } from 'src/app/repositories/HR/employee-custody-repository/EmployeeCustodyRepository';
import { EmployeeNoticeRepository } from '../../repositories/HR/employee-notes-repository/EmployeeNoticeRepository';
import { EmployeeDependentRepository } from '../../repositories/HR/employee-dependent-repository/EmployeeDependentRepository';
import { LeavesTypeRepository } from '../../repositories/HR/leaves-type-repository/LeavesTypeRepository';
import { WeekendStructureRepository } from '../../repositories/HR/weekend-structure-repository/EmployeeDependentRepository';
import { HRExpensesRepository } from 'src/app/repositories/HR/employee-expenses-repository/HRExpensesRepository';
import { EmployeeContractRepository } from '../../repositories/HR/employee-contract-repository/EmployeeContractRepository';
import { PayGroupRepository } from '../../repositories/HR/pay-groups-repository/PayGroupRepository';
import { TravelExpenseRepository } from 'src/app/repositories/HR/travel-expense-repository/TravelExpenseRepository';
import { SalaryTaxLevelRepository } from '../../repositories/HR/salary-tax-level-repository/SalaryTaxLevelRepository';
import { AllowanceCategoryRepository } from 'src/app/repositories/HR/allowance-category-repository/AllowanceCategoryRepository';
import { DeductionCategoryRepository } from 'src/app/repositories/HR/deduction-category-repository/DeductionCategoryRepository';
import { DeductionStructureRepository } from '../../repositories/HR/deduction-structure-repository/DeductionStructureRepository';
import { AllowanceStructureRepository } from '../../repositories/HR/allowance-structure-repository/AllowanceStructureRepository';
import { PayrollRepository } from '../../repositories/HR/payroll-repository/PayrollRepository';
import { PayrollPostingSetupRepository } from '../../repositories/HR/payroll-posting-setup-repository/PayrollPostingSetupRepository';
import { AttendanceRepository } from '../../repositories/HR/attendance-repository/AttendanceRepository';
import { VacationStructureRepository } from '../../repositories/HR/vacation-structure-repository/VacationStructureRepository';
import { EmployeeLeaveRepository } from '../../repositories/HR/employee-leave-repository/EmployeeLeaveRepository';
import { ReturnFromLeaveRepository } from '../../repositories/HR/return-from-leave-repository/ReturnFromLeaveRepository';
import { PublicHolidayRepository } from '../../repositories/HR/public-holiday-repository/PublicHolidayRepository';
import { AssetsEntryRepository } from '../../repositories/HR/assets-entry-repository/AssetsEntryRepository';
import { EOSBReasonRepository } from '../../repositories/HR/EOSBReasonRepository/EOSBReasonRepository';
import { EOSBRepository } from '../../repositories/HR/eosb-repository/EOSBRepository';
import { WithdrawlRepository } from '../../repositories/HR/withdrawl-repository/WithdrawlRepository';
import { PayrollSettingRepository } from '../../repositories/HR/payroll-setting-repository/PayrollSettingRepository';
import { LetterHeadRepository } from '../../repositories/HR/letter-head-repository/LetterHeadRepository';
import { LoanRepository } from '../../repositories/HR/loan-repository/LoanRepository';
import { TaskTypesRepository } from '../../repositories/HR/task-types-repository/TaskTypesRepository';
import { TasksRepository } from 'src/app/repositories/HR/task-repository/TaskRepository';
import { ProjectsRepository } from 'src/app/repositories/HR/projects-repository/ProjectRepository';
import { TaskRatingRepository } from 'src/app/repositories/HR/task-rating-repository/TaskRatingRepository';
import { TaskStatusRepository } from 'src/app/repositories/HR/task-status-repository/TaskStatusRepository';

@Injectable({
    providedIn: 'root'
})
export class HrBusinessService {

    constructor(private injector: Injector) { }

    private branchRepository: BranchRepository;
    public get branchService(): BranchRepository {
        if (!this.branchRepository) {
            this.branchRepository = this.injector.get(BranchRepository);
        }
        return this.branchRepository;
    }

    private departmentRepository: DepartmentRepository;
    public get departmentService(): DepartmentRepository {
        if (!this.departmentRepository) {
            this.departmentRepository = this.injector.get(DepartmentRepository);
        }
        return this.departmentRepository;
    }

    private tasksRepository: TasksRepository;
    public get tasksService(): TasksRepository {
        if (!this.tasksRepository) {
            this.tasksRepository = this.injector.get(TasksRepository);
        }
        return this.tasksRepository;
    }

    private projectsRepository: ProjectsRepository;
    public get projectsService(): ProjectsRepository {
        if (!this.projectsRepository) {
            this.projectsRepository = this.injector.get(ProjectsRepository);
        }
        return this.projectsRepository;
    }

    private taskTypesRepository: TaskTypesRepository;
    public get taskTypesService(): TaskTypesRepository {
        if (!this.taskTypesRepository) {
            this.taskTypesRepository = this.injector.get(TaskTypesRepository);
        }
        return this.taskTypesRepository;
    }

    private taskStatusRepository: TaskStatusRepository;
    public get taskStatusService(): TaskStatusRepository {
        if (!this.taskStatusRepository) {
            this.taskStatusRepository = this.injector.get(TaskStatusRepository);
        }
        return this.taskStatusRepository;
    }

    private taskRatingRepository: TaskRatingRepository;
    public get taskRatingService(): TaskRatingRepository {
        if (!this.taskRatingRepository) {
            this.taskRatingRepository = this.injector.get(TaskRatingRepository);
        }
        return this.taskRatingRepository;
    }

    private designationRepository: DesignationRepository;
    public get designationService(): DesignationRepository {
        if (!this.designationRepository) {
            this.designationRepository = this.injector.get(DesignationRepository);
        }
        return this.designationRepository;
    }

    private gradeRepository: GradeRepository;
    public get gradeService(): GradeRepository {
        if (!this.gradeRepository) {
            this.gradeRepository = this.injector.get(GradeRepository);
        }
        return this.gradeRepository;
    }

    private nationalityRepository: NationalityRepository;
    public get nationalityService(): NationalityRepository {
        if (!this.nationalityRepository) {
            this.nationalityRepository = this.injector.get(NationalityRepository);
        }
        return this.nationalityRepository;
    }

    private religionRepository: ReligionRepository;
    public get religionService(): ReligionRepository {
        if (!this.religionRepository) {
            this.religionRepository = this.injector.get(ReligionRepository);
        }
        return this.religionRepository;
    }

    private cityRepository: CityRepository;
    public get cityService(): CityRepository {
        if (!this.cityRepository) {
            this.cityRepository = this.injector.get(CityRepository);
        }
        return this.cityRepository;
    }

    private areaRepository: AreaRepository;
    public get areaService(): AreaRepository {
        if (!this.areaRepository) {
            this.areaRepository = this.injector.get(AreaRepository);
        }
        return this.areaRepository;
    }

    private assetTypeRepository: AssetTypeRepository;
    public get assetTypeService(): AssetTypeRepository {
        if (!this.assetTypeRepository) {
            this.assetTypeRepository = this.injector.get(AssetTypeRepository);
        }
        return this.assetTypeRepository;
    }

    private insuranceRepository: InsuranceRepository;
    public get insuranceService(): InsuranceRepository {
        if (!this.insuranceRepository) {
            this.insuranceRepository = this.injector.get(InsuranceRepository);
        }
        return this.insuranceRepository;
    }

    private officialDocumentRepository: OfficialDocumentRepository;
    public get officialDocumentService(): OfficialDocumentRepository {
        if (!this.officialDocumentRepository) {
            this.officialDocumentRepository = this.injector.get(OfficialDocumentRepository);
        }
        return this.officialDocumentRepository;
    }

    private employeeRepository: EmployeeRepository;
    public get employeeService(): EmployeeRepository {
        if (!this.employeeRepository) {
            this.employeeRepository = this.injector.get(EmployeeRepository);
        }
        return this.employeeRepository;
    }

    private hrCommonRepository: HrCommonRepository;
    public get hrCommonService(): HrCommonRepository {
        if (!this.hrCommonRepository) {
            this.hrCommonRepository = this.injector.get(HrCommonRepository);
        }
        return this.hrCommonRepository;
    }

    private deductionCategoryRepository: DeductionCategoryRepository;
    public get deductionCategoryService(): DeductionCategoryRepository {
        if (!this.deductionCategoryRepository) {
            this.deductionCategoryRepository = this.injector.get(DeductionCategoryRepository);
        }
        return this.deductionCategoryRepository;
    }

    private allowanceCategoryRepository: AllowanceCategoryRepository;
    public get allowanceCategoryService(): AllowanceCategoryRepository {
        if (!this.allowanceCategoryRepository) {
            this.allowanceCategoryRepository = this.injector.get(AllowanceCategoryRepository);
        }
        return this.allowanceCategoryRepository;
    }

    private _employeeCustody: EmployeeCustodyRepository;
    public get employeeCustody(): EmployeeCustodyRepository {
        if (!this._employeeCustody) {
            this._employeeCustody = this.injector.get(EmployeeCustodyRepository);
        }
        return this._employeeCustody;
    }

    private employeeNoticeRepository: EmployeeNoticeRepository;
    public get employeeNoticeService(): EmployeeNoticeRepository {
        if (!this.employeeNoticeRepository) {
            this.employeeNoticeRepository = this.injector.get(EmployeeNoticeRepository);
        }
        return this.employeeNoticeRepository;
    }

    private employeeDependentRepository: EmployeeDependentRepository;
    public get employeeDependentService(): EmployeeDependentRepository {
        if (!this.employeeDependentRepository) {
            this.employeeDependentRepository = this.injector.get(EmployeeDependentRepository);
        }
        return this.employeeDependentRepository;
    }

    private _travelExpenseRepository: TravelExpenseRepository;
    public get travelExpenseRepository(): TravelExpenseRepository {
        if (!this._travelExpenseRepository) {
            this._travelExpenseRepository = this.injector.get(TravelExpenseRepository);
        }
        return this._travelExpenseRepository;
    }

    private leavesTypeRepository: LeavesTypeRepository;
    public get leavesTypeService(): LeavesTypeRepository {
        if (!this.leavesTypeRepository) {
            this.leavesTypeRepository = this.injector.get(LeavesTypeRepository);
        }
        return this.leavesTypeRepository;
    }

    private weekendStructureRepository: WeekendStructureRepository;
    public get weekendStructureService(): WeekendStructureRepository {
        if (!this.weekendStructureRepository) {
            this.weekendStructureRepository = this.injector.get(WeekendStructureRepository);
        }
        return this.weekendStructureRepository;
    }

    
    private _hrExpensesRepository: HRExpensesRepository;
    public get hrExpensesRepository(): HRExpensesRepository {
        if (!this._hrExpensesRepository) {
            this._hrExpensesRepository = this.injector.get(HRExpensesRepository);
        }
        return this._hrExpensesRepository;
    }

    private employeeContractRepository: EmployeeContractRepository;
    public get employeeContractService(): EmployeeContractRepository {
        if (!this.employeeContractRepository) {
            this.employeeContractRepository = this.injector.get(EmployeeContractRepository);
        }
        return this.employeeContractRepository;
    }

    private payGroupRepository: PayGroupRepository;
    public get payGroupService(): PayGroupRepository {
        if (!this.payGroupRepository) {
            this.payGroupRepository = this.injector.get(PayGroupRepository);
        }
        return this.payGroupRepository;
    }

    private salaryTaxLevelRepository: SalaryTaxLevelRepository;
    public get salaryTaxLevelService(): SalaryTaxLevelRepository {
        if (!this.salaryTaxLevelRepository) {
            this.salaryTaxLevelRepository = this.injector.get(SalaryTaxLevelRepository);
        }
        return this.salaryTaxLevelRepository;
    }

    private deductionStructureRepository: DeductionStructureRepository;
    public get deductionStructureService(): DeductionStructureRepository {
        if (!this.deductionStructureRepository) {
            this.deductionStructureRepository = this.injector.get(DeductionStructureRepository);
        }
        return this.deductionStructureRepository;
    }

    private allowanceStructureRepository: AllowanceStructureRepository;
    public get allowanceStructureService(): AllowanceStructureRepository {
        if (!this.allowanceStructureRepository) {
            this.allowanceStructureRepository = this.injector.get(AllowanceStructureRepository);
        }
        return this.allowanceStructureRepository;
    }

    private payrollRepository: PayrollRepository;
    public get payrollService(): PayrollRepository {
        if (!this.payrollRepository) {
            this.payrollRepository = this.injector.get(PayrollRepository);
        }
        return this.payrollRepository;
    }

    private payrollPostingSetupRepository: PayrollPostingSetupRepository;
    public get  payrollPostingSetupService(): PayrollPostingSetupRepository {
        if (!this.payrollPostingSetupRepository) {
            this.payrollPostingSetupRepository = this.injector.get(PayrollPostingSetupRepository);
        }
        return this.payrollPostingSetupRepository;
    }

    private attendanceRepository: AttendanceRepository;
    public get attendanceService(): AttendanceRepository {
        if (!this.attendanceRepository) {
            this.attendanceRepository = this.injector.get(AttendanceRepository);
        }
        return this.attendanceRepository;
    }

    private vacationStructureRepository: VacationStructureRepository;
    public get vacationStructureService(): VacationStructureRepository {
        if (!this.vacationStructureRepository) {
            this.vacationStructureRepository = this.injector.get(VacationStructureRepository);
        }
        return this.vacationStructureRepository;
    }

    private employeeLeaveRepository: EmployeeLeaveRepository;
    public get employeeLeaveService(): EmployeeLeaveRepository {
        if (!this.employeeLeaveRepository) {
            this.employeeLeaveRepository = this.injector.get(EmployeeLeaveRepository);
        }
        return this.employeeLeaveRepository;
    }

    private returnFromLeaveRepository: ReturnFromLeaveRepository;
    public get returnFromLeaveService(): ReturnFromLeaveRepository {
        if (!this.returnFromLeaveRepository) {
            this.returnFromLeaveRepository = this.injector.get(ReturnFromLeaveRepository);
        }
        return this.returnFromLeaveRepository;
    }

    private publicHolidayRepository: PublicHolidayRepository;
    public get publicHolidayService(): PublicHolidayRepository {
        if (!this.publicHolidayRepository) {
            this.publicHolidayRepository = this.injector.get(PublicHolidayRepository);
        }
        return this.publicHolidayRepository;
    }

    private assetsEntryRepository: AssetsEntryRepository;
    public get assetsEntryService(): AssetsEntryRepository {
        if (!this.assetsEntryRepository) {
            this.assetsEntryRepository = this.injector.get(AssetsEntryRepository);
        }
        return this.assetsEntryRepository;
    }

    private eosbReasonRepository: EOSBReasonRepository;
    public get eosbReasonService(): EOSBReasonRepository {
        if (!this.eosbReasonRepository) {
            this.eosbReasonRepository = this.injector.get(EOSBReasonRepository);
        }
        return this.eosbReasonRepository;
    }

    private eosbRepository: EOSBRepository;
    public get eosbService(): EOSBRepository {
        if (!this.eosbRepository) {
            this.eosbRepository = this.injector.get(EOSBRepository);
        }
        return this.eosbRepository;
    }

    private withdrawlRepositoryy: WithdrawlRepository;
    public get withdrawlService(): WithdrawlRepository {
        if (!this.withdrawlRepositoryy) {
            this.withdrawlRepositoryy = this.injector.get(WithdrawlRepository);
        }
        return this.withdrawlRepositoryy;
    }

    private payrollSettingRepository: PayrollSettingRepository;
    public get payrollSettingService(): PayrollSettingRepository {
        if (!this.payrollSettingRepository) {
            this.payrollSettingRepository = this.injector.get(PayrollSettingRepository);
        }
        return this.payrollSettingRepository;
    }

    private letterHeadRepository: LetterHeadRepository;
    public get letterHeadService(): LetterHeadRepository {
        if (!this.letterHeadRepository) {
            this.letterHeadRepository = this.injector.get(LetterHeadRepository);
        }
        return this.letterHeadRepository;
    }

    private loanRepository: LoanRepository;
    public get loanService(): LoanRepository {
        if (!this.loanRepository) {
            this.loanRepository = this.injector.get(LoanRepository);
        }
        return this.loanRepository;
    }
}