import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ICardsRepository } from "./ICardsRepository";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CardsModel } from "../../../models/Banking/Cards/cards.model";
@Injectable(
    { providedIn: 'root' }
)
export class CardsRepository extends GenericRepository<CardsModel> implements ICardsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/cards', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage)
    }
    DeleteRec(code: string): Observable<any> {
        return this.Delete('DeleteRec/' + code);
    }
    //GetRec(code: string = ""): Observable<any> {
    //    return this.Get('GetRec/' + code);
    //}
}
