import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";

import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { ITaxesRepository } from "./ITaxesRepository";
import { TaxesModel } from "src/app/models/TaxSetup/taxes.model";

@Injectable(
    { providedIn: 'root' }
)
export class TaxesRepository extends GenericRepository<TaxesModel> implements ITaxesRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Taxes', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage)
    }
    DeleteRec(code: string): Observable<any> {
        return this.Delete('DeleteRec/' + code);
    }
    GetRec(code: string = ""): Observable<any> {
        return this.Get('GetRec/' + code);
    } 
}
