import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISalesInvoiceHistoryRepository } from "./ISalesInvoiceHistoryRepository";
import { SalesInvoiceHistory } from "../../models/SalesInvoiceHistory/sales-invoice-history.model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class SalesInvoiceHistoryRepository extends GenericRepository<SalesInvoiceHistory> implements ISalesInvoiceHistoryRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/SalesInvoiceHistory', environment.urlAddress);
    }
   
    UpdateDeliveryStatus(SalesInvoiceDetailIDs: string): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/UpdateDeliveryStatus/' + SalesInvoiceDetailIDs, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    GetAllHoldInvoices(): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetAllHoldInvoices', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }  

    GetRec(invoiceNumber: string, fiscalSpanID: number): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetRec/' + invoiceNumber + '/' + fiscalSpanID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetSaleInvoiceRec(invoiceNumber: string): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetSaleInvoiceRec/' + invoiceNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetSaleInvoiceReturn(invoiceNumber: string): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetSaleInvoiceReturn/' + invoiceNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetRecForSaleReturn(invoiceNumber: string): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetRecForSaleReturn/' + invoiceNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetRecForDeliveryStatus(invoiceNumber: string): Observable<any> {
        return this._http
            .get(this.url + 'api/SalesInvoiceHistory/GetRecForDeliveryStatus/' + invoiceNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(invoiceNumber: string) {
        return this._http.delete(this.url + 'api/SalesInvoiceHistory/DeleteRec/' + invoiceNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}