
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Login } from "../../models/Login/Login.model";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { UserModel } from "src/app/models/ErpUser/user-model";
import { IErpUserRepository } from "./IErpUserRepository";
import { Graph } from "src/app/models/ErpUser/graph-model";
import { isAccessor } from "typescript";

@Injectable(
  {
    providedIn: "root"
  }
)

export class ErpUserRepository extends GenericRepository<any> implements IErpUserRepository {

  url: string = environment.urlAddress;

  constructor(private _http: HttpClient) {
    super(_http, 'api/User/', environment.urlAddress);
  }

  SaveAccess(userID: number, IsAccess: boolean): Observable<any> {
    return this.GetById('SaveAcess/'+userID + '/' + IsAccess).pipe(map(this.extractData), catchError(this.handleError));
  }
  
  SaveRec(item: UserModel): Observable<any> {
    return this.PostData('SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
  }

  LoadUsers(): Observable<any> {
    return this.Get('SelectAll/').pipe(map(this.extractData), catchError(this.handleError));
  }

  LoadGraphs(userId:number): Observable<any> {
    return this.GetById('SelectAllGraphsByUserID/'+userId).pipe(map(this.extractData), catchError(this.handleError));
  }

  LoadRec(code: number): Observable<any> {
    return this.GetById('LoadRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
  }

  DeleteRec(code: number): Observable<any> {
    return this.Delete('DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
  }

  ResetPassword(item: any): Observable<any> {
    return this.PostData('ResetPassword', item).pipe(map(this.extractData), catchError(this.handleError));
  }
  ChangePassword(item: any): Observable<any> {
      return this.PostData('ChangePassword', item).pipe(map(this.extractData), catchError(this.handleError));
  }
  UpdateGraphs(item: Array<Graph>): Observable<any> {

    return this._http.post(this.url + "api/User/UpdateGraphs", item, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));

    // return this.PostData('UpdateGraphs', item).pipe(map(this.extractData), catchError(this.handleError));
  }
 
}
