import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusinessServices } from '../singleton/business-services';
import { Globals } from '../../../environments/Globals';

declare var $: any;

@Injectable()
export class TranslateService {
    arrayOfLangs = ['en-US', 'ar-SA']
    data: any[] = [];
    constructor(private http: HttpClient, private businessService: BusinessServices, private global: Globals) { }

    //use(lang: string) {
    //    this.global.SetCultureCode(lang);       

    //    let langPath = "";
    //    this.arrayOfLangs.forEach(element => {
    //        langPath = `/i18n/SmartAppStrings_${element || 'en_US'}.json`;

    //        this.http.get(langPath).subscribe((translation: any) => {
    //            this.data.push(translation);
    //            // if (translation && translation.Key === lang) {
    //            //     this.data = Object.assign({}, translation.Value);
    //            // }
    //        });
    //    });

    //    // langPath= `/i18n/SmartAppStrings_${lang || 'en_US'}.json`;

    //    // this.http.get(langPath).subscribe((translation: any) => {
    //    //     if (translation && translation.Key === lang) {
    //    //         this.data = Object.assign({}, translation.Value);
    //    //     }
    //    // });

    //}

     use(lang: string): Promise<{}> {

         return new Promise<{}>((resolve, reject) => {

             this.global.SetCultureCode(lang);
             // this.data = null;
             this.arrayOfLangs.forEach(element => {
                 const langPath = `/i18n/SmartAppStrings_${element || 'en_US'}.json`;

                 this.http.get(langPath).subscribe(
                     (translation: any) => {                        

                         if (translation) {                             
                             this.data.push(translation);

                         }
                         else {

                             this.businessService.commonService.SetStringsInCache().subscribe((data: any) => {
                                 if (data._obj) {
                                     this.global.SetCultureCode(lang);
                                     this.use(lang);
                                 }
                             });
                         }
                     },
                     error => {
                         console.log("File Missing!");
                         console.log("Calling server method to create file.");
                         this.businessService.commonService.SetStringsInCache().subscribe((data: any) => {
                             if (data._obj) {
                                 this.global.SetCultureCode(lang);
                                 this.use(lang);
                             }
                         });
                     }
                 );
             }); // end for each
         });
     }
}
