import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { writeToLog } from 'src/app/shared/constent/global-setup-constants';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {  
        
        if (this.isLoggedIn()) {
            let pageID = route.data["PageID"]

            if(this.checkPageAccess(pageID))
            return true;
            else{
                this.router.navigate(['/NoAccess']);
                return false;
            }
        }
        // navigate to login page as user is not authenticated      
        this.router.navigate(['/Login']);
        return false;
    }

    private isLoggedIn(): boolean {
        return localStorage.getItem('isLoggedIn') === "true";
    }

    private checkPageAccess(pageID): boolean {

        if (!pageID) return true;

        let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission') || '[]')
        if (rolepermissionObject.length > 0) {
            let permissionObj = rolepermissionObject.find(x => x.PageID == pageID)
            if (permissionObj) {
                // console.log(permissions)
                if (permissionObj.PermissionObj && permissionObj.PermissionObj.length > 0) {
                    
                    if(!permissionObj.PermissionObj[0].IsAllowed)
                        writeToLog(permissionObj);                        

                    return permissionObj.PermissionObj[0].IsAllowed;
                }
                else {
                    console.log("Operation not found");
                    // console.log(permissions);
                }
            }
            else {
                console.log(pageID + " : id not found")
            }
        }
        return true;
    }
}
