import { Component, OnInit } from '@angular/core';
import { ReportViewer, ReportFilter } from '../../models/common/report-params.model';
import { LoaderService } from '../../services/loader/LoaderService';
import { Globals } from '../../../environments/Globals';
import { CommonConstant } from '../constent/applicationcodes';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { BusinessServices } from '../../services/singleton/business-services';
import { writeToLog } from '../constent/global-setup-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dynamic-pager',
    templateUrl: './dynamic-pager.component.html',
    styles: []
})
export class DynamicPagerComponent implements OnInit {
    recordsPerPage = 20;
    reportHeader: string = "";
    reportName: string = ""; 
    commonConst = new CommonConstant();
    onNextPage: Function = null;
    reportModel: ReportFilter = new ReportFilter();
    pagiantionModel: ReportFilter = new ReportFilter();
    reportViewer: ReportViewer = new ReportViewer();
    constructor(private loaderService: LoaderService, private globals: Globals, private translate: TranslatePipe,
        private businessService: BusinessServices, private toastrService: ToastrService) {
    }

    ngOnInit() {

    }

    loadReportViewer() {

        this.loaderService.display(true);
        this.reportModel.ReportParameter = this.reportModel.Col1 + "_" + this.reportModel.Col2 + "_" + this.reportModel.Col3 + "_" + this.reportModel.Col4 + "_"
            + this.reportModel.Col5 + "_" + this.reportModel.Col6 + "_" + this.reportModel.Col7 + "_" + this.reportModel.Col8 + "_" + this.reportModel.Col9 + "_"
            + this.reportModel.Col10 + "_" + this.reportModel.Col11 + "_" + this.reportModel.Col12 + "_" + this.reportModel.Col13 + "_" + this.reportModel.Col14 + "_"
            + this.reportModel.Col15 + "_" + this.reportModel.Col16 + "_"

        this.pagiantionModel = this.reportModel;
        this.businessService.reportViewerRepository.selectReport(this.reportModel).subscribe((data) => {
            this.loaderService.display(false);
            writeToLog(data);
            this.reportViewer.isReportViewerShow = true;
            if (data._statusCode === 200 && data._obj) {
                debugger
                // console.log(data._obj)
                if(this.reportModel.PageNumber == '63' || this.reportModel.PageNumber == '61' || this.reportModel.PageNumber == '62' || this.reportModel.PageNumber == '64'
                 || this.reportModel.PageNumber == '65' || this.reportModel.PageNumber == '66' || this.reportModel.PageNumber == '67' || this.reportModel.PageNumber == '2' || this.reportModel.PageNumber =='2985' || this.reportModel.PageNumber =='3'
                 || this.reportModel.PageNumber =='5' || this.reportModel.PageNumber =='7' || this.reportModel.PageNumber == '4' || this.reportModel.PageNumber == '101'
                 || this.reportModel.PageNumber == '103' || this.reportModel.PageNumber == '104' || this.reportModel.PageNumber == '105' || this.reportModel.PageNumber == '106'){
                    if (data._obj.Defaultdata.length > 0) {
                        this.reportViewer.items = data._obj.Defaultdata;
                        setTimeout(() => { // wait until all resources loaded      
                            this.pagination({ pageNumber: 1, pageSize: 20, totalRecord: data._obj.total });
                            this.seHeader();
                        }, 100);
                    }
                }
                else if (data._obj.data.length > 0) {
                    this.reportViewer.items = data._obj.data;
                    setTimeout(() => { // wait until all resources loaded      
                        this.pagination({ pageNumber: 1, pageSize: 20, totalRecord: data._obj.total });
                        this.seHeader();
                    }, 100);
                }
            }
            else {
                this.reportViewer.items = null;
                this.toastrService.info(this.translate.transform(data._message));
            } this.pagination({ pageNumber: 1, pageSize: 20, totalRecord: 0 });
            setTimeout(() => { // wait until all resources loaded           
                this.loaderService.display(false);
            }, 100);
        });
    }

    pagination(settings?: any) {
        const defaults = {
            pageNumber: 1, pageSize: 20, totalRecord: 0, el: document.getElementById("Pager") as HTMLElement
        };

        const extensions = Object.assign({}, defaults, settings); // values in settings override values in defaults
        this.recordsPerPage = extensions.pageSize;
        let showingPage: HTMLElement;
        let pageInfo: HTMLElement;
        let btFirst: HTMLElement;
        let btPrevious: HTMLElement;
        let btNext: HTMLElement;
        let btLast: HTMLElement;
        let ddlPageSize: HTMLElement;
        const totalPages = Math.ceil(extensions.totalRecord / extensions.pageSize);
        if (extensions.totalRecord > 0)
            extensions.el.classList.remove("d-none");
        else
            extensions.el.classList.add("d-none");

        const setPagingValues = () => {

            btFirst.removeAttribute("disabled");
            btPrevious.removeAttribute("disabled");
            btNext.removeAttribute("disabled");
            btLast.removeAttribute("disabled");


            if (extensions.pageNumber === 1) {
                btFirst.setAttribute("disabled", "true");
                btPrevious.setAttribute("disabled", "true");
            }

            if (extensions.pageNumber === totalPages) {
                btNext.setAttribute("disabled", "true");
                btLast.setAttribute("disabled", "true");
            }
            pageInfo.innerText = this.translate.transform('1166') + " " + extensions.pageNumber + " " + this.translate.transform('2807') + " " + totalPages;

            if (extensions.totalRecord < extensions.pageSize) {
                showingPage.innerText = this.translate.transform('2963') + " " + (Number((extensions.pageNumber - 1) * extensions.pageSize) + 1) + " " + this.translate.transform('1088') + " " + extensions.totalRecord + " " + this.translate.transform('2807') + " " + extensions.totalRecord;
            }
            else if (Number(extensions.pageNumber * extensions.pageSize) > extensions.totalRecord) {
                showingPage.innerText = this.translate.transform('2963') + " " + (Number((extensions.pageNumber - 1) * extensions.pageSize) + 1) + " " + this.translate.transform('1088') + " " + extensions.totalRecord + " " + this.translate.transform('2807') + " " + extensions.totalRecord;
            }
            else {
                showingPage.innerText = this.translate.transform('2963') + " " + (Number((extensions.pageNumber - 1) * extensions.pageSize) + 1) + " " + this.translate.transform('1088') + " " + Number(extensions.pageNumber * extensions.pageSize) + " " + this.translate.transform('2807') + " " + extensions.totalRecord;
            }
        }

        if (extensions.el) {

            btFirst = extensions.el.querySelector(".btFirst") as HTMLElement;
            btPrevious = extensions.el.querySelector(".btPrevious") as HTMLElement;
            btNext = extensions.el.querySelector(".btNext") as HTMLElement;
            btLast = extensions.el.querySelector(".btLast") as HTMLElement;
            showingPage = extensions.el.querySelector(".showingPage") as HTMLElement;
            pageInfo = extensions.el.querySelector(".pageInfo") as HTMLElement;
            ddlPageSize = extensions.el.querySelector(".pagesize") as HTMLElement;
            setPagingValues();
            const refreshData = () => {
                this.selectNextPage(Number(extensions.pageNumber), Number(extensions.pageSize));
            }

            btFirst.onclick = () => {
                extensions.pageNumber = 1;
                refreshData();
            }
            btPrevious.onclick = () => {
                extensions.pageNumber--;
                refreshData();
            }
            btNext.onclick = () => {
                extensions.pageNumber++;
                refreshData();
            }
            btLast.onclick = () => {
                extensions.pageNumber = totalPages;
                refreshData();
            }
            ddlPageSize.onchange = () => {
                extensions.pageNumber = 1;
                extensions.pageSize = this.recordsPerPage;
                refreshData();
            }

        }



    }

    selectNextPage(currentPage: number, pageSize: number) {

        if (this.recordsPerPage > 0) {
            this.loaderService.display(true);
            if (currentPage < 1) {
                currentPage = 1;
                this.loaderService.display(false);
                return;
            }
        }

        let rowIndexStart: number =
            (currentPage === 1 ? 1 : (this.recordsPerPage * (currentPage - 1) + 1));
        let rowIndexEnd: number = (currentPage * this.recordsPerPage);

        this.reportHeader = this.reportViewer.reportHeaderLabel;
        this.loaderService.display(true);
        this.reportViewer = new ReportViewer();
        this.pagiantionModel.Skip = rowIndexStart;
        this.pagiantionModel.Next = rowIndexEnd;
        this.pagiantionModel.ReportParameter = this.reportModel.ReportParameter;
        this.pagiantionModel.PageNumber = this.reportModel.PageNumber;
        this.pagiantionModel.Flag = this.reportModel.Flag;

        this.businessService.reportViewerRepository.selectNextPage(this.pagiantionModel).subscribe(data => {
            writeToLog(data);
            if (data._statusCode === 200 && data._obj) {
                this.reportViewer.isReportViewerShow = true;
                this.reportViewer.reportHeaderLabel = this.reportHeader;
                if((this.reportModel.PageNumber == '63' || this.reportModel.PageNumber == '61' || this.reportModel.PageNumber == '62' || this.reportModel.PageNumber == '64'
                    || this.reportModel.PageNumber == '65' || this.reportModel.PageNumber == '66' || this.reportModel.PageNumber == '67'  || this.reportModel.PageNumber == '2' || this.reportModel.PageNumber =='2985' || this.reportModel.PageNumber =='3'
                || this.reportModel.PageNumber =='5' || this.reportModel.PageNumber =='7' || this.reportModel.PageNumber == '4') && data._obj.Defaultdata.length > 0)
                {
                    this.reportViewer.items = data._obj.Defaultdata;
                    setTimeout(() => { // wait until all resources loaded          
                        this.pagination({ pageNumber: currentPage, pageSize: pageSize, totalRecord: data._obj.total });
                    }, 100);
                }
               else if (data._obj.data.length > 0) {
                    this.reportViewer.items = data._obj.data;
                    setTimeout(() => { // wait until all resources loaded          
                        this.pagination({ pageNumber: currentPage, pageSize: pageSize, totalRecord: data._obj.total });
                    }, 100);
                }
            }
            else {
                writeToLog(this.translate.transform(data._message));
            }
            setTimeout(() => { // wait until all resources loaded           
                this.loaderService.display(false);
            }, 100);
        });
    }
    seHeader() { 
        const ccrNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyCCRNumber);
        const vatNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyVatNumber);
        const companyName = this.globals.getDataFromLocalStorage(this.commonConst.CompanyName);
        this.reportViewer.reportHeaderLabel = "";        
        const headerSetting: string = this.reportHeader;
        if (headerSetting) {
           
            const showCompanyName = headerSetting.includes("1");
            const showCcr = headerSetting.includes("2");
            const showVat = headerSetting.includes("3");
            
            //if (showCompanyName)
            //this.reportViewer.reportHeaderLabel = companyName; 

            if (showVat) 
                this.reportViewer.reportHeaderLabel = showCompanyName ? this.reportViewer.reportHeaderLabel + this.translate.transform("5") + ": " + vatNumber : this.translate.transform("5") + ": " + vatNumber;
          
            if (showCcr) {
                //this.reportViewer.reportHeaderLabel = (showCompanyName || showVat) ? this.reportViewer.reportHeaderLabel + " - " + this.translate.transform("6") + ": " + ccrNumber : this.translate.transform("6") + ": " + ccrNumber;
                this.reportViewer.reportHeaderCCR = this.translate.transform("6") + " : " + ccrNumber; 
            }
            else {
                this.reportViewer.reportHeaderCCR = "";
            }
                
        }
    }
    
    SelectReportFile() {
        this.loaderService.display(true);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/Report/SelectReportFile/", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.responseType = "blob";
        xhr.onload = (requestReponse: any) => {
            let response = requestReponse.currentTarget;
            if (response && response.status == 200) {
                var blob = response.response;
                //Get filename from Content-Disposition header 
                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '');
                }
                // This does the trick
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = filename;
                a.dispatchEvent(new MouseEvent('click'));

            }
            else {
                this.toastrService.error(this.translate.transform('863'))
            }
        };
        xhr.onerror = function (e) {
            console.log(e)
        };

        this.reportModel.ReportName = this.reportName;
        if (this.reportModel.Theader.length == 0) {
            this.reportModel.Theader = [];
            let header = document.getElementsByTagName("th");
            for (var i = 0; i < header.length; i++) {
                if (!document.getElementsByTagName("th")[i].textContent.includes("Sr #")) {
                    this.reportModel.Theader.push(document.getElementsByTagName("th")[i].textContent);
                }
            }
        }

        let data = JSON.stringify(this.reportModel);
        xhr.send(data);
        setTimeout(() => { // wait until all resources loaded           
            this.loaderService.display(false);
        }, 500);
    }
    printPage() {

        $('body').addClass('PrintableBody');
        $(".PrintableDiv").unwrap();
        var divToPrint = document.getElementById('divRpt');
        var htmlToPrint = '' +
            '<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap" rel="stylesheet">' +
            '<style type="text/css">' +
            '.printpage .toppaging, .printpage .printbtns, .printpage .reportpaging, .printpage .showipad, .printpage .reporttopbutton, .printpage .pagingbottom {' +
            'display: none' +
            '}' +
            'body{' +
            'font-family: "Montserrat", sans-serif;' +
            '}' +
            '@media (min-width: 1200px) {' +
            '#reportbody[dir=\"rtl\"] .col-lg-1, #reportbody[dir=\"rtl\"] .col-lg-2, #reportbody[dir=\"rtl\"] .col-lg-3, #reportbody[dir=\"rtl\"] .col-lg-4, #reportbody[dir=\"rtl\"] .col-lg-5, #reportbody[dir=\"rtl\"] .col-lg-6, #reportbody[dir=\"rtl\"] .col-lg-7, #reportbody[dir=\"rtl\"] .col-lg-8, #reportbody[dir=\"rtl\"] .col-lg-9, #reportbody[dir=\"rtl\"] .col-lg-10, #reportbody[dir=\"rtl\"] .col-lg-11, #reportbody[dir=\"rtl\"] .col-lg-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 992px) {' +
            '#reportbody[dir=\"rtl\"] .col-md-1, #reportbody[dir=\"rtl\"] .col-md-2, #reportbody[dir=\"rtl\"] .col-md-3, #reportbody[dir=\"rtl\"] .col-md-4, #reportbody[dir=\"rtl\"] .col-md-5, #reportbody[dir=\"rtl\"] .col-md-6, #reportbody[dir=\"rtl\"] .col-md-7, #reportbody[dir=\"rtl\"] .col-md-8, #reportbody[dir=\"rtl\"] .col-md-9, #reportbody[dir=\"rtl\"] .col-md-10, #reportbody[dir=\"rtl\"] .col-md-11, #reportbody[dir=\"rtl\"] .col-md-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 768px) {' +
            '#reportbody[dir=\"rtl\"] .col-sm-1, #reportbody[dir=\"rtl\"] .col-sm-2, #reportbody[dir=\"rtl\"] .col-sm-3, #reportbody[dir=\"rtl\"] .col-sm-4, #reportbody[dir=\"rtl\"] .col-sm-5, #reportbody[dir=\"rtl\"] .col-sm-6, #reportbody[dir=\"rtl\"] .col-sm-7, #reportbody[dir=\"rtl\"] .col-sm-8, #reportbody[dir=\"rtl\"] .col-sm-9, #reportbody[dir=\"rtl\"] .col-sm-10, #reportbody[dir=\"rtl\"] .col-sm-11, #reportbody[dir=\"rtl\"] .col-sm-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '#reportbody[dir=\"rtl\"] .col-xs-1, #reportbody[dir=\"rtl\"] .col-xs-2, #reportbody[dir=\"rtl\"] .col-xs-3, #reportbody[dir=\"rtl\"] .col-xs-4, #reportbody[dir=\"rtl\"] .col-xs-5, #reportbody[dir=\"rtl\"] .col-xs-6, #reportbody[dir=\"rtl\"] .col-xs-7, #reportbody[dir=\"rtl\"] .col-xs-8, #reportbody[dir=\"rtl\"] .col-xs-9, #reportbody[dir=\"rtl\"] .col-xs-10, #reportbody[dir=\"rtl\"] .col-xs-11, #reportbody[dir=\"rtl\"] .col-xs-12 {' +
            'float: right;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-right {' +
            'text-align: left;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-left {' +
            'text-align: right;' +
            '}' +
            'table,th,td {' +
            'border: none !important;' +
            'border-collapse: separate;' +
            '}' +
            'table thead tr th {' +
            'border-top:2px solid #000 !important;' +
            'border-bottom:2px solid #000 !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0em 0.5em;' +
            'font-size: 12px;' +
            'height: 34px' +
            '}' +
            'table tbody tr td {' +
            'border-top:none !important;' +
            'border-bottom:1px solid #ccc !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0.45rem 0.45rem;' +
            'font-size: 12px;' +
            '}' +
            '[dir = "rtl"] table tbody tr td' +
            '{' +
            'text-align: right !important;' +
            '}' +
            '.reportname, .texttwo{' +
            'margin-bottom: 20px;' +
            'font-size: 13px;' +
            'font-weight: 600;' +
            'display: block;' +
            '}' +
            'table {' +
            'border-bottom:2px solid #000!important;' +
            'border-collapse: collapse;' +
            '}' +
            'table thead tr th:last-child, table tbody tr td:last-child {' +
            '}' +
            'table {' +
            '}' +
            'table tbody tr td {' +
            '}' +
            'table tbody tr:nth-of-type(even) td {' +
            '}' +
            'table tbody tr:nth-of-type(odd) td {' +
            '}' +
            'table th {' +
            '}' +
            'table th:last-child {' +
            '}' +
            '.report-header {' +
            'margin-bottom:10px;' +
            '}' +
            '#reportbody[dir=\"rtl\"] table th,#reportbody[dir=\"rtl\"] table td {' +
            'text-align:right !important;' +
            '}' +
            '.report-header {' +
            'padding:  0px !important;' +
            '}' +
            '.signature-line {' +
            'margin-bottom:  0px !important;' +
            '}' +
            '.signature-line {' +
            'border-color: #000 !important;' +
            '}' +
            '.tb {' +
            'font-weight: 600;' +
            '}' +
            '.printpage .space11 {' +
            'margin: 10px 0 !important;' +
            'display: block;' +
            '}' +
            '.hrreportprint table thead tr th{' +
            'text-align: left;' +
            '}' +
            '.hrreportprint[dir="rtl"] table thead tr th {' +
            'text-align: right !important;' +
            '}' +
            '</style>';

        if (this.globals.isRTL)
            $('#divRpt').attr('dir', 'RTL').addClass('printpage');
        else
            $('#divRpt').attr('dir', 'LTR').addClass('printpage');
        htmlToPrint += divToPrint.outerHTML;
        let newWin = window.open("");
        newWin.document.write(htmlToPrint);
        $(newWin.document).find("#reportName").html(this.reportName);//show name only in case of print
        $('body').removeClass('PrintableBody');
        $('#divRpt').removeAttr('dir').removeClass('printpage');
        setTimeout(() => { // wait until all resources loaded                                                
            newWin.document.close(); // necessary for IE >= 10
            newWin.focus(); // necessary for IE >= 10
            newWin.print(); // change window to winPrint
        }, 100);
    }
}
