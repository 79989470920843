import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChannelPrices } from "../../models/ChannelPrices/channelprices.model";
import { IChannelPricesRepository } from "./IChannelPricesRepository";


@Injectable()
export class ChannelPricesRepository extends GenericRepository<ChannelPrices> implements IChannelPricesRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ChannelPrices/', environment.urlAddress);
    }
    LoadRec(PriceListCode: string): Observable<any> {
        return this.GetById('/GetRec/' + PriceListCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/ChannelPrices/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PriceListCode: string) {
        return this.Delete('/DeleteRec/' + PriceListCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(): Observable<any> {
        return this._http.get(this.url + 'api/ChannelPrices/GetAll', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}