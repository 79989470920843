import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate-pipe/translate.pipe';
import { GroupFilterPipe } from './group-filter/group-filter.pipe';
import { DateFormatterPipe } from './date-formatter.pipe/date-formatter.pipe';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ArraySumPipe } from './array-sum-pipe/array-sum.pipe';

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        TranslatePipe,
        GroupFilterPipe,
        DateFormatterPipe,
        ArraySumPipe
    ],
    exports: [TranslatePipe, GroupFilterPipe, DateFormatterPipe, PipesModule,ArraySumPipe]
})
export class ApplicationPipesModule { }
