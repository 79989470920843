import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Params } from '@angular/router';
import { filter} from 'rxjs/operators';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Injectable({
  providedIn:'root'
})
export class AppBreadcrumbService {
  public breadcrumbs: IBreadcrumb[];
  //public containder: string()
    private router;
    private route;    
    constructor(router: Router, route: ActivatedRoute){
      this.router = router;
      this.route = route;
      this.breadcrumbs = [];        
        this.router.events.pipe(filter((
        event => event instanceof NavigationEnd))).subscribe((        
          (event) => {                    
          let currentRoute = this.route.root;          
          this.breadcrumbs = this.getBreadcrumbs(currentRoute);
            return this.breadcrumbs;
        }));
  }
  
  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

    //get the child routes
      let children: ActivatedRoute[] = route.children;      
    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");
      //append route URL to URL
      url += `/${routeURL}`;      
      let data = breadcrumbs.find(ob => ob['label'] === child.snapshot.data[ROUTE_DATA_BREADCRUMB]);

      //add breadcrumb
      if (data == null && child.snapshot.data[ROUTE_DATA_BREADCRUMB]!="Home") {
        let breadcrumb: IBreadcrumb = {
          label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
          params: child.snapshot.params,
          url: url
        };
        breadcrumbs.push(breadcrumb);
        }        
      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
      }      
    return breadcrumbs;
  }

}
