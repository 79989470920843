import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IFiscalSpansRepository } from "./IFiscalSpansRepository";
import { FiscalSpans } from "../../models/FiscalSpans/fiscal-spans.model";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()
export class FiscalSpansRepository extends GenericRepository<FiscalSpans> implements IFiscalSpansRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/FiscalSpans/', environment.urlAddress);
    }

    LoadRec(FiscalSpanID?: number): Observable<any> {
        return this.GetById('/GetRec/' + FiscalSpanID).pipe(map(this.extractData), catchError(this.handleError));
    } 

    CheckDateToSave(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/CheckDateToSave', span).pipe(map(this.extractData), catchError(this.handleError));
    }

    CheckNewPeriodDate(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/CheckNewPeriodDate', span).pipe(map(this.extractData), catchError(this.handleError));
    }

    StartFiscalYear(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/StartFiscalYear', span).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    SaveDate(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/SaveDate', span).pipe(map(this.extractData), catchError(this.handleError));
    }

    CheckDataExistForNewPeriod(): Observable<ResponseModel> {
        return this.PostData('/CheckDataExistForNewPeriod',null).pipe(map(this.extractData), catchError(this.handleError));
    }    

    NextPeriodStartingDate(): Observable<ResponseModel> {
        return this.PostData('/NextPeriodStartingDate', null).pipe(map(this.extractData), catchError(this.handleError));
    }   

    ClosedBalance(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/ClosedBalance', span).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetProfitLossAccount(): Observable<ResponseModel> {
        return this.Get('/GetProfitLossAccount').pipe(map(this.extractData), catchError(this.handleError));
    }
    FinalizedBalance(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/FinalizedBalance', span).pipe(map(this.extractData), catchError(this.handleError));
    }
    CloseFiscalSpan(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/CloseFiscalSpan', span).pipe(map(this.extractData), catchError(this.handleError));
    }    

    OpenFiscalSpan(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/OpenFiscalSpan', span).pipe(map(this.extractData), catchError(this.handleError));
    }    

    RemoveFiscalSpan(span: FiscalSpans): Observable<ResponseModel> {
        return this.PostData('/RemoveFiscalSpan', span).pipe(map(this.extractData), catchError(this.handleError));
    }        
    
}