import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { ICommonRepository } from "./ICommonRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ResponseModel } from "src/app/models/SharedModels/response.model";


@Injectable()
export class CommonRepository extends GenericRepository<any> implements ICommonRepository {

    GetTransactionType(): Observable<ResponseModel> {
        return this._http
            .get(environment.urlAddress + 'api/common/GetTransactionType', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    constructor(protected _http: HttpClient) {
        super(_http, 'api/common', environment.urlAddress);
    }

    GetQuantityFromAllGodowns(productCode: string, godownCode: string = ""): Observable<any> {
        return this.Get('/GetQuantityFromAllGodowns/' + productCode + '/' + godownCode)
    }

    IsValidDate(date: string, validationOption: number): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/IsValidDate/' + date + '/' + validationOption, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }


    IsAutoNumber(PageName: string): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/IsAutoNumber/' + PageName, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    AbondonSession(): Observable<any> {
        return this.Get('AbondonSession');
    }

    RestartSessionTime(): Observable<any> {
        return this.Get('RestartSessionTime');
    }

    SetStringsInCache(): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/SetStringsInCache', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    // Will Use It for now onwards
    SetStringsInCacheCulturewise(cultureCode: string, refreshCache: boolean = false): Observable<any> {
        return this.Get('SetStringsInCache/' + cultureCode + '/' + refreshCache)
    }

    LoadLocalLanguages(): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/LoadLocalLanguages', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadAllLocalLanguages(): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/LoadAllLocalLanguages', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadLocalLanguagesWithTableValue(Table: string, CodeColumn: string, CodeValue?: string, IsInserting?: boolean, CheckFiscalSpanID?: boolean, codeCol2: string = "", valueCol2 = ""): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/LoadLocalLanguagesWithTableValue/' + Table + '/' + CodeColumn + '/' + CodeValue + '/' + IsInserting + "/" + CheckFiscalSpanID+ '/' + codeCol2 + '/' + valueCol2, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadMasterNames(code: string, codeCol: string, tableName: string): Observable<any> {

        return this._http
            .get(environment.urlAddress + 'api/common/LoadMasterNames/' + code + '/' + codeCol + '/' + tableName, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadMasterNamesSameTable(code: string, codeCol: string, nameCol: string, tableName: string): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/LoadMasterNamesSameTable/' + code + '/' + codeCol + '/' + nameCol + '/' + tableName, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SetSessionVariables(string: string, value: string): Observable<any> {
        return this.Get('SetSessionVariables/' + string + '/' + value);
    }

    IsFieldValueDuplicated(table: string, column: string, value: string): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/IsFieldValueDuplicated/' + table + '/' + column + '/' + value, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    IsDuplicated(table: string, column: string, value: string, primaryColumn: string, primaryColumnValue: string): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/IsDuplicated/' + table + '/' + column + '/' + value + '/' + primaryColumn + '/' + primaryColumnValue, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    public GetReportData(procName: string, reportParams: string): Observable<ResponseModel> {
        return this._http
            .get(environment.urlAddress + 'api/Report/GetReportData/' + procName + '/' + reportParams, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    public ConvertDateToSaveFormat(dates: string): Observable<ResponseModel> {

        while (dates.includes('/')) {
            dates = dates.replace('/', '-')
        }

        return this._http
            .get(environment.urlAddress + 'api/common/ConvertDateToSaveFormat/' + dates, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchAccount(subAccountSupport: number = 1, codeLevel: number = 0, onlyChild: boolean = false, parentKey: string = ""): Observable<any> {
        return this.GetById('/SearchAccount/' + subAccountSupport + '/' + codeLevel + '/' + onlyChild + '/' + parentKey)
    }

    SelectAnalyticTags(accountCode: string): Observable<any> {
        return this.GetById('/SearchAnalyticTagsByAccountCode/' + accountCode)
    }

    SelectTransactions(transactionNumber: number): Observable<ResponseModel> {
        return this.GetById('/SelectTransactions/' + transactionNumber)
    }

    //1 for Buyer
    //2 for vendor
    SelectEmailAddress(code: string, codeType: number) {
        return this.GetById('/SelectVendorBuyer/' + code + '/' + codeType);
    }

    //#region Test Methods By Hamza

    CachedValueCheck(actionName: string, key: string = ""): Observable<ResponseModel> {
        return this.GetById(actionName + '/' + key)
    }

    GetTimeZones(): Observable<ResponseModel> {
        return this._http
            .get(environment.urlAddress + 'api/common/GetTimeZones/', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetDates(month: string, year: string): Observable<ResponseModel> {
        return this.GetById('GetDates/' + month + '/' + year)
    }

    //#endregion

    SelectStockBatchSerial(godownCode: string, productCode: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerial/' + godownCode + '/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerialAuto(stockBatchInwardID: string[], qty: number, excludedSerials?: string[]): Observable<any> {
        return this.GetById('/SelectStockBatchSerialAuto/' + stockBatchInwardID + '/' + qty + '/' + excludedSerials).pipe(map(this.extractData), catchError(this.handleError));
    }

    //#region HR Directory

    SelectAttendanceForDirectory(employeeCode: string, month: string, year: string): Observable<ResponseModel> {
        return this.GetById('SelectAttendanceForDirectory/' + employeeCode + '/' + month + '/' + year)
    }

    SelectBenfits(employeeCode: string): Observable<ResponseModel> {
        return this.GetById('SelectBenefits/' + employeeCode)
    }


    SelectBuyerForSMS(buyerCode: string): Observable<ResponseModel> {
        return this.GetById('SelectBuyerForSMS/' + buyerCode);
    }

    LoadAllProductsLocation(): Observable<any> {
        return this._http
            .get(environment.urlAddress + 'api/common/LoadAllProductsLocation', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}

