﻿/*
 * added by Yameen 05-01-2020
 * For quantity cell
 */
import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
@Component({
    selector: 'quantity-cell',
    template: `<input #input quantityInput [(ngModel)]="value">`,
    //host: {
    //    '(change)': 'onImportQtyChange($event.target.value,rowIndex)'
    //},
})
export class QuantityEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    private params: any;
    private rowIndex: number;
    public value: number;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input', { read: ViewContainerRef }) public input;

    agInit(params: any): void {
        this.params = params;
        this.rowIndex = params.rowIndex;
        this.value = this.params.value;
        this.input.element.nativeElement.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart = false;
    }
    getValue(): any {
        return this.value;
    }
    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }
    isCancelAfterEnd(): boolean { // will reject the number if spicfy the condition
        //return this.value > 1000000;
        return false;
    };
    ngAfterViewInit() {// dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
        //this.input.element.nativeElement.focus();
    }
    focusIn(): void {
        this.input.element.nativeElement.focus();
    }
   
}