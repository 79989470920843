import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { ExpenseTypes } from "src/app/models/Finance/Expenses/expense-types.model";
import { IExpenseTypesRepository } from "./IExpenseTypesRepository";

@Injectable({providedIn:'root'})
export class ExpenseTypesRepository extends GenericRepository<any> implements IExpenseTypesRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/ExpenseType', environment.urlAddress);
    } 
    SelectAll(currentPage: number, recordsPerPage: number): Observable<ResponseModel> {        
        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage);
    }

    SaveRec(item: ExpenseTypes): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }
 
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }

}