import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Currency } from "../../models/Currency/currency.model";
import { ICurrencyRepository } from "./ICurrencyRepository";
import { CurrencyRates } from "../../models/Currency/currency-rates.model";

@Injectable()
export class CurrencyRepository extends GenericRepository<any> implements ICurrencyRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Currency', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/Currency/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(currencyCode: string): Observable<any> {
        return this.GetById('/GetRec/' + currencyCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: Currency): Observable<any> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    } 

    DeleteRec(currencyCode: string) {
        return this._http.delete(this.url + 'api/Currency/DeleteRec/' + currencyCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllCurrencyRate(currencyCode: string, currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/Currency/SelectAllCurrencyRate' + '/' + currencyCode + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadCurrencyRateRec(currencyRateID: string): Observable<any> {
        return this.GetById('/LoadCurrencyRateRec/' + currencyRateID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCurrencyRate(item: CurrencyRates): Observable<any> {
        return this.PostData('/SaveCurrencyRate', item).pipe(map(this.extractData), catchError(this.handleError));
    } 

    DeleteCurrencyRateRec(currencyRateID: string) {
        return this._http.delete(this.url + 'api/Currency/DeleteCurrencyRateRec/' + currencyRateID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
