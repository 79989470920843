import { Component, AfterViewInit, OnDestroy } from "@angular/core";

declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './ecommerce-client-layout.html'
})
export class EcommerceClientLayoutComponent implements AfterViewInit, OnDestroy {

    ngOnDestroy(): void {
        $("body").removeClass("ec");
    } 

    ngAfterViewInit(): void { 
        $("body").addClass("ec"); 
    } 

}
