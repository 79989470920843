import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WeighScaleSetup } from "../../models/WeighScaleSetup/weighscalesetup.model";
import { IWeighScaleSetupRepository } from "./IWeighScaleSetupRepository";
@Injectable()
export class WeighScaleSetupRepository extends GenericRepository<WeighScaleSetup> implements IWeighScaleSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/WeighScaleSetup/', environment.urlAddress);
    }
    LoadRec(BarcodePrefix: string): Observable<any> {
        return this.GetById('/GetRec/' + BarcodePrefix).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/WeighScaleSetup/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(BarcodePrefix: string) {
        return this.Delete('/DeleteRec/' + BarcodePrefix).pipe(map(this.extractData), catchError(this.handleError));
    }
}