import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GroupFilter',
  pure:false
})
export class GroupFilterPipe implements PipeTransform {

  transform(value: any[], valueToCompare: any, fieldToCompare: string = "GroupType"): any[] {
    
    if (!(fieldToCompare && value)) {
      return null;
    }
    return value.filter(x => x[fieldToCompare] === valueToCompare);
  }

}
