import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Godown } from "src/app/models/Godown/Godown.model";
import { IGodownRepository } from "./IGodownRepository";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
@Injectable()
export class GodownRepository extends GenericRepository<Godown> implements IGodownRepository {


    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/godown', environment.urlAddress);
    }
    GetGodowns(): Observable<any> {
        return this.Get('GetGodown').pipe(map(this.extractData), catchError(this.handleError));
    }
    GetGodownHierarchy(): Observable<any> {
        return this.Get('GetGodownHierarchy').pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(godownCode: string): Observable<any> {
        return this.GetById('GetRec/' + godownCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(): Observable<any> {
        return this.GetById('GetAll/').pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(godownCode: string) {
        return this.Delete('DeleteRec/' + godownCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}
