import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SaleQuotations } from "../../models/SaleQuotations/salequotations.model";
import { ISaleQuotationRepository } from "./ISaleQuotationRepository";

@Injectable(
    {
        providedIn: "root"
    }
)

export class SaleQuotationRepository extends GenericRepository<SaleQuotations> implements ISaleQuotationRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/SaleQuotation', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/SaleQuotation/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(quotationNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + quotationNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    ProcessQuotation(quotationNumber: string): Observable<any> {
        return this.GetById('/ProcessQuotation/' + quotationNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/SaleQuotation/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SearchQuotationNumber(SearchQuotation: any): Observable<any> {
        return this._http.post(this.url + 'api/SaleQuotation/SearchQuotationRec', SearchQuotation, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}