import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { ITasksRepository } from "./ITasksRepository";
import { EmployeeTasks } from "../../../models/HR/EmployeeTasks/employee-tasks";

@Injectable()
export class TasksRepository extends GenericRepository<any> implements ITasksRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Tasks', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string, completed: number): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText+ '/' + completed)
    }
  
    UpdateStatus(code: number, status: number, taskStatusCode: string): Observable<ResponseModel> {
        return this.GetById('UpdateStatus/' + code + '/' + status+ '/' + taskStatusCode);
    }

    SendMessage(item: EmployeeTasks): Observable<ResponseModel> {
        return this.PostData('/SendMessage/', item);
    }

    SaveRec(item: EmployeeTasks): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('NavRec/' + navType + '/' + currentIndex);
    }
    LoadRec(code: number): Observable<ResponseModel> {
        return this.GetById('GetRec/' + code);
    }

    SelectRating(code: number): Observable<ResponseModel> {
        return this.GetById('GetTaskRating/' + code);
    }
    DeleteRec(code: number): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code);
    }
    
}