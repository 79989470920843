import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { IReportViewerRepository } from "./IReportViewer";
import { ReportFilter, ReportParamsModel } from "../../models/common/report-params.model";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class ReportViewerRepository extends GenericRepository<ResponseModel> implements IReportViewerRepository {

    constructor(protected _http: HttpClient) {
        super(_http, 'api/Report/', environment.urlAddress);
    }

    public GetReportData(procName: string, reportParams: string): Observable<ResponseModel> {
        return this._http
            .get(environment.urlAddress + 'api/Report/GetReportData/' + procName + '/' + reportParams, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    public GetReportFile(procName: string, downloadType: number, reportParams: string, ): Observable<ResponseModel> {
        alert(downloadType);
        return this._http
            .get(environment.urlAddress + 'api/Report/GetReportFile/' + procName + '/' + downloadType + '/' + reportParams, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    public GenerateAndDownloadFileAsync(procName: string, downloadType: number, reportParams: string, ): Observable<ResponseModel> {
        alert(downloadType);
        return this._http
            .get(environment.urlAddress + 'api/Report/GenerateAndDownloadFileAsync/' + procName + '/' + downloadType + '/' + reportParams, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    // GetReportDataPageWise(currentPage: number, recordsPerPage: number, reportParameter): Observable<ResponseModel> {
    //     return this.Get('GetReportDataPageWise/' + currentPage + '/' + recordsPerPage + '/' + reportParameter).pipe(map(this.extractData), catchError(this.handleError));
    // }

    GetReportDataPageWise(item: any): Observable<ResponseModel> {
        return this._http.post(environment.urlAddress + 'api/Report/GetReportDataPageWise', item, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }


    GetReportDataByPost(item: any): Observable<ResponseModel> {
        return this._http.post(environment.urlAddress + 'api/Report/GetReportInData', item, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    GetReportDataForDesing(procName: string, reportParams: string): Observable<ResponseModel> {
        return this.Get('GetReportForDesign/' + procName + '/' + reportParams);
    }
    selectReport(item: any): Observable<ResponseModel> {
        return this._http.post(environment.urlAddress + 'api/Report/selectReport', JSON.stringify(item) , this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    // selectNextPage(item: ReportFilter, currentPage: number, recordsPerPage: number, reportParameter: string, sp: string = '', flag: string = ''): Observable<ResponseModel> {
    //     return this.Get('SelectNextPage/' + JSON.stringify(item) + '/' +  currentPage + '/' + recordsPerPage + '/' + reportParameter +  '/' + sp + '/' + flag).pipe(map(this.extractData), catchError(this.handleError));
    // }

    
    selectNextPage(item: any): Observable<ResponseModel> {
        return this._http.post(environment.urlAddress + 'api/Report/SelectNextPage', JSON.stringify(item) , this.httpOptions)
        .pipe(map(this.extractData), catchError(this.handleError));
    }
}

