import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISalesGroupRepository } from "./ISalesGroupRepository";
import { SalesGroup } from "../../models/SalesGroup/sales-group.model";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";


@Injectable()
export class SalesGroupRepository extends GenericRepository<SalesGroup> implements ISalesGroupRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/salesgroup', environment.urlAddress);
  }   
    SaveRec(item: SalesGroup): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SearchRec(searchText: string): Observable<any> {
        return this.GetById('SearchRec/' + searchText);
    }
}