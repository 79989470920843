import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { PaymentTerm } from "../../models/PaymentTerm/paymentterm.model";
import { IPaymentTermRepository } from "./IPaymentTermRepository";


@Injectable()
export class PaymentTermRepository extends GenericRepository<PaymentTerm> implements IPaymentTermRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PaymentTerm', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/PaymentTerm/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(PriceSetupCode: string) {
        return this._http.delete(this.url + 'api/PaymentTerm/DeleteRec/' + PriceSetupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SavePriceNames(item: PaymentTerm) {
        return this._http.post(this.url + 'api/PaymentTerm/SavePriceNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllForDD(): Observable<any> {
        return this._http
            .get(this.url + 'api/PaymentTerm/GetAllForDD', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
