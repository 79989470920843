import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IBranchRepository } from "./IBranchRepository";
import { Branch } from "../../../models/HR/Branch/branch";

@Injectable()
export class BranchRepository extends GenericRepository<Branch> implements IBranchRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/Branch', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: Branch): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}