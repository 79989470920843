import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IProductLocationRepository } from "./IProductLocationRepository";
import { ProductLocation } from "src/app/models/ProductLocation/product-location";

@Injectable()
export class ProductLocationRepository extends GenericRepository<ProductLocation> implements IProductLocationRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/productlocation', environment.urlAddress);
  }
  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductLocation/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(code: string): Observable<any> {
    return this._http
      .delete(this.url + 'api/ProductLocation/DeleteRec' + '/' + code, this.httpOptions)
      .pipe(map(this.extractData), catchError(this.handleError));
  }
  // GetUnitOfMeasurementsOnDimension(isDimensional?: boolean): Observable<any> {
  //   return this._http
  //     .get(this.url + 'api/ProductLocation/SearchUnitOfMeasurements' + '/' + isDimensional, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  // }
  GetAllProductLocation(uomCode:string = ""): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductLocation/GetRec' + '/' + uomCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  SearchLocationCode(): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductLocation/SearchLocationCode' + '/' , this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
}
