﻿import { ToastrService } from "ngx-toastr";
import { TranslatePipe } from "../../shared/custom-pipes/translate-pipe/translate.pipe";
import { LoaderService } from "../loader/LoaderService";
import { DateFormatterPipe } from "../../shared/custom-pipes/date-formatter.pipe/date-formatter.pipe";

declare var $: any;
let dateSaveFormat= "yyyyMMdd";

export function DownlaodFile(reportParams: string, storeProc: string, exportOption: string, loaderService: LoaderService, toastrService: ToastrService =  null,translate: TranslatePipe =  null) {
    if (!loaderService.status.value)
        loaderService.display(true)
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "/api/Report/GetReportFile/" + storeProc + "/" + exportOption + "/" + reportParams, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
        if(this.status == 202){
            //$('#emailSendModal').modal('show');
            $(".progrsssmain").css( "display", "block" );
            generateAndDownloadFile(reportParams,storeProc,exportOption,loaderService,toastrService,translate);
            xhr.abort();
        }
        if (this.status == 200) {
            var blob = this.response;
            //Get filename from Content-Disposition header 
            var filename = "";
            var disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition); ``
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }
                // This does the trick
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = filename;
                a.dispatchEvent(new MouseEvent('click'));
        }
        loaderService.display(false);
    };
    xhr.send();
}
export function generateAndDownloadFile(reportParams: string, storeProc: string, exportOption: string, loaderService: LoaderService, toastrService: ToastrService, translate: TranslatePipe) {

    loaderService.display(false);
    toastrService.success(translate.transform('3714'));

    // Make an AJAX request to initiate the file generation
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/Report/GenerateAndDownloadFileAsync/" + storeProc + "/" + exportOption + "/" + reportParams, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.responseType = "blob";
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                // File generation response received, hide the message
                //document.getElementById("message").style.display = "none";
                // Start the file download

                var blob = this.response;
                //Get filename from Content-Disposition header 
                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '');
                }
                if (getBoolFromLocalStorage('isDownloadingFile')) {
                    // This does the trick
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = filename;
                    a.dispatchEvent(new MouseEvent('click'));
                }
                //downloadFile();
                $( ".preloadmain" ).css( "display", "none" );
                localStorage.setItem('isDownloadingFile', "false");
                
            } else {
                toastrService.error("Error generating the file.");
                $( ".preloadmain" ).css( "display", "none" );
                localStorage.setItem('isDownloadingFile', "false");
                
            }
        }
    };
    localStorage.setItem('isDownloadingFile', "true");
    $( ".preloadmain" ).css( "display", "flex" );
    xhr.send();
}
export function getBoolFromLocalStorage(key: string): boolean {
    let storageData = localStorage.getItem(key)
    return storageData !== null && storageData.toString().toLowerCase() === 'true'
}
//for email
//function downloadFile() {
//    // Create a temporary download link
    
//    var downloadLink = document.createElement("a");
//    downloadLink.href = "/api/Report/GenerateAndDownloadFileAsync";
//    downloadLink.download = "YourGeneratedFile.xlsx";
//    downloadLink.style.display = "none";

//    // Add the link to the document body and trigger the download
//    document.body.appendChild(downloadLink);
//    downloadLink.click();
//    document.body.removeChild(downloadLink);
//}

export function EmailFile(reportParams: string, storeProc: string, emailAddress: string, loaderService: LoaderService, toastrService: ToastrService, translate: TranslatePipe) {
    if (!loaderService.status.value)
        loaderService.display(true)
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "/api/Report/EmailReportFile/" + storeProc + "/" + emailAddress + "/" + reportParams, true);
    xhr.onload = function (e) {
        if (this.status == 200) {
            toastrService.success(translate.transform('2351'));
        }
        else {
            toastrService.error(translate.transform('66'));
        }
        $('#emailSendModal').modal('hide');
        loaderService.display(false);
    };
    xhr.send();
}

export function DownlaodFile_POST(reportParams: string, storeProc: string, exportOption: string, loaderService: LoaderService, reportName: string, reportHead: string) {
    if (!loaderService.status.value)
        loaderService.display(true)
    var xhr = new XMLHttpRequest();
    let model = new ExportFileInModel();
    model.DownloadType = Number(exportOption);
    model.ProcedureName = storeProc;
    model.Params = reportParams;
    model.ReportName = reportName;
    xhr.open("POST", "/api/Report/GetReportFile_POST/", true);
    //Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.responseType = "blob";
    xhr.onload = function (e) {
        if (this.status == 200) {
            var blob = this.response;
            //Get filename from Content-Disposition header 
            var filename = "";
            var disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }
            // This does the trick
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.dispatchEvent(new MouseEvent('click'));
        }
        loaderService.display(false);
    };
    let data = JSON.stringify(model)
    xhr.send(data);
}

export function getCommaSaparatedStringOfSelectedField(selectedItems: any[], fieldName: string, delimeter: string = ','): string {
    let element = '';
    if (selectedItems && selectedItems.length > 0 && fieldName) {

        for (let index = 0; index < selectedItems.length; index++) {

            element = element + selectedItems[index][fieldName];

            if ((index + 1) !== selectedItems.length)
                element = element + delimeter
        }
    }
    return element;
}

export function getRandomArbitrary(min:number, max:number):number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export class ExportFileInModel {
    DownloadType: number;
    ProcedureName: string;
    Params: string;
    ReportName: string;
}

export function findArrayMin(arr: any[], fieldName: string): any {
    if (arr && arr.length > 0) {
        return arr.reduce((preObj, nxtObj) => {
            return (preObj[fieldName] < nxtObj[fieldName] ? preObj : nxtObj);
        });
    } else return null;
}

export function findArrayMax(arr: any[], fieldName: string): any {
    if (arr && arr.length > 0) {
        return arr.reduce((preObj, nxtObj) => {
            return (preObj[fieldName] > nxtObj[fieldName] ? preObj : nxtObj);
        });
    } else return null;
}


export function findArrayColumnSum(arrayOfObject: any[], fieldName: string) {

    if (!arrayOfObject && arrayOfObject.length == 0)
        return 0;

    let values = arrayOfObject.map((obj) => Number(obj[fieldName]) || 0)
    return values.reduce((a, b) => a + b, 0);
}
/***
 * arrayOfObject => List Of Objects 
 * fieldName =>  Field To Use for Sorting  
 */
export function sortArray(arrayOfObject: any[], fieldName: string): any[] {
    if (arrayOfObject && arrayOfObject.length == 0) return [];

    return arrayOfObject.sort((a, b) => a[fieldName] > b[fieldName] ? 1 : a[fieldName] === b[fieldName] ? 0 : -1);
}

export function findArrayColumnDistinct(arrayOfObject: any[], column: string): any[] {
    let distinctArray: any[] = []
    let isUnique: boolean = false;
    for (var i = 0; i < arrayOfObject.length; i++) {

        distinctArray.forEach(element => {
            if (arrayOfObject[i][column] == element[column]) {
                isUnique = true;
                return false;
            }
        })

        if (!isUnique) {
            distinctArray.push(arrayOfObject[i]);
        }
        isUnique = false;
    }

    return distinctArray;
}

export function GetProductTypes(translationService: any): any[] {
    return [
        { Id: 1, Name: translationService.transform('248') },
        { Id: 2, Name: translationService.transform('1189') },
        { Id: 3, Name: translationService.transform('1168') }
    ];
}
/***
 * returns array Of Object{text,value]* 
 */
export function GetMonthsArray(): { text: string, value: string }[] {
    return [
        { text: "324", value: "01" },
        { text: "337", value: "02" },
        { text: "338", value: "03" },
        { text: "339", value: "04" },
        { text: "340", value: "05" },
        { text: "341", value: "06" },
        { text: "342", value: "07" },
        { text: "343", value: "08" },
        { text: "344", value: "09" },
        { text: "345", value: "10" },
        { text: "346", value: "11" },
        { text: "347", value: "12" }
    ]
}

/***
 * yearStart => difference from current year 
 * yearEnd =>  difference from current year 
 */
export function GetYearsArray(yearStart: number, yearEnd: number): TextValue[] {
    let currentYear: number = new Date().getFullYear();
    yearEnd = currentYear + yearEnd;
    let yearArray: TextValue[] = [];
    for (let i: number = currentYear - yearStart; i <= yearEnd; i++) {
        let obj: TextValue = {
            text: String(i),
            value: String(i)
        }
        yearArray.push(obj);
    }
    return yearArray;
}

export function GetMonths(totalmonth: number): TextValue[] {
    let currentYear: number = new Date().getFullYear();
    let monthArray: TextValue[] = [];
    for (let i: number = 1; i < totalmonth; i++) {
        let obj: TextValue = {
            text: String(i),
            value: String(i)
        }
        monthArray.push(obj);
    }
    return monthArray;
}
//#region  Models and Interfaces

export interface TextValue {
    text: string, value: string
}

//#endregion
export function GetStartEndDatesOfMonth(month: string, year: string): string {

    let datePipe: DateFormatterPipe = new DateFormatterPipe();

    let startDate = new Date(Number(year), Number(month) - 1, 1);
    let EndDate = new Date(Number(year), Number(month), 0);
    let start: string = datePipe.transform(startDate);
    let end: string = datePipe.transform(EndDate);
    return start + "," + end;
}


// let monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
let monthNames = ["324", "337", "338", "339", "340", "341", "342", "343", "344", "345", "346", "347"];

export function GetMonthNamesBetweenDates(datFrom: Date, datTo: Date, tsService: TranslatePipe): Array<{ text, value }> {

    var monthArray: any[] = [];
    var fromYear = datFrom.getFullYear();
    var toYear = datTo.getFullYear();
    var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {

        let text = tsService.transform(monthNames[i % 12]) + " " + Math.floor(fromYear + (i / 12));

        let value = tsService.transform(Math.floor(fromYear + (i / 12)) + '' + ((i % 12) + 1).toString().padStart(2, '00') + '01');

        monthArray.push({
            text: text,
            value: value
        })
    }
    return monthArray;
}

export function newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function updateGridSerials(obj: Array<any>, FieldName: string = "SerialNumber") {
    if (obj && obj.length > 0) {
        let serial = 0;
        obj.forEach(element => {
            element[FieldName] = serial++;
        });
    }
}
//#region Date Related Methods
export function CreateTimeObj(time: string): Date {
    if (time && time.includes(':')) {
        let currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), Number(time.split(':')[0]), Number(time.split(':')[1]))
    }
    return new Date();
}

export function createDateTimeObj(date: string): Date {
    if(date && date.includes('/') && date.split('/').length == 3){
        let splittedDate = date.split('/');
        return new Date(Number(splittedDate[2]), Number(splittedDate[1]) - 1, Number(splittedDate[0]), 0, 0, 0, 0);
    }
    return null;
}

export function createDateSaveFormat(date: string): string {
    let formatedDate: Date = createDateTimeObj(date);
    if(formatedDate){
        let datePipe: DateFormatterPipe = new DateFormatterPipe();
        return datePipe.transform(formatedDate,dateSaveFormat);
    }
    return null;
}


//#endregion
export function ValidateEmail(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export function ValidatePhone(Phone: string): boolean {
    let regex = new RegExp(/^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})$/);
    return regex.test(Phone); 
}

export function GetHijriDate(dateTime:Date):string {        
    let localeFormat = 'ar-SA-islamic-umalqura';
    return Intl.DateTimeFormat(localeFormat).format(dateTime)
}