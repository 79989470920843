import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SignUp } from 'src/app/models/Login/SignUp.model';
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { BusinessServices } from 'src/app/services/singleton/business-services';

@Component({
  selector: 'app-client-signup',
  templateUrl: './client-signup.component.html'
})
export class ClientSignupComponent implements OnInit {
  signUp = new SignUp();
  constructor(private loaderService: LoaderService, private touster: ToastrService, private loginBusinessServices: BusinessServices) { }

  ngOnInit() {
  }


  onSubmit(companyName: string, userName: string, email: string) {
    debugger
    this.loaderService.display(true);
    this.signUp.CompanyName = companyName;
    this.signUp.Username = userName;
    this.signUp.Email = email;
    try {
        this.loginBusinessServices.signupService.ClientSignUp(this.signUp).subscribe((data: any) => {
            this.loaderService.display(false);
            if (data && data._obj) {
                if (data._statusCode === 200) {
                    this.touster.success(data._message);
                } else
                    this.touster.error(data._message);
            } else
                this.touster.error(data._message);
        });
    } catch (error) {
        console.log(error);
        this.touster.error(error);
    }
  }

}
