import { Component, Output } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-button-renderer',
  template: `
    <button type="button"  (click)="onClick($event)" ><i class="{{icon}}"></i>{{label}}</button>
    `
})
export class AgGridButtonRendererComponent implements ICellRendererAngularComp {
    params;
    label: string;
    propertyName:string;
    showIcon:boolean;
    icon:string
    agInit(params: any): void {

        // console.log(params);
        this.params = params;
        this.label = this.params.label || '';
        this.showIcon = this.params.showIcon || false;
        if(this.showIcon)
        {
          this.icon = this.params.icon;
        }
        else{
          this.icon = ''
        }
        this.propertyName = this.params.colDef.field;        
        
    }

    refresh(params?: any): boolean {
        return true;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
          // put anything into params u want pass into parents component
          const params = {
            event: $event,
            rowData: this.params.node.data,
            value: this.params.node.data[this.propertyName],
            // ...something
          }
          this.params.onClick(params);    
        }
      }
}

