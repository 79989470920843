﻿/*
 * added by Yameen 19-12-2019
 * Generic pipe to handle Number of decimals in Price, Amount, Quantity and Currency
 * --==========Input Type=============
 * 1 for Price
 * 2 for Amount
 * 3 for Quantity
  
 * in case of Amount also handle amount Format i.e Comma separator
 * --==========Amount Format values=============
 * 1 for 999999999.99
 * 2 for 99,99,99,999.99
 * 3 for 999,999,999.99
 *  --=======================
 */
import { Pipe, PipeTransform } from '@angular/core';
import { NumericService } from '../services/numeric/numeric-service';
import { Globals } from '../../environments/Globals';
import { CommonConstant } from '../shared/constent/applicationcodes';

@Pipe({
    name: 'nullableNumericPipe'
})
export class NullableNumericPipe implements PipeTransform {
    commonconstant = new CommonConstant();

    constructor(private numberService: NumericService, private globals: Globals) { }

    transform(input: any, inputType: number): any {

        var AmountFormat = this.globals.getDataFromLocalStorage(this.commonconstant.AmountFormat);
        var DecimalsInQuantity = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInQuantity);
        var DecimalsInAmount = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInAmount);
        var DecimalsInPrice = this.globals.getDataFromLocalStorage(this.commonconstant.DecimalsInPrice);

        AmountFormat = AmountFormat != null ? Number(AmountFormat) : 2;
        DecimalsInQuantity = DecimalsInQuantity != null ? Number(DecimalsInQuantity) : 2;
        DecimalsInAmount = DecimalsInAmount != null ? Number(DecimalsInAmount) : 2;
        DecimalsInPrice = DecimalsInPrice != null ? Number(DecimalsInPrice) : 2;

        if (!input) return "";
        if (input.toString().startsWith(".")) input = ("0" + input);
        if (inputType === 1)
            return this.numberService.get(input, DecimalsInPrice, AmountFormat);
        else if (inputType === 2)
            return this.numberService.get(input, DecimalsInAmount, AmountFormat);
        else if (inputType === 3)
            return this.numberService.get(input, DecimalsInQuantity, AmountFormat);
        else if (inputType === 4)
            return this.numberService.get(input, 1, AmountFormat);
        else if (inputType === 5)
            return this.numberService.get(input, DecimalsInAmount, AmountFormat);
        return this.numberService.get(input, 4, AmountFormat);
    }

}
