import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var $: any; 

@Component({
  selector: 'app-header-cart',
  templateUrl: './header-cart.component.html',
  styles: []
})

export class HeaderCartComponent implements OnInit, AfterViewInit {

    constructor() {}
     
    ngAfterViewInit() { 
        $(".headcart a").click(function () { 
            $(".headcartpopup").toggle();
        });
    }

    ngOnInit() { 
    }

}