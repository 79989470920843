import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { GenericRepository } from '../generic-repository/GenericRepository';

import { ProductCardMaster } from 'src/app/models/ProductCardMaster/productcardmaster.model';
import { IProductCardMasterCategoryRepository } from './IProduct-Card-Master-Category';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ProductCardMasterCategoryRepositoryService extends GenericRepository<ProductCardMaster> implements IProductCardMasterCategoryRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ProductCardMaster', environment.urlAddress);
    }
    LoadProductCategoryRec(productCode: string): Observable<any> {
        return this.GetById('/LoadProductCategoryRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteCategoryRec(productCode: string) {
        return this.Delete('/DeleteCategoryRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProductWithCategory(CategoryCode: string, TaxType: number): Observable<any> {
        return this.Get('/ProductWithCategory/' + CategoryCode + '/' + TaxType).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProductByProductGroup(ProductGroupCode: string): Observable<any> {
        return this.Get('/ProductByProductGroup/' + ProductGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    

}
