import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";

import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IDashboardRepository } from "./IDashboardRepository";
import { GraphsSearchModel } from "src/app/models/common/graph-values-search.model";

@Injectable(
    { providedIn: 'root' }
)
export class DashboardRepository extends GenericRepository<any> implements IDashboardRepository {
    url: string = environment.urlAddress;

    constructor(protected _http: HttpClient) {
        super(_http, 'api/Graphs', environment.urlAddress);
    }
    
    GetGraphsOnLoad(graphIds: string): Observable<any> {
        return this.Get('SelectGraphsOnLoad' + '/' + graphIds)
    }
    GetGraphValueById(grph: GraphsSearchModel): Observable<any> {
        return this.PostData('/SelectGraphsByID',grph)
    }


   

}
