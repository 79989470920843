import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SaleOrder } from "src/app/models/sale-order/sale-order.model";
import { ISaleOrderRepository } from "./ISaleOrderRepository";
import { DocumentSearchViewModel } from "../../models/common/document-search-model";
@Injectable()
export class SaleOrderRepository extends GenericRepository<SaleOrder> implements ISaleOrderRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/SalesOrder/', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/SalesOrder/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/SalesOrder/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PurchaseOrder: string) {
        return this.Delete('/DeleteRec/' + PurchaseOrder).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchSaleOrder(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/SalesOrder/SearchForSaleInvoice', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectGodownInvoices(currentPage: number, recordsPerPage: number, godownCode: string, DateFrom:string='',DateTo:string=''): Observable<any> {
        godownCode = godownCode!='' ? godownCode : 'null';
        return this.GetById('SelectGodownInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode+ '/' + DateFrom + '/' + DateTo).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaleInvoiceWithOrderSearch(DocumentNumber: string , documentType:number): Observable<any> {
        return this.GetById('/SaleInvoiceWithOrderSearch/' + DocumentNumber + '/' + documentType).pipe(map(this.extractData), catchError(this.handleError));
    }
}