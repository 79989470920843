import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IPrinterGroupRepository } from "./IPrinterGroupRepository";
import { PrinterGroup } from "../../models/PrinterGroup/printergroup.model";

@Injectable()
export class PrinterGroupRepository extends GenericRepository<PrinterGroup> implements IPrinterGroupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PrinterGroup', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/PrinterGroup/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(PrinterGroupCode: string) {
        return this._http.delete(this.url + 'api/PrinterGroup/DeleteRec/' + PrinterGroupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveGroupNames(item: PrinterGroup) {
        return this._http.post(this.url + 'api/PrinterGroup/SaveGroupNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
