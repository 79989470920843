import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CardRateSetup } from "../../../models/Banking/CardRateSetup/card-rate-setup.model";
import { ICardRateSetupRepository } from "./ICardRateSetupRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { map, catchError } from "rxjs/operators";

@Injectable(
    { providedIn: 'root' }
)
export class CardRateSetupRepository extends GenericRepository<CardRateSetup> implements ICardRateSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/cardratesetup', environment.urlAddress);
    }
    SaveRec(item: CardRateSetup): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectByCardCodeAndBankCode(cardCode: string, bankCode: string): Observable<ResponseModel> {
        return this.GetById('/SelectByCardCodeAndBankCode/' + cardCode + '/' + bankCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    Select(): Observable<ResponseModel> {
        return this.GetById('/Select').pipe(map(this.extractData), catchError(this.handleError));
    }  
}
