import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResReceiptSetup } from "src/app/models/Restaurant/resreceiptsetup.model";
import { IResReceiptSetupRepository } from "./IResReceiptSetupRepository";

@Injectable()
export class ResReceiptSetupRepository extends GenericRepository<ResReceiptSetup> implements IResReceiptSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResReceiptSetup/', environment.urlAddress);
    }
    LoadRec(CounterCode: string): Observable<any> {
        return this.GetById('/GetRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }    
}