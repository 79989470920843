import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Event, Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { LoaderService } from './services/loader/LoaderService';

@Component({
    // tslint:disable-next-line
    selector: 'body',
    template: `<router-outlet><span *ngIf="showLoader" class="loading"></span></router-outlet>`,
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {

    showLoader: boolean = false;
    showChat: boolean = true;

    constructor(private router: Router, private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
        if (window.location.host.includes('localhost')) {
            this.showChat = false;
        }
    }

    ngAfterViewInit(): void {
        loadScript();
    }

    ngOnInit() {

        // this.translateService.use('en-US');

        this.router.events.subscribe(
            (evt: Event) => {

                if (evt instanceof NavigationStart || evt instanceof RouteConfigLoadStart) {
                    this.loaderService.show();
                }

                if (evt instanceof RouteConfigLoadEnd || evt instanceof NavigationCancel || evt instanceof NavigationError || evt instanceof NavigationEnd) {
                    this.loaderService.hide();;
                }

                if (!(evt instanceof NavigationEnd)) {
                    window.scrollTo(0, 0);
                    return;
                }

            });

        this.loaderService.status.subscribe((val: boolean) => {
            if (val != this.showLoader) {
                this.showLoader = val;
                this.cdRef.detectChanges();
            }
        });
    }
}

function loadScript() {
    if (!window.location.host.includes('localhost')) {
        let node = document.createElement('script');
        node.src = '../../../assets/js/pure-chat.js';
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}