import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getLocale } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CodeNameSearchModel } from 'src/app/models/common/code-name-search.model';
import { SmartAppUtilities, TransactionPosting } from 'src/app/models/common/getjsonfrom-controls-service-model';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { Globals } from 'src/environments/Globals';
import { ApplicationConstants } from '../constent/applicationcodes';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
declare var $: any;
@Component({
  selector: 'posting-accounts-slider',
  templateUrl: './posting-accounts-slider.component.html'
})
export class PostingAccountsSliderComponent implements OnInit {
  @Output() saveRecord = new EventEmitter<any>();
  
  postingSetups = new Array<TransactionPosting>();
  accountOptions: any[] = [];
  smartUtilities = new SmartAppUtilities(this.toastrService);
  isAnalyticTagCode: boolean = false;

  constructor(private businessServices: BusinessServices, private toastrService: ToastrService, private translate: TranslatePipe, private global: Globals) {
    this.isAnalyticTagCode = this.global.getBoolFromLocalStorage(ApplicationConstants.IsAnalyticTagCodeRequired)
  }

  ngOnInit() {
  }

  onCancelPosting() {
    this.hideSlider()  
    this.toastrService.error(this.translate.transform('1573'));
  }

  showSlider() {
    this.updatePostingAccountList();
    $(".PostingTransactionSlider.slider-box").animate({ width: "toggle" });
  }

  hideSlider() {
    this.postingSetups = [];
    this.accountOptions = []
    $('.PostingTransactionSlider.slider-box').hide({ direction: 'right' }, 'slow');
  }

  onAccountChange(item: TransactionPosting, value: string) {

    let selectedItem = this.accountOptions.find(x => x.AccountCode == value);

    if (selectedItem == null || selectedItem == undefined)
      selectedItem = this.accountOptions.find(x => x.AccountName.toLowerCase() == value.toLowerCase());

    if (selectedItem) {
      item.AccountCode = selectedItem.AccountCode;
      item.AccountName = selectedItem.AccountName;
      this.setAnalyticTagAgainstAccount(selectedItem.AccountCode, item)
    }
    else {
      item.AccountCode = '';
      item.AccountName = '';

      item.AnalyticTagsList = new Array<CodeNameSearchModel>();
      item.AnalyticTagCode = ''
    }
  }

  setAnalyticTagAgainstAccount(accountCode: string, selectedObj: TransactionPosting) {
    this.businessServices.commonService.SelectAnalyticTags(accountCode).subscribe((data: ResponseModel) => {
      if (data._statusCode == 200 && data._obj) {
        selectedObj.AnalyticTagsList = data._obj;
      }
      else {
        selectedObj.AnalyticTagsList = new Array<CodeNameSearchModel>();
      }
    });
  }

  oProcessPosting() {

    let ok: boolean = this.smartUtilities.ValidateForm('#AccountsFormsContainer');

    if (!ok) {
      return false;
    }

    if (this.saveRecord) {
      this.saveRecord.next(null)
    }
  }

  private validateAccount(value, returnType = 1): any {
    let selectedItem = this.accountOptions.find(x => x.AccountCode == value);
    if (selectedItem) {
      return returnType === 1 ? true : selectedItem.AccountName;
    }
    else
      return returnType === 1 ? false : '';
  }

  // this is will set account name for setup whose account is set and analytic code is required but not given.
  private updatePostingAccountList() {

    // if AnalyticTagCode is optional we this case will skip
    if (!this.isAnalyticTagCode)
      return false

    if (this.postingSetups && this.postingSetups.length > 0 && this.accountOptions && this.accountOptions.length > 0) {
      let name: string;
      this.postingSetups.filter(x=>x.IsCodeValid == false).forEach(element => {
        debugger
        if (element.AccountCode)
          name = this.validateAccount(element.AccountCode, 2)
        if (name) {
          element.AccountName = name
        }
        else {
          element.AccountName = ''
          element.AccountCode = ''
        }
      });
    }
  }

}
