import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IPayrollPostingSetupRepository } from "./IPayrollPostingSetupRepository";
import { PayrollPostingSetup } from "../../../models/HR/PayrollPostingSetup/payroll-posting-setup.model";

@Injectable()
export class PayrollPostingSetupRepository extends GenericRepository<PayrollPostingSetup> implements IPayrollPostingSetupRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/PayrollPostingSetup', environment.urlAddress);
    }
  SaveRec(item: PayrollPostingSetup): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(paymentType: number,branchCode: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + paymentType + '/' + branchCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}