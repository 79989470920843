import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-top-buttons',
  templateUrl: './top-buttons.component.html'
})
export class TopButtonsComponent implements OnInit {
  printAllowed: boolean = true;
  showEmailButton: boolean = false;

  constructor(route: ActivatedRoute) {
    route.data.subscribe(data => {
      this.setOperationAccess(data["PageID"]);
      // if (data["PageID"] == 403003) {
      //   this.showEmailButton = true;
      // }
    });

  }

  ngOnInit() {
  }

  private setOperationAccess(pageID): void {
    if (!pageID) return;
    let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission') || '[]')
    if (rolepermissionObject.length > 0) {
      let permissions = rolepermissionObject.filter(x => x.PageID == pageID)
      if (permissions.length > 0) {
        // console.log(permissions)
        if (permissions[0].PermissionObj && permissions[0].PermissionObj.length > 0) {

          let printObj = permissions[0].PermissionObj.find(x => x.OperationID == pageID + '04');
          if (printObj)
            this.printAllowed = printObj.IsAllowed;
          else
            this.printAllowed = false;

          let emailObj = permissions[0].PermissionObj.find(x => x.OperationID == pageID + '05');
          if (emailObj)
            this.showEmailButton = emailObj.IsAllowed;
          else
            this.showEmailButton = false;

        }
        else {
          console.log("Operations not found");
          console.log(permissions);
        }
      }
      else {
        console.log(pageID + " : id not found");
      }
    }
  }
}
