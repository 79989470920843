import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-quantity-in-godowns',
  templateUrl: './product-quantity-in-godowns.component.html',
  styles: []
})
export class ProductQuantityInGodownsComponent implements OnInit {
  title: string;
  closeBtnName: string;
  listOfProductQtyInGodowns: any[] = [];
 
  constructor(public bsModalRef: BsModalRef) {  
  }
 
  ngOnInit() {    
  }
}
