import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IProcurementPostingSetupRepository } from "./IProcurementPostingSetupRepository";
import { ProcurementPostingSetup } from "src/app/models/ProcurementPostingSetup/procurement-posting-setup.model";

@Injectable()
export class ProcurementPostingSetupRepository extends GenericRepository<ProcurementPostingSetup> implements IProcurementPostingSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/procurementpostingsetup', environment.urlAddress);
    }
    GetRec(GodownCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/procurementpostingsetup/Select/' + GodownCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllRec(GodownCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/procurementpostingsetup/SelectAll/' + GodownCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
