import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GenericRepository } from '../generic-repository/GenericRepository';
import { IProductCardMasterRepository } from './product-card-master-repository.interface';
import { ProductsImport, ProductCardMaster, ProductCardSalesTax, ProductCategories } from 'src/app/models/ProductCardMaster/ProductMasterCardModel';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';

@Injectable({
    providedIn: 'root'
})

export class ProductCardMasterRepositoryService extends GenericRepository<ProductCardMaster> implements IProductCardMasterRepository {

    LoadRec(productCode: string): Observable<any> {
        return this.GetById('LoadRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadNavRec(navType: number, currentIndex: number): Observable<any> {
        return this.GetById('LoadNavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProductOnLowQuantity(skip: number, next: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this.GetById('ProductOnlowQuantitySelectAll/' + skip + '/' + next+ '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadMeasurementRec(productCode: string): Observable<any> {
        return this.GetById('LoadMeasurementRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadSalesRec(productCode: string): Observable<any> {
        return this.GetById('LoadSalesRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadPurchaseRec(productCode: string): Observable<any> {
        return this.GetById('LoadPurchaseRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadMediaRec(productCode: string): Observable<any> {
        return this.GetById('LoadMediaRec/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    IsProductCardUnitDeleteAble(productCode: string,productBarCode:string): Observable<any> {
        return this.GetById('IsProductCardUnitDeleteAble/' + productCode + '/' + productBarCode)
    }

    LoadEqProducts(productCode: string): Observable<any> {
        return this.GetById('LoadEqProducts/' + productCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllProductCardMaster(isCategory: boolean): Observable<any> {
        return this._http
            .get('api/ProductCardMaster/GetAllProductCardMaster' + '/' + isCategory, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProductSearch(searchCodeName: string, taxtType: number, productType: number = 0): Observable<any> {
        return this._http
            .get('api/ProductCardMaster/PROC_ProductCardNames' + '/' + taxtType + '/' + productType + '?Name=' + searchCodeName, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    SelectProducts(searchCodeName:string, isExact:Boolean): Observable<any> {
        return this._http
            .get('api/ProductCardMaster/SelectProducts' + '/' + searchCodeName +'/' + isExact, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectInventoryProducts(searchCodeName: string, isExact: Boolean): Observable<any> {
        return this._http
            .get('api/ProductCardMaster/SelectInventoryProducts' + '/' + searchCodeName + '/' + isExact, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteProductCardMaster(ProductCode: string) {
        return this._http.delete('/DeleteProductCardMaster/' + ProductCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(productCardMaster: any): Observable<any> {
        return this._http.post('api/ProductCardMaster/SaveRec', productCardMaster, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectProductsByCategory(productCategory: ProductCategories): Observable<any> {
        return this._http.post('api/ProductCardMaster/SelectProductsByCategory', productCategory, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectParentProductsByCategory(productCategory: ProductCategories): Observable<any> {
        return this._http.post('api/ProductCardMaster/SelectParentProductsByCategory', productCategory, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectProductsByCategoryForECommerce(productCategory: string): Observable<any> {
        return this.Get('SelectProductsByCategoryForECommerce/' + productCategory)
    }

    updateProductTax(productTax: ProductCardSalesTax): Observable<any> {
        return this._http.post('api/ProductCardMaster/updateProductTax', productTax, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectProductCodeforInsert(): Observable<any> {
        return this._http.get('api/ProductCardMaster/GetReferenceByName', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectAllcodeAndBarCodes(): Observable<any> {
        return this._http.get('api/ProductCardMaster/GetAllcodeAndBarCodes', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveProductImportRec(ImportProducSaveData: ProductsImport): Observable<any> {
        return this._http.post('api/ProductCardMaster/SaveProductImportRec', ImportProducSaveData, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectAllProductsData(): Observable<any> {
        return this._http.get('api/ProductCardMaster/GetAllProductsData', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ProductCardMaster', environment.urlAddress);
    }

    SelectAllColorAndSizes():Observable<ResponseModel>{
        return this.GetById('SelectAllColorAndSizes').pipe(map(this.extractData), catchError(this.handleError));        
    }
    
    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('/SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));;
    }
    
    ProductSearch_ForPurchase(searchCodeName: string, taxType: number = 1, productType = 0): Observable<any> {
        return this._http
            .get('api/ProductCardMaster/PROC_ProductCardNames_ForPurchase' + '/' + taxType + '/' + productType + '?Name=' + searchCodeName, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
