import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResStrings } from "src/app/models/Restaurant/res-strings.model";
import { IResStringsRepository } from "./IResStringsRepository";
import { environment } from "src/environments/environment";


@Injectable()
export class ResStringsRepository extends GenericRepository<ResStrings> implements IResStringsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResStrings', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> { 
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/ResStrings/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(smartAppStringID: number): Observable<any> {
        return this.GetById('/GetRec/' + smartAppStringID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: ResStrings): Observable<any> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }     
}
