import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ProductSize } from "src/app/models/ProductSize/productSize.model";
import { IProductSizeRepository } from "./IProductSizeRepository";

@Injectable()
export class ProductSizeRepository extends GenericRepository<ProductSize> implements IProductSizeRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ProductSize', environment.urlAddress);
    }

    GetAll(skip: number, next: number): Observable<any> {
        return this._http
            .get(this.url + 'api/ProductSize/SelectAll' + '/' + skip + '/' + next, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(productSizeCode: string, cultureCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/ProductSize/Select' + '/' + productSizeCode + '/' + cultureCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(productsSizeCode: string) {
        return this._http.delete(this.url + 'api/ProductSize/DeleteRec/' + productsSizeCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: ProductSize) {
        return this._http.post(this.url + 'api/ProductSize/SaveRec', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
