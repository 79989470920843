import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IReportSetupRepository } from "./IReportSetupRepository";
import { ReportSetup } from "../../models/ReportSetup/report-setup.model";
import { ResponseModel } from "../../models/SharedModels/response.model";
@Injectable()
export class ProcurementReportRepository extends GenericRepository<ReportSetup> implements IReportSetupRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/procurementreportretup', environment.urlAddress);
  }   
    SaveRec(item: ReportSetup): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }   
    GetReports(mouduleID: number): Observable<ResponseModel> {
        return this.GetById('/GetReports/' + mouduleID).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetReportsByPageID(pageID: number): Observable<ResponseModel> {
        return this.GetById('/GetReportsByPageID/' + pageID).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetReportsBySerialNo(pageID: number, serialNo: number): Observable<ResponseModel> {
        return this.GetById('/GetReportsBySerialNo/' + pageID + '/' + serialNo).pipe(map(this.extractData), catchError(this.handleError));
    }

    SavePreviewHtml(item: ReportSetup): Observable<ResponseModel> {
        return this.PostData('/SavePreviewHtml', item).pipe(map(this.extractData), catchError(this.handleError));

    }
}