﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { MediaModel } from "../../models/ApprovedService/MediaModel";
import { PartnerModel } from "../../models/PartnerServices/Partner";
import { PartnerServicesModel } from "../../models/PartnerServices/PartnerServicesModel";
import { ServiceRequest } from "../../models/PartnerServices/ServiceRequest";

import { ResponseModel } from "../../models/SharedModels/response.model";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { IPartnerServicesRepository } from "./IPartnerServicesRepository";

@Injectable({ providedIn: 'root' })
export class PartnerServicesRepository extends GenericRepository<any> implements IPartnerServicesRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PartnerSerivces', environment.urlAddress);
    }

    SelectAllApproved() {
        return this.Get('/SelectAllApproved').pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectApprovedServiceByServiceCode(ServicesCode: string): Observable<any> {
        return this.Get('/SelectServiceByServiceCode/' + ServicesCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetPartners() {
       
        return this._http
            .get('/api/PartnerSerivces/GetPartners').pipe(map(this.extractData), catchError(this.handleError));
    }

    GetPartnerServicesByPartnerID(PartnerCode:string) {
     
        return this._http
            .get('/api/PartnerSerivces/GetPartnerServices/' + PartnerCode).pipe(map(this.extractData), catchError(this.handleError));

    }

    Select_AllPartnerServiceType() {
        return this.Get('/Select_AllPartnerServiceType').pipe(map(this.extractData), catchError(this.handleError));
    }

    Select(ServiceCode: string, ClientRegistrationCode: string): Observable<any>  {
     
        return this.Get('/Select/' + ServiceCode + '/' + ClientRegistrationCode).pipe(map(this.extractData), catchError(this.handleError));

    }

    ServiceRequest(model: PartnerServicesModel): Observable<any> {
             return this.PostData('/ServiceRequest', model).pipe(map(this.extractData), catchError(this.handleError));
       

    }

    GetMyServices() {

        return this._http
            .get('/api/PartnerSerivces/GetMyServices').pipe(map(this.extractData), catchError(this.handleError));
    }


  


    SelectMyService(ServiceCode: string, requestId: string): Observable<any> {
        return this.Get('/SelectMyService/' + ServiceCode + '/' + requestId).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveMediaContent(model: Array<MediaModel>): Observable<any> {
        return this.PostData('/SaveMediaContent' , model).pipe(map(this.extractData), catchError(this.handleError));
    }
}
