import { Injectable } from '@angular/core';    
import { Router } from '@angular/router';
import { BusinessServices } from 'src/app/services/singleton/business-services';
// import { ILogin } from '../interfaces/login';   
    
@Injectable({    
   providedIn: 'root'    
})    
export class AuthService {
   // cm = new CommonConstant();
   // private pageID:number;
   constructor(private router: Router,private businessSerivce:BusinessServices){
      
     
   }

   logout(): void {  
        
      // this.businessSerivce.commonService.AbondonSession().subscribe(data => {
      //    localStorage.clear();
      //    console.log("LogOut");
      // }); 
      localStorage.clear();
      this.router.navigate(['/Login']);
   }

   resetServerSessionTime(): void {

      this.businessSerivce.commonService.RestartSessionTime().subscribe(data => {         
         console.log("Session Re-Activated");
      });
      
   }

   // GetRolePermissions(){
   //    let roleId = this.gb.getDataFromLocalStorage(this.cm.UserRoleID)
   //    this.businessSerivce.accessRightsRepository.LoadOperationsForModulePages(0,roleId).subscribe((data:ResponseModel)=>{
   //       if(data._statusCode == 200){
   //          let modulePagesObj = data._obj.Table;
   //          data._obj.Table1;
   //          if(data._obj.Table1 && data._obj.Table1.length>0)
   //          {
   //             modulePagesObj.forEach((element, index) => {
   //                element.PermissionObj = data._obj.Table1.filter(x => x.PageID == element.PageID);             
   //             });
   //          }
   //          console.log(modulePagesObj)
   //          this.gb.setDataInLocalStorage(this.cm.RoleOperationsPermission,JSON.stringify(modulePagesObj))
   //       }
   //    })

   // }

   

}  
