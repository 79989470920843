import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IPayrollRepository } from "./IPayrollRepository";
import { Payroll } from "../../../models/HR/Payroll/payroll.model";
import { GeneralDocuments } from "../../../models/SharedModels/General-documents.model";

@Injectable()
export class PayrollRepository extends GenericRepository<any> implements IPayrollRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Payroll', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, employeeCode: string, executionMonth: string, executionYear: string): Observable<any> {
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + (employeeCode ? employeeCode : null) + '/' + (executionMonth ? executionMonth : null) + '/' + (executionYear ? executionYear : null))
    }
    GetAllHistory(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        return this.Get('/SelectAllHistory' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: Payroll): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex);
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code);
    }
    LoadDocRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetDocRec/' + code);
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code);
    }
    DeleteHistory(): Observable<ResponseModel> {
        return this.Delete('/DeleteHistory/');
    }

    SaveDocRec(item: GeneralDocuments): Observable<ResponseModel> {
        return this.PostData('/SaveDocRec', item);
    }
}