import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SaleInvoice } from "../../models/SaleInvoice/sale-invoice.model";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { TransactionPosting } from "../../models/common/getjsonfrom-controls-service-model";
import { POSSalesInvoiceSelectTableDto } from "src/app/models/POSModels/POSSalesInvoiceSelectTableDto";
import { ActivityTraceModel } from "src/app/models/ActivityTrace/activity.trace.model";
import { IActivityTracingRepository } from "./IActivityTracingRepository";

@Injectable(
    {
        providedIn: "root"
    }
)

export class ActivityTracingRepository extends GenericRepository<ActivityTraceModel> implements IActivityTracingRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ActivityTracing', environment.urlAddress);
    }
  
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/ActivityTracing/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
 
}