import { Injectable, Injector } from '@angular/core';
import { AccountCodeSetupRepository } from '../../repositories/finance/account-code-setup-repository/AccountCodeSetupRepository';
import { AccountMasterRepository } from '../../repositories/finance/account-master-repository/AccountMasterRepository';
import { AccountMasterBalanceRepository } from '../../repositories/finance/account-master-balance-repository/AccountMasterBalanceRepository';
import { AccountBookSetupRepository } from '../../repositories/finance/account-book-setup-repository/AccountBookSetupRepository';
import { ReceiptsRepository } from '../../repositories/finance/receipts-repository/ReceiptsRepository';
import { PaymentsRepository } from '../../repositories/finance/payments-repository/PaymentsRepository';
import { DebitNotesRepository } from '../../repositories/finance/debit-notes-repository/DebitNotesRepository';
import { CreditNotesRepository } from '../../repositories/finance/credit-notes-repository/CreditNotesRepository';
import { JournalVoucherRepository } from '../../repositories/finance/journal-voucher-repository/JournalVoucherRepository';
import { TransactionViewerRepository } from '../../repositories/finance/transaction-viewer-repository/TransactionViewerRepository';
import { VoucherRepository } from 'src/app/repositories/voucher-repository/VoucherRepository';
import { InvoicePostingSetupRepository } from '../../repositories/invoice-posting-setup-repository/InvoicePostingSetup';
import { ChequesRepository } from 'src/app/repositories/finance/cheques-repository/ChequesRepository';
import { IncomingPaymentsRepository } from '../../repositories/finance/incoming-payments-repository/IncomingPaymentsRepository';
import { OutgoingPaymentsRepository } from '../../repositories/finance/outgoing-payments-repository/OutgoingPaymentsRepository';
import { ChequesProcessRepository } from 'src/app/repositories/finance/cheques-process-repository/ChequesProcessRepository';
import { InvoiceRepository } from '../../repositories/finance/invoice-repository/InvoiceRepository';
import { InvoiceCollectionRepository } from '../../repositories/finance/invoice-collection-repository/InvoiceCollectionRepository';
import { PLBSClassNamesRepository } from '../../repositories/plbs-class-names-repository/PLBSClassNamesRepository';
import { ExpenseTypesRepository } from 'src/app/repositories/finance/expense-types-repository/ExpenseTypesRepository';
import { ExpensesRepository } from 'src/app/repositories/finance/expensesRepository/ExpensesRepository';
import { ExpensePostingSetupRepository } from 'src/app/repositories/finance/expense-posting-setup-repository/ExpensePostingSetupRepository';
import { AnalyticGroupsRepository } from '../../repositories/analytic-groups-repository/AnalyticGroupsRepository';
import { AnalyticTagsRepository } from 'src/app/repositories/finance/analytic-tags-repository/AnalyticTagsRepository';
import { AccountTransferRepository } from '../../repositories/finance/account-transfer-repository/AccountTransferRepository';
import { ChequeBookConfigurationRepository } from '../../repositories/finance/cheque-book-configuration-repository/ChequeBookConfigurationRepository';
import { FundsTransferRepository } from 'src/app/repositories/finance/funds-transfer-repository/FundsTransferRepository';


@Injectable({
  providedIn: 'root'
})
export class FinanceBusinessService {

    constructor(private injector: Injector) { }

    private _accountCodeSetupRepository: AccountCodeSetupRepository;
    public get accountCodeSetupRepository(): AccountCodeSetupRepository {
        if (!this._accountCodeSetupRepository) {
            this._accountCodeSetupRepository = this.injector.get(AccountCodeSetupRepository);
        }
        return this._accountCodeSetupRepository;
    }

    private _accountMasterRepository: AccountMasterRepository;
    public get accountMasterRepository(): AccountMasterRepository {
        if (!this._accountMasterRepository) {
            this._accountMasterRepository = this.injector.get(AccountMasterRepository);
        }
        return this._accountMasterRepository;
    }

    private _accountMasterBalanceRepository: AccountMasterBalanceRepository;
    public get accountMasterBalanceRepository(): AccountMasterBalanceRepository {
        if (!this._accountMasterBalanceRepository) {
            this._accountMasterBalanceRepository = this.injector.get(AccountMasterBalanceRepository);
        }
        return this._accountMasterBalanceRepository;
    }

    private _accountBookSetupRepository: AccountBookSetupRepository;
    public get accountBookSetupRepository(): AccountBookSetupRepository {
        if (!this._accountBookSetupRepository) {
            this._accountBookSetupRepository = this.injector.get(AccountBookSetupRepository);
        }
        return this._accountBookSetupRepository;
    }

    private _receiptsRepository: ReceiptsRepository;
    public get receiptsRepository(): ReceiptsRepository {
        if (!this._receiptsRepository) {
            this._receiptsRepository = this.injector.get(ReceiptsRepository);
        }
        return this._receiptsRepository;
    }

    private _incomingPaymentsRepository: IncomingPaymentsRepository;
    public get incomingPaymentsService(): IncomingPaymentsRepository {
        if (!this._incomingPaymentsRepository) {
            this._incomingPaymentsRepository = this.injector.get(IncomingPaymentsRepository);
        }
        return this._incomingPaymentsRepository;
    }
    private _outgoingPaymentsRepository: OutgoingPaymentsRepository;
    public get outgoingPaymentsService(): OutgoingPaymentsRepository {
        if (!this._outgoingPaymentsRepository) {
            this._outgoingPaymentsRepository = this.injector.get(OutgoingPaymentsRepository);
        }
        return this._outgoingPaymentsRepository;
    }
    private _fundsTransferRepository: FundsTransferRepository;
    public get fundsTransferService(): FundsTransferRepository {
        if (!this._fundsTransferRepository) {
            this._fundsTransferRepository = this.injector.get(FundsTransferRepository);
        }
        return this._fundsTransferRepository;
    }
    private _paymentsRepository: PaymentsRepository;
    public get paymentsRepository(): PaymentsRepository {
        if (!this._paymentsRepository) {
            this._paymentsRepository = this.injector.get(PaymentsRepository);
        }
        return this._paymentsRepository;
    }

    private _debitNotesRepository: DebitNotesRepository;
    public get debitNotesRepository(): DebitNotesRepository {
        if (!this._debitNotesRepository) {
            this._debitNotesRepository = this.injector.get(DebitNotesRepository);
        }
        return this._debitNotesRepository;
    }

    private _creditNotesRepository: CreditNotesRepository;
    public get creditNotesRepository(): CreditNotesRepository {
        if (!this._creditNotesRepository) {
            this._creditNotesRepository = this.injector.get(CreditNotesRepository);
        }
        return this._creditNotesRepository;
    }

    private _journalVoucherRepository: JournalVoucherRepository;
    public get journalVoucherRepository(): JournalVoucherRepository {
        if (!this._journalVoucherRepository) {
            this._journalVoucherRepository = this.injector.get(JournalVoucherRepository);
        }
        return this._journalVoucherRepository;
    }

    private _voucherRepository: VoucherRepository;
    public get voucherRepository(): VoucherRepository {
        if (!this._voucherRepository) {
            this._voucherRepository = this.injector.get(VoucherRepository);
        }
        return this._voucherRepository;
    }

    private _chequesRepository: ChequesRepository;
    public get chequesRepository(): ChequesRepository {
        if (!this._chequesRepository) {
            this._chequesRepository = this.injector.get(ChequesRepository);
        }
        return this._chequesRepository;
    }

    private _chequesProcessRepository: ChequesProcessRepository;
    public get chequesProcessRepository(): ChequesProcessRepository {
        if (!this._chequesProcessRepository) {
            this._chequesProcessRepository = this.injector.get(ChequesProcessRepository);
        }
        return this._chequesProcessRepository;
    }

    private _chequeBookConfigurationRepository: ChequeBookConfigurationRepository;
    public get ChequeBookConfigurationRepository(): ChequeBookConfigurationRepository {
        if (!this._chequeBookConfigurationRepository) {
            this._chequeBookConfigurationRepository = this.injector.get(ChequeBookConfigurationRepository);
        }
        return this._chequeBookConfigurationRepository;
    }
    
    private _transactionViewerRepository: TransactionViewerRepository;
    public get transactionViewerRepository(): TransactionViewerRepository {
        if (!this._transactionViewerRepository) {
            this._transactionViewerRepository = this.injector.get(TransactionViewerRepository);
        }
        return this._transactionViewerRepository;
    }

    private _invoicePostingSetupRepository: InvoicePostingSetupRepository;
    public get invoicePostingSetupRepository(): InvoicePostingSetupRepository {
        if (!this._invoicePostingSetupRepository) {
            this._invoicePostingSetupRepository = this.injector.get(InvoicePostingSetupRepository);
        }
        return this._invoicePostingSetupRepository;
    }

    private _invoiceRepository: InvoiceRepository;
    public get invoiceRepository(): InvoiceRepository {
        if (!this._invoiceRepository) {
            this._invoiceRepository = this.injector.get(InvoiceRepository);
        }
        return this._invoiceRepository;
    }

    private _invoiceCollectionRepository: InvoiceCollectionRepository;
    public get invoiceCollectionRepository(): InvoiceCollectionRepository {
        if (!this._invoiceCollectionRepository) {
            this._invoiceCollectionRepository = this.injector.get(InvoiceCollectionRepository);
        }
        return this._invoiceCollectionRepository;
    }

    private _plbsClassNamesRepository: PLBSClassNamesRepository;
    public get plbsClassNamesRepository(): PLBSClassNamesRepository {
        if (!this._plbsClassNamesRepository) {
            this._plbsClassNamesRepository = this.injector.get(PLBSClassNamesRepository);
        }
        return this._plbsClassNamesRepository
    }


    private _expenseTypesRepository: ExpenseTypesRepository;
    public get expenseTypesRepository(): ExpenseTypesRepository {
        if (!this._expenseTypesRepository) {
            this._expenseTypesRepository = this.injector.get(ExpenseTypesRepository);
        }
        return this._expenseTypesRepository
    }

    private _expensesRepository: ExpensesRepository;
    public get expensesRepository(): ExpensesRepository {
        if (!this._expensesRepository) {
            this._expensesRepository = this.injector.get(ExpensesRepository);
        }
        return this._expensesRepository
    }

    private _expensePostingSetupRepository: ExpensePostingSetupRepository;
    public get expensePostingSetupRepository(): ExpensePostingSetupRepository {
        if (!this._expensePostingSetupRepository) {
            this._expensePostingSetupRepository = this.injector.get(ExpensePostingSetupRepository);
        }
        return this._expensePostingSetupRepository
    }

    private _analyticGroupsRepository: AnalyticGroupsRepository;
    public get analyticGroupsRepository(): AnalyticGroupsRepository {
        if (!this._analyticGroupsRepository) {
            this._analyticGroupsRepository = this.injector.get(AnalyticGroupsRepository);
        }
        return this._analyticGroupsRepository
    }

    private _analyticTagsRepository: AnalyticTagsRepository;
    public get analyticTagsRepository(): AnalyticTagsRepository {
        if (!this._analyticTagsRepository) {
            this._analyticTagsRepository = this.injector.get(AnalyticTagsRepository);
        }
        return this._analyticTagsRepository
    }

    private _accountTransferRepository: AccountTransferRepository;
    public get accountTransferRepository(): AccountTransferRepository {
        if (!this._accountTransferRepository) {
            this._accountTransferRepository = this.injector.get(AccountTransferRepository);
        }
        return this._accountTransferRepository;
    }
}
