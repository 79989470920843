import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPOSOrderHistoryRepository } from "./IPOSOrderHistoryRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { POSSalesInvoiceSelectTableDto } from "src/app/models/POSModels/POSSalesInvoiceSelectTableDto";

@Injectable(
    {
        providedIn: "root"
    }
)

export class POSOrderHistoryRepository extends GenericRepository<POSSalesInvoiceSelectTableDto> implements IPOSOrderHistoryRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PosOrders', environment.urlAddress);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(code: string, invoiceType: number, billId: string, fiscalSpanID: number): Observable<ResponseModel> {
        return this.GetById('/LoadRec/' + code + '/' + invoiceType + '/' + billId + '/' + fiscalSpanID).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/PosOrders/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    ProcessInvoice(code: string): Observable<any> {
        return this.GetById('/ProcessInvoice/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveAccountRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveAccountRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteMUlRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteMulRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}