﻿import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[EmailValidator]'
})
export class EmailValidatorDirective {

    private regex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/; // regex pattern that can match some less common email formats as well

    constructor(private el: ElementRef) { }

    @HostListener('focusout')
    onFocusOut() {
        // Clear the input field if the email is not valid
        if (this.el.nativeElement.value && !String(this.el.nativeElement.value).match(this.regex)) {
            this.el.nativeElement.value = '';
        }
    }

}