import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CommissionSetup } from "src/app/models/CommissionSetup/commissionsetup.model";
import { ICommissionSetupRepository } from "./ICommissionSetupRepository";

@Injectable()
export class CommissionSetupRepository extends GenericRepository<CommissionSetup> implements ICommissionSetupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/CommissionSetup', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/CommissionSetup/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(CommissionSetupCode: string) {
        return this._http.delete(this.url + 'api/CommissionSetup/DeleteRec/' + CommissionSetupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCommissionNames(item: CommissionSetup) {
        return this._http.post(this.url + 'api/CommissionSetup/SaveCommissionNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllForDD(): Observable<any> {
        return this._http
            .get(this.url + 'api/CommissionSetup/GetAllForDD', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
