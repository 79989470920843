import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IStockAdjustmentRepository } from "./IStockAdjustmentRepository";
import { StockAdjustment } from "../../models/StockAdjustment/stockadjustment.model";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()
export class StockAdjustmentRepository extends GenericRepository<StockAdjustment> implements IStockAdjustmentRepository {
  
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/stockadjustment', environment.urlAddress);
  }   
    SaveRec(item: StockAdjustment): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }   
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCode: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
}