import { Component, OnInit, ViewEncapsulation, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
    selector: 'auto-complete',
    encapsulation: ViewEncapsulation.None,
    host: {
        style: `position: absolute;
					left: 0px; 
					top: 0px;
					background-color: transparant;
					` },
    template: ` 
		<input #input
			[(ngModel)]="inputValue"
            id="inpSearch"
            (focus)="focusDataInput()"
			(ngModelChange)="processDataInput($event)"
			style="height: 28px; font-weight: 400; font-size: 12px;"
			[style.width]="params.column.actualWidth + 'px'">
		<ag-grid-angular
			style="font-weight: 150;width:400px;" 
			[style.height]="gridHeight + 'px'"
			[style.max-width]="gridWidth + 'px'"
			class="ag-theme-balham"
			[rowData]="rowData" 
            headerHeight="32"            
			[columnDefs]="columnDefs"
			[rowSelection]="rowSelection"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
			(gridReady)="onGridReady($event)"
			(rowClicked)="rowClicked($event)">
		</ag-grid-angular>
	`
})
export class AutoCompleteComponent implements ICellEditorAngularComp, AfterViewInit {
    // variables for agGrid
    public params: any;
    public gridApi: any;
    public rowData: any;
    public columnDefs: [{}];
    public rowSelection: string = 'single';
    public columnFilter: any;
    // variables for component
    public returnObject: boolean;
    public cellValue: string;
    public filteredRowData: any;
    public inputValue: string;
    public apiEndpoint: string;
    public gridHeight: number = 175;
    public gridWidth: number = 475;
    public useApi: boolean;
    public propertyName: string;
    public propertyCode: string;
    public isCanceled: boolean = true;
    public selectedObject: any = {};
    public overlayNoRowsTemplate;

    @ViewChild("input") input: ElementRef;

    constructor(private httpClient: HttpClient) {
        this.overlayNoRowsTemplate = "<span style=\"text-align:center;\">Please type atleast 3 characters to search.</span>";
    }


    ngAfterViewInit() {
        window.setTimeout(() => {
            if (this.inputValue == this.cellValue) {
                this.input.nativeElement.select();
            } else {
                this.input.nativeElement.focus();
            }
            if (this.inputValue && !this.useApi) this.updateFilter();
        });
    }

    // ICellEditorAngularComp functions
    agInit(params: any): void {       
        this.params = params;
        if (!params.rowData) {
            if (params.StockSearch) {
                this.apiEndpoint = params.apiEndpoint + "/true/" + params.data.GoDownCode;
            }
            else if (params.ProductTypeSearch) {
                this.apiEndpoint = params.apiEndpoint + "/" + params.data.ProductType;
            }

            else {
                this.apiEndpoint = params.apiEndpoint;
            }
            this.useApi = true;
            this.rowData = [{}];
        } else {
            this.rowData = params.rowData;
        }
        if (params.gridHeight) this.gridHeight = params.gridHeight;
        if (params.gridWidth) this.gridWidth = params.gridWidth;
        this.columnDefs = params.columnDefs;
        this.propertyName = params.propertyRendered;
        this.propertyCode = params.propertyCode;
        this.cellValue = params.value[this.propertyName];
        this.returnObject = params.returnObject;

        if (!params.charPress) {
            if (this.cellValue) this.inputValue = this.cellValue;
        } else {
            this.inputValue = params.charPress;
        }
    }

    getValue(): any {
        if (!this.returnObject) return this.selectedObject[this.propertyName];
        return this.selectedObject;
    }
    isPopup(): boolean {
        return true;
    }
    isCancelAfterEnd(): boolean {
        return this.isCanceled
    }

    // ag-Grid functions
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.columnFilter = this.gridApi.getFilterInstance(this.propertyName);
        this.gridApi.setRowData([]);
    }

    // component functions
    rowClicked(params) {
        var itemData = params.data;
        if (this.params.rowClicked instanceof Function) {
            // put anything into params u want pass into parents component
            const params = {
                params: this.params,
                rowIndex: this.params.rowIndex,
                rowData: this.params.node.data,
                rowItem: itemData
            }
            this.params.rowClicked(params);
        }

        this.selectedObject = params.data;
        this.isCanceled = false;
        this.params.api.stopEditing();
    }

    rowConfirmed() {
        if (this.gridApi.getSelectedRows()[0]) {
            this.selectedObject = this.gridApi.getSelectedRows()[0];
            this.isCanceled = false;
        }
        this.params.api.stopEditing();
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event) {
        event.stopPropagation();
        if (event.key == "Escape") {
            this.params.api.stopEditing();
            return false;
        }
        if (event.key == "Enter" || event.key == "Tab") {            
           // this.rowConfirmed();
            return false;
        }
        if (event.key == "ArrowUp" || event.key == "ArrowDown") {
            this.navigateGrid();
            return false;
        }
    }

    focusDataInput() {
        $("#inpSearch").data('val', $("#inpSearch").val());

        //alert($("#inpSearch").val());
    }

    processDataInput(event) {
        
        var prev = $("#inpSearch").data('val');
        var current = $("#inpSearch").val();
        $("#inpSearch").data('val', $("#inpSearch").val());
        
        if (this.useApi) {
            if (event.length == 0) this.gridApi.setRowData();
            if (event.length == 3 || event.length == 5 || event.length >= 7) {
                this.getApiData(event).subscribe((data: any) => {
                    if (data != null && data.length > 0) {
                        this.rowData = data;
                        setTimeout(() => { this.updateFilter() });
                    }
                    else if (data != null) {
                        this.rowData = data._obj;
                    }
                });
            }
            else if (prev.length == 0 && current.length > 3) {
                this.getApiData(event).subscribe((data: any) => {
                    if (data != null && data.length > 0) {
                        this.rowData = data;
                        setTimeout(() => { this.updateFilter() });
                    }
                    else if (data != null) {
                        this.rowData = data._obj;
                    }
                });
            }
            if (!(event.length == 3 || event.length == 5 || event.length > 7) && event.length > 3)
                this.updateFilter();
        } else {
            this.updateFilter();
        }
    }

    getApiData(filter) {
        return this.httpClient.get(this.apiEndpoint + this.toQueryString(filter));
    }

    toQueryString(filter) {
        return "?" + this.propertyName + "=" + filter;
    }

    updateFilter() {        
        this.gridApi.setQuickFilter(this.inputValue);
        /*this.columnFilter.setModel({
          type: 'startsWith',
          filter: this.inputValue,
        });
        this.columnFilter.onFilterChanged();
        */
        /*
        var hardcodedFilter = {
            code: {
                type: "contains",
                filter: this.inputValue
            },
            name: {
                type: "contains",
                filter: this.inputValue
            }            
        };        
        this.gridApi.setFilterModel(hardcodedFilter);
        this.gridApi.onFilterChanged();
        */

        if (this.gridApi.getDisplayedRowAtIndex(0)) {
            this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
            this.gridApi.ensureIndexVisible(0, 'top');
        } else {
            this.gridApi.deselectAll();
        }

    }

    navigateGrid() {
        if (this.gridApi.getFocusedCell() == null || this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex) == null) { // check if no cell has focus, or if focused cell is filtered
            this.gridApi.setFocusedCell(this.gridApi.getDisplayedRowAtIndex(0).rowIndex, this.propertyName);
            this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
        } else {
            this.gridApi.setFocusedCell(this.gridApi.getFocusedCell().rowIndex, this.propertyName);
            this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
        }
    }


}
