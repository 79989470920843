import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAddOnGroupRepository } from "./IAddOnGroupRepository";
import { AddOnGroup } from "../../models/AddOnGroup/addongroup.model";


@Injectable()
export class AddOnGroupRepository extends GenericRepository<AddOnGroup> implements IAddOnGroupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/AddOnGroup/', environment.urlAddress);
    }
    LoadRec(AddonGroupCode: string): Observable<any> {
        return this.GetById('/GetRec/' + AddonGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/AddOnGroup/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(AddonGroupCode: string) {
        return this.Delete('/DeleteRec/' + AddonGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(): Observable<any> {
        return this._http.get(this.url + 'api/AddOnGroup/GetAll', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}