import { Injectable } from "@angular/core";

import { CanDeactivate } from "@angular/router";
import { Globals } from "src/environments/Globals";
import { CommonConstant } from "src/app/shared/constent/applicationcodes";

@Injectable(
    {
        providedIn: "root"
    }
)
export class CanDeactivateGuard implements CanDeactivate<any> {
    commonConst = new CommonConstant();
    constructor(private globals: Globals) { }

    canDeactivate(component: any): boolean {
        this.globals.RemoveDataFromLocalStorage(this.commonConst.QueryParameters + "," + this.commonConst.ReportName + "," + this.commonConst.ReportHeadInformation + "," + this.commonConst.ReportParams
            + "," + this.commonConst.ReportStoreProcedure + "," + this.commonConst.BackPagePath + "," + this.commonConst.HttpMethod + "," + this.commonConst.FinancialManagerSig + "," + this.commonConst.Address
            + "," + this.commonConst.Email + "," + this.commonConst.Fax + "," + this.commonConst.ShowCompanyLogo + "," + this.commonConst.CCRNumber + "," + this.commonConst.ContactNumber
            + "," + this.commonConst.AccountantSig + "," + this.commonConst.FinancialrefernceSig + "," + this.commonConst.GeneralManagerSig)
        return true;
    }
}