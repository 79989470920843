import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ProductColor } from "src/app/models/ProductColor/productColor.model";
import { IProductColorRepository } from "./IProductColorRepository";

@Injectable()
export class ProductColorRepository extends GenericRepository<ProductColor> implements IProductColorRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ProductColor', environment.urlAddress);
    }

    GetAll(skip: number, next: number): Observable<any> {
        return this._http
            .get(this.url + 'api/ProductColor/SelectAll' + '/' + skip + '/' + next, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(productColorCode: string, cultureCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/ProductColor/Select' + '/' + productColorCode + '/' + cultureCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(productsSizeCode: string) {
        return this._http.delete(this.url + 'api/ProductColor/DeleteRec/' + productsSizeCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: ProductColor) {
        return this._http.post(this.url + 'api/ProductColor/SaveRec', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
