import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MasterNamesComponent } from './master-names/master-names.component';
import { TopButtonsComponent } from "./top-buttons/top-buttons.component";
import { ApplicationPipesModule } from "./custom-pipes/application-pipes.module";
import { ReportsViewerComponent } from "../views/procurement/Reports/reports-viewer/reports-viewer.component";
import { AdminLayoutComponent } from "../layouts/admin";
import { AppHeaderComponent } from "./header/header.component";
import { AppBreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { HttpClientModule } from "@angular/common/http";
import { LeftMenuComponent } from "./left-menu/left-menu.component";
import { RouterModule } from "@angular/router";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { ButtomActionBarComponent } from "./buttom-action-bar/buttom-action-bar.component";
import { BatchSerialInwardsComponent } from "./batch-serial-inwards/batch-serial-inwards.component";
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { BatchSerialOutwardsComponent } from "./batch-serial-outwards/batch-serial-outwards.component";
import { TreeViewComponent } from "./tree-view.directory/tree-view.component";
import { DynamicReportViewerComponent } from './dynamic-report-viewer/dynamic-report-viewer.component';
import { DynamicPagerComponent } from './dynamic-pager/dynamic-pager.component';
import { AnalyticTagsDropDownComponent } from './analytic-tags-drop-down/analytic-tags-drop-down.component';
import { PostingAccountsSliderComponent } from './posting-accounts-slider/posting-accounts-slider.component';
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { MasterNamesSameTableComponent } from "./master-names-same-table/master-names-same-table.component";
import { NgxTypeaheadModule } from "ngx-typeahead";
import { AddressComponent } from "./accordion/address/address.component";
import { AddNewProductSliderComponent } from './add-new-product-slider/add-new-product-slider.component';
import { EmployeeTaskRatingComponent } from "./employee-task-rating/employee-task-rating.component";
import { AdjustmentAccountSliderComponent } from './adjustment-account-slider/adjustment-account-slider.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ApplicationPipesModule,
        HttpClientModule,
        RouterModule,
        TypeaheadModule.forRoot(),    
        NgxTypeaheadModule,
        MalihuScrollbarModule.forRoot(),
    ],
    exports: [
        MasterNamesComponent,
        TopButtonsComponent,
        ApplicationPipesModule,
        FormsModule,
        ReportsViewerComponent,
        AdminLayoutComponent,
        ButtomActionBarComponent,
        BatchSerialInwardsComponent,
        BatchSerialOutwardsComponent,
        DynamicReportViewerComponent,
        UploadDocumentComponent,
        TreeViewComponent,
        HttpClientModule,
        DynamicPagerComponent,
        AnalyticTagsDropDownComponent,
        PostingAccountsSliderComponent,
        TypeaheadModule,
        NgxTypeaheadModule,
        MalihuScrollbarModule,
        CommonModule,
        AddressComponent,
        MasterNamesSameTableComponent,
        AddNewProductSliderComponent,
        EmployeeTaskRatingComponent,
        AdjustmentAccountSliderComponent   
    ],

    declarations: [
        MasterNamesComponent,
        TopButtonsComponent,
        ReportsViewerComponent,
        AdminLayoutComponent,
        AppHeaderComponent,
        AppBreadcrumbComponent,
        LeftMenuComponent,
        ButtomActionBarComponent,
        BatchSerialInwardsComponent,
        BatchSerialOutwardsComponent,
        UploadDocumentComponent,
        TreeViewComponent,
        DynamicReportViewerComponent,
        DynamicPagerComponent,
        AnalyticTagsDropDownComponent,
        PostingAccountsSliderComponent,        
        AddressComponent,
        MasterNamesSameTableComponent,
        AddNewProductSliderComponent,
        EmployeeTaskRatingComponent,
        AdjustmentAccountSliderComponent,          
    ],
    entryComponents: []
})
export class SharedModule { }
