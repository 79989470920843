﻿/*
 * added by Yameen 11-03-2020
 * For price type account cell
 */
import { Component, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
declare var $: any;
@Component({
    selector: 'Tax-Dropdown-cell',
    template: `
<style>

</style>
<select #input class="form-trans form-control square custom-select no_enable" [(ngModel)]="value">
 <option  value=""></option>  
  <option *ngFor="let item of Items" value="{{item.TaxGroupCode}}">{{item.TaxGroupName}}</option>  
</select>
`,

})
export class TaxDropdownRenderComponent implements ICellRendererAngularComp {
    private params: any;
    public value: number;
    public Items: any;
    @ViewChild('input', { read: ViewContainerRef }) public input;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        if (params.colDef.cellRendererParams.Items && params.colDef.cellRendererParams.Items.length > 0)
            this.Items = params.colDef.cellRendererParams.Items;
        else
            this.Items = [];
        //this.input.element.nativeElement.className = "ag-input-text-wrapper ag-cell-edit-input";

    }
    refresh(params: any): boolean {
        return false;
    }
}