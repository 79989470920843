
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { ICollectionAgentRepository } from "./ICollectionAgent";
import { CollectionAgent as CollectionAgnet } from "../../models/CollectionAgent/collection-agent-model";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class CollectionAgentRepository extends GenericRepository<CollectionAgnet> implements ICollectionAgentRepository {

  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {

    super(_http, 'api/collectionagent', environment.urlAddress);
  }
  GetCollectionAgentAddress(collectionAgentCode: string, addressType: string) {
    return this._http.get(this.url + 'api/collectionagent/CollectionAgentAddress' + '/' + collectionAgentCode + '/' + addressType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  GetNavRec(navType: number, currentIndex: number): Observable<any> {
    return this._http.get(this.url + 'api/collectionagent/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  LoadRec(collectionAgentCode: string): Observable<any> {
    return this.GetById('/GetRec/' + collectionAgentCode).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(collectionAgentCode: string) {
    return this.Delete('/DeleteRec/' + collectionAgentCode).pipe(map(this.extractData), catchError(this.handleError));
  }
  SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

    filterText = filterText.split('/').join('~');
    if (filterText == "") {
        filterText = "|";
    }

    return this.Get('/SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));;
}
}
