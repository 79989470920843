﻿/*
 * added by Yameen 05-01-2020
 * For price cell
 */
import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { SanitizeValueFormatter } from '../value-formatters/sanitize-value-formatter';

@Component({
    selector: 'price-cell',
    template: `<input #input priceInput [(ngModel)]="value" (change)="onPriceChange($event.target.value)">`
})
export class PriceEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    constructor(private svf: SanitizeValueFormatter) {}
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;

    @ViewChild('input', { read: ViewContainerRef }) public input;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.input.element.nativeElement.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart = false;
    }
    getValue(): any {
        return this.value;
    }
    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }
    isCancelAfterEnd(): boolean { // will reject the number if spicfy the condition
        //return this.value > 1000000;
        return false;
    };
    ngAfterViewInit() {// dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
        //this.input.element.nativeElement.focus();
    }
    focusIn(): void {
        this.input.element.nativeElement.focus();
    }   
    onPriceChange = (value: any): void => {
        this.params.data.PriceOriginal = this.svf.priceFormat(value);
        console.log(this.params.data.PriceOriginal)
    }
    
}