import { Component, Output } from "@angular/core";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subject } from 'rxjs';
import { MultiTablesNamesServices } from "../multi-tables-names/multi-tables-names.service";
import { BusinessServices } from "src/app/services/singleton/business-services";
import { Globals } from "src/environments/Globals";
declare var $: any;
@Component({
    selector: 'app-names-renderer',
    templateUrl: './agGrid-names-renderer.html'
})
export class TablesNamesRenderer implements ICellRendererAngularComp {
    private params: any;
    private mood: string;
    public name: string;
    public body: string;
    public title: string;
    public onClose: Subject<boolean>;
    public active: boolean = false;
    public disabledNameIcon:boolean=false;
    public disabledNameId:string;

    constructor(private multiTablesNamesServices: MultiTablesNamesServices,private businessServices:BusinessServices) {
        this.disabledNameIcon=true;
    }
  agInit(params: any): void {    
        this.params = params;    
       // this.setMood(params);
        this.onClose = new Subject
        this.disabledNameId=params.value;
    }

    refresh(params: any): boolean {
        this.params = params;
       // this.setMood(params);
        return true;
    }

    private setMood(params) {
        this.name=params.value;
    };
  onShowPopup() {
    this.multiTablesNamesServices.showConfirmationModal(this.params,this.businessServices);
    }
   
}

