import { GenericRepository } from "../generic-repository/GenericRepository";
import { IPurchaseOrderRepository } from "./IPurchaseOrderRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { PurchaseOrder } from "src/app/models/purchase-order/purchase-order.model";
import { Injectable } from "@angular/core";
import { DocumentSearchViewModel } from "src/app/models/common/document-search-model";
@Injectable()
export class PurchaseOrderRepository extends GenericRepository<PurchaseOrder> implements IPurchaseOrderRepository {


    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/purchaseorder', environment.urlAddress);
    }
    
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/purchaseorder/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllForDashBoard (currentPage: number, recordsPerPage: number, godownCode: string ='', vendorCode:string='', DateFrom:string='',DateTo:string=''): Observable<any>{
        godownCode = godownCode!='' ? godownCode : 'null';
        return this._http
            .get(this.url + 'api/purchaseorder/SelectAllForDashBoard' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode + '/' + DateFrom + '/' + DateTo+ '/' + vendorCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/purchaseorder/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PurchaseOrder: string) {
        return this.Delete('/DeleteRec/' + PurchaseOrder).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchPurchaseOrder(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/purchaseorder/SearchForPurchaseInvoice', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SearchAllPurchaseOrder(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/purchaseorder/SearchForPurchaseInvoice/1', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllForGRRDashBoard (currentPage: number, recordsPerPage: number, godownCode: string ='', vendorCode:string='', DateFrom:string='',DateTo:string=''): Observable<any>{
        godownCode = godownCode!='' ? godownCode : 'null';
        return this._http
            .get(this.url + 'api/purchaseorder/GetAllForGRRDashBoard' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode + '/' + DateFrom + '/' + DateTo+ '/' + vendorCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}