import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { BridgeServerConfiguration } from "src/app/models/BridgeServerConfiguration/BridgeServerConfiguration";
import { IBridgeServerConfigurationRepository } from "./IBridgeServerConfigurationRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class BridgeServerConfigurationRepository extends GenericRepository<BridgeServerConfiguration> implements IBridgeServerConfigurationRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/BridgeServerConfiguration', environment.urlAddress);
    }
   
    SelectAll(): Observable<any> {
        return this._http
            .get(this.url + 'api/BridgeServerConfiguration/SelectAll', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));        
    } 

    UpdatePassword(bridgeServer: BridgeServerConfiguration): Observable<ResponseModel> {
        return this.PostData('/UpdatePassword', bridgeServer).pipe(map(this.extractData), catchError(this.handleError));
    }

}
