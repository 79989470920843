import {Component, Input, Output, EventEmitter} from '@angular/core';  
@Component({  
    selector: 'app-tree-view',      
    templateUrl: './tree-view.component.html'  
})  
export class TreeViewComponent {  
    @Input() treeList: any;  
    @Output() emitCodeValue = new EventEmitter<string>();
    toggleOption(code: string){
        this.emitCodeValue.emit(code);
    }
}  