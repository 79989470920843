﻿import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import {  ReportParamsModel } from '../../models/common/report-params.model';
import { ResponseModel } from '../../models/SharedModels/response.model';
import { BusinessServices } from '../../services/singleton/business-services';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { LoaderService } from '../../services/loader/LoaderService';
import { CommonConstant } from '../constent/applicationcodes';
import { Globals } from '../../../environments/Globals';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
    selector: 'app-dynamic-report-viewer',
    templateUrl: './dynamic-report-viewer.component.html',
    styles: []
})
export class DynamicReportViewerComponent implements OnInit {
    isReportViewerShow = false;
    reportName: string = '';
    reportContent: SafeHtml = '';
    reportHeader: string = '';
    reportParamsToPrint: string = '';
    reportHeaderLabel: string = '';
    reportHeaderCCR: string = ''; 
    showCompanyLogo: boolean = true;
    showCCR: boolean = false;
    showVat: boolean = false;
    showCompanyName: boolean = false;
    CompanyLogo: string;
    CCRNumber: string;
    VATNumber: string;
    CompanyName: string;
    showActntSig: boolean = false;
    showGMSig: boolean = false;
    showFRSig: boolean = false;
    showFMSig: boolean = false;
    commonConst = new CommonConstant();
    //Paging
    private currentPage: number = 1;
    private totalRecord: number = 0;
    private totalPages: number = 0;
    private showingPage: string = "";
    private pageInfo: string = "";
    private recordsPerPage = 20;
    private removedRows: number = 3;
    reportModel = new ReportParamsModel();
    //#region Aeromatic Report Params
    FromDate: string = "";
    ToDate: string = "";
    StockName: string = "";
    ProductCode: string = "";
    ProductName: string = "";
    CompanyId: string = "";
    ReportId: string = ""; // use to show specific header in stock ledger aeromatic report 
    //#endregion
    constructor(private businessService: BusinessServices, private loaderService: LoaderService, private translate: TranslatePipe, private globals: Globals, protected sanitizer: DomSanitizer
        , private toastrService: ToastrService, private el: ElementRef) {

    }

    ngOnInit() {
        this.isReportViewerShow = false;
        this.CCRNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyCCRNumber);
        this.VATNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyVatNumber);
        this.CompanyName = this.globals.getDataFromLocalStorage(this.commonConst.CompanyName);
        this.CompanyLogo = this.globals.getDataFromLocalStorage(this.commonConst.CompanyLogo);
        this.CompanyId = this.globals.getDataFromLocalStorage(this.commonConst.LoggedInCompanyID);
    }
    loadReportViewer() {
        console.log(this.reportModel.ReportParameter);
        this.isReportViewerShow = false;
        this.currentPage = 1;
        this.totalRecord = 0;
        this.totalPages = 0;
        this.showingPage = "";
        this.pageInfo = "";
        this.recordsPerPage = 20;
        this.removedRows = 3;
        this.reportHeaderLabel = "";
        this.reportHeaderCCR = "";
        if (this.reportModel.ReportParameter && this.reportModel.StoreProcedure) {
            let headerSetting: string = this.reportHeader;
            if (headerSetting) {
                this.showCompanyName = headerSetting.includes('1');
                this.showCCR = headerSetting.includes('2');
                this.showVat = headerSetting.includes('3');

                //if (this.showCompanyName)
                //    this.reportHeaderLabel = this.CompanyName;

                if (this.showVat)
                    this.reportHeaderLabel = this.showCompanyName ? this.reportHeaderLabel + this.translate.transform("5") + ': ' + this.VATNumber : this.translate.transform("5") + ': ' + this.VATNumber;

                //if (this.showCCR)
                //    this.reportHeaderLabel = (this.showCompanyName || this.showVat) ? this.reportHeaderLabel + ' - ' + this.translate.transform("6") + ': ' + this.CCRNumber : this.translate.transform("6") + ': ' + this.CCRNumber; 
                if (this.showCCR) {
                    this.reportHeaderCCR = this.translate.transform("6") + ": " + this.CCRNumber;
                }
                else {
                    this.reportHeaderCCR = "";
                }
            }
            this.loaderService.display(true); 
            this.businessService.reportViewerRepository.GetReportDataByPost(this.reportModel).subscribe((data: ResponseModel) => {
                if (data._statusCode == 200 && data._obj && data._obj.HyperTextMarkup) {
                    this.reportContent = this.sanitizer.bypassSecurityTrustHtml(data._obj.HyperTextMarkup);
                    //
                    this.totalRecord = data._obj.TotalRecords - this.removedRows;
                    this.totalPages = Math.ceil(this.totalRecord / this.recordsPerPage);
                    this.setPagingValues();
                    this.isReportViewerShow = true;
                    this.loaderService.display(false);
                }
                else {
                    console.log(this.translate.transform(data._message));
                    this.loaderService.display(false);
                }
                // setTimeout(() => { // wait until all resources loaded           
                // },
                //     100);
            });
        }

    }


    loadReportDataByPage() {

        if (this.recordsPerPage > 0) {
            this.loaderService.display(true);
            if (this.currentPage < 1) {
                this.currentPage = 1;
                this.toastrService.error(this.translate.transform('1689')); 
                this.loaderService.display(false);
                return;
            }
            

            let rowIndexStart: number =
                (this.currentPage == 1 ? 1 : (this.recordsPerPage * (this.currentPage - 1) + 1)) + this.removedRows;
            let rowIndexEnd: number = (this.currentPage * this.recordsPerPage) + this.removedRows;
            this.reportModel.RowIndexStart = rowIndexStart;
            this.reportModel.RowIndexEnd = rowIndexEnd;
            this.reportModel.Parameter = this.reportModel.ReportParameter + "_";
            this.businessService.reportViewerRepository.GetReportDataPageWise(this.reportModel).subscribe(
                (data: ResponseModel) => {
                    if (data._statusCode == 200 && data._obj) {

                        this.reportContent = this.sanitizer.bypassSecurityTrustHtml(data._obj);
                        this.setPagingValues();
                        this.isReportViewerShow = true;

                    } else {
                        this.toastrService.error(this.translate.transform(data._message));
                    }
                    setTimeout(() => { // wait until all resources loaded           
                        this.loaderService.display(false);
                    },
                        100);
                });
        } else
            this.toastrService.error(this.translate.transform('1691'));

    }
    firstPage() {
        this.currentPage = 1;
        this.loadReportDataByPage();
    }

    previousPage() {
        this.currentPage--;
        this.loadReportDataByPage();
    }

    lastPage() {
        this.currentPage = this.totalPages;
        this.loadReportDataByPage();
    }

    nextPage() {
        this.currentPage++;
        this.loadReportDataByPage();
    }
    onPageSizeChanged() {
        this.currentPage = 1;
        this.totalPages = Math.ceil(this.totalRecord / this.recordsPerPage);
        this.loadReportDataByPage();
    }
    setPagingValues() {
        this.pageInfo = this.translate.transform('1166') + " " + this.currentPage + " " + this.translate.transform('2807') + " " + this.totalPages;

        if (this.totalRecord < this.recordsPerPage) {
            this.showingPage = this.translate.transform('2963') + " " + (Number((this.currentPage - 1) * this.recordsPerPage) + 1) + " " + this.translate.transform('1088') + " " + this.totalRecord + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
        else if (Number(this.currentPage * this.recordsPerPage) > this.totalRecord) {
            this.showingPage = this.translate.transform('2963') + " " + (Number((this.currentPage - 1) * this.recordsPerPage) + 1) + " " + this.translate.transform('1088') + " " + this.totalRecord + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
        else {
            this.showingPage = this.translate.transform('2963') + " " + (Number((this.currentPage - 1) * this.recordsPerPage) + 1) + " " + this.translate.transform('1088') + " " + Number(this.currentPage * this.recordsPerPage) + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
    }

    PrintReport() {

        $('body').addClass('PrintableBody');
        $(".PrintableDiv").unwrap();
        var divToPrint = document.getElementById('divRptContainer');
        var htmlToPrint = '' +
            '<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap" rel="stylesheet">' +
            '<style type="text/css">' +
            '.printpage .toppaging, .printpage .printbtns, .printpage .reportpaging, .printpage .showipad, .printpage .reporttopbutton, .printpage .pagingbottom {' +
            'display: none' +
            '}' +
            'body{' +
            'font-family: "Montserrat", sans-serif;' +
            '}' +
            '@media (min-width: 1200px) {' +
            '#reportbody[dir=\"rtl\"] .col-lg-1, #reportbody[dir=\"rtl\"] .col-lg-2, #reportbody[dir=\"rtl\"] .col-lg-3, #reportbody[dir=\"rtl\"] .col-lg-4, #reportbody[dir=\"rtl\"] .col-lg-5, #reportbody[dir=\"rtl\"] .col-lg-6, #reportbody[dir=\"rtl\"] .col-lg-7, #reportbody[dir=\"rtl\"] .col-lg-8, #reportbody[dir=\"rtl\"] .col-lg-9, #reportbody[dir=\"rtl\"] .col-lg-10, #reportbody[dir=\"rtl\"] .col-lg-11, #reportbody[dir=\"rtl\"] .col-lg-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 992px) {' +
            '#reportbody[dir=\"rtl\"] .col-md-1, #reportbody[dir=\"rtl\"] .col-md-2, #reportbody[dir=\"rtl\"] .col-md-3, #reportbody[dir=\"rtl\"] .col-md-4, #reportbody[dir=\"rtl\"] .col-md-5, #reportbody[dir=\"rtl\"] .col-md-6, #reportbody[dir=\"rtl\"] .col-md-7, #reportbody[dir=\"rtl\"] .col-md-8, #reportbody[dir=\"rtl\"] .col-md-9, #reportbody[dir=\"rtl\"] .col-md-10, #reportbody[dir=\"rtl\"] .col-md-11, #reportbody[dir=\"rtl\"] .col-md-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 768px) {' +
            '#reportbody[dir=\"rtl\"] .col-sm-1, #reportbody[dir=\"rtl\"] .col-sm-2, #reportbody[dir=\"rtl\"] .col-sm-3, #reportbody[dir=\"rtl\"] .col-sm-4, #reportbody[dir=\"rtl\"] .col-sm-5, #reportbody[dir=\"rtl\"] .col-sm-6, #reportbody[dir=\"rtl\"] .col-sm-7, #reportbody[dir=\"rtl\"] .col-sm-8, #reportbody[dir=\"rtl\"] .col-sm-9, #reportbody[dir=\"rtl\"] .col-sm-10, #reportbody[dir=\"rtl\"] .col-sm-11, #reportbody[dir=\"rtl\"] .col-sm-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '#reportbody[dir=\"rtl\"] .col-xs-1, #reportbody[dir=\"rtl\"] .col-xs-2, #reportbody[dir=\"rtl\"] .col-xs-3, #reportbody[dir=\"rtl\"] .col-xs-4, #reportbody[dir=\"rtl\"] .col-xs-5, #reportbody[dir=\"rtl\"] .col-xs-6, #reportbody[dir=\"rtl\"] .col-xs-7, #reportbody[dir=\"rtl\"] .col-xs-8, #reportbody[dir=\"rtl\"] .col-xs-9, #reportbody[dir=\"rtl\"] .col-xs-10, #reportbody[dir=\"rtl\"] .col-xs-11, #reportbody[dir=\"rtl\"] .col-xs-12 {' +
            'float: right;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-right {' +
            'text-align: left;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-left {' +
            'text-align: right;' +
            '}' +
            'table,th,td {' +
            'border: none !important;' +
            'border-collapse: separate;' +
            '}' +
            'table thead tr th {' +
            'border-top:2px solid #000 !important;' +
            'border-bottom:2px solid #000 !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0em 0.5em;' +
            'font-size: 12px;' +
            'height: 34px' +
            '}' +
            '[dir="rtl"] table thead tr th{ ' +
            'text-align: right !important;' +
            '}' +
            'table tbody tr td {' +
            'border-top:none !important;' +
            'border-bottom:1px solid #ccc !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0.45rem 0.45rem;' +
            'font-size: 12px;' +
            '}' +
            '[dir = "rtl"] table tbody tr td' +
            '{' +
            'text-align: right !important;' +
            '}' +
            '.reportname, .texttwo{' +
            'margin-bottom: 20px;' +
            'font-size: 13px;' +
            'font-weight: 600;' +
            'display: block;' +
            '}' +
            'table {' +
            'border-bottom:2px solid #000!important;' +
            'border-collapse: collapse;' +
            '}' +
            'table thead tr th:last-child, table tbody tr td:last-child {' +
            '}' +
            'table th {' +
            '}' +
            'table th:first-child {' +
            'text-align: left;' +
            '}' +
            '.report-header {' +
            'margin-bottom:10px;' +
            '}' +
            '#reportbody[dir=\"rtl\"] table th,#reportbody[dir=\"rtl\"] table td {' +
            'text-align:right !important;' +
            '}' +
            '.report-header {' +
            'padding:  0px !important;' +
            '}' +
            '.signature-line {' +
            'margin-bottom:  0px !important;' +
            '}' +
            '.signature-line {' +
            'border-color: #000 !important;' +
            '}' +
            '.tb {' +
            'font-weight: 600;' +
            '}' +
            '.printpage .space11 {' +
            'margin: 10px 0 !important;' +
            'display: block;' +
            '}' +
            '</style>';

        if (this.globals.isRTL)
            $('#divRptContainer').attr('dir', 'RTL').addClass('printpage');
        else
            $('#divRptContainer').attr('dir', 'LTR').addClass('printpage');
        htmlToPrint += divToPrint.outerHTML;
        htmlToPrint += "<div style='border-bottom: 1px #ddd solid; color-adjust: exact !important; width: 1100px; margin: auto; font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif; -webkit-print-color-adjust: exact !important;'><div style='clear: both; display: inline-block; padding-bottom: 20px; padding-top: 20px;'><div style='width: 350px; text-align: left; float: left; padding: 5px;'>Recipient<span style='border-bottom: 1px #353535 solid; width: 70%; color-adjust: exact !important; display: inline-block; margin-right: 10px; -webkit-print-color-adjust: exact !important;'>&nbsp;</span></div><div style='width: 370px; text-align: center; float: left; padding: 5px;'>Delivered By<span style='border-bottom: 1px #353535 solid; width: 70%; color-adjust: exact !important; display: inline-block; margin-right: 10px; -webkit-print-color-adjust: exact !important;'>&nbsp;</span></div><div style='width: 350px; text-align: right; float: right; padding: 5px;'>Released By<span style='border-bottom: 1px #353535 solid; width: 70%; color-adjust: exact !important; display: inline-block; margin-right: 10px; -webkit-print-color-adjust: exact !important;'>&nbsp;</span></div></div></div>";
        let newWin = window.open("");
        newWin.document.write(htmlToPrint);
        $(newWin.document).find("#reportName").html(this.reportName);//show name only in case of print
        $('body').removeClass('PrintableBody');
        $('#divRptContainer').removeAttr('dir').removeClass('printpage');
        setTimeout(() => { // wait until all resources loaded                                                
            newWin.document.close(); // necessary for IE >= 10
            newWin.focus(); // necessary for IE >= 10
            newWin.print(); // change window to winPrint
        }, 100);
    }




}
