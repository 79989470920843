﻿import { Injectable, Injector } from '@angular/core';
import { AreasRepository } from 'src/app/repositories/Restaurant/araes-repository/AreaRepository';
import { MenuSetupRepository } from 'src/app/repositories/Restaurant/menu-setup-repository/MenuSetupRepository';
import { OrderTakerRepository } from 'src/app/repositories/Restaurant/order-taker-repository/OrderTakerRepository';
import { OrdersRepository } from 'src/app/repositories/Restaurant/orders-repository/OrdersRepository';
import { ResOrderHistoryRepository } from 'src/app/repositories/Restaurant/res-order-history-repository/ResOrderHistoryRepository';
import { ResPrinterGroupRepository } from 'src/app/repositories/Restaurant/res-printer-group-repository/PrinterGroupRepository';
import { ResProductGroupsRepository } from 'src/app/repositories/Restaurant/res-product-groups-repository/ResProductGroupsRepository';
import { ResReceiptSetupRepository } from 'src/app/repositories/Restaurant/res-receipt-setup/ReceiptSetupRepository';
import { ResStringsRepository } from 'src/app/repositories/Restaurant/res-strings-repository/POSStringsRepository';
import { ResUsersRepository } from 'src/app/repositories/Restaurant/resusers-repository/ResUsersRepository';
import { Res_CountersRepository } from 'src/app/repositories/Restaurant/Table-repository/res-counters-repository/Res_CountersRepository';
import { TableRepository } from 'src/app/repositories/Restaurant/Table-repository/TableRepository';

@Injectable({
    providedIn: 'root'
})
export class RestaurantService {

    constructor(private injector: Injector) { }

    private tableRepository: TableRepository;
    public get tableService(): TableRepository {
        if (!this.tableRepository) {
            this.tableRepository = this.injector.get(TableRepository);
        }
        return this.tableRepository;
    }

    private _res_CountersRepository: Res_CountersRepository;
    public get res_CountersRepository(): Res_CountersRepository {
        if (!this._res_CountersRepository) {
            this._res_CountersRepository = this.injector.get(Res_CountersRepository);
        }
        return this._res_CountersRepository;
    }

    private _resUserRepository: ResUsersRepository;
    public get resUserRepository(): ResUsersRepository {
        if (!this._resUserRepository) {
            this._resUserRepository = this.injector.get(ResUsersRepository);
        }
        return this._resUserRepository;
    } 

    private _resStringsRepository: ResStringsRepository;
    public get resStringsRepository(): ResStringsRepository {
        if (!this._resStringsRepository) {
            this._resStringsRepository = this.injector.get(ResStringsRepository); 
        }
        return this._resStringsRepository;
    }

    private _resPrinterGroupRepository: ResPrinterGroupRepository;
    public get resPrinterGroupRepository(): ResPrinterGroupRepository {
        if (!this._resPrinterGroupRepository) {
            this._resPrinterGroupRepository = this.injector.get(ResPrinterGroupRepository);
        }
        return this._resPrinterGroupRepository;
    }

    private _resReceiptSetupRepository: ResReceiptSetupRepository;
    public get resReceiptSetupRepository(): ResReceiptSetupRepository {
        if (!this._resReceiptSetupRepository) {
            this._resReceiptSetupRepository = this.injector.get(ResReceiptSetupRepository);
        }
        return this._resReceiptSetupRepository;
    }

    private _resProductGroupsRepository: ResProductGroupsRepository;
    public get resProductGroupsRepository(): ResProductGroupsRepository {
        if (!this._resProductGroupsRepository) {
            this._resProductGroupsRepository = this.injector.get(ResProductGroupsRepository);
        }
        return this._resProductGroupsRepository;
    }

    private _areaRepository: AreasRepository;
    public get areaRepository(): AreasRepository {
        if (!this._areaRepository) {
            this._areaRepository = this.injector.get(AreasRepository);
        }
        return this._areaRepository;
    }

    private _orderTakerRepository: OrderTakerRepository;
    public get orderTakerRepository(): OrderTakerRepository {
        if (!this._orderTakerRepository) {
            this._orderTakerRepository = this.injector.get(OrderTakerRepository);
        }
        return this._orderTakerRepository;
    }

    private _ordersRepository: OrdersRepository;
    public get ordersRepository(): OrdersRepository {
        if (!this._ordersRepository) {
            this._ordersRepository = this.injector.get(OrdersRepository);
        }
        return this._ordersRepository;
    }

    private _resOrderHistoryRepository: ResOrderHistoryRepository;
    public get resOrderHistoryRepository(): ResOrderHistoryRepository {
        if (!this._resOrderHistoryRepository) {
            this._resOrderHistoryRepository = this.injector.get(ResOrderHistoryRepository);
        }
        return this._resOrderHistoryRepository;
    }

    private menuSetupRepository: MenuSetupRepository;
    public get menuSetupService(): MenuSetupRepository {
        if (!this.menuSetupRepository) {
            this.menuSetupRepository = this.injector.get(MenuSetupRepository);
        }
        return this.menuSetupRepository;
    }

}