import { Component, OnInit, AfterViewInit,OnDestroy} from '@angular/core';
import { Globals } from '../../../environments/Globals';
import { AuthService } from 'src/app/views/authentication/authentication-helpers/auth.service';
import { BusinessServices } from '../../services/singleton/business-services';
import { CommonConstant } from '../constent/applicationcodes';
import { TranslateServiceLocal } from 'src/app/services/translateservice/translate.service.local';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader/LoaderService';
import { SmartAppUtilities } from '../../models/common/getjsonfrom-controls-service-model';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { ChangePasswordModel } from '../../models/ErpUser/password-reset-model';
import { FiscalSpans } from 'src/app/models/FiscalSpans/fiscal-spans.model';
import { CompanyProfile } from 'src/app/models/CompanyProfile/company-profile.model';

declare var $: any;

@Component({
    selector: 'app-header, cui-header',
    templateUrl: "./header.component.html"

})
export class AppHeaderComponent implements OnInit, AfterViewInit  {
    
    currentFiscalSpanDates: string = "";
    companyprofile: CompanyProfile;
    fiscalSpans: Array<FiscalSpans>;
    commonconstant = new CommonConstant();
    UserName:string;
    CultureCode:string;
    IsCultureCodeAR:boolean = false;
    RoleName:string;   
    currentyTime: string = "";  
    passModel = new ChangePasswordModel();
    smartUtilities = new SmartAppUtilities(this.toastrService);
    selectedFiscalSpan: any;
    unpostedPOSInvoices: number = 0;
    isProductOnLowQuantity: boolean = false;
    unpostedNotificationRead: boolean = true;
    IsShowNewFundsTransferred:boolean = false;
    ShowLicenceAlert: boolean = true;
    DaysLeft: any;
    SystemFiscalSpan: any;
    IsDemoUser: boolean = false;
    productList: any[] = [];

    //Paging
    currentPage: number = 1;
    totalRecord: number = 0;
    totalPages: number = 0;
    showingPage: string = "";
    pageInfo: string = "";
    private paginationPageSize = 10;
    pageingDisplay: string = "d-none";
    searchText: string = '';
    showMainContent: Boolean = true;
    //Paging


    public showFiscalspanNote(): boolean {
        return this.globals.getBoolFromLocalStorage(this.commonconstant.ShowFiscalspanNote);
    }


    constructor(private globals: Globals, private authService: AuthService, private businessService: BusinessServices,
        private loaderService: LoaderService, private toastrService: ToastrService, private transService: TranslateServiceLocal, private translate: TranslatePipe) {
        $("#divPurechat").html(translate.transform('2719'));
        this.currentFiscalSpanDates = this.globals.getDataFromLocalStorage(this.commonconstant.CurrentFiscalSpanDates);        
        setInterval(() => {           
          let machineDate = new Date();      
          this.currentyTime = ((machineDate.getHours() < 10)?"0":"") + machineDate.getHours() +":"+ ((machineDate.getMinutes() < 10)?"0":"") + machineDate.getMinutes();              
        }, 1);
        this.businessService.fiscalSpansRepository.LoadRec(null).subscribe((data: any) => {
            this.loaderService.display(false);
            this.fiscalSpans = data._obj;                
        });

        this.selectedFiscalSpan = this.globals.getDataFromLocalStorage(this.commonconstant.CurrentFiscalSpanID);
        this.unpostedPOSInvoices = Number(this.globals.getDataFromLocalStorage(this.commonconstant.UnPostedPOSInvoices));
        this.isProductOnLowQuantity = this.globals.getBoolFromLocalStorage(String(this.commonconstant.IsProductOnLowQuantity));
        this.unpostedNotificationRead = this.globals.getBoolFromLocalStorage(this.commonconstant.UnPostedNotificationRead);
        this.IsShowNewFundsTransferred = this.globals.getBoolFromLocalStorage(this.commonconstant.IsShowNewFundsTransferred);

        this.DaysLeft = this.globals.getDataFromLocalStorage(this.commonconstant.DaysLeft);

        this.IsDemoUser = this.globals.getBoolFromLocalStorage(this.commonconstant.IsDemoClient);

        if(this.globals.getDataFromLocalStorage(this.commonconstant.ShowLicenseAlert)){
            this.ShowLicenceAlert = this.globals.getBoolFromLocalStorage(this.commonconstant.ShowLicenseAlert);
        }
        
        this.SystemFiscalSpan = this.selectedFiscalSpan;        
    }
    GetNewFundsTransferred() : number{
       return Number( this.globals.getDataFromLocalStorage(this.commonconstant.NewFundsTransferred) );
    }
    // ngOnDestroy(): void {
    //     this.globals.setDataInLocalStorage(this.commonconstant.ShowFiscalspanNote, false);
    // }

    HideAlert(){
        $("#dvLicenseAlert").hide();
        this.globals.setDataInLocalStorage(this.commonconstant.ShowLicenseAlert, false);
        //this.globals.setDataInLocalStorage(this.commonconstant.DaysLeft);
    }

    ChangeLangauge(selectOption: any) {

         this.businessService.commonService.SetSessionVariables('CultureCode',selectOption.value).subscribe((data: ResponseModel) => {
             if (data._statusCode == 200) {
                 this.globals.SetCultureCode(selectOption.value);
                 this.globals.SetTextDirection(selectOption.options[selectOption.selectedIndex].getAttribute('data-direction').toLowerCase() === 'rtl');
                 this.transService.setStringData(data._obj)
                 window.location.reload();
             }
            
         }); 
     }
     ChangeFiscalSpan(FiscalSpanID: any) {
        this.loaderService.display(true);
         let fs = this.fiscalSpans.find(x => x.FiscalSpanID == Number(FiscalSpanID));

         this.globals.setDataInLocalStorage(this.commonconstant.CurrentFiscalSpanID, FiscalSpanID);
         this.globals.setDataInLocalStorage(this.commonconstant.CurrentFiscalSpanDates, fs.DateFrom + " - " + fs.DateTo);
         $('#lblCurrentFiscalSpanDates').html(fs.DateFrom + " - " + fs.DateTo);
         
         this.businessService.commonService.SetSessionVariables('FiscalSpanID',FiscalSpanID).subscribe((data: ResponseModel) => {
            if (data._statusCode === 200) {

                if (data._obj != null) {

                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyEmail, data._obj.Email)
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyFax, data._obj.Fax)
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyCCRNumber, data._obj.CCRNumber)
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyAddress, data._obj.Address)
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyName, data._obj.CompanyName)
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyVatNumber, data._obj.VATNumber)
                    this.globals.setDataInLocalStorage(this.commonconstant.AmountFormat, data._obj.AmountFormat);
                    this.globals.setDataInLocalStorage(this.commonconstant.MobileNo, data._obj.MobileNo);
                    this.globals.setDataInLocalStorage(this.commonconstant.DefaultPrintStyle, data._obj.DefaultPrintStyle);
                    this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInAmount, data._obj.DecimalsInAmount);
                    this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInPrice, data._obj.DecimalsInPrice);
                    this.globals.setDataInLocalStorage(this.commonconstant.DecimalsInQuantity, data._obj.DecimalsInQuantity);
                    this.globals.setDataInLocalStorage(this.commonconstant.ContactNumber, data._obj.ContactNumber);
                    this.globals.setDataInLocalStorage(this.commonconstant.ZipCode, data._obj.ZipCode);

                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyLogo, data._obj.MediaContentType + "," + data._obj.MediaContents);
                    this.globals.setDataInLocalStorage(this.commonconstant.CompanyStamp, data._obj.StampMediaContentType + "," + data._obj.StampMediaContents);
                
                    
                }

                this.loaderService.display(false);
                window.location.reload();
            }
        }); 
    }
     SelectLangauge(selectOption: any) {
        this.loaderService.display(true);
        this.businessService.commonService.SetSessionVariables('CultureCode',selectOption).subscribe((data: ResponseModel) => {
            if (data._statusCode == 200) {
                this.globals.SetCultureCode(selectOption);
                this.globals.SetTextDirection(('ar-SA').toLowerCase() === selectOption.toLowerCase());
                this.transService.setStringData(data._obj)
                console.log(this.globals.isRTL)
                window.location.reload();
            }
            //else {
            //    this.touster.error(this.transService.getItem(data._message))
            //}
        }); 
        this.loaderService.display(false);
    }
  
    ngOnInit(): void {
        this.UserName = localStorage.getItem('userName') || 'User Name'
        this.RoleName = localStorage.getItem('roleName') || 'Role Name' 
        this.SetCultureValue();
        $('.closemenu').click(function () { 
            $(".has-sub-menu").removeClass('active');
        });
        $('.sub-menu li a').click(function () {
            $(".has-sub-menu").removeClass('active');
        });
        this.CultureCode = this.globals.getDataFromLocalStorage('cultureCode');
        if(this.globals.getDataFromLocalStorage('cultureCode') == 'ar-SA'){
            this.IsCultureCodeAR = true;
        } 
 

        // Collapsible Panels
        //$(document).on('click', '[data-toggle="collapse"]', function () {
        //    $(this).children('.os-dropdown-trigger').children('.os-icon').toggleClass('os-icon-ui-23 os-icon-ui-22');
        //});
        //this.customselectmenu();
    } 
    showViewDetails() {
        this.productList = [];
        $(".importModal34").animate({ width: "toggle" }); 
        this.LoadProductOnLowQuantityGrid();
    }
    onlowBoxColse() {
        $('.importModal34').hide({ direction: 'right' }, 'slow'); 
    }

    LoadProductOnLowQuantityGrid() {
        this.loaderService.display(true);
        this.businessService.productCardRepository.ProductOnLowQuantity(this.currentPage, this.paginationPageSize, this.searchText).subscribe(data => {
          this.loaderService.display(false);
          if (data) {
            this.productList = data._obj;
            if (this.productList != null && this.productList.length > 0) {
              this.pageingDisplay = "";
              this.totalRecord = data._obj[0]["Total"];
              this.totalPages = Math.ceil(this.totalRecord / this.paginationPageSize);
              this.setPagingValues();
            }
            else {
              this.pageingDisplay = "d-none";
            }
          }
        });
    }

    ngAfterViewInit(): void { 

        // if (this.showFiscalspanNote() && !this.IsDemoUser) {
        //     $(".notifyclient").show().css({ "top": "60px" });
        //    
        // }
        this.businessService.fiscalSpansRepository.LoadRec(null).subscribe((data: any) => {
            this.loaderService.display(false);
            this.fiscalSpans = data._obj;                
        });
        $(document).on('click', '[data-toggle="collapse"]', function () {
            $(this).children('.os-dropdown-trigger').children('.os-icon').toggleClass('os-icon-ui-23 os-icon-ui-22');
        });

        // Language Selection Dropdown
        $('.dropdown > .caption').on('click', function () {
            $(this).parent().toggleClass('open');
        });
        $('.dropdown > .list > .item').on('click', function () {
            $('.dropdown > .list > .item').removeClass('selected');
            $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').html($(this).html());                
            if ($(this).data("item") == "EN") {
                console.log('EN');
            } else if ($(this).data("item") == "SA") {
                console.log('SA');
            } else { 
                console.log('EN');
            }
        });
        $(document).on('keyup', function (evt) {
            if ((evt.keyCode || evt.which) === 27) {
                $('.dropdown').removeClass('open');
            }
        }); 
        $(document).on('click', function (evt) {
            if ($(evt.target).closest(".dropdown > .caption").length === 0) {
                $('.dropdown').removeClass('open');
            }
        });  

        // Date Selection Dropdown
        $(".drop-down22 .selected a").click(function () {
            $(".drop-down22 .options ul").toggle();
        }); 
        $(".drop-down22 .options ul li a").click(function () {
            var text = $(this).html(); 
            $(".drop-down22 .selected a span").html(text);
            $(".drop-down22 .options ul").hide();
        }); 
        $(document).bind('click', function (e) {
            var $clicked = $(e.target);
            if (!$clicked.parents().hasClass("drop-down22"))
                $(".drop-down22 .options ul").hide();
        });
        if(this.globals.getBoolFromLocalStorage("isDownloadingFile")){
            $( ".preloadmain" ).css( "display", "flex" );
        }
       
    }
     
    clicknotify(): void { 
        $(".notifybox").toggle();
    }

    SetCultureValue(): void {

        $('.cLang').val(this.globals.cultureCode);
        //this.dateTimeAdapter.setLocale(this.globals.cultureCode);
        this.globals.SetTextDirectionFromCultureCode();
        $('html').attr('dir', this.globals.isRTL ? 'rtl' : 'ltr');

    }

    logout(): void {
        this.DeleteCookie();
        this.authService.logout();
    }
    DeleteCookie()
    {
        let expire = new Date(1970, 12, 31);

        document.cookie = "bnodyName=;expires=" + expire.toUTCString();
        document.cookie = "bnodyPassword=;expires=" + expire.toUTCString();
    } 
    LoadModel() {
        $('#passwordModal').modal('show');
        this.smartUtilities.EnableDisableFormElement("#passwordModal", true);
}
    changeUserPassword() {
        this.loaderService.display(true);
        if (!this.smartUtilities.ValidateForm('#passwordModal')) {
            this.loaderService.display(false);
            return;
        }
        if (this.passModel.NewPassword !== this.passModel.ConfirmNewPassword) {
            this.toastrService.error(this.translate.transform('1440'));
            this.loaderService.display(false);
            return;
        }

        this.businessService.erpUserRepository.ChangePassword(this.passModel).subscribe(
            (data: ResponseModel) => {
                if (data._statusCode === 200 && data._obj) {
                    this.toastrService.success(this.translate.transform('1344'));
                    this.passModel = new ChangePasswordModel();
                    $('#passwordModal').modal('hide');
                }
                else
                    this.toastrService.error(this.translate.transform(data._message));
                this.loaderService.display(false);
            });
    }

      //#region Paging Related Events

    dispalyGrid() {
        this.LoadProductOnLowQuantityGrid();
        this.showMainContent = true;
    }
    searchDocument() {
        this.currentPage = 1;
        this.LoadProductOnLowQuantityGrid();
    }

    firstPage() {
        this.currentPage = 1;
        this.LoadProductOnLowQuantityGrid();
    }

    previousPage() {
        this.currentPage--;
        this.LoadProductOnLowQuantityGrid();
    }

    lastPage() {
        this.currentPage = this.totalPages;
        this.LoadProductOnLowQuantityGrid();
    }

    nextPage() {
        this.currentPage++;
        this.LoadProductOnLowQuantityGrid();
    }

    onSearchkeyUp(e) {
        this.currentPage = 1;
        if (e.keyCode === 13 && e.target.value !== '') {
        this.LoadProductOnLowQuantityGrid();
        }
        else if (e.target.value == '' || e.target.value == null) {
        this.LoadProductOnLowQuantityGrid();
        }
    }

    setPagingValues() {
        this.pageingDisplay = "";
        $("#btrFirst").prop("disabled", false);
        $("#btrPrevious").prop("disabled", false);

        $("#btnNext").prop("disabled", false);
        $("#btnLast").prop("disabled", false);


        if (this.currentPage == 1) {
        $("#btrFirst").prop("disabled", true);
        $("#btrPrevious").prop("disabled", true);
        }

        if (this.currentPage == this.totalPages) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
        }
        this.pageInfo = this.translate.transform('1166') + " " + this.currentPage + " " + this.translate.transform('2807') + " " + this.totalPages;

        if (this.totalRecord < this.paginationPageSize) {
        this.showingPage = (Number((this.currentPage - 1) * this.paginationPageSize) + 1) + " " + this.translate.transform('1088') + " " + this.totalRecord + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
        else if (Number(this.currentPage * this.paginationPageSize) > this.totalRecord) {
        this.showingPage = (Number((this.currentPage - 1) * this.paginationPageSize) + 1) + " " + this.translate.transform('1088') + " " + this.totalRecord + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
        else {
        this.showingPage = (Number((this.currentPage - 1) * this.paginationPageSize) + 1) + " " + this.translate.transform('1088') + " " + Number(this.currentPage * this.paginationPageSize) + " " + this.translate.transform('2807') + " " + this.totalRecord;
        }
    }
    //#endregion
}
