import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IAssetsEntryRepository } from "./IAssetsEntryRepository";
import { AssetsEntry } from "../../../models/HR/AssetsEntry/assetsEntry";

@Injectable()
export class AssetsEntryRepository extends GenericRepository<AssetsEntry> implements IAssetsEntryRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/AssetsEntry', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllByAssetTypes(assetTypeCode: string): Observable<any> {
        if (assetTypeCode == '') {
            assetTypeCode = '|';
        }
        return this.Get('/SelectAllByAssetTypes' + '/' + assetTypeCode).pipe(map(this.extractData), catchError(this.handleError));
    }    
    SaveRec(item: AssetsEntry): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}