import { Injectable } from '@angular/core';
import { TopToolBarOptions } from './toptoolbar-options';
import { SmartAppUtilities } from 'src/app/models/common/getjsonfrom-controls-service-model';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogServices } from 'src/app/shared/confirmation-dialog/confirmation-dialog.service';
import { TranslateServiceLocal } from '../translateservice/translate.service.local';
import { Globals } from 'src/environments/Globals';

declare var $: any;

@Injectable()
export class ImplementOperationToolbar {
    // Global Variables
    private _isInserting: boolean = false;
    private _isRecordInView: boolean = false;
    public _isAutoPrint: boolean = false;
    private _cmd: string = '';
    private _index: number = 0;
    public _enableCB: Function;
    // PRINT      
    private _printRecordDate: string = '';
    private _printMiscFilters: string = '';
    private _isChequeType: string = '';
    private _ts:TranslateServiceLocal; // translate Service use to Translate Msgs => will use later
    topToolBarOptions = new TopToolBarOptions();
    smartAppUtilities = new SmartAppUtilities(this.toastrService);
    
    constructor(topToolBarOptions: TopToolBarOptions, public toastrService: ToastrService, public confirmationDialogServices?: ConfirmationDialogServices) {
        let gb = new Globals();
        this._ts = new TranslateServiceLocal(gb, this.toastrService);       
        
        this.topToolBarOptions = topToolBarOptions;
        this._enableCB = topToolBarOptions.enableCB; //for External Use
        this.bindClickEvents();
        this.textBoxWrapping();

    }
    private textBoxWrapping(): void {

        //this.smartAppUtilities.SpecialTextBox('.EmailTextBox', { textBoxType: 'email' });
        //this.smartAppUtilities.SpecialTextBox('.MobileTextBox', { textBoxType: 'mobile' });
        //this.smartAppUtilities.SpecialTextBox('.LandlineTextBox', { textBoxType: 'landline' });
        //this.smartAppUtilities.SpecialTextBox('.PhoneTextBox', { textBoxType: 'phone' });
        //this.smartAppUtilities.SpecialTextBox('.FaxTextBox', { textBoxType: 'fax' });

        ///////////////////////////////////////////////////////////////////////////////

        //this.smartAppUtilities.Numberify('.DecimalTextBox', { numberType: 'decimal' });
        //this.smartAppUtilities.Numberify('.AmountTextBox', { numberType: 'decimal' });
        //this.smartAppUtilities.Numberify('.IntegerTextBox', { numberType: 'integer' });
        //this.smartAppUtilities.Numberify('.QuantityTextBox', { numberType: 'integer' });

        //this.smartAppUtilities.Numberify('.ProfitRateTextBox', { numberType: 'decimal', allowNegative: true, min: -100 });
        //this.smartAppUtilities.Numberify('.DiscountTextBox', { numberType: 'decimal', max: 100 });
        //this.smartAppUtilities.Numberify('.TaxTextBox', { numberType: 'decimal' });
        //this.smartAppUtilities.Numberify('.PriceTextBox', { numberType: 'decimal' });
        //this.smartAppUtilities.Numberify('.CurrencyRateTextBox', { numberType: 'decimal' });
        this.smartAppUtilities.MakeRequired('.Required');
    }
    private bindClickEvents(): void {

        let documentObject: any = $(document);

        this._resetButtons();

        if (this.topToolBarOptions.enableCB) {

            this.topToolBarOptions.enableCB(false); // disable
            this.disableGrid(false)
        }
        if (this.topToolBarOptions.screenType !== 4)    // No navigtion,view and print in type 4
        {
            documentObject.find('.btnFirst1').unbind("click").on("click", () => {
                this._index = 0;

                if (this.topToolBarOptions.screenType == 2) {
                    //this._loadGridNavigation(1);
                }
                else {
                    this._loadNavigation(1);
                }
                return false;
            });

            documentObject.find('.btnPrev1').unbind("click").on("click", () => {
                // if (this.topToolBarOptions.navFuncPath == '') {                    
                //    this.smartAppUtilities.ShowMessageOnClient("No nav path", "Red", 2000);
                //     return false;
                // }

                if (this.topToolBarOptions.screenType == 2) {
                    this._index = (this._index === 0 ? 1 : this._index);
                    //this._loadGridNavigation(2);
                }
                else {
                    this._loadNavigation(2);
                }
                return false;
            });

            documentObject.find('.btnNext1').unbind("click").on("click", () => {
                // if (this.topToolBarOptions.navFuncPath == '') {                    
                //    this.smartAppUtilities.ShowMessageOnClient("No nav path", "Red", 2000);
                //     return false;
                // }

                if (this.topToolBarOptions.screenType == 2) {
                    this._index = (this._index === 0 ? 1 : this._index);
                    //this._loadGridNavigation(3);
                }
                else {
                    this._loadNavigation(3);
                }
                return false;
            });

            documentObject.find('.btnLast1').unbind("click").on("click", () => {
                // if (this.topToolBarOptions.navFuncPath == '') {
                //    this.smartAppUtilities.ShowMessageOnClient("No nav path", "Red", 2000);
                //     return false;
                // }

                if (this.topToolBarOptions.screenType == 2) {
                    this._index = (this._index === 0 ? 1 : this._index);
                    //this._loadGridNavigation(4);
                }
                else {
                    this._loadNavigation(4);
                }
                return false;
            });
        }

        documentObject.find('.btnAddNew1').unbind("click").on('click', () => {
            let preAddOk: boolean = true;
            // location changed with below : not working if preadd not defind
            if (!this.topToolBarOptions.isPrimaryKeyAuto)
                $(".txtPrimaryKey").removeClass("no_enable");
            else
                $(".txtPrimaryKey").addClass("no_enable");

            if (this.topToolBarOptions.preAddCB) {
                preAddOk = this.topToolBarOptions.preAddCB();
            }

            if (preAddOk) {

                //this._index = 0;

                if (this.topToolBarOptions.enableCB) {                    
                        this.topToolBarOptions.enableCB(true); // enable
                        this.disableGrid(true)                    
                }
                this.toastrService.info("Enter data to add");
                this._cmd = 'Add';

                this.topToolBarOptions.navParentContainer.find('.btnSet1').hide();
                this.topToolBarOptions.navParentContainer.find('.btnSet2').hide();
                this.topToolBarOptions.navParentContainer.find('.btnSet3').show();

                if (!this.topToolBarOptions.isAutoPrint) {
                    this.topToolBarOptions.navParentContainer.find('.btnSavePrintAddUpdate').hide();
                }

                this._isInserting = true;

                this._clearForm('selective');

                if (this.topToolBarOptions.postAddCB) {
                    this.topToolBarOptions.postAddCB();
                    this._log('post add');
                }

                if (this.topToolBarOptions.isPrimaryKeyAuto)
                    $('.txtPrimaryKey').addClass('no_enable').attr("disabled", "disabled");                // Disabled in case of auto             
                else
                    $('.txtPrimaryKey').focus();
            }
            return false;
        });

        // Update---------------------------------------------------------------------------------

        documentObject.find('.btnUpdate1').unbind("click").on("click", () => {
            let preUpdateOk: Boolean = true;


            if (this.topToolBarOptions.preUpdateCB) {
                $(".txtPrimaryKey").addClass("no_enable");
                preUpdateOk = this.topToolBarOptions.preUpdateCB();
            }
            
            if (preUpdateOk) {

                //this._index = 0;
                if (this.topToolBarOptions.screenType === 3 || this.topToolBarOptions.screenType === 4)
                    this._isRecordInView = true;

                if (this._isRecordInView) {
                    // this.toastrService.info("Please modify data and click 'Save")
                    if (this.topToolBarOptions.enableCB) {
                        this.topToolBarOptions.enableCB(true);
                        this.disableGrid(true)
                    }

                    if (this.topToolBarOptions.screenType === 3)
                        $('.txtPrimaryKey').attr('disabled', 'disabled');
                    else
                        $('.txtPrimaryKey').attr('disabled', 'disabled');

                    this.topToolBarOptions.navParentContainer.find('.btnSet1').hide();
                    this.topToolBarOptions.navParentContainer.find('.btnSet2').hide();
                    this.topToolBarOptions.navParentContainer.find('.btnSet3').show();
                    
                    this.topToolBarOptions.navParentContainer.find('.btnSavePrintAddUpdate').hide();
                    
                    this._isInserting = false;

                    if (this.topToolBarOptions.postUpdateCB)
                        this.topToolBarOptions.postUpdateCB();
                }
                else
                    this._toLoadEnvironment('update');
            }

            return false;
        });

        // Delete---------------------------------------------------------------------------------

        documentObject.find('.btnDelete1').unbind("click").on("click", () => {

            if (this.topToolBarOptions.deleteCB === null) {

                //this.smartAppUtilities.ShowMessageOnClient("No delete path available", "Red", 2000);
                this.toastrService.error("Delete event not found.");
                return false;
            }

            if (this.topToolBarOptions.screenType === 3 || this.topToolBarOptions.screenType === 4)
                this._isRecordInView = true;
            var preDeleteOk = true;

            if (this.topToolBarOptions.preDeleteCB)
                preDeleteOk = this.topToolBarOptions.preDeleteCB();

            if (preDeleteOk) {
                if (this._isRecordInView) {

                    if (preDeleteOk) {
                        this.confirmationDialogServices.showConfirmationModal();
                        return false;
                    }
                    else {
                        this._resetButtons();
                    }
                }
                else
                    this._toLoadEnvironment('delete');
            }

            return false;
        });

        // Print-------------------------------------------------------------------------------------
        
        if (this.topToolBarOptions.screenType !== 4)    // No navigtion,view and print in type 4
        {
            documentObject.find('.btnPrint1').unbind("click").on("click", () => {  
                if (this.topToolBarOptions.loadCB === null) {
                    this.toastrService.error("Data load event not found.");
                    return false;
                }

                if (this._isRecordInView) {
                    if (this.topToolBarOptions.enableCB) {
                        this.topToolBarOptions.enableCB(false); // disable
                        this.disableGrid(false)
                    }

                    this._resetButtons();

                    //this._index = 0;
                    if (this.topToolBarOptions.printRecCB == null) {                        
                        if (this.topToolBarOptions.printPath) {
                            let printdate: string = '';
                            if (this._printRecordDate !== '')
                                printdate = '&filter_date=' + this._printRecordDate;

                            let printMiscFilters: string = '';
                            if (this._printMiscFilters !== '')
                                printMiscFilters = '&misc_filters=' + this._printMiscFilters;

                            let printIsChequeType: string = '';
                            if (this._isChequeType !== '')
                                printIsChequeType = '&is_cheque_type=' + this._isChequeType;

                            window.open(this.topToolBarOptions.printPath + '?page_id=' + this.topToolBarOptions.printPageID + '&record_number=' + $('.txtPrimaryKey').val() + '&isPreview=' + false + printdate + printMiscFilters + printIsChequeType, "Printable Page", "width=1500,height=650px");
                        }
                        else
                            alert('Print functionality not implemented on this page.');
                    }
                    else {
                        setTimeout(() => {
                            this.topToolBarOptions.printRecCB();
                        }, 300);
                    }
                    //}
                }
                else
                    this._toLoadEnvironment('print');

                return false;
            });
            // Send Email Event
            documentObject.find('.btnSendEmail').on("click", () => {

                //this._index = 0;

                if (this.topToolBarOptions.screenType === 3 || this.topToolBarOptions.screenType === 4)
                    this._isRecordInView = true;

                if (this._isRecordInView) {

                    $('#emailSendModal').modal('show');

                    if (this.topToolBarOptions.getEmailAddressFromDB) {
                        this.topToolBarOptions.getEmailAddressFromDB();
                        this.bindEventEmailSendButton();
                        return false;
                    }
                    setTimeout(() => {

                        if (this.topToolBarOptions.getEmailAddress) {
                            $('#RequiredEmail').val(this.topToolBarOptions.getEmailAddress());
                            this.bindEventEmailSendButton();
                        }
                        else {
                            this._log('email method not defined.');
                        }
                    }, 400);

                }
                else
                    this._toLoadEnvironment('email');


                return false;
            });

            // View-------------------------------------------------------------------------------------

            documentObject.find('.btnView1').unbind("click").on("click", () => {
                if (this.topToolBarOptions.loadCB === null) {
                    this.toastrService.error("Data load event not found.");
                    return false;
                }

                this._index = 0;

                let preViewOk: Boolean = true;

                if (this.topToolBarOptions.preViewCB) {
                    preViewOk = this.topToolBarOptions.preViewCB();
                }

                if (preViewOk)
                    this._toLoadEnvironment('view');

                if (this.topToolBarOptions.postViewCB)
                    this.topToolBarOptions.postViewCB()
                return false;
            });
        }

        // Cancel Load----------------------------------------------------------------------------------

        documentObject.find('.btnCancelLoad').unbind("click").on("click", () => {

            $('.txtPrimaryKey').attr('unique_active', 'true').val('').attr('disabled', 'disabled');// EnableDisableTextBox({ value: true });

            if (this.topToolBarOptions.enableCB) {
                setTimeout(() => {
                    this.topToolBarOptions.enableCB(false); // disable    
                    this.disableGrid(false)
                }, 300);
            }
            if (this.topToolBarOptions.cancelLoadCB) {
                this.topToolBarOptions.cancelLoadCB();
            }
            this._clearForm("clearall");
            this._resetButtons();

            //reset command 
            this._cmd = '';

            return false;
        });

        // Cancel Add New/Update----------------------------------------------------------------------------------

        documentObject.find('.btnCancelAddUpdate').unbind("click").on("click", () => {
            $('.txtPrimaryKey').attr('unique_active', 'true');

            if (this.topToolBarOptions.enableCB) {
                this.topToolBarOptions.enableCB(false); // disable       
                this.disableGrid(false)                
            }

            this._resetButtons();

            if (this.topToolBarOptions.screenType !== 4)
                this._clearForm('clearall');

            if (this.topToolBarOptions.cancelAddUpdateCB) {
                this._log("cancel add update CB");
                this.topToolBarOptions.cancelAddUpdateCB();
            }

            this._log("Add/Update cancelled");

            return false;
        });

        ////////////////////////////////////AJAX Calls/////////////////////////////////////////////////

        //-- Save (On Add or Update)------------------------------------------------------------------------------------
        documentObject.find('.btnSavePrintAddUpdate').unbind("click").on("click", () => {            
            this._isAutoPrint  = true;
            var preSaveOk = true;

            if (this.topToolBarOptions.preSaveCB) {
                preSaveOk = this.topToolBarOptions.preSaveCB(this._isInserting);
            }

            if (preSaveOk) {
                this._log("pre save OK");

                var saveOk = false;

                if (this.topToolBarOptions.saveAddUpdateCB) {
                    saveOk = this.topToolBarOptions.saveAddUpdateCB(this._isInserting); // Do it yourself on page
                }

                if (saveOk) {
                    var postSaveOk = true;

                    if (this.topToolBarOptions.postSaveCB)
                        postSaveOk = this.topToolBarOptions.postSaveCB(this._isInserting);
                                        
                    if (postSaveOk) {
                        if (this.topToolBarOptions.enableCB) {
                            this.topToolBarOptions.enableCB(false); // disable
                            this.disableGrid(false)
                        }
                    }
                }
            }

            return false;
        });

        documentObject.find('.btnSaveAddUpdate').unbind("click").on("click", () => {            
            this._isAutoPrint = false;
            var preSaveOk = true;

            if (this.topToolBarOptions.preSaveCB) {
                preSaveOk = this.topToolBarOptions.preSaveCB(this._isInserting);
            }

            if (preSaveOk) {
                this._log("pre save OK");

                var saveOk = false;

                if (this.topToolBarOptions.saveAddUpdateCB) {
                    saveOk = this.topToolBarOptions.saveAddUpdateCB(this._isInserting); // Do it yourself on page
                }

                if (saveOk) {
                    var postSaveOk = true;

                    if (this.topToolBarOptions.postSaveCB)
                        postSaveOk = this.topToolBarOptions.postSaveCB(this._isInserting);

                    if (postSaveOk) {
                        if (this.topToolBarOptions.enableCB) {
                            this.topToolBarOptions.enableCB(false); // disable
                            this.disableGrid(false)
                        }
                    }
                }
            }

            return false;
        });
        
        //-- Loading------------------------------------------------------------------------------------

        documentObject.find('.btnLoad2').unbind("click").on("click", () => {
            let $primaryKeyField: any = $('.txtPrimaryKey');

            if (this.topToolBarOptions.loadCB === null) {
                this.toastrService.error("Data load event not found.");
                return false;
            }

            if ($primaryKeyField.val() === '') {
                this.toastrService.error($primaryKeyField.data('jsname') + ' is empty.');
                $primaryKeyField.focus();
                return false;
            }
            if (this.topToolBarOptions.screenType === 2)
                $('.rowIndex').val(this._index);

            this.topToolBarOptions.loadCB($primaryKeyField.val());
            return false;
        });
        // Delete Popup Yes,No events 
        documentObject.find('.btnDeleteContent').unbind("click").on("click", () => {

            this._index = this._index - 1;

            if (this.topToolBarOptions.deleteCB)
                this.topToolBarOptions.deleteCB();

            $('#deleteConentConfirmation').modal('hide');
        });

        documentObject.find('.btnCancelDelete').unbind("click").on("click", function () {
            $('.txtPrimaryKey').attr('unique_active', 'true').attr('disabled', 'disabled'); //EnableDisableTextBox({ value: false });

            this._resetButtons();
        });
    } // End Bind Click Events 

    SetOption(option: string, value: any, isRecordAutoPrint: boolean): void {

        this._setOption(option, value, isRecordAutoPrint);
    }

    _setOption(key, value, isRecordAutoPrint): void {

        this._log('TTB > Setting options');
        // will see later
        // this.options[key] = value;
        this._update();

        if (typeof isRecordAutoPrint != "undefined" && isRecordAutoPrint != null && isRecordAutoPrint === true) {
            this._isRecordInView = isRecordAutoPrint;
            $('.btnPrint1').click();
        }
    }

    _addRecord(): void {        
        $('.btnAddNew1').click();
    }

    _printRecord(): void {        
        this._isRecordInView = true;
        $('.btnPrint1').click();
    }

    _PostLoad(IsRecordLoaded: boolean): void {
        
        if (this.topToolBarOptions.postDataLoadCB)
            this.topToolBarOptions.postDataLoadCB();
        
            if (IsRecordLoaded) {
                this._isRecordInView = true;        
                switch (this._cmd) {
                    case "update":
                        this.topToolBarOptions.navParentContainer.find('.btnUpdate1').click();
                        break;

                    case "delete":
                        this.topToolBarOptions.navParentContainer.find('.btnDelete1').click();
                        break;

                    case "print":
                        this.topToolBarOptions.navParentContainer.find('.btnPrint1').click();
                        break;

                    case "email":
                        this._resetButtons();
                        if (this.topToolBarOptions.enableCB) {
                            this.topToolBarOptions.enableCB(false); // disable
                            this.disableGrid(false)
                        }
                        this.topToolBarOptions.navParentContainer.find('.btnSendEmail').click();
                        break;

                    case "view":
                        this._resetButtons();
                        if (this.topToolBarOptions.enableCB) {
                            this.topToolBarOptions.enableCB(false); // disable
                            this.disableGrid(false)
                        }
                        break;

                    case "select":
                        console.log(this._cmd);
                        break;

                    default:
                        console.log(this._cmd);
                        //alert('no such command');
                        break;
                }
            }
            else {
                this._clearForm('clearall');
                $('.txtPrimaryKey').focus();
            }
    }
    _PostDelete(): void {
        this._clearForm('clearall');
        this._resetButtons();
        // $('.txtPrimaryKey').attr('unique_active', 'true').prop('disabled', true);

        if (this.topToolBarOptions.postDeleteCB)
            this.topToolBarOptions.postDeleteCB();

    }
    _log(message: string): void {
        if (this.topToolBarOptions.loggingEnabled)
            console.log(message);
    }
    _toLoadEnvironment(cmd: string): void {

        if (this.topToolBarOptions.loadCB === null) {
            this.toastrService.error("Data load event not found.");
        }

        this._cmd = cmd;

        // will check later. 
        this._clearForm("clearall");

        /*In case of loading, on focusout, unique should not be checked*/
        // In case PK is auto, no_enable will be re-added to txtPrimaryKey
        $('.txtPrimaryKey').removeClass('no_enable').attr('unique_active', 'false').removeAttr("disabled");

        this.topToolBarOptions.navParentContainer.find('.btnSet1').hide();
        this.topToolBarOptions.navParentContainer.find('.btnSet2').show();
        this.topToolBarOptions.navParentContainer.find('.btnSet3').hide();
        this.toastrService.info("Enter key to load for " + cmd);
    }

    _clearForm(clearType: string = "clearall"): void {
        this.smartAppUtilities.ClearForm(this.topToolBarOptions.formContainer, { extension2: clearType });
        this._isRecordInView = false;
    }
    _resetButtons(): void {

        this.topToolBarOptions.navParentContainer.find('.btnSet1').show();
        this.topToolBarOptions.navParentContainer.find('.btnSet2').hide();
        this.topToolBarOptions.navParentContainer.find('.btnSet3').hide();

        if (this.topToolBarOptions.screenType === 4) // No navigtion,view and print in type 4
            $('.btnNav,.btnView1,.btnPrint1').hide();
        if (this.topToolBarOptions.screenType === 3) // No navigtion,view and print in type 4
        {
            $('.btnSet1').not('.btnUpdate1').hide();
            $('.btnUpdate1').removeClass('btn-outline-primary').addClass('btn-primary');
            $('.pagination').hide();
        }

    }
    _update(): void {
        var $plugin = this;
        $plugin._log('TTB > Updated');
    }
    _destroy(): void {
        var $plugin = this;
        $plugin._log('TTB > Destroyed');
    }
    UpdateIndex(idx): void {
        var $plugin = this;
        $plugin._index = idx;
    }
    // will check it later
    // UpdatePrintDate(printdate): void {
    //     this._printRecordDate = printdate;
    // }

    // UpdatePrintMiscFilter(miscfilter): void  {
    //     this._printMiscFilters = miscfilter;
    // }

    // UpdatePrintChequeType(chequetype): void {        
    //     this._isChequeType = chequetype;
    // }

    _loadNavigation(navType: number): void {

        if ((navType === 2 || navType === 3) && ($('#RowIndex').val() !== ''))
            this._index = $('#RowIndex').val();

        if (this._index === -1)
            this._index = 0;

        var isLoaded = false;
        //var rec = CallServerFunction(this.topToolBarOptions.navFuncPath, "{'navType':'" + navType + "', 'currentIndex':'" + this._index + "'}");

        if (this.topToolBarOptions.loadNavRec) {
            this.topToolBarOptions.loadNavRec(navType, this._index);
            isLoaded = true;
        }

        if (this.topToolBarOptions.postNavLoad) {
            //Any complex ui management will be handled via this callback
            isLoaded = this.topToolBarOptions.postNavLoad();
        }


        if (isLoaded) {
            this._isRecordInView = true;
            this._resetButtons();
            if (this.topToolBarOptions.enableCB) {
                this.topToolBarOptions.enableCB(false); // disable
                this.disableGrid(false)
            }
        }
        else {
            if (navType == 2)  //prev
            {
                if (this._isRecordInView)
                    this.toastrService.error("This is the first record");
                else
                    this.toastrService.error("No data found.");
            }
            else if (navType == 3)  //next
            {
                if (this._isRecordInView)
                    this.toastrService.error("This is the last record");
                else
                    this.toastrService.error("No data found.");
            }
            else // first or last
                this.toastrService.error("No data found.");
        }
        // return isLoaded;
    }
    public LoadEvent(Code: string) {

        if (this.topToolBarOptions.loadCB)
            this.topToolBarOptions.loadCB(Code);
    }

    private bindEventEmailSendButton() {

        $('#btnEmailSend').unbind("click").click(() => {


            let email = $('#RequiredEmail').val();
            if (!email) {    
                this.toastrService.error(this._ts.getItem('2364'));
                return false
            }

            let isEmailValidated = this.smartAppUtilities.ValidateEmail(email);

            if (isEmailValidated) {

                if (this.topToolBarOptions.sendEmailCB)
                    this.topToolBarOptions.sendEmailCB(email,
                        $('.txtPrimaryKey').val(),
                        this.topToolBarOptions.printPageID);
                else
                    console.log('email function not defined.')
            }
        });
    }

    PostBindEmail(email: string): void {
        $('#RequiredEmail').val(email);
    }

    ExternalSaveOK(): void {        
        var postSaveOk = true;

        if (this.topToolBarOptions.postSaveCB)
            postSaveOk = this.topToolBarOptions.postSaveCB(this._isInserting);

        if (postSaveOk) {
            if (this.topToolBarOptions.enableCB) {
                this.topToolBarOptions.enableCB(false); // disable
                this.disableGrid(false)
            }

            this.topToolBarOptions.navParentContainer.find('.btnSet1').show();
            this.topToolBarOptions.navParentContainer.find('.btnSet2').hide();
            this.topToolBarOptions.navParentContainer.find('.btnSet3').hide();

            this._isRecordInView = false;

            if (this._isInserting)
                this.toastrService.success("Insertion successful");
            else
                this.toastrService.success("Updation successful");
        }
        else {
            if (this._isInserting)
                this.toastrService.error("Insertion failed");
            else
                this.toastrService.error("Updation failed");
        }
    }

    private disableGrid(enableGrid: boolean): void {
        let containerGrid = $('#FormGrid .divTable')[0] ? '#FormGrid .divTable' : '.disableDynamicElements';
        if ($(containerGrid)[0]) {
            let smartUtilities = new SmartAppUtilities(this.toastrService);
            setTimeout(() => {
                smartUtilities.EnableDisableFormElement(containerGrid, enableGrid, enableGrid ? ':disabled' : ':not(:disabled)');
            }, 500);
        }
    }


} //END TopToolbar Implementation
