export class CompanyProfile {

    constructor() {

        this.IsCultureCodeChange = false
        this.IsFiscalSpanChange = false
    }
    CompanyName: string;
    Address: string;
    TermsAndConditions: string;
    Email: string;
    ContactNumber: string;
    VATNumber: string;
    NickName: string;
    MasterPassword: string;
    CultureCode: string;
    CalendarCode: string;
    Theme: string;
    AmountFormat: number;
    DecimalsInAmount: number;
    DecimalsInPrice: number;
    DecimalsInQuantity: number;
    DefaultCurrency: string;
    DefaultCurrencySymbol: string;
    ShowCurrencyName: boolean;
    MediaContents: string;
    MediaContentType: string;
    StampMediaContents: string;
    StampMediaContentType: string;
    StopLogin: boolean;
    TaxEnabled: boolean;
    ShowMessageOnLogin: boolean;
    MessageCode: string;
    ShowStartUpWizardOnLogin: boolean;
    CCRNumber: string;
    Fax: string;
    DefaultPrintStyle: string;
    SMTPServer: string;
    SMTPEMail: string;
    SMTPPort: string;
    SMTPPassword: string;
    MobileNo: string;
    ZipCode: string;
    City: string;
    CountryCode: string;
    IsFiscalSpanChange: boolean;
    FiscalSpanToChange: number;
    IsCultureCodeChange: boolean;
    EnableSsl: boolean;
    IsRequestSent: boolean;
    DaysLeft: number;
    LicenseEndDate: string;
    GoDownCode: string;
    ProductText: string;
    ZidConsumerKey: string;
    ZidToken: string;
    ZidStoreCode: string;
    GoDownName: string;
    ProductTextName: string;
    Slogan: string;
    BankDetail: any;
    PrintStockInformation: boolean;
    ISKitchenDisplay: boolean;
    SelectionType: string; // 1. Print On Godown Name 2. Print On Godown Name + Company Name
    IsBridgeServerRequired: boolean;
}
