import { Component, OnInit, HostListener } from '@angular/core';
import { CommonConstant } from 'src/app/shared/constent/applicationcodes';
import { Globals } from 'src/environments/Globals';
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReportParamsModel } from '../../../../models/common/report-params.model';
import { TranslatePipe } from '../../../../shared/custom-pipes/translate-pipe/translate.pipe';

declare var $: any;
@Component({
    selector: 'app-reports-viewer',
    templateUrl: './reports-viewer.component.html'
})


export class ReportsViewerComponent implements OnInit {

    commonConst = new CommonConstant();
    reportHead = "";
    reportName = "";
    companyHeadInfo = "";
    totalRecords: number = 0;
    currentPage: number = 1;
    recordsPerPage: number = 25;
    jumpToPage: 1;
    arrayAsCounter: any[];
    arrayAsCounter4PageSize: any[];
    IsEmail: string;
    IsFax: string;
    IsCompanyLogo: string;
    IsContactNumber: string
    IsAddress: string;
    IsCCRNumber: string;
    Email: string;
    Fax: string;
    CompanyLogo: string;
    Address: string;
    CCRNumber: string;
    VATNumber: string;
    CompanyName: string;
    IsGMSig: string;
    IsFRSig: string;
    IsFMSig: string;
    IsAccountantSig: string;
    // created by Hamza for new design    
    showCCR:boolean=false;
    showVat:boolean=false;
    showCompanyName:boolean=false;
    // temp 
    companyHeadNew:boolean=false;

    constructor(private globals: Globals, private loaderService: LoaderService, private businessService: BusinessServices, private router: Router, private toastr: ToastrService, private translate: TranslatePipe) { }

    ngOnInit() {

        this.loaderService.display(true);

        this.reportHead = this.globals.getDataFromLocalStorage(this.commonConst.ReportHeadInformation);
        this.reportName = this.globals.getDataFromLocalStorage(this.commonConst.ReportName);
        this.IsEmail = this.globals.getDataFromLocalStorage(this.commonConst.Email);
        this.IsFax = this.globals.getDataFromLocalStorage(this.commonConst.Fax);
        this.IsCompanyLogo = this.globals.getDataFromLocalStorage(this.commonConst.ShowCompanyLogo);
        this.IsContactNumber = this.globals.getDataFromLocalStorage(this.commonConst.ContactNumber);
        this.IsAddress = this.globals.getDataFromLocalStorage(this.commonConst.Address);
        this.IsCCRNumber = this.globals.getDataFromLocalStorage(this.commonConst.CCRNumber);
        this.Email = this.globals.getDataFromLocalStorage(this.commonConst.CompanyEmail);
        this.Fax = this.globals.getDataFromLocalStorage(this.commonConst.CompanyFax);
        this.CompanyLogo = this.globals.getDataFromLocalStorage(this.commonConst.CompanyLogo);
        this.Address = this.globals.getDataFromLocalStorage(this.commonConst.CompanyAddress);
        this.CCRNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyCCRNumber);
        this.VATNumber = this.globals.getDataFromLocalStorage(this.commonConst.CompanyVatNumber);
        this.CompanyName = this.globals.getDataFromLocalStorage(this.commonConst.CompanyName);
        this.IsGMSig = this.globals.getDataFromLocalStorage(this.commonConst.GeneralManagerSig);
        this.IsFRSig = this.globals.getDataFromLocalStorage(this.commonConst.FinancialrefernceSig);
        this.IsFMSig = this.globals.getDataFromLocalStorage(this.commonConst.FinancialManagerSig);
        this.IsAccountantSig = this.globals.getDataFromLocalStorage(this.commonConst.AccountantSig);
        
        let companyNewHead = this.globals.getDataFromLocalStorage(this.commonConst.CompanyHeadParams);        
        if(companyNewHead)
            this.companyHeadNew = companyNewHead == 'true' ? true : false;

        let companySetting:string = this.globals.getDataFromLocalStorage(this.commonConst.CompanyHeadParams);
        if(companySetting)
            {
            this.showCompanyName = companySetting.includes('1') ? true : false;
            this.showCCR = companySetting.includes('2') ? true : false;
            this.showVat = companySetting.includes('3') ? true : false;
            }

        

        let sp = this.globals.getDataFromLocalStorage(this.commonConst.ReportStoreProcedure);

        if (companyNewHead) {
            
            if (this.showCompanyName)
                this.companyHeadInfo = this.CompanyName;

            if (this.showVat)
                this.companyHeadInfo = this.companyHeadInfo + ' - ' + this.translate.transform("5") + ': ' + this.VATNumber;

            if (this.showCCR)
                this.companyHeadInfo = this.companyHeadInfo + ' - ' + this.translate.transform("6") + ': ' + this.CCRNumber;
        }
        else {
            if (this.CompanyName != null && this.CompanyName != "" && this.VATNumber != null && this.VATNumber != "") {
                this.companyHeadInfo = this.CompanyName + ' - ' + this.translate.transform("5") + ' ' + this.VATNumber;
            }
            else if ((this.CompanyName != null && this.CompanyName != "") && (this.VATNumber == null || this.VATNumber == "")) {
                this.companyHeadInfo = this.CompanyName;
            }
            else if ((this.CompanyName == null || this.CompanyName == "") && (this.VATNumber != null && this.VATNumber != "")) {
                this.companyHeadInfo = this.translate.transform("5") + ' ' + this.VATNumber;
            }
        }
        

        if (sp) {

            if (this.globals.getDataFromLocalStorage(this.commonConst.HttpMethod) != "POST") {

                this.businessService.reportViewerRepository.GetReportData(sp, this.globals.getDataFromLocalStorage(this.commonConst.ReportParams)).subscribe((data: ResponseModel) => {
                    if (data._statusCode == 200 && data._obj && data._obj.HyperTextMarkup) {
                        $('#divReportData').html(data._obj.HyperTextMarkup);
                        //this.companyHeadInfo = data._obj.CompanyHeadInfo;
                        this.totalRecords = data._obj.TotalRecords;
                        this.arrayAsCounter = new Array(Math.ceil(this.totalRecords / this.recordsPerPage))
                        this.arrayAsCounter4PageSize = this.arrayAsCounter
                    }
                    else {
                        this.toastr.error(this.translate.transform(data._message))
                        console.log(data._obj);
                    }
                    this.loaderService.display(false);
                });
            }
            else {

                let reportModel = new ReportParamsModel()
                reportModel.ReportParameter = this.globals.getDataFromLocalStorage(this.commonConst.ReportParams)
                reportModel.StoreProcedure = sp


                this.businessService.reportViewerRepository.GetReportDataByPost(reportModel).subscribe((data: ResponseModel) => {
                    if (data._statusCode == 200 && data._obj && data._obj.HyperTextMarkup) {
                        $('#divReportData').html(data._obj.HyperTextMarkup);
                        //this.companyHeadInfo = data._obj.CompanyHeadInfo;
                        this.totalRecords = data._obj.TotalRecords;
                        this.arrayAsCounter = new Array(Math.ceil(this.totalRecords / this.recordsPerPage))
                        this.arrayAsCounter4PageSize = this.arrayAsCounter
                    }
                    else {
                        console.log(this.translate.transform(data._message));
                    }
                    this.loaderService.display(false);
                });

            }
        }
        else {
            console.log('Error: Store Procedure is missing.');
            this.loaderService.display(false);
        }
    }

    goBack() {

        let backPagePath = this.globals.getDataFromLocalStorage(this.commonConst.BackPagePath);

        let qParams = this.getQueryStringParams()
        if (backPagePath) {
            if (qParams)
                this.router.navigate([backPagePath], qParams);
            else
                this.router.navigate([backPagePath]);
        }

    }

    getQueryStringParams(): any {

        let qParams = this.globals.getDataFromLocalStorage(this.commonConst.QueryParameters);

        let routerParams = { queryParams: {} }

        if (qParams) {
            routerParams.queryParams = { 'q': qParams };
            return routerParams;
        }

        return null;

    }

    printDiv() {

        //$('.reportpaging').hide(); 
        $('body').addClass('PrintableBody');
        $(".PrintableDiv").unwrap();
        var divToPrint = document.getElementById('divRptContainer');
        var htmlToPrint = '' +
            '<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap" rel="stylesheet">' +
            '<style type="text/css">' +
            '.printpage .toppaging, .printpage .printbtns, .printpage .reportpaging, .printpage .showipad, .printpage .reporttopbutton, .printpage .pagingbottom {' +
            'display: none' +
            '}' +
            'body{' +
            'font-family: "Montserrat", sans-serif;' +
            '}' +
            '@media (min-width: 1200px) {' +
            '#reportbody[dir=\"rtl\"] .col-lg-1, #reportbody[dir=\"rtl\"] .col-lg-2, #reportbody[dir=\"rtl\"] .col-lg-3, #reportbody[dir=\"rtl\"] .col-lg-4, #reportbody[dir=\"rtl\"] .col-lg-5, #reportbody[dir=\"rtl\"] .col-lg-6, #reportbody[dir=\"rtl\"] .col-lg-7, #reportbody[dir=\"rtl\"] .col-lg-8, #reportbody[dir=\"rtl\"] .col-lg-9, #reportbody[dir=\"rtl\"] .col-lg-10, #reportbody[dir=\"rtl\"] .col-lg-11, #reportbody[dir=\"rtl\"] .col-lg-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 992px) {' +
            '#reportbody[dir=\"rtl\"] .col-md-1, #reportbody[dir=\"rtl\"] .col-md-2, #reportbody[dir=\"rtl\"] .col-md-3, #reportbody[dir=\"rtl\"] .col-md-4, #reportbody[dir=\"rtl\"] .col-md-5, #reportbody[dir=\"rtl\"] .col-md-6, #reportbody[dir=\"rtl\"] .col-md-7, #reportbody[dir=\"rtl\"] .col-md-8, #reportbody[dir=\"rtl\"] .col-md-9, #reportbody[dir=\"rtl\"] .col-md-10, #reportbody[dir=\"rtl\"] .col-md-11, #reportbody[dir=\"rtl\"] .col-md-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '@media (min-width: 768px) {' +
            '#reportbody[dir=\"rtl\"] .col-sm-1, #reportbody[dir=\"rtl\"] .col-sm-2, #reportbody[dir=\"rtl\"] .col-sm-3, #reportbody[dir=\"rtl\"] .col-sm-4, #reportbody[dir=\"rtl\"] .col-sm-5, #reportbody[dir=\"rtl\"] .col-sm-6, #reportbody[dir=\"rtl\"] .col-sm-7, #reportbody[dir=\"rtl\"] .col-sm-8, #reportbody[dir=\"rtl\"] .col-sm-9, #reportbody[dir=\"rtl\"] .col-sm-10, #reportbody[dir=\"rtl\"] .col-sm-11, #reportbody[dir=\"rtl\"] .col-sm-12 {' +
            'float: right;' +
            '}' +
            '}' +
            '#reportbody[dir=\"rtl\"] .col-xs-1, #reportbody[dir=\"rtl\"] .col-xs-2, #reportbody[dir=\"rtl\"] .col-xs-3, #reportbody[dir=\"rtl\"] .col-xs-4, #reportbody[dir=\"rtl\"] .col-xs-5, #reportbody[dir=\"rtl\"] .col-xs-6, #reportbody[dir=\"rtl\"] .col-xs-7, #reportbody[dir=\"rtl\"] .col-xs-8, #reportbody[dir=\"rtl\"] .col-xs-9, #reportbody[dir=\"rtl\"] .col-xs-10, #reportbody[dir=\"rtl\"] .col-xs-11, #reportbody[dir=\"rtl\"] .col-xs-12 {' +
            'float: right;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-right {' +
            'text-align: left;' +
            '}' +
            '#reportbody[dir=\"rtl\"] .text-left {' +
            'text-align: right;' +
            '}' +
            'table,th,td {' +
            'border: none !important;' +
            'border-collapse: separate;' +
            '}' +
            'table thead tr th {' +
            'border-top:2px solid #000 !important;' +
            'border-bottom:2px solid #000 !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0em 0.5em;' +
            'font-size: 12px;' +
            'height: 34px' +
            '}' +
            'table tbody tr td {' +
            'border-top:none !important;' +
            'border-bottom:1px solid #ccc !important;' +
            'border-left:none !important;' +
            'border-right:none !important;' +
            'padding: 0.45rem 0.45rem;' +
            'font-size: 12px;' +
            '}' +
            '.reportname, .texttwo{' +
            'margin-bottom: 20px;' +
            'font-size: 13px;' +
            'font-weight: 600;' +
            'display: block;' +
            '}' +
            'table {' +
            'border-bottom:2px solid #000!important;' +
            'border-collapse: collapse;' +
            '}' +
            'table thead tr th:last-child, table tbody tr td:last-child {' +
            //'border-right:1px solid #000 !important;' +
            '}' +
            'table {' +
            //'border-bottom:1px solid #000 !important;' +
            '}' +
            'table tbody tr td {' +
            //'border-top:1px solid #000 !important;' +
            //'border-bottom:0px solid #000 !important;' +
            '}' +
            'table tbody tr:nth-of-type(even) td {' +
            //'border-top:0px solid #000 !important;' +
            //'border-bottom:0px solid #000 !important;' +
            '}' +
            'table tbody tr:nth-of-type(odd) td {' +
            //'border-bottom:1px solid #000 !important;' +
            '}' +
            'table th {' +
            //'border-top:1px solid #000 !important;' +
            //'border-left:1px solid #000 !important;' +
            //'border-right:0px solid #000 !important;' +
            //'border-bottom:1px solid #000 !important;' +
            //'text-align:left !important;' +
            //'font-size: 12px;' +
            //'padding: 5px 2px !important;' +
            '}' +
            'table th:last-child {' +
            //'border-right:1px solid #000 !important;' +
            '}' +
            '.report-header {' +
            'margin-bottom:10px;' +
            '}' +
            '#reportbody[dir=\"rtl\"] table th,#reportbody[dir=\"rtl\"] table td {' +
            'text-align:right !important;' +
            //'border: 1px solid #000 !important;' +
            '}' +
            '.report-header {' +
            'padding:  0px !important;' +
            '}' +
            '.signature-line {' +
            'margin-bottom:  0px !important;' +
            '}' +
            '.signature-line {' +
            'border-color: #000 !important;' +
            '}' +
            '.tb {' +
            'font-weight: 600;' +
            '}' +
            '</style>';

        if(this.globals.isRTL)
            $('#divRptContainer').attr('dir', 'RTL').addClass('printpage');
        else
            $('#divRptContainer').attr('dir', 'LTR').addClass('printpage');

        htmlToPrint += divToPrint.outerHTML;
        let newWin = window.open("");
        newWin.document.write(htmlToPrint);
        $('body').removeClass('PrintableBody');
        $('#divRptContainer').removeAttr('dir').removeClass('printpage');
        newWin.print();
        //newWin.close();        
        window.onload = function () {                        
            newWin.print();
            //setTimeout(function () { window.close(); }, 1);
        }

    }

    generateReportPageWise(navigationType?: number, specificPage?: number) {
        
        if (this.recordsPerPage > 0) {
            this.loaderService.display(true);

            let removedRows: number = 3; // 3 rows removed from datatable  because they contains headings,alignements and width of columns

            if (navigationType) {
                switch (navigationType) {
                    case 1:
                        this.currentPage = 1;
                        break;

                    case 2:
                        this.currentPage -= 1;
                        break;

                    case 3:
                        this.currentPage += 1;
                        break;

                    case 4:
                        this.currentPage = Math.ceil(this.totalRecords / this.recordsPerPage);
                        break;

                    case 5:
                        this.currentPage = specificPage;
                        break;

                    default:
                        this.currentPage = 1;
                        break;
                }
            }

            if (this.currentPage < 1) {
                this.currentPage = 1
                this.toastr.error(this.translate.transform('1689'));
                this.loaderService.display(false);
                return
            }
            let rowIndexStart: number = (this.currentPage == 1) ? (1 + removedRows) : (this.recordsPerPage * (this.currentPage - 1) + removedRows);

            if (rowIndexStart > (this.totalRecords + removedRows)) {
                this.currentPage -= 1;
                this.toastr.error(this.translate.transform('1690'))
                this.loaderService.display(false);
                return
            }

            let rowIndexEnd: number = this.recordsPerPage * this.currentPage + removedRows;
            let reportModel = new ReportParamsModel();
            reportModel.RowIndexStart = rowIndexStart;
            reportModel.RowIndexEnd = rowIndexEnd;
            reportModel.Parameter = this.globals.getDataFromLocalStorage(this.commonConst.ReportParams) + "_";
            this.businessService.reportViewerRepository.GetReportDataPageWise(reportModel).subscribe(
                (data: ResponseModel) => {
                    if (data._statusCode == 200 && data._obj) {

                        $('#divReportData').html(data._obj);
                    }
                    else if (data._statusCode == 504) {
                        window.location.reload();
                    }
                    else {
                        this.toastr.error(this.translate.transform(data._message));
                    }
                    this.loaderService.display(false)
                });
        } else
            this.toastr.error(this.translate.transform('1691'))

    }

    recordsPerPageChangeEvent(selectedValue: number) {        
        this.recordsPerPage = Number(selectedValue);
        this.currentPage = 1;
        this.arrayAsCounter = new Array(Math.ceil(this.totalRecords / this.recordsPerPage))
        
        this.generateReportPageWise();
    }

    // @HostListener('window:beforeunload', ['$event'])
    // unloadNotification($event: any) {
    //     this.ClearLocalStorage()
    //     // $event.returnValue = true;

    // }
}
