import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Users } from "../../models/Users/users.model";
import { IUsersRepository } from "./IUsersRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()
export class UsersRepository extends GenericRepository<Users> implements IUsersRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Users/', environment.urlAddress);
    }
    
    GetAllRec(): Observable<any> {
        return this._http
            .get(this.url + 'api/Users/GetAllRec', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));        
    }

    GetAllRecForPOS(): Observable<any> {
        return this._http
            .get(this.url + 'api/Users/GetAllRecForPOS', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
        
    DeleteRec(LoginID: string) {
        return this.Delete('/DeleteRec/' + LoginID).pipe(map(this.extractData), catchError(this.handleError));
    }

    UpdatePassword(user: Users): Observable<ResponseModel> {
        return this.PostData('/UpdatePassword', user).pipe(map(this.extractData), catchError(this.handleError));
    }

    LogoutForcefully(user: Users): Observable<ResponseModel> {
        return this.PostData('/LogoutForcefully', user).pipe(map(this.extractData), catchError(this.handleError));
    }
}