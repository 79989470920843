import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BusinessServices } from 'src/app/services/singleton/business-services';

import { ToastrService } from 'ngx-toastr';
import { PriceSetup } from 'src/app/models/PriceSetup/pricesetup.model';
import { UOMGroup } from 'src/app/models/UOMGroup/uomgroup.model';
import { CommonRepository } from 'src/app/repositories/common-repository/CommonRepository';
declare var $: any;

@Component({
  selector: 'app-multi-tables-names',
  templateUrl: './multi-tables-names.component.html'
})
export class MultiTablesNamesModalComponent implements OnInit {
  public active: boolean = false;
  groupDetails: UOMGroup;
  priceSetup: PriceSetup;
  Languages: any;
  public params: any;
  public serviceName: BusinessServices;
  public isAgGridDelete: boolean = false;
  public body: string;
  public title: string;
  public onClose: Subject<boolean>;
  documentObject: any = $(document);
    IsInserting: boolean;
    CheckFiscalSpanID: boolean = true;
  public constructor(
    private _bsModalRef: BsModalRef, private businessService: BusinessServices, private toastrService: ToastrService
  ) { }

  public ngOnInit(): void {
    this.onClose = new Subject
  }

    public showConfirmationModal(params?: any, serviceName?: BusinessServices) {
      
        this.params = params;        
        this.IsInserting = params.data.IsInserting;
        if (params.hasOwnProperty("CheckFiscalSpanID")) {
            this.CheckFiscalSpanID = params.CheckFiscalSpanID;
        }
        
        this.serviceName = serviceName;
        if (this.IsInserting) {
            this.serviceName.commonService.LoadLocalLanguagesWithTableValue(params.Table, params.CodeColumn, null, params.data.IsInserting, this.CheckFiscalSpanID,params.CodeCol2,params.data[params.CodeCol2]).subscribe((data: any) => {
               if (data._obj != null || data._obj != undefined) {
                    this.Languages = data._obj;
                    this.active = true;
                }
                else {
                    this.active = false;
                }
            });
            //params.data.IsInserting = false;
        }
        else {
            if(this.params.data.TempName){
              var names = this.params.data.TempName.replace("<-|->ar-SA-<|>-", "");
              if(names){
                this.Languages = [{CharacterSet: "Arabic_CI_AS", CodePage: 1256, CultureCode: "ar-SA", FValue: names, FiscalSpanID: this.params.data.FiscalSpanID, IsRTL: true, Name: "العربية", Name_Info: "Arabic"}];
                this.active = true;
              }
            }
            else{
              this.serviceName.commonService.LoadLocalLanguagesWithTableValue(params.Table, params.CodeColumn, params.data[params.CodeColumn], params.data.IsInserting, this.CheckFiscalSpanID,params.CodeCol2,params.data[params.CodeCol2]).subscribe((data: any) => {
                if (data._obj != null || data._obj != undefined) {
                      this.Languages = data._obj;
                      this.active = true;
                  }
                  else {
                      this.active = false;
                      this.Languages = [];
                  }
              });
            }
        }
    }

  public onCancel(): void {
    this.active = false;
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
  public onSave(): void {
    let namesString: string = '';
    let currentCultureName: string = '';
    let inputElements: any = $('#NamesFormContainer input');
    inputElements.each((index, elementValue) => {
      if (this.params.CultureCode == elementValue.getAttribute('id')) {
        currentCultureName = elementValue.value;
      }
      namesString += elementValue.getAttribute('id') + '-<|>-' + elementValue.value;

      if (index < inputElements.length - 1)
        namesString += '<-|->';
    });   
      this.params.data.TempName = '<-|->' + namesString;
      this.params.data.IsInserting = false;

    // if (this.params.onPopupButtonClick instanceof Function) {
    //   // put anything into params u want pass into parents component            
    //   const params = {
    //     rowIndex: this.params.rowIndex,
    //     rowData: this.params.node.data,
    //     newName: currentCultureName,
    //     NameString: namesString
    //     // ...something
    //   }
    //   this.params.onPopupButtonClick(params);
    // }
    this.active = false;
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public onUpdate(): void {
    let namesString: string = '';
    let currentCultureName: string = '';
    let inputElements: any = $('#NamesFormContainer input');
    inputElements.each((index, elementValue) => {
      if (this.params.CultureCode == elementValue.getAttribute('id')) {
        currentCultureName = elementValue.value;
      }
      namesString += elementValue.getAttribute('id') + '-<|>-' + elementValue.value;

      if (index < inputElements.length - 1)
        namesString += '<-|->';
    });
    this.params.data.TempName='<-|->' + namesString;    
    this.active = false;
    this.onClose.next(false);
    this._bsModalRef.hide();    
  }
  public hideConfirmationModal(): void {
    this.active = false;
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
