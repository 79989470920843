import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AccessRightsModel } from "src/app/models/AccessRight/access-right.model";
import { IAccessRightsRepository } from "./IAccessRightsRepository";

@Injectable(
    {
        providedIn:"root"
    }
)
export class AccessRightsRepository extends GenericRepository<AccessRightsModel> implements IAccessRightsRepository {
    
    url: string = environment.urlAddress;
    
    constructor(protected _http: HttpClient) {
        super(_http, 'api/AccessRights', environment.urlAddress);
    }

    SaveRec(item: AccessRightsModel):Observable<any> {
        return this.PostData('/SaveRec',item).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadOperationsForModulePages(moduleID: number, roleID: number,parentID:number): Observable<any> {
        return this.GetById('/LoadOperationsForModulePages/' + roleID + '/' + moduleID  + '/' + parentID).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(): Observable<any> {
        return this.Get('/LoadRec').pipe(map(this.extractData), catchError(this.handleError));
    }

}
