import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ProcurementCodeSetup } from "src/app/models/ProcurementCodeSetup/procurement-code-setup.model";
import { IProcurementCodeSetupRepository } from "./IProcurementCodeSetupRepository";

@Injectable()
export class ProcurementCodeSetupRepository extends GenericRepository<ProcurementCodeSetup> implements IProcurementCodeSetupRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/procurementcodesetup', environment.urlAddress);
  }
  GetRec(): Observable<any> {
    return this._http
      .get(this.url + 'api/procurementcodesetup/Select', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

}
