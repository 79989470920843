import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Vendor } from "../../models/Vendor/vendor.model";
import { IUnitOfMeasurementRepository } from "./IUnitOfMeasurementRepository";
import { UnitsOfMeasurement } from "../../models/UnitsOfMeasurement/units_of_mesurement";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class UnitOfMeasurementRepository extends GenericRepository<UnitsOfMeasurement> implements IUnitOfMeasurementRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/unitsofmeasurement', environment.urlAddress);
  }
  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
      .get(this.url + 'api/UnitsOfMeasurement/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(code: string): Observable<any> {
    return this._http
      .delete(this.url + 'api/UnitsOfMeasurement/DeleteRec' + '/' + code, this.httpOptions)
      .pipe(map(this.extractData), catchError(this.handleError));
  }
  GetUnitOfMeasurementsOnDimension(isDimensional?: boolean): Observable<any> {
    return this._http
      .get(this.url + 'api/UnitsOfMeasurement/SearchUnitOfMeasurements' + '/' + isDimensional, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  GetAllUnitOfMeasurements(uomCode:string = ""): Observable<any> {
    return this._http
      .get(this.url + 'api/UnitsOfMeasurement/GetRec' + '/' + uomCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
}
