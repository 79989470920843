import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { PocketStyle } from "../../models/PocketStyle/pocketstyle.model";
import { IPocketStyleRepository } from "./IPocketStyleRepository";

@Injectable()
export class PocketStyleRepository extends GenericRepository<PocketStyle> implements IPocketStyleRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PocketStyle', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/PocketStyle/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(PocketStyleCode: string) {
        return this._http.delete(this.url + 'api/PocketStyle/DeleteRec/' + PocketStyleCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveStyleNames(item: PocketStyle) {
        return this._http.post(this.url + 'api/PocketStyle/SaveStyleNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
