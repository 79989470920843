import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IEmployeeLeaveRepository } from "./IEmployeeLeaveRepository";
import { EmployeeLeave } from "../../../models/HR/EmployeeLeave/employee-leave.model";

@Injectable()
export class EmployeeLeaveRepository extends GenericRepository<EmployeeLeave> implements IEmployeeLeaveRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/EmployeeLeave', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number,filterText: string,callFromDirectory:boolean=false): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + callFromDirectory+'/'+filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: EmployeeLeave): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectLeaveBalance(employeeCode: string, leaveStartDate: string, leaveEndDate: string, leaveTypeCode: string): Observable<ResponseModel> {
        leaveStartDate = leaveStartDate.split('/').join('~');       
        leaveEndDate = leaveEndDate.split('/').join('~');       
        return this.GetById('/SelectLeaveBalance/' + employeeCode + '/' + leaveStartDate + '/' + leaveEndDate + '/' + leaveTypeCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}