import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CodeNameSearchModel } from 'src/app/models/common/code-name-search.model';
import { PostingSetup, SmartAppUtilities } from 'src/app/models/common/getjsonfrom-controls-service-model';
import { ProcurementPostingSetup } from 'src/app/models/ProcurementPostingSetup/procurement-posting-setup.model';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { ImplementOperationToolbar } from 'src/app/services/btnservice/toptoolbar-implementation';
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { Globals } from 'src/environments/Globals';
import { ApplicationConstants } from '../constent/applicationcodes';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
declare var $: any;

@Component({
  selector: 'app-adjustment-account-slider',
  templateUrl: './adjustment-account-slider.component.html',
  styles: []
})
export class AdjustmentAccountSliderComponent implements OnInit {

  @Output() saveRecord = new EventEmitter<any>();

  constructor(private businessServices: BusinessServices, private toastrService: ToastrService, private translate: TranslatePipe, private global: Globals,
    private loaderService: LoaderService) {
    this.isAnalyticTagCode = this.global.getBoolFromLocalStorage(ApplicationConstants.IsAnalyticTagCodeRequired);
  }
  isAnalyticTagCode: boolean = false;
  isAuthenticated: boolean = true;
  accountOptions: any[] = [];
  topToolbar: ImplementOperationToolbar;
  afterValidationFunc: Function;
  adjustmentAccountTags = new Array<CodeNameSearchModel>();
  procurementAccountTags = new Array<CodeNameSearchModel>();
  procurementPostingSetup: ProcurementPostingSetup = new ProcurementPostingSetup();
  smartUtilities = new SmartAppUtilities(this.toastrService);
  ngOnInit() {
  }

  showSlider(goDownCode: string) {
    let postingSetup: Array<PostingSetup> = [];
    postingSetup.push(new PostingSetup('GrandAmount', 10));

    this.businessServices.stockAdjustmentRepository.SelectPostingSetup(postingSetup, goDownCode).subscribe(data => {
      if (data && data._statusCode === 200) {
        
        let postingaccountOptions = data._obj;
        if (postingaccountOptions.length > 0 && postingaccountOptions.filter(x => x.IsCodeValid == false).length > 0) {
          // if accountOptions Not set then select Posting Setup
          this.businessServices.procurementPostingSetupRepository.GetRec(goDownCode).subscribe(data => {
            this.loaderService.display(false);
            
            if (data && data._obj) {
              console.log(data._obj);
              $(".AdjustmentAccountsForm.slider-box").animate({ width: "toggle" });
              this.procurementPostingSetup = data._obj.ProcurementPostingSetup;
            }
            else {
              $(".AdjustmentAccountsForm.slider-box").animate({ width: "toggle" });
              this.procurementPostingSetup = new ProcurementPostingSetup();
              this.procurementPostingSetup.GodownCode = goDownCode;
            }
          });
        }
        else {
          // if account already set then just save
          // if (this.afterValidationFunc)
          //   this.afterValidationFunc();
          if (this.saveRecord) {
            this.saveRecord.next(null);
          }
        }
      }
      else {
        this.toastrService.error(this.translate.transform(data._message))
      }
    });
  }

  ContinueProcessing() {
    let ok = this.smartUtilities.ValidateForm('#AdjustmentAccountsForm');
    this.loaderService.display(true);
    if (ok) {

      this.businessServices.procurementPostingSetupRepository.Post(this.procurementPostingSetup).subscribe((data: any) => {
        if (data._statusCode === 200) {
          $('.AdjustmentAccountsForm.slider-box').hide({ direction: 'right' }, 'slow');
          // if (this.afterValidationFunc)
          //   this.afterValidationFunc();
          if (this.saveRecord) {
            this.saveRecord.next(null);
          }
        }
        else {
          this.toastrService.error(this.translate.transform(data._message));
          this.loaderService.display(false);
        }
      });
    }
  }

  hideSlider() {
    $('.AdjustmentAccountsForm.slider-box').hide({ direction: 'right' }, 'slow');

  }


  // local events
  onAccountChange(selectedObjValue, controlID: string) {

    var selectedItem = this.accountOptions.find(x => x.AccountCode == selectedObjValue);
    if (!selectedItem) {
      selectedItem = this.accountOptions.find(x => x.AccountName.toLowerCase() == selectedObjValue.toLowerCase());
      if (!selectedItem) {
        $("#" + controlID).val("");
        $("#" + controlID + "_Name").val("");
        this.checkAnalyticTagsAndShowDropDown(null, controlID);
      }
      else {

        $("#" + controlID).val(selectedItem.AccountCode);
        $("#" + controlID + "_Name").val(selectedItem.AccountName);
        this.getAnalyticTagAgainstAccount(selectedItem.AccountCode, controlID)
        if (controlID == "Adjustment_Account") {
          this.procurementPostingSetup.Adjustment_Account = selectedItem.AccountCode;
        }

        if (controlID == "Procurement_Account") {
          this.procurementPostingSetup.Procurement_Account = selectedItem.AccountCode;
        }
      }
    }
    else {
      $("#" + controlID + "_Name").val(selectedItem.AccountName);
      this.getAnalyticTagAgainstAccount(selectedItem.AccountCode, controlID)
    }
  }

  getAnalyticTagAgainstAccount(accountCode: string, controlId: string) {
    this.businessServices.commonService.SelectAnalyticTags(accountCode).subscribe((data: ResponseModel) => {
      if (data._statusCode == 200 && data._obj) {
        this.checkAnalyticTagsAndShowDropDown(data._obj, controlId);
      }
      else {
        this.checkAnalyticTagsAndShowDropDown(null, controlId);
      }
    });
  }

  checkAnalyticTagsAndShowDropDown(dropDownObject: Array<CodeNameSearchModel>, controlId: string) {

    switch (controlId) {
      case 'Adjustment_Account':
        this.adjustmentAccountTags = dropDownObject;
        this.procurementPostingSetup.Adjustment_Account_AnalyticTagCode = '';
        break;
      case 'Procurement_Account':
        this.procurementAccountTags = dropDownObject;
        this.procurementPostingSetup.Procurement_Account_AnalyticTagCode = '';
        break;
      default:
        this.toastrService.error("Control Id not found.")
        console.log(controlId + ' Not Found.')
        break;
    }
  }




}
