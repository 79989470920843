import { UOMGroupDetails } from "./uomgroup-details.model";
import { UOMGroupNames } from "./uomgroup-name.model";

export class UOMGroup {
    RowNum: number;
    Total: number;
    UOMGroupCode: string;    
    IsAutoNumbering: boolean;
    SubSupportActive: boolean;
    GroupName: string;
    Action:string;
    IsInserting: boolean;  
    DetailsTable?: Array<UOMGroupDetails>;
    NamesTable?: Array<UOMGroupNames>;

  }
