import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IResUsersRepository } from "./IResUsersRepository";
import { ResUsers } from "src/app/models/Restaurant/ResUsers.model";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class ResUsersRepository extends GenericRepository<ResUsers> implements IResUsersRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResUsers/', environment.urlAddress);
    }
    
    GetAllRec(): Observable<any> {
        return this._http
            .get(this.url + 'api/ResUsers/GetAllRec', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));        
    }

    GetAllRecForRes(): Observable<any> {
        return this._http
            .get(this.url + 'api/ResUsers/GetAllRecForRes', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
        
    DeleteRec(LoginID: string) {
        return this.Delete('/DeleteRec/' + LoginID).pipe(map(this.extractData), catchError(this.handleError));
    }

    UpdatePassword(user: ResUsers): Observable<ResponseModel> {
        return this.PostData('/UpdatePassword', user).pipe(map(this.extractData), catchError(this.handleError));
    }

    LogoutForcefully(user: ResUsers): Observable<ResponseModel> {
        return this.PostData('/LogoutForcefully', user).pipe(map(this.extractData), catchError(this.handleError));
    }
}