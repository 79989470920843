import { ResOrderDetails } from "./ResOrderDetails.model";

export class ResOrders {
    RowIndex: number;
    Total: number;
    OrderCode: string;  
    TableCode : string;
    CustomerNote : string;
    StatusName : string;
    RestaurantUserName : string;
    OrderTypeName: string;
    AreaCode : string;
    AreaName : string;
    OrderTaker: string;
    UserID : number;
    ResUserID : number;
    TimeRequired : number;
    OrderType : number;
    Status : number;
    OrderTime : any;
    UpdationTime: any;
    Hour: number;
    Minutes: number;
    Seconds: number;
    CompletionTime : any;
    RecordTimeStamp : any;
    OrderRecordTimeStamp : Date;
    ResOrderDetailList: ResOrderDetails[]; 
}