import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/environments/Globals';
import { DateFormatterPipe } from 'src/app/shared/custom-pipes/date-formatter.pipe/date-formatter.pipe';
import { TaxGroupInModel } from '../tax-group/tax-rate-in-model';
import { SalesGroupTaxModel } from './sales-group-tax.model';
import { ValueFormatter } from 'src/app/value-formatters/value-formatter';
import { TotalProductTaxesModel } from '../tax-group/total-product-taxes.model';
import { TranslatePipe } from 'src/app/shared/custom-pipes/translate-pipe/translate.pipe';
import { CommonConstant } from '../../shared/constent/applicationcodes';
import { CodeNameSearchModel } from './code-name-search.model';
import { TranslateServiceLocal } from 'src/app/services/translateservice/translate.service.local';
import { SanitizeValueFormatter } from 'src/app/value-formatters/sanitize-value-formatter';

declare var $: any;

@Injectable()
export class SmartAppUtilities {

    constructor(private toastr: ToastrService) {
        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' }); // for tooltips


    }

    public GetStartEndDatesOfMonth(month: string, year: string): string {

        let datePipe: DateFormatterPipe = new DateFormatterPipe();
        let global = new Globals();

        let startDate = new Date(Number(year), Number(month) - 1, 1);
        let EndDate = new Date(Number(year), Number(month), 0);
        let start: string = datePipe.transform(startDate, global.dateSaveFormat);
        let end: string = datePipe.transform(EndDate, global.dateSaveFormat);
        return start + "," + end;
    }

    getLastDaysFromCurrentDate(days: number = 0, month: number = 0, isReturnTypeString = 1, fiscalDate: Date = null) {
        let todaysDate = new Date();
        if (fiscalDate)
            todaysDate = fiscalDate;
            
        todaysDate.setDate(todaysDate.getDate() - days);
        todaysDate.setMonth(todaysDate.getMonth() - month);
        // console.log(todaysDate)
        if (isReturnTypeString) {
            let datePipe: DateFormatterPipe = new DateFormatterPipe();
            return datePipe.transform(todaysDate);
        }
        return todaysDate;
    }

    GetJSONFromControls(formContainerSelector: string, isInserting?: boolean): any {
        
        var objectData;
        let formContainer: any = $(formContainerSelector);
        objectData = {};
        formContainer.find('input[type=text],input[type=hidden],input[type=password],input[type=checkbox],input[type=radio],textarea,select,ng-multiselect-dropdown').each(function () {
          
            var value: any;
            var para = '';
            var type = $(this).attr('type');
            var ID = $(this).attr('name');
            if (ID === undefined)
                ID = $(this).attr('id');
            if (ID === undefined) // if still undefined, then we skip this input
            {

                // alert($(this).parent().html() + ' has a control without name and ID');
                return true;
            }

            if (ID.indexOf('CountryCode') == 0) {
                ID = ID.split('-')[0];
            }

            var p = ID.split('$');
            para = p[p.length - 1];
            if (!type) {

                if ($(this).is("textarea"))
                    type = 'textarea';

                if ($(this).is("ng-multiselect-dropdown")) {
                    type = 'ng-multiselect-dropdown';

                }
            }
            if (para !== '') {
                switch (type) {
                    case "text":
                        if ($(this).data('scn')) {
                            value = $(this).data('SCNCode');

                            if (value === '' || value === undefined)

                                value = "";
                        }
                        else {
                            value = $.trim($(this).val());

                            if ($(this).hasClass('Numeric')) {
                                if (value === '' || value === undefined) {
                                    value = "0";
                                }
                            }
                        }

                        break;
                    case "password":
                        value = $.trim($(this).val());
                        break;
                    case "hidden":
                        value = $.trim($(this).val());
                        break;
                    case "textarea":
                        value = $.trim($(this).val());
                        break;
                    case "checkbox":
                        value = $(this).is(':checked') ? true : false;
                        break;
                    case "ng-multiselect-dropdown":
                        value = $(this).val();
                        break;
                    case "radio":
                        if (!objectData[para])
                            value = $("input[name='" + $(this).prop('name') + "']:checked").val();
                        else
                            return true;
                        break;
                }
                if (para.indexOf('txtLanguage') > -1) {
                    value = '';
                }
                if (!type) {

                    if ($(this).is('select')) {
                        value = $(this).val();
                    }

                    if ($(this).is('ng-multiselect-dropdown')) {

                        value = $(this).val();
                    }
                }
                if (objectData[para] != null) {
                    if (!objectData[para].push) {
                        objectData[para] = [objectData[para]];
                    }
                    objectData[para].push(value);
                }
                else {
                    objectData[para] = value;
                }
            }
        });
        return objectData;
    }

    SetJSONToControls(containerForm: string, data: any) {
        if (data == '')
            return;

        if (data.RowIndex)
            $('#RowIndex').val(data.RowIndex);

        let $containerForm: any = $(containerForm);

        $.each(data, function (key, value) {

            var $ctrl = $containerForm.find('input[id=' + key + ']');

            if ($ctrl.length == 0)
                $ctrl = $containerForm.find('textarea[id=' + key + ']');

            if ($ctrl.length == 0)
                $ctrl = $containerForm.find('select[id=' + key + ']');

            if ($ctrl.length == 0) // Added By Hamza For Radio Button
                $ctrl = $containerForm.find('input[name=' + key + ']')


            switch ($ctrl.prop("type")) {
                case "text":
                case "password":
                    if ($.isNumeric(value) && value == "0") {
                        var x = +value;
                        value = x.toFixed(2);
                        $ctrl.val(value);
                    }
                    else {
                        $ctrl.val(value);
                    }
                    break;
                case "hidden":
                    $ctrl.val(value);
                    break;

                case "textarea":
                    $ctrl.text(value);
                    $ctrl.val(value);
                    break;

                case "select-one":
                    var $this = $ctrl.children();
                    for (var i = 0; i < $this.length; i++) {
                        var opt = $this[i];
                        if ($(opt).text() == value) {
                            $(opt).prop("selected", true);
                        }
                        else {
                            if ($(opt).val() == value) {
                                $(opt).prop("selected", true);
                            }
                        }
                    };
                    break;


                case "select-multiple":
                    var vall = value.trim();
                    if (value.trim() != "") {
                        var _formulakeys = [];
                        _formulakeys = value.split(',');
                        for (let i: number = 0; i < _formulakeys.length; i++) {
                            let opt: string = "<option value='" + _formulakeys[i] + "'>" + _formulakeys[i] + "</option>";
                            $ctrl.append(opt).attr('selected', 'selected');
                        };
                    }
                    break;

                case "radio":
                    $ctrl.each(function () {
                        if ($(this).prop('value') == value.toString()) {
                            $(this).prop("checked", value);
                            return false;
                        }
                    });
                    break
                case "checkbox":
                    $ctrl.each(function () {

                        $(this).prop("checked", value);
                    });
                    break;
            }
        });
    }

    EnableFormElement(formContainer: string, isEnabled: boolean = false) {
        //Note: .no_enable will be ignored
        //Note: .no_disable will be ignored
        
        $(formContainer + " input").each(function () {
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }

        });

        $(formContainer + " textarea").each(function () {
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }
        });

        $(formContainer + " button").each(function () {
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');

            }
        });
        $(formContainer + " select").each(function () {
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }
        });
        
        $(formContainer + " ckeditor").each(function () {
            
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                
                    $(this).attr('disabled', 'disabled');
            }
        });

        $(formContainer + " ng-multiselect-dropdown").each(function () {
            
            if (isEnabled) {
                $(this).removeAttr('disabled');
            }
            else {
                
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }
        });

    }

    EnableDisableFormElement(formContainer: string, isEnabled: boolean = false, otherSelectionProperty = '') {
        //Note: .no_enable will be ignored
        //Note: .no_disable will be ignored
        
        $(formContainer + " input" + otherSelectionProperty).each(function () {
            if (isEnabled) {
                if (!$(this).hasClass('no_enable'))
                    $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }

        });

        $(formContainer + " textarea" + otherSelectionProperty).each(function () {

            if (!$(this).hasClass('no_enable')) {
                if (isEnabled) {
                    if (!$(this).hasClass('no_enable'))
                        $(this).removeAttr('disabled');
                }
                else {
                    if (!$(this).hasClass('no_disable'))
                        $(this).attr('disabled', 'disabled');
                }
            }
        });

        $(formContainer + " button" + otherSelectionProperty).each(function () {

            if (!$(this).hasClass('no_enable')) {

                if (isEnabled) {
                    if (!$(this).hasClass('no_enable'))
                        $(this).removeAttr('disabled');
                }
                else {
                    if (!$(this).hasClass('no_disable'))
                        $(this).attr('disabled', 'disabled');

                }
            }
        });
        $(formContainer + " select" + otherSelectionProperty).each(function () {
            if (isEnabled) {
                if (!$(this).hasClass('no_enable'))
                    $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }
        });
        
        $(formContainer + " ckeditor" + otherSelectionProperty).each(function () {
            
            if (isEnabled) {
                
                if (!$(this).hasClass('no_enable'))
                    
                    $(this).removeAttr('disabled');
            }
            else {
                if (!$(this).hasClass('no_disable'))
                
                    $(this).attr('disabled', 'disabled');
            }
        });

        $(formContainer + " ng-multiselect-dropdown" + otherSelectionProperty).each(function () {
            
            if (isEnabled) {
                
                if (!$(this).hasClass('no_enable'))
                
                    $(this).removeAttr('disabled');
            }
            else {
                
                if (!$(this).hasClass('no_disable'))
                    $(this).attr('disabled', 'disabled');
            }
        });

    }

    ClearForm(containerElement: string, clearType?: any): void {

        let container: any = $(containerElement);

        let extensions = $.extend({ extension1: '', extension2: 'clearall' }, clearType);

        let coloredClasses = [
            'Textbox_Required',
            'Textbox_EntryError',
            'Textbox_EntryReset',
            'Textbox_EntryGreen',
            'Textbox_EntryOk'
        ];
        let dateFieldsArray: string[] = [];
        if (extensions.extension2 === 'clearall') {
            container.find('input[type=text],input[type=password],input[type=hidden],textarea').not('.no_clear').each(function () {

                if (this.type === 'hidden') { // reset hidden value if "data-default" attribute exists else empty value
                    var defaultValue = this.getAttribute('data-default');
                    if (defaultValue)
                        $(this).val(defaultValue);
                    else
                        $(this).val(extensions.extension1);
                    return true;
                }
                if ($(this).hasClass('Numeric')) {
                    $(this).val("0");
                }
                else {
                    $(this).val(extensions.extension1);
                }

                var currentClasses = $(this).attr('class');

                var filteredClasses = '';

                if (currentClasses != undefined)
                    filteredClasses = currentClasses.replace(new RegExp('(' + coloredClasses.join('|') + ')', 'g'), '');

                $(this).removeClass().addClass(filteredClasses).addClass('Textbox_EntryReset');
            });


            //container.find('input.OkDialog').removeAttr('disabled');

            container.find('input[type=checkbox]:not(.no_clear)').prop('checked', false);

            container.find('select:not(.no_clear)').each(function () {
                $(this).val($(this).find('option:first').val())
            });
            container.find('.asterisk_input').remove('.asterisk_input');
            container.find('.cell_required').removeClass('cell_required');
        }
        else {
            container.find('input[type=text],input[type=hidden],textarea').not('.no_clear').each(function () {

                if (this.type === 'hidden') {
                    var defaultValue = $(this).data("default");
                    if (defaultValue)
                        $(this).val(defaultValue);
                    else
                        $(this).val(extensions.extension1);
                }
                else if ($(this).hasClass('Numeric')) {
                    $(this).val("0");
                }
                else {
                    $(this).val(extensions.extension1);
                }

                if (this.id.toLowerCase().includes('date')) {
                    dateFieldsArray.push(this)
                }

                var currentClasses = $(this).attr('class');

                var filteredClasses = '';

                if (currentClasses != undefined)
                    filteredClasses = currentClasses.replace(new RegExp('(' + coloredClasses.join('|') + ')', 'g'), '');

                $(this).removeClass().addClass(filteredClasses).addClass('Textbox_EntryReset');
            });

            //container.find('input.OkDialog').removeAttr('disabled');

            container.find('input[type=checkbox]:not(.no_clear)').prop('checked', false);

            container.find('select:not(.no_clear)').each(function () {
                $(this).val($(this).find('option:first').val())
            });
            container.find('.asterisk_input').remove('.asterisk_input');
            container.find('.cell_required').removeClass('cell_required');
        }
        
        dateFieldsArray.forEach(element => {
            this.updateModelValue(element)
        });
    }

    updateModelValue(field) {
        var createEvent = function (name) {
            var event = document.createEvent('Event');
            event.initEvent(name, true, true);
            return event;
        }
        field.dispatchEvent(createEvent('input'));
    }
    /**
    * @gridClass this will be used for finding rows in Grid; Default Value = .divRow
    */
    ValidateForm(frmID, gridContainer = undefined, cbgridContainer = undefined, optgridContainer = undefined, gridClass = '.divRow'): boolean {
        var $divBody = "";
        var errorCount = 0;
        let gb = new Globals();
        let ts = new TranslateServiceLocal(gb, this.toastr);
        let rqrdMsg: string = ts.getItem('2610');

        $(frmID).find("input.Required,textarea.Required").each(function (e) {
            if (!($(this).hasClass('no_enable'))) {
                if ($(this).val().trim() === "") {
                    // $divBody += $(this).attr('data-jsname') + ' is required<br/>';
                    $divBody += $(this).attr('data-jsname') + ' ' + rqrdMsg + '<br/>';
                    errorCount++;
                }
            }
        });

        $(frmID).find("select.Required").each(function (e) {
            if (!($(this).hasClass('no_enable'))) {
                if ($(this).val() == null || $(this).val() == "0" || $(this).val() == "") {
                    $divBody += $(this).data('jsname') + ' ' + rqrdMsg + '<br/>';
                    errorCount++;
                }
            }
        });

        $(frmID).find("input.Length").each(function (e) {
            if (!($(this).hasClass('no_enable'))) {
                var minValue = 0;
                var maxValue = 0;
                if ($(this).attr("minlength") !== undefined) {
                    minValue = parseInt($(this).attr("minlength"));
                }
                if ($(this).attr("maxlength") !== undefined) {
                    maxValue = parseInt($(this).attr("maxlength"));
                }

                if ($(this).val().length < minValue || $(this).val().length > maxValue) {
                    if (minValue == maxValue) {
                        $divBody += $(this).data('jsname') + ' length must be ' + maxValue + ' characters<br/>';
                    }
                    else {
                        $divBody += $(this).data('jsname') + ' length will be minimum ' + minValue + ' and maximum ' + maxValue + ' characters<br/>';
                    }
                    errorCount++;
                }
            }
        });

        if (errorCount === 0) {
            //Check for other error generic Entry error for any other reason...
            $(frmID).find("input[type=text]").each(function (e) {

                if ($(this).hasClass('Textbox_EntryError')) {

                    $divBody += $(this).data('jsname') + ' is not correct<br/>';
                    errorCount++;
                }
            });
        }
        if (errorCount > 0) {

            this.toastr.error($divBody, ts.getItem('874'));
        }

        /************************Mandatory grid you have to add atleast one row************************************ADDED BY YAMEEN*/
        if (errorCount === 0 && gridContainer != undefined && gridContainer != null && gridContainer.length > 0) {
            let fillRows = 0;
            $(gridContainer).find(gridClass).not('.skip_validation').each((e, row) => {
                let $row: any = $(row);
                if (!this.IsValidRow($row)) {
                    fillRows++;
                    $row.find("input.Required,textarea.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateRequired($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                    $row.find("select.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateDdL($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                }
            });
            if (fillRows === 0) {
                errorCount++;
                this.toastr.error('Please fill at-least one row.<br/>', "Problems");
            }
        }

        /************************Optional grid if added any row than validate************************************ADDED BY YAMEEN*/
        if (errorCount === 0 && optgridContainer != undefined && optgridContainer != null && optgridContainer.length > 0) {
            let fillRows = 0;
            $(optgridContainer).find(".divRow").not('.skip_validation').each((e, row) => {
                let $row: any = $(row);
                if (!this.IsValidRow($row)) {
                    fillRows++;
                    $row.find("input.Required,textarea.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateRequired($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                    $row.find("select.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateDdL($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                }
            });
            if (fillRows === 0 && $(optgridContainer).find(".divRow").not('.skip_validation').length > 0) {
                errorCount++;
                this.toastr.error('Please fill at-least one row.<br/>', "Problems");
            }
        }

        /************************Checkbox grid if checkbox true than validate************************************ADDED BY YAMEEN*/
        if (errorCount === 0 && cbgridContainer != undefined && cbgridContainer != null && cbgridContainer.length > 0) {
            let fillRows = 0;
            $(cbgridContainer).find('[cb-validation]:checked').parents('.divRow').not('.skip_validation').each((e, row) => {
                let $row: any = $(row);
                if (!this.IsValidRow($row)) {
                    fillRows++;
                    $row.find("input.Required,textarea.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateRequired($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                    $row.find("select.Required").each((e, item) => {
                        let $element: any = $(item);
                        if (!($element.hasClass('no_enable'))) {
                            var Isvalid = this.ValidateDdL($element);
                            if (!(Isvalid))
                                errorCount++;
                        }
                    });
                }
            });
            if (fillRows === 0) {
                errorCount++;
                this.toastr.error('Please fill at-least one row.<br/>', "Problems");
            }
        }


        if (errorCount > 0)
            return false;
        return true;
    }

    ValidateRequired($element): boolean {
        $element.change(() => {
            this.RemoveValidator($element);
            if ($element.val().trim() === "") {
                this.GenerateValidator($element);
            }
        });
        if ($element.val().trim() === "") {
            this.GenerateValidator($element);
            return false;
        }
        return true;
    }
    ValidateDdL($element): boolean {
        $element.change(() => {
            this.RemoveValidator($element);
            if ($element.val() == null || $element.val() == "0" || $element.val() == "") {
                this.GenerateValidator($element);
            }
        });
        if ($element.val() == null || $element.val() == "0" || $element.val() == "") {
            this.GenerateValidator($element);
            return false;
        }
        return true;
    }
    GenerateValidator($element) {
        $element.parent().find('.asterisk_input').remove('.asterisk_input');
        $element.removeClass("cell_required");
        $element.before(`<span class="asterisk_input"></span>`)
        $element.addClass("cell_required");
    }
    RemoveValidator($element) {
        $element.removeClass("cell_required");
        $element.parent().find('.asterisk_input').remove('.asterisk_input');
    }

    IsDivHasAnycell_requiredClass(div: string) {
        var items = $(div).find('.cell_required');
        if(items.length > 0){
            return true;
        }
        else{
            return false;
        }
    }
    IsValidRow($element): boolean {
        var totalBoxes = $element.find('input[type=text],input[type=file]').length;
        totalBoxes = totalBoxes + $element.find('select').length;
        var emtyBoxes = $element.find('input[type=text],input[type=file]').filter(function () {
            return $.trim($(this).val()) == "";
        });
        var emtyDdl = $element.find('select').filter(function () {
            return ($(this).val() == null || $(this).val() == '0' || $(this).val() == '');
        });
        console.log("totalBoxes:" + totalBoxes);
        console.log("emtyBoxes:" + emtyBoxes.length);
        console.log("emtyDdl:" + emtyDdl.length);
        return ((emtyBoxes.length + emtyDdl.length) === totalBoxes);

    };

    MakeRequired(inputClass: any) {
        $('.Required').parents().children('label').addClass("rqd");
    }

    Numberify(inputClass: any, options: any): void {

        let $this: any = $(inputClass);

        if (!$this || $this.length === 0)
            return;

        let settings: any = $.extend({ numberType: 'integer', maxLength: 15, allowNegative: false, min: 0, max: 0 }, options);

        if ($this.attr('maxlength') === undefined)
            $this.attr('maxlength', settings.maxLength);

        $this.addClass("Numeric");

        let ex;

        if (settings.numberType === 'integer') {
            ex = /[^\d].+/;

            if (settings.allowNegative)
                ex = /^-?\d*$/;

        }
        else if (settings.numberType === 'decimal')
            ex = /[^0-9\.]/g;


        $this.each((key, value) => {

            let currentElement: any = $(value);

            if (settings.numberType === 'integer') {
                currentElement.on("keypress keyup blur", (event) => {

                    currentElement.val(currentElement.val().replace(ex, ''));

                    if ((event.which < 48 || event.which > 57)) {
                        event.preventDefault();
                    }

                });
            }
            else if (settings.numberType === 'decimal') {

                currentElement.on("keypress keyup blur", (event) => {

                    currentElement.val(currentElement.val().replace(ex, ''));

                    if ((event.which != 46 || currentElement.val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
                        event.preventDefault();
                    }

                });

            }


            // $(value).bind("keyup", (event) => {            
            //     var ex = /^[0-9]+\.?[0-9]*$/g;
            //     if (settings.allowNegative)
            //         ex = /^[-]?\d*\.?\d+$/g;

            //     if (inputClass == ".QuantityTextBox" || inputClass == ".IntegerTextBox") {
            //         var ex = /^[0-9]*$/;
            //     }          
            //     currentElement.val(currentElement.val().replace(ex,''));

            //       if ((event.which != 46 || currentElement.val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
            //           event.preventDefault();
            //       }                    
            //     if (ex.test(currentElement.val()) == false) {
            //        currentElement.val(currentElement.val().substring(0, currentElement.val().length - 1));
            //     }
            // });


        });
    }

    SpecialTextBox(inputClass: string, options: any): void {
        var $this = $(inputClass);
        var settings = $.extend({ textBoxType: '' }, options);

        settings.textBoxType = settings.textBoxType.toLowerCase();

        $this.each((key, value) => {
            let currentElement: any = $(value);
            if (settings.textBoxType === 'email')
                currentElement.attr('maxlength', '50');

            else if (settings.textBoxType === 'mobile')
                currentElement.attr('maxlength', '20');

            else if (settings.textBoxType === 'landline')
                currentElement.attr('maxlength', '20');

            else if (settings.textBoxType === 'phone')  /*can be mobile or landline*/
                currentElement.attr('maxlength', '20');
            else if (settings.textBoxType === 'fax')  /*can be mobile or landline*/
                currentElement.attr('maxlength', '20');

            currentElement.on("blur", () => {
                let reg: RegExp;

                if (currentElement.val() === '') {
                    this.Colorify(currentElement);
                    return false;
                }

                if (settings.textBoxType === 'email') {
                    reg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

                    if (!reg.test(currentElement.val())) {
                        this.toastr.error($this.data('jsname') + ' is not correct');
                        this.Colorify(currentElement, { errorType: 'entryerror' });
                    } else {
                        this.Colorify(currentElement);
                    }

                } else {

                    if (settings.textBoxType === 'mobile') {
                        reg = /^\d[0-9]{6,19}/;

                        if (!reg.test(currentElement.val())) {
                            this.toastr.error($this.data('jsname') + ' is not correct');
                            this.Colorify(currentElement, { errorType: 'entryerror' });
                        } else {
                            this.Colorify(currentElement);
                        }

                    } else {

                        if (settings.textBoxType === 'landline') {
                            reg = /^\d[0-9]{6,19}/;

                            if (!reg.test(currentElement.val())) {
                                this.toastr.error($this.data('jsname') + ' is not correct');
                                this.Colorify(currentElement, { errorType: 'entryerror' });
                            } else {
                                this.Colorify(currentElement);
                            }

                        }
                    }
                }
            });
        });
    }

    Colorify(elementClass: string, options?: any): void {

        let settings: any = $.extend({ errorType: 'none' }, options);
        let $this: any = $(elementClass);


        var currentClasses = $this.attr('class');
        var filteredClasses = '';

        var coloredClasses = [
            'Textbox_Required',
            'Textbox_EntryError',
            'Textbox_EntryReset',
            'Textbox_EntryGreen',
            'Textbox_EntryOk'
        ];

        if (currentClasses != undefined) {
            filteredClasses = currentClasses.replace(new RegExp('(' + coloredClasses.join('|') + ')', 'g'), '');
        }

        $this.removeClass().addClass(filteredClasses);

        return $this.each(() => {
            if (settings.errorType === 'required')
                $this.addClass("Textbox_Required");
            else
                if (settings.errorType === 'entryerror')
                    $this.addClass("Textbox_EntryError");
                else
                    if (settings.errorType === 'uniqueerror')
                        $this.addClass("Textbox_EntryError");
                    else
                        if (settings.errorType === 'green')
                            $this.addClass('Textbox_EntryGreen');
                        else
                            $this.addClass("Textbox_EntryOk");
        });

    }

    ValidateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    EnableDisableElement(elementIdentifier: string, isEnabled: boolean) {
        //Note: .not_enable will be ignored            
        $(elementIdentifier).each(function () {

            if (isEnabled) {
                if (!$(this).hasClass('no_enable'))
                    $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });

    }

    public ConvertToDecimal(value: any, decimals: number = 2) {

        if (this.isNumeric(value))
            return Number(value).toFixed(decimals);

        return (0).toFixed(decimals);
    }


    public DecimalFormat(value: any, decimals: number = 2): number {

        if (this.isNumeric(value))
            return Number(value.toFixed(decimals));

        return Number((0).toFixed(decimals));
    }

    public isNumeric(num: string): Boolean {
        return !isNaN(parseFloat(num));
    }

    parseStringToDate(value: any, days: number = 0): Date | null {
        if ((typeof value === 'string') && (value.includes('/'))) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date + days);
        } else if ((typeof value === 'string') && value === '') {
            return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
    //BindFocusEventDatePicker(elementClass: string = 'DateTextBox'): void {

    //  const DatePickerElements = document.getElementsByClassName(elementClass);

    //  for (let i: number = 0; i < DatePickerElements.length; i++) {
    //    DatePickerElements[i].addEventListener('focusin', (event) => {
    //      event.currentTarget.click();
    //    }, true);
    //    DatePickerElements[i].addEventListener('focusout', (event) => {
    //      event.currentTarget.click();
    //    }, true);
    //  }
    //}

    getNumericCellEditor() {

        function isCharNumeric(charStr) {
            return !!/\d/.test(charStr);
        }
        function isKeyPressedNumeric(event) {
            var charCode = getCharCodeFromEvent(event);
            var charStr = String.fromCharCode(charCode);
            if (charStr == "." && event.target.value.indexOf(".") < 0 && event.target.value.length > 0) {
                return true;
            }
            else {
                return isCharNumeric(charStr);
            }
        }
        function getCharCodeFromEvent(event) {
            event = event || window.event;
            return typeof event.which === "undefined" ? event.keyCode : event.which;
        }


        function NumericCellEditor() { }
        NumericCellEditor.prototype.init = function (params) {
            this.focusAfterAttached = params.cellStartedEdit;
            this.eInput = document.createElement("input");
            this.eInput.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
            // this.eInput.style.width = "100%";
            // this.eInput.style.height = "100%";
            this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
            var that = this;
            this.eInput.addEventListener("keypress", function (event) {
                if (!isKeyPressedNumeric(event)) {
                    that.eInput.focus();
                    if (event.preventDefault) event.preventDefault();
                }
            });
        };
        NumericCellEditor.prototype.getGui = function () {
            return this.eInput;
        };
        NumericCellEditor.prototype.afterGuiAttached = function () {
            if (this.focusAfterAttached) {
                this.eInput.focus();
            }
        };
        NumericCellEditor.prototype.isCancelBeforeStart = function () {
            return this.cancelBeforeStart;
        };
        NumericCellEditor.prototype.isCancelAfterEnd = function () { };
        NumericCellEditor.prototype.getValue = function () {
            return this.eInput.value;
        };
        NumericCellEditor.prototype.focusIn = function () {
            var eInput = this.getGui();
            eInput.focus();
        };
        NumericCellEditor.prototype.focusOut = function () {
        };
        return NumericCellEditor;
    }

    //ADDED BY YAMEEN 29-12-2019
    public getTaxableAmount(calcID: number, amount: number, discount: number = 0, tax1Amount: number = 0, tax2Amount: number = 0, tax3Amount: number = 0, tax4Amount: number = 0, totalCharges: number = 0, addCharges?: Charges): number {
        let calcAmount: number = 0;
        switch (calcID) {
            case 1:     // Amount after Discount
            case 2:     // Amount before Discount
                calcAmount = amount - (calcID == 1 ? discount : 0);
                break;

            case 3:     // Tax 1 Amount
                calcAmount = tax1Amount;
                break;

            case 4:     // Amount after Discount + Tax 1 Amount
            case 5:     // Amount before Discount + Tax 1 Amount
                calcAmount = (amount - (calcID == 4 ? discount : 0)) + tax1Amount;
                break;

            case 6:     // Tax 2 Amount
            case 7:     // Tax 1 Amount + Tax 2 Amount
                calcAmount = (calcID == 7 ? tax1Amount : 0) + tax2Amount;
                break;

            case 8:     // Amount after Discount + Tax 1 Amount + Tax 2 Amount
            case 9:     // Amount before Discount + Tax 1 Amount + Tax 2 Amount
                calcAmount = (amount - (calcID == 8 ? discount : 0)) + (tax1Amount + tax2Amount);
                break;

            ///// Following will be applicable for Global Taxes
            case 10:     // Additional Charges
                calcAmount = totalCharges;
                break;

            case 11:     // Amount after Discount + Additional Charges
            case 12:     // Amount before Discount + Additional Charges
                calcAmount = (amount - (calcID == 11 ? discount : 0)) + totalCharges;
                break;

            case 13:     // Amount after Discount + Tax 1 Amount + Additional Charges
            case 14:     // Amount before Discount + Tax 1 Amount + Additional Charges
                calcAmount = (amount - (calcID == 13 ? discount : 0)) + (tax1Amount + totalCharges);
                break;

            case 15:     // Amount after Discount + Tax 1 Amount + Tax 2 Amount + Additional Charges
            case 16:     // Amount before Discount + Tax 1 Amount + Tax 2 Amount + Additional Charges
                calcAmount = (amount - (calcID == 15 ? discount : 0)) + (tax1Amount + tax2Amount + totalCharges);
                break;

            case 17:     // Tax 1 Amount + Additional Charges
            case 18:     // Tax 1 Amount + Tax 2 Amount + Additional Charges
                calcAmount = (tax1Amount + totalCharges) + (calcID == 18 ? tax2Amount : 0);
                break;

            case 19: // Additional Charges 1
                calcAmount = addCharges.Charges1;
                break;

            case 20: // Additional Charges 2
                calcAmount = addCharges.Charges2;
                break;

            case 21: // Additional Charges 3
                calcAmount = addCharges.Charges3;
                break;

            case 22: // Additional Charges 4
                calcAmount = addCharges.Charges4;
                break;

            case 23: // Additional Charges 5
                calcAmount = addCharges.Charges5;
                break;

            case 24: // Additional Charges 6
                calcAmount = addCharges.Charges6;
                break;

            case 25: // Additional Charges 7
                calcAmount = addCharges.Charges7;
                break;

            case 26: // Additional Charges 8
                calcAmount = addCharges.Charges8;
                break;

            case 27: // Additional Charges 9
                calcAmount = addCharges.Charges9;
                break;

            case 28: // Additional Charges 1 + Charges 2
                calcAmount = addCharges.Charges1 + addCharges.Charges2;
                break;

            case 29: // Additional Charges 1 + Charges 2 + Charges 3
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3;
                break;

            case 30: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4;
                break;

            case 31: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4 + Charges 5
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4 + addCharges.Charges5;
                break;

            case 32: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4 + Charges 5 + Charges 6
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4 + addCharges.Charges5 + addCharges.Charges6;
                break;

            case 33: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4 + Charges 5 + Charges 6 + Charges 7
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4 + addCharges.Charges5 + addCharges.Charges6 + addCharges.Charges7;
                break;

            case 34: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4 + Charges 5 + Charges 6 + Charges 7 + Charges 8
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4 + addCharges.Charges5 + addCharges.Charges6 + addCharges.Charges7 + addCharges.Charges8;
                break;

            case 35: // Additional Charges 1 + Charges 2 + Charges 3 + Charges 4 + Charges 5 + Charges 6 + Charges 7 + Charges 8 + Charges 9
                calcAmount = addCharges.Charges1 + addCharges.Charges2 + addCharges.Charges3 + addCharges.Charges4 + addCharges.Charges5 + addCharges.Charges6 + addCharges.Charges7 + addCharges.Charges8 + addCharges.Charges9;
                break;

            default:
                break;
        }
        return calcAmount;
    }
    //#region 
    //ADDED BY YAMEEN 05-01-2020
    // calculateTaxes(quantity: number, currencyRate: number, price: number, discountAmount: number, enableTax: boolean, enableTax1: boolean, enableTax2: boolean, enableTax3: boolean, enableTax4: boolean, taxGroupID: string, taxData: TaxRateMaster[], totalCharges: number = 0, addCharges?: Charges): Taxes {
    //     let taxAmountIncluded: number = 0.00;
    //     let amount: number = 0.00;
    //     let amountBeforeDiscount: number = 0.00;
    //     let amountAfterTax: number = 0.00;

    //     let taxes: Taxes = new Taxes();
    //     amountBeforeDiscount = price;
    //     let orgQuantity: number = Number(quantity);
    //     if (quantity == 0)
    //         quantity = 1;
    //     if (amountBeforeDiscount > 0 && quantity > 0)
    //         taxes.Price = amountBeforeDiscount / (quantity * currencyRate);
    //     if (enableTax) {

    //         if (taxGroupID != '') {

    //             let selectedproductTaxes: TaxRateMaster = taxData.find(x => x.TaxGroupID == taxGroupID);//get selected tax information & calculation methods
    //             if (selectedproductTaxes) {
    //                 let calcID: number = 0;
    //                 let amt: number = 0;
    //                 let rate: number = 0;
    //                 let disc: number = 0;
    //                 let discount: number = 0;
    //                 if (discountAmount)
    //                     discount = discountAmount;
    //                 //// Tax1Amount Calculation
    //                 if (enableTax1) {
    //                     amount = amountBeforeDiscount;
    //                     taxes.IsTax1IncludedInPrice = selectedproductTaxes.IsTax1IncludedInPrice;
    //                     if (selectedproductTaxes.IsTax1FixedAmount) {
    //                         taxes.Tax1Amount = selectedproductTaxes.Tax1Amount;
    //                     }
    //                     else {
    //                         taxes.Tax1Percentage = selectedproductTaxes.Tax1Percentage;
    //                         if (selectedproductTaxes.Tax1Percentage == 0)
    //                             taxes.Tax1Amount = 0;
    //                         else {

    //                             calcID = selectedproductTaxes.Tax1CalculationID;
    //                             rate = selectedproductTaxes.Tax1Percentage;
    //                             disc = selectedproductTaxes.IsTax1IncludedInPrice ? 0 : discount;

    //                             if ((calcID > 0) && (rate > 0)) {

    //                                 if ((selectedproductTaxes.IsTax1IncludedInPrice) && (amount > 0))
    //                                     amount = ((amount * 100) / (100 + rate));

    //                                 amt = this.getTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, taxes.Tax3Amount, taxes.Tax4Amount, totalCharges, addCharges);

    //                                 if (amt > 0 && rate > 0)
    //                                     taxes.Tax1Amount = ((amt * rate) / 100);
    //                             }
    //                         }

    //                     }

    //                     if (selectedproductTaxes.IsTax1IncludedInPrice)
    //                         taxAmountIncluded += taxes.Tax1Amount;
    //                 }

    //                 //// Tax2Amount Calculation
    //                 if (enableTax2) {
    //                     amount = amountBeforeDiscount;
    //                     taxes.IsTax2IncludedInPrice = selectedproductTaxes.IsTax2IncludedInPrice;
    //                     if (selectedproductTaxes.IsTax2FixedAmount) {
    //                         taxes.Tax2Amount = selectedproductTaxes.Tax2Amount;
    //                     }
    //                     else {
    //                         taxes.Tax2Percentage = selectedproductTaxes.Tax2Percentage;
    //                         if (selectedproductTaxes.Tax2Percentage == 0)
    //                             taxes.Tax2Amount = 0;
    //                         else {

    //                             calcID = selectedproductTaxes.Tax2CalculationID;
    //                             rate = selectedproductTaxes.Tax2Percentage;
    //                             disc = selectedproductTaxes.IsTax2IncludedInPrice ? 0 : discount;

    //                             if ((calcID > 0) && (rate > 0)) {

    //                                 if ((selectedproductTaxes.IsTax2IncludedInPrice) && (amount > 0))
    //                                     amount = ((amount * 100) / (100 + rate));

    //                                 amt = this.getTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, taxes.Tax3Amount, taxes.Tax4Amount, totalCharges, addCharges);

    //                                 if (amt > 0 && rate > 0)
    //                                     taxes.Tax2Amount = ((amt * rate) / 100);
    //                             }
    //                         }

    //                     }

    //                     if (selectedproductTaxes.IsTax2IncludedInPrice)
    //                         taxAmountIncluded += taxes.Tax2Amount;
    //                 }

    //                 //// Tax3Amount Calculation
    //                 if (enableTax3) {
    //                     amount = amountBeforeDiscount;
    //                     taxes.IsTax3IncludedInPrice = selectedproductTaxes.IsTax3IncludedInPrice;
    //                     if (selectedproductTaxes.IsTax3FixedAmount) {
    //                         taxes.Tax3Amount = selectedproductTaxes.Tax3Amount;
    //                     }
    //                     else {
    //                         taxes.Tax3Percentage = selectedproductTaxes.Tax3Percentage;
    //                         if (selectedproductTaxes.Tax3Percentage == 0)
    //                             taxes.Tax3Amount = 0;
    //                         else {

    //                             calcID = selectedproductTaxes.Tax3CalculationID;
    //                             rate = selectedproductTaxes.Tax3Percentage;
    //                             disc = selectedproductTaxes.IsTax3IncludedInPrice ? 0 : discount;

    //                             if ((calcID > 0) && (rate > 0)) {

    //                                 if ((selectedproductTaxes.IsTax3IncludedInPrice) && (amount > 0))
    //                                     amount = ((amount * 100) / (100 + rate));

    //                                 amt = this.getTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, taxes.Tax3Amount, taxes.Tax4Amount, totalCharges, addCharges);

    //                                 if (amt > 0 && rate > 0)
    //                                     taxes.Tax3Amount = ((amt * rate) / 100);
    //                             }
    //                         }

    //                     }

    //                     if (selectedproductTaxes.IsTax3IncludedInPrice)
    //                         taxAmountIncluded += taxes.Tax3Amount;
    //                 }

    //                 //// Tax4Amount Calculation
    //                 if (enableTax4) {
    //                     amount = amountBeforeDiscount;
    //                     taxes.IsTax4IncludedInPrice = selectedproductTaxes.IsTax4IncludedInPrice;
    //                     if (selectedproductTaxes.IsTax4FixedAmount) {
    //                         taxes.Tax4Amount = selectedproductTaxes.Tax4Amount;
    //                     }
    //                     else {
    //                         taxes.Tax4Percentage = selectedproductTaxes.Tax4Percentage;
    //                         if (selectedproductTaxes.Tax4Percentage == 0)
    //                             taxes.Tax4Amount = 0;
    //                         else {

    //                             calcID = selectedproductTaxes.Tax4CalculationID;
    //                             rate = selectedproductTaxes.Tax4Percentage;
    //                             disc = selectedproductTaxes.IsTax4IncludedInPrice ? 0 : discount;

    //                             if ((calcID > 0) && (rate > 0)) {

    //                                 if ((selectedproductTaxes.IsTax4IncludedInPrice) && (amount > 0))
    //                                     amount = ((amount * 100) / (100 + rate));

    //                                 amt = this.getTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, taxes.Tax3Amount, taxes.Tax4Amount, totalCharges, addCharges);

    //                                 if (amt > 0 && rate > 0)
    //                                     taxes.Tax4Amount = ((amt * rate) / 100);
    //                             }
    //                         }

    //                     }

    //                     if (selectedproductTaxes.IsTax4IncludedInPrice)
    //                         taxAmountIncluded += taxes.Tax4Amount;
    //                 }
    //             }

    //         }

    //     }


    //     if (taxAmountIncluded > 0) {
    //         amountAfterTax = amountBeforeDiscount - taxAmountIncluded;

    //         if (amountAfterTax > 0 && orgQuantity > 0)
    //             taxes.Price = (amountAfterTax / (quantity * currencyRate));
    //         else
    //             taxes.Price = 0; //we will not allow to proceed if price is zero
    //     }

    //     return taxes;

    // }


    //#endregion


    // ADD BY HAMZA 
    // Reason Tax Group Pages Change
    calculateTaxeGroups(quantity: number, currencyRate: number, price: number, discountAmount: number, enableTax: boolean, enableTax1: boolean, enableTax2: boolean, taxGroupID: string, taxData: TaxGroupInModel[], totalCharges: number = 0, addCharges?: Charges, svf?: SanitizeValueFormatter, discountRate:number=0,productType:number=1): TaxeGroupFields {
        let taxAmountIncluded: number = 0.00;
        let amount: number = 0.00;
        let amountBeforeDiscount: number = 0.00;
        let amountAfterTax: number = 0.00;
        let taxes: TaxeGroupFields = new TaxeGroupFields();
        let isAfterDiscountCase:boolean = false;
        let afterDiscountTax = 0.00;

        amountBeforeDiscount = price;

        let orgQuantity: number = Number(quantity);
        if (quantity == 0)
            quantity = 1;
        if (amountBeforeDiscount > 0 && quantity > 0) {
            if (svf)
                taxes.Price = svf.amountFormat(amountBeforeDiscount / (quantity * currencyRate));
            else
                taxes.Price = amountBeforeDiscount / (quantity * currencyRate);
        }
        if (enableTax) {

            if (taxGroupID != '') {

                let selectedproductTaxes: TaxGroupInModel = taxData.find(x => x.TaxGroupCode == taxGroupID);//get selected tax information & calculation methods                
                if (selectedproductTaxes) {
                    let calcID: number = 0;
                    let amt: number = 0;
                    let rate: number = 0;
                    let disc: number = 0;
                    let discount: number = 0;
                    if (discountAmount)
                        discount = discountAmount;
                    //// Tax1Amount Calculation
                    if (enableTax1 && selectedproductTaxes.Tax1Code !== '') {
                        amount = amountBeforeDiscount;
                        taxes.IsTax1IncludedInPrice = selectedproductTaxes.IsTax1InclusiveInPrice;
                        if (selectedproductTaxes.Tax1Faction == 2) {
                            taxes.Tax1Amount = selectedproductTaxes.Tax1Value;
                        }
                        else {
                            taxes.Tax1Percentage = selectedproductTaxes.Tax1Value;
                            if (selectedproductTaxes.Tax1Value == 0)
                                taxes.Tax1Amount = 0;
                            else {

                                calcID = selectedproductTaxes.Tax1CalculationID;
                                rate = selectedproductTaxes.Tax1Value;
                                disc = discount;
                                if ((calcID > 0) && (rate > 0)) {

                                    // if ((selectedproductTaxes.IsTax1InclusiveInPrice) && (amount > 0) && (calcID == 9 || calcID == 1) && disc > 0) {
                                    //     if (svf)
                                    //         amt = svf.amountFormat(this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges));
                                    //     else
                                    //         amt = this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges);
                                    //     // amountBeforeDiscount = amt;
                                    //     if (svf)
                                    //         amount = svf.amountFormat(svf.amountFormat(amt / (100 + rate)) * 100);
                                    //     else
                                    //         amount = (amt * 100) / (100 + rate);

                                    //     amt = amount;
                                    // }
                                    // else {
                                    disc = selectedproductTaxes.IsTax1InclusiveInPrice ? 0 : discount;
                                    if ((selectedproductTaxes.IsTax1InclusiveInPrice) && (amount > 0)) {
                                        amount = (amount * 100) / (100 + rate);

                                        if ((calcID == 9 || calcID == 1)) {

                                            if (Number(discountRate) > 0 || discountAmount>0) {
                                                disc = discountRate > 0 ? (amount * Number(discountRate)) / 100:discountAmount;
                                                amountBeforeDiscount = amount;
                                                taxes.DiscountAmount = disc;
                                                isAfterDiscountCase = true;
                                            }
                                        }
                                    }
                                    if(!selectedproductTaxes.IsTax1InclusiveInPrice && productType==3){
                                        taxes.DiscountAmount=discount;
                                    }
                                        if (svf)
                                            amt = svf.amountFormat(this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges));
                                        else
                                            amt = this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges);
                                    // }

                                    if (amt > 0 && rate > 0) {
                                        if (svf)
                                            taxes.Tax1Amount = svf.amountFormat(((amt / quantity) * rate) / 100) * quantity;
                                        else
                                            taxes.Tax1Amount = ((amt * rate) / 100);

                                        if ((calcID == 9 || calcID == 1) && disc > 0) {
                                            afterDiscountTax += taxes.Tax1Amount
                                        }
                                        
                                        taxes.Tax1CalculationID = calcID;
                                    }
                                }
                            }
                        } 

                        if (selectedproductTaxes.IsTax1InclusiveInPrice && !((calcID == 9 || calcID == 1) && disc > 0))
                            taxAmountIncluded += taxes.Tax1Amount;

                        taxes.Tax1Code = selectedproductTaxes.Tax1Code;
                        taxes.Tax1Name = selectedproductTaxes.Tax1Name;
                    }

                    //// Tax2Amount Calculation
                    if (enableTax2 && selectedproductTaxes.Tax2Code !== '') {
                        amount = amountBeforeDiscount;
                        taxes.IsTax2IncludedInPrice = selectedproductTaxes.IsTax2InclusiveInPrice;
                        if (selectedproductTaxes.Tax2Faction == 2) {
                            taxes.Tax2Amount = selectedproductTaxes.Tax2Value;
                        }
                        else {
                            taxes.Tax2Percentage = selectedproductTaxes.Tax2Value;
                            if (selectedproductTaxes.Tax2Value == 0)
                                taxes.Tax2Amount = 0;
                            else {

                                calcID = selectedproductTaxes.Tax2CalculationID;
                                rate = selectedproductTaxes.Tax2Value;
                                disc = discount;

                                if ((calcID > 0) && (rate > 0)) {

                                    // if ((selectedproductTaxes.IsTax2InclusiveInPrice) && (amount > 0) && (calcID == 9 || calcID == 1) && disc > 0) {
                                    //     if (svf) {
                                    //         amt = svf.amountFormat(this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges))
                                    //         amount = svf.amountFormat(svf.amountFormat(amount / (100 + rate)) * 100);
                                    //     }
                                    //     else {
                                    //         amt = this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges)
                                    //         amount = (amt * 100) / (100 + rate);
                                    //     }
                                    //     // amountBeforeDiscount = amt;
                                    //     amt = amount;
                                    //     // taxes.Price = amt;
                                    // }
                                    // else {

                                    disc = selectedproductTaxes.IsTax2InclusiveInPrice ? 0 : discount;
                                    if ((selectedproductTaxes.IsTax2InclusiveInPrice) && (amount > 0)) {
                                        amount = ((amount * 100) / (100 + rate));
                                        if ((calcID == 9 || calcID == 1)) {
                                            if (Number(discountRate) > 0 || discountAmount>0) {                                                
                                                disc = discountRate > 0 ? (amount * Number(discountRate)) / 100:discountAmount;
                                                amountBeforeDiscount = amount;
                                                taxes.DiscountAmount = disc;
                                                isAfterDiscountCase = true;
                                            }
                                        }
                                    }
                                    if(!selectedproductTaxes.IsTax2InclusiveInPrice && productType==3){
                                        taxes.DiscountAmount=discount;
                                    }
                                        if (svf)
                                            amt = svf.amountFormat(this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges));
                                        else
                                            amt = this.calculateTaxableAmount(calcID, amount, disc, taxes.Tax1Amount, taxes.Tax2Amount, totalCharges, addCharges)
                                    // }

                                    if (amt > 0 && rate > 0) {
                                        if (svf)
                                            taxes.Tax2Amount = svf.amountFormat((amt * rate) / 100);
                                        else
                                            taxes.Tax2Amount = (amt * rate) / 100;

                                        if ((calcID == 9 || calcID == 1) && disc > 0) {
                                            afterDiscountTax += taxes.Tax2Amount
                                        }

                                        taxes.Tax2CalculationID = calcID;
                                    }
                                }
                            }

                        }

                        if (selectedproductTaxes.IsTax2InclusiveInPrice && !((calcID == 9 || calcID == 1) && disc > 0))
                            taxAmountIncluded += taxes.Tax2Amount;

                        taxes.Tax2Code = selectedproductTaxes.Tax2Code;
                        taxes.Tax2Name = selectedproductTaxes.Tax2Name;
                    }
                }

            }

        }


        if (taxAmountIncluded > 0 || isAfterDiscountCase) {
            amountAfterTax = amountBeforeDiscount - taxAmountIncluded;

            if (amountAfterTax > 0 && orgQuantity > 0) {
                if (svf)
                    taxes.Price = svf.amountFormat(amountAfterTax / (quantity * currencyRate));
                else
                    taxes.Price = (amountAfterTax / (quantity * currencyRate));
            }
            else
                taxes.Price = 0; //we will not allow to proceed if price is zero
        }
        taxes.AfterDiscountTax = afterDiscountTax;
        return taxes;

    }

   
    private calculateTaxableAmount(calcID: number, amount: number, discount: number = 0, tax1Amount: number = 0, tax2Amount: number = 0, totalCharges: number = 0, addCharges?: Charges): number {
        let calcAmount: number = 0;

        switch (calcID) {
            case 1:     // subtotal after discount => on all places subtotal is same 
                calcAmount = amount - discount;
                break;
            case 2:     // Tax 1 Amount
                calcAmount = tax1Amount;
                break;
            case 3:     // Amount before Discount + Tax 1 Amount
                calcAmount = (amount - discount) + tax1Amount;
                break;

            // Following will be applicable on Invoice Taxes
            case 4:     // Expenditures           
                calcAmount = totalCharges;
                break;
            case 5:     // SubTotal + Expenditures
                calcAmount = (amount - discount) + totalCharges;
                break;
            case 6:     // SubTotal + Expenditures
                calcAmount = (amount - discount) + totalCharges + tax1Amount;
                break;

            case 7:
                calcAmount = (amount - discount) + totalCharges + tax1Amount;
                break;
            case 8:     // Calculate Before Discount
                calcAmount = amount;
                break;
            case 9:     // Calculate After Discount
                calcAmount = amount - discount;
                break;

            default:
                console.log('calculation id is not found. calculation is implementing on SubTotal')
                calcAmount = (amount - discount);
                break;
        }

        return calcAmount;
    }

    // for Adjusting column width automatically    
    autoSizeAll(gridColumnApi: any, skipHeader = true) {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    public getSum(arrayOfObject: any[], column: string) {

        if (!arrayOfObject && arrayOfObject.length == 0)
            return 0;

        let values = arrayOfObject.map((obj) => Number(obj[column]) || 0)
        return values.reduce((a, b) => a + b, 0);

    }

    generateTaxesHtml(columnData: any, vf: ValueFormatter): string {

        let htmlRow: string = '';
        let global = new Globals();
        let commonconstant = new CommonConstant();
        // let currencySymbol = global.getDataFromLocalStorage(commonconstant.CompanyCurrencySymbol);

        if (columnData.SalesGroupTaxObject && columnData.SalesGroupTaxObject.length > 0) {

            columnData.SalesGroupTaxObject.forEach((element: SalesGroupTaxModel) => {

                htmlRow += '<span class="taxgroup ' + (columnData.Tax1Code ? "" : "hidden") + '">' + (element.Tax1Name ? element.Tax1Name : 'Tax1') + ' ' + (element.Tax1Rate && element.Tax1Rate > 0 ? vf.numericFormat(element.Tax1Rate) + '%' : '') + ' - ' +  (element.Tax1Amount ? vf.taxFormat(element.Tax1Amount) : '0') + '</span>' +
                            '<span class="taxgroup ' + (columnData.Tax2Code ? "" : "hidden") + '">' + (element.Tax2Name ? element.Tax2Name : 'Tax2') + ' ' + (element.Tax2Rate && element.Tax2Rate > 0 ? vf.numericFormat(element.Tax2Rate) + '%' : '') + ' - ' + (element.Tax2Amount ? vf.taxFormat(element.Tax2Amount) : '0') + '</span>'
            });
        }
        else {
            htmlRow = '<span class="taxgroup ' + (columnData.Tax1Code ? "" : "hidden") + '">' + (columnData.Tax1Name ? columnData.Tax1Name : 'Tax1') + ' ' + (columnData.Tax1Rate ? vf.numericFormat(columnData.Tax1Rate) : '0') + '% - ' +  (columnData.Tax1Amount ? vf.taxFormat(columnData.Tax1Amount) : '0') + '</span>' +
                '<span class="taxgroup ' + (columnData.Tax2Code ? "" : "hidden") + '">' + (columnData.Tax2Name ? columnData.Tax2Name : 'Tax2') + ' ' + (columnData.Tax2Rate ? vf.numericFormat(columnData.Tax2Rate) : '0') + '% - ' +  (columnData.Tax2Amount ? vf.taxFormat(columnData.Tax2Amount) : '0') + '</span>'
        }

        return htmlRow;
    }

    generateGlobalTaxesHtml(columnData: any, vf: ValueFormatter): string {

        let global = new Globals();
        let commonconstant = new CommonConstant();
        // let currencySymbol = global.getDataFromLocalStorage(commonconstant.CompanyCurrencySymbol) ? global.getDataFromLocalStorage(commonconstant.CompanyCurrencySymbol):'';

        return '<span class="taxgroup ' + (columnData.GlobalTax1Code ? "" : "hidden") + '">' + (columnData.GlobalTax1Name ? columnData.GlobalTax1Name : 'Tax1') + ' - ' +  (columnData.GlobalTax1Amount ? vf.taxFormat(columnData.GlobalTax1Amount) : '0') + '</span>' +
            '<span class="taxgroup ' + (columnData.GlobalTax2Code ? "" : "hidden") + '">' + (columnData.GlobalTax2Name ? columnData.GlobalTax2Name : 'Tax2') + ' - ' +  (columnData.GlobalTax2Amount ? vf.taxFormat(columnData.GlobalTax2Amount) : '0') + '</span>'
    }

    generateChequesStatus(params: any, translate: TranslatePipe): string {
        let cls = 'badge-secondary';
        if (params.data.Status == 1)
            cls = 'badge-primary';
        else if (params.data.Status == 2)
            cls = 'badge-success';
        else if (params.data.Status == 3)
            cls = 'badge-danger';


        return '<span class="badge ' + cls + '">' + (params.value ? translate.transform(params.value) : '') + '</span>'
    }
    generateProductTaxesGrid(gridApi: any): Array<TotalProductTaxesModel> {

        if (!gridApi) return null;

        let productTaxesTotal = new Array<TotalProductTaxesModel>();

        gridApi.forEachNode((node) => {

            let element = node.data;

            if (element.Tax1Code) {

                let tax1 = productTaxesTotal.find(x => x.TaxCode == element.Tax1Code);
                if (tax1) {
                    tax1.TaxAmount += element.Tax1Amount;
                    let index = productTaxesTotal.indexOf(tax1)
                    productTaxesTotal[index] = tax1;
                }
                else {

                    let newTax1: TotalProductTaxesModel = {
                        TaxCode: element.Tax1Code,
                        TaxName: element.Tax1Name,
                        TaxAmount: element.Tax1Amount
                    }
                    productTaxesTotal.push(newTax1);
                }

            }

            if (element.Tax2Code) {

                let tax2 = productTaxesTotal.find(x => x.TaxCode == element.Tax2Code);

                if (tax2) {

                    tax2.TaxAmount += element.Tax2Amount;
                    let index = productTaxesTotal.indexOf(tax2)
                    productTaxesTotal[index] = tax2;
                }
                else {

                    let newTax2: TotalProductTaxesModel = {
                        TaxCode: element.Tax2Code,
                        TaxName: element.Tax2Name,
                        TaxAmount: element.Tax2Amount
                    }
                    productTaxesTotal.push(newTax2);
                }
            }
        });
        return productTaxesTotal;
    }

    generateProductTaxesFromArray(jsonArray: any): Array<TotalProductTaxesModel> {

        if (!jsonArray) return null;

        let productTaxesTotal = new Array<TotalProductTaxesModel>();

        jsonArray.forEach((element) => {

            if (element.Tax1Code) {

                let tax1 = productTaxesTotal.find(x => x.TaxCode == element.Tax1Code);
                if (tax1) {
                    tax1.TaxAmount += element.Tax1Amount;
                    let index = productTaxesTotal.indexOf(tax1)
                    productTaxesTotal[index] = tax1;
                }
                else {

                    let newTax1: TotalProductTaxesModel = {
                        TaxCode: element.Tax1Code,
                        TaxName: element.Tax1Name,
                        TaxAmount: element.Tax1Amount
                    }
                    productTaxesTotal.push(newTax1);
                }

            }

            if (element.Tax2Code) {

                let tax2 = productTaxesTotal.find(x => x.TaxCode == element.Tax2Code);

                if (tax2) {

                    tax2.TaxAmount += element.Tax2Amount;
                    let index = productTaxesTotal.indexOf(tax2)
                    productTaxesTotal[index] = tax2;
                }
                else {

                    let newTax2: TotalProductTaxesModel = {
                        TaxCode: element.Tax2Code,
                        TaxName: element.Tax2Name,
                        TaxAmount: element.Tax2Amount
                    }
                    productTaxesTotal.push(newTax2);
                }
            }
        });
        return productTaxesTotal;
    }

    generateProductTaxesFromProductArray(productArray: any): Array<TotalProductTaxesModel> {

        if (!productArray) return null;

        let productTaxesTotal = new Array<TotalProductTaxesModel>();

        productArray.forEach((data) => {

            let element = data;

            if (element.Tax1Code) {

                let tax1 = productTaxesTotal.find(x => x.TaxCode == element.Tax1Code);
                if (tax1) {
                    tax1.TaxAmount += element.Tax1Amount;
                    let index = productTaxesTotal.indexOf(tax1)
                    productTaxesTotal[index] = tax1;
                }
                else {

                    let newTax1: TotalProductTaxesModel = {
                        TaxCode: element.Tax1Code,
                        TaxName: element.Tax1Name,
                        TaxAmount: element.Tax1Amount
                    }
                    productTaxesTotal.push(newTax1);
                }

            }

            if (element.Tax2Code) {

                let tax2 = productTaxesTotal.find(x => x.TaxCode == element.Tax2Code);

                if (tax2) {

                    tax2.TaxAmount += element.Tax2Amount;
                    let index = productTaxesTotal.indexOf(tax2)
                    productTaxesTotal[index] = tax2;
                }
                else {

                    let newTax2: TotalProductTaxesModel = {
                        TaxCode: element.Tax2Code,
                        TaxName: element.Tax2Name,
                        TaxAmount: element.Tax2Amount
                    }
                    productTaxesTotal.push(newTax2);
                }
            }
        });
        return productTaxesTotal;
    }

    generateValueWithCurrencySymbol(value: any, currency:any, vf: ValueFormatter): string {
         if(value){
            let formattedValue = vf.numericFormat(value);
            value = currency + ' ' + formattedValue;
         } 
        return value;
    }

} // Smart Utilities Class End Point

//ADDED BY Hamza 18-05-2020 
// these fields will return after calculation
export class TaxeGroupFields {

    Price: number;
    Tax1Code: string;
    Tax1Name: string;
    Tax2Code: string;
    Tax2Name: string;

    Tax1Percentage: number;
    IsTax1FixedAmount: boolean;
    Tax1Amount: number;
    IsTax1IncludedInPrice: boolean;


    Tax2Percentage: number;
    IsTax2FixedAmount: boolean;
    Tax2Amount: number;
    IsTax2IncludedInPrice: boolean;
    DiscountAmount:number;
    AfterDiscountTax:number;
    Tax1CalculationID:number;
    Tax2CalculationID:number;
    // 
    constructor() {
        this.Price = 0.00;
        this.DiscountAmount = 0.00;
        this.AfterDiscountTax = 0.00;
        this.Tax1Amount = 0.00;
        this.Tax2Amount = 0.00;
        this.Tax1Name = '';
        this.Tax2Name = '';
        this.Tax1Percentage = 0.00;
        this.Tax2Percentage = 0.00;
        this.IsTax1IncludedInPrice = false;
        this.IsTax2IncludedInPrice = false;
        this.Tax1CalculationID = 0;
        this.Tax2CalculationID = 0;
    }
}

export class Taxes {//ADDED BY YAMEEN 05-01-2020

    Price: number;

    Tax1Percentage: number;
    IsTax1FixedAmount: boolean;
    Tax1Amount: number;
    IsTax1IncludedInPrice: boolean;

    Tax2Percentage: number;
    IsTax2FixedAmount: boolean;
    Tax2Amount: number;
    IsTax2IncludedInPrice: boolean;

    Tax3Percentage: number;
    IsTax3FixedAmount: boolean;
    Tax3Amount: number;
    IsTax3IncludedInPrice: boolean;

    Tax4Percentage: number;
    IsTax4FixedAmount: boolean;
    Tax4Amount: number;
    IsTax4IncludedInPrice: boolean;

    constructor() {
        this.Price = 0.00;
        this.Tax1Amount = 0.00;
        this.Tax2Amount = 0.00;
        this.Tax3Amount = 0.00;
        this.Tax4Amount = 0.00;
        this.Tax1Percentage = 0.00;
        this.Tax2Percentage = 0.00;
        this.Tax3Percentage = 0.00;
        this.Tax4Percentage = 0.00;
        this.IsTax1IncludedInPrice = false;
        this.IsTax2IncludedInPrice = false;
        this.IsTax3IncludedInPrice = false;
        this.IsTax4IncludedInPrice = false;
    }

}

export class Charges {//ADDED BY YAMEEN 29-12-2019
    Charges1: number;
    Charges2: number;
    Charges3: number;
    Charges4: number;
    Charges5: number;
    Charges6: number;
    Charges7: number;
    Charges8: number;
    Charges9: number;

    constructor(charges1: number, charges2: number, charges3: number, charges4: number, charges5: number, charges6: number, charges7: number, charges8: number, charges9: number) {
        this.Charges1 = charges1;
        this.Charges2 = charges2;
        this.Charges3 = charges3;
        this.Charges4 = charges4;
        this.Charges5 = charges5;
        this.Charges6 = charges6;
        this.Charges7 = charges7;
        this.Charges8 = charges8;
        this.Charges9 = charges9;
    }

}

export class PostingSetup {//ADDED BY YAMEEN 19-02-2020
    SetupName: string;
    SetupValue: number;
    constructor(stupName: string, setupValue: number) {
        this.SetupName = stupName;
        this.SetupValue = setupValue;
    }
}

export class TransactionPosting {//ADDED BY YAMEEN 19-02-2020
    PostingAccount: string;
    AccountCode: string;
    AccountName: string;
    IsCodeValid: boolean;
    DebitAmount: number;
    CreditAmount: number;
    CostCenterCode: string;
    CostCenterSupport: boolean;
    AnalyticTagCode: string;
    AnalyticTagSupport: boolean;
    AnalyticTagsList: Array<CodeNameSearchModel>;
    PostingDescription: string;
    TransactionDescription: string;
    RecordType: number;
    AccountType : number ; // 1 for Sale Bill Account , 2 for Purchase Bill Account = > For Use in Branch to Branch sale

    constructor(postingAccount: string, accountCode: string, isCodeValid: boolean, debitAmount: number, creditAmount: number, costCenterCode: string, isCostCenterSupport: boolean, postingDescription: string, transactionDescription: string, recordType: number, analyticTagCode: string, analyticTagSupport: boolean ,AccountType:number=1) {
        this.PostingAccount = postingAccount;
        this.AccountCode = accountCode;
        this.IsCodeValid = isCodeValid;
        this.DebitAmount = debitAmount;
        this.CreditAmount = creditAmount;
        this.CostCenterCode = costCenterCode;
        // this.CostCenterName = costCenterName;
        this.AnalyticTagCode = analyticTagCode;
        this.AnalyticTagSupport = analyticTagSupport;
        this.CostCenterSupport = isCostCenterSupport;
        this.PostingDescription = postingDescription;
        this.TransactionDescription = transactionDescription;
        this.RecordType = recordType;
        this.AccountType = AccountType;
    }
}

export class AccountCodeName {//ADDED BY YAMEEN 19-02-2020
    constructor() {
        this.PostingDescription = '';
        this.AccountCode = '';
        this.CostCenterCode = '';
        this.AccountName = '';
        this.CostCenterName = '';
    }
    PostingDescription: string;
    AccountCode: string;
    CostCenterCode: string;
    AccountName: string;
    CostCenterName: string;

}

export class StockSerialBatch{
    constructor(){
        this.StockBatchInwardID = '';
        this.StockBatchOutwardID = '';
        this.SerialNumber = '';
        this.BatchNumber = '';
    }
    BatchNumber: string;
    StockBatchInwardID: string;
    StockBatchOutwardID: string;
    SerialNumber: string;
}

export class StockBatchSerials {//ADDED BY YAMEEN 02-04-2020

    constructor() {  
        this.StockBatchInwardID = '', 
        this.StockBatchOutwardID = '', 
        this.BatchNumber = '', 
        this.SerialNumber = '',
        this.ProductCode = '', 
        this.InwardDocument = '', 
        this.InwardRecordID = '', 
        this.ManufactureDate = '',
        this.ExpiryDate = '', 
        this.Length = 0, 
        this.Width = 0, 
        this.Height = 0, 
        this.BaseQuantity = 0, 
        this.BalanceQuantity = 0,
        this.InwardWeight = 0, 
        this.InwardQuantity = 0, 
        this.OutwardWeight = 0, 
        this.OutwardQuantity = 0, 
        this.UnitWeight = 0,
        this.CostPrice = 0, 
        this.DetailsID = '', 
        this.GroupDataID = '', 
        this.IsSelected = false, 
        this.IsNewAdded = false,
        this.OutwardLength = 0, 
        this.OutwardWidth = 0, 
        this.OutwardHeight = 0, 
        this.TotalRemainingBalance = 0, 
        this.TrackID = '',
        this.SellingPrice = 0,
        this.IsInserting = false,
        this.InwardPageID = 0,
        this.InwardDate = '',
        this.GoDownCode = ''
        // this.StockSerialBatchList = [];
    }

    public StockBatchInwardID: string = ''; 
    public StockBatchOutwardID: string = ''; 
    public BatchNumber: string = ''; 
    public SerialNumber: string = '';
    public ProductCode: string = ''; 
    public InwardDocument: string = ''; 
    public InwardRecordID: string = ''; 
    public ManufactureDate: string = '';
    public ExpiryDate: string = ''; 
    public Length: number = 0; 
    public Width: number = 0; 
    public Height: number = 0; 
    public BaseQuantity: number = 0; 
    public BalanceQuantity: number = 0;
    public InwardWeight: number = 0; 
    public InwardQuantity: number = 0; 
    public OutwardWeight: number = 0; 
    public OutwardQuantity: number = 0; 
    public UnitWeight: number = 0;
    public CostPrice: number = 0; 
    public DetailsID: string = ''; 
    public GroupDataID: string = ''; 
    public IsSelected: boolean = false; 
    public IsNewAdded: boolean = false;
    public OutwardLength: number = 0; 
    public OutwardWidth: number = 0; 
    public OutwardHeight: number = 0; 
    public TotalRemainingBalance: number = 0; 
    public TrackID: string = '';
    public SellingPrice: Number = 0;
    public IsInserting: boolean = false;
    public InwardPageID: number = 0;
    public InwardDate: string = '';
    public GoDownCode: string = '';
    // public StockSerialBatchList:StockSerialBatch[];

}
