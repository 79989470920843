import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPrintReportRepository } from "./IPrintReportRepository";
import { EmailSendModel } from "src/app/models/common/email-send.model";
@Injectable()
export class ProcurementPrintReportRepository extends GenericRepository<any> implements IPrintReportRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/procurementprintreport', environment.urlAddress);
  }   
    GetPrintRec(pageID: string, record_number: string, is_Preview: boolean, reportFormat: number): Observable<any> {
        return this.GetById('/GetPrintRec/' + pageID + '/' + record_number+ '/' + is_Preview+ '/' + reportFormat).pipe(map(this.extractData), catchError(this.handleError)); 
    }

    SendReportInMail(model:EmailSendModel): Observable<any> {
        return this.PostData('/SendEmail/', model);
    }
}