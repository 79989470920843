import { Component, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StockBatchSerials } from '../../models/common/getjsonfrom-controls-service-model';
declare var $: any;
@Component({
    selector: 'app-stock-batch-serial-outwards',
    templateUrl: './stock-batch-serial-outwards.component.html',
})
export class StockBatchSerialOutwardsComponent implements OnInit { 

    params: any;
    smartUtilities: any;
    toastrService: any;
    onProcess: any;
    IsSerialShow: boolean = false;
    ProductCode: string = '';
    IsBatchRequired: boolean = false;
    IsSerialRequired: boolean = false;
    IsMeasureRequired: boolean = false;
    IsMfgRequired: boolean = false;
    IsExpRequired: boolean = false;
    MeasurementType: number = 0;
    Quantity: number = 0;
    documentNumber: string = '';
    batchRequiredOnly: boolean = false;
    serialRequiredOnly: boolean = false;
    batchSerialQuantity: number = 0;
    Weight: number = 0;
    model: StockBatchSerials[] = [];
    batchModel: StockBatchSerials = new StockBatchSerials();
    serialItems: StockBatchSerials[] = [];
    batchItems: StockBatchSerials[] = [];
    dimensionItems: StockBatchSerials[] = [];
    isEnabled: boolean = false;
    isAutoSerials: boolean = true;
    lblBatchNumber: string = '';

    constructor(public bsModalRef: BsModalRef, private el: ElementRef) {

    }


    ngOnInit() {
        
        if (this.IsBatchRequired && this.IsSerialRequired)
            this.IsSerialShow = false;
        else
            this.IsSerialShow = this.IsSerialRequired;

        if ((this.IsBatchRequired || this.IsMeasureRequired) && !this.IsSerialRequired)
            this.batchRequiredOnly = true;
        else
            this.batchRequiredOnly = false;

        if (this.IsSerialRequired && (!this.IsBatchRequired && !this.IsMeasureRequired)) {
            this.serialRequiredOnly = true;


            if (this.model.length > 0) {
                this.serialItems = this.model;
            }

            this.isAutoSerials = true;
            // this.onAutoSerialsClick();
        }
        else
            this.serialRequiredOnly = false;
            
        if ((this.IsBatchRequired)) {
            
            if (this.model.length > 0) {
                this.loadBatches();
                if (this.IsSerialRequired) {
                    setTimeout(() => {

                        const element: HTMLElement = document.getElementsByClassName('btnSerialToBatch')[0] as HTMLElement;
                        if (element)
                            element.click();
                    }, 100);

                }
            }
        }
        if ((this.IsMeasureRequired)) {

            if (this.model.length > 0) {
                this.loadMeasurements();
                setTimeout(() => {

                    const element: HTMLElement = document.getElementsByClassName('btnBatchToDimension')[0] as HTMLElement;
                    if (element)
                        element.click();
                }, 100);
            }
        }
        setTimeout(() => {
            this.smartUtilities.EnableDisableFormElement("#BatchDiv", this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
            this.smartUtilities.EnableDisableElement('#btn-process', this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
        }, 100);

    }

    onCancelClick() {
        this.bsModalRef.hide();
    }
    onProcessClick() {
        
        if (!this.validateBatchSerialsCount())
            return;

        if (this.onProcess instanceof Function) {

            const params = {
                params: this.params,
                value: this.model,
            }
            this.onProcess(params);
        }
        this.bsModalRef.hide();
    }

    scrollBatchDiv() {
        const objDiv = this.el.nativeElement.querySelector('#batch-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }

    loadBatches() {

        this.batchItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;

        if (!this.IsMeasureRequired) {
            let Qty: number = this.batchSerialQuantity;
            const existingQty: number = this.getExistingBatchQty();

            if (Qty != existingQty)//means update qty
                if (batches.length > 0)
                    for (let i = 0; i < batches.length; i++) {
                        
                        let BalanceQuantity = Number(batches[i].BalanceQuantity);
                        let OutwardQuantity = Number(batches[i].OutwardQuantity);

                        if (BalanceQuantity <= Qty)
                            OutwardQuantity = BalanceQuantity;
                        else
                            OutwardQuantity = Qty;

                        batches[i].OutwardQuantity = OutwardQuantity;
                        batches[i].BaseQuantity = BalanceQuantity - OutwardQuantity;
                        batches[i].OutwardWeight = batches[i].OutwardQuantity * this.Weight;

                        Qty -= Number(OutwardQuantity);
                    }
        }


    }

    loadBatchSerials(stockBatchInwardID) {

        this.serialItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        setTimeout(() => {
            let BatcheSerials = this.model.filter(x => x.StockBatchInwardID === stockBatchInwardID);
            if (BatcheSerials && BatcheSerials.length > 0)
                this.serialItems = BatcheSerials;


        });

    }

    onSerialToBatchClick(data: StockBatchSerials) {
        if (this.model == undefined || this.model.length == 0)
            return;
        let qty = Number(data.OutwardQuantity);
        if (isNaN(qty))
            qty = 0;
        if (qty == 0) {
            this.toastrService.error('Please enter quantity');
            return false;
        }
        this.IsSerialShow = true;
        this.isAutoSerials = true;
        this.lblBatchNumber = data.BatchNumber;
        this.serialItems = new Array<StockBatchSerials>();
        let BatcheSerials = this.model.filter(x => x.StockBatchInwardID === data.StockBatchInwardID);
        if (BatcheSerials && BatcheSerials.length > 0)
            this.serialItems = BatcheSerials;
        this.onAutoSerialsClick();
    }


    onAddSerialClick(data: StockBatchSerials) {
        
        let ok: boolean = data.IsSelected;
        if (ok) {
            let qty = 0;
            if (this.serialRequiredOnly)
                qty = Number(this.batchSerialQuantity);
            else
                qty = Number(data.OutwardQuantity);
            if (isNaN(qty))
                qty = 0;
            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                let item = this.model.find(x => x.StockBatchInwardID === data.StockBatchInwardID && x.SerialNumber === data.SerialNumber);
                if (item)
                    item.IsSelected == false;
                data.IsSelected = false;
                this.loadBatchSerials(data.StockBatchInwardID);
                return false;
            }
            let alreadyAddedSerials = 0;
            if (this.model != undefined && this.model.length > 0)
                alreadyAddedSerials = this.model.filter(x => x.StockBatchInwardID === data.StockBatchInwardID && x.IsSelected == true).length - 1;
            if (alreadyAddedSerials >= qty) {
                this.toastrService.error('Limit exceeds');
                let item = this.model.find(x => x.StockBatchInwardID === data.StockBatchInwardID && x.SerialNumber === data.SerialNumber);
                if (item)
                    item.IsSelected == false;
                data.IsSelected = false;
                this.loadBatchSerials(data.StockBatchInwardID);
                return false;
            }
            let item = this.model.find(x => x.StockBatchInwardID === data.StockBatchInwardID && x.SerialNumber === data.SerialNumber);
            if (item)
                item.IsSelected == true;
            this.loadBatchSerials(data.StockBatchInwardID);
        }
        else {
            let item = this.model.find(x => x.StockBatchInwardID === data.StockBatchInwardID && x.SerialNumber === data.SerialNumber);
            if (item)
                item.IsSelected == false;
            this.loadBatchSerials(data.StockBatchInwardID);
        }

    }

    generateUUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    validateBatchSerialsCount() {
        
        if (this.model == undefined) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.model.length == 0) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }

        if (this.batchRequiredOnly) {

            const sum: number = this.model.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.serialRequiredOnly) {
            let item = this.model.filter(x => x.IsSelected == true);
            if (item) {
                for (let i = 0; i < item.length; i++) {
                    item[i].OutwardQuantity = this.batchSerialQuantity;
                    item[i].OutwardWeight = item[i].OutwardQuantity * this.Weight;
                }

            }
            if (this.batchSerialQuantity !== this.model.filter(x => x.IsSelected == true).length) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.IsBatchRequired && this.IsSerialRequired) {
            
            for (let batches of this.model) {
                
                const stockBatchInward: string = batches.StockBatchInwardID
                const batchNumber: string = batches.BatchNumber;
                let OutwardQuantity: number = Number(batches.OutwardQuantity);
                if (isNaN(OutwardQuantity))
                    OutwardQuantity = 0;

                const result = this.model.filter(x => x.StockBatchInwardID === stockBatchInward && x.IsSelected == true);

                if (this.batchSerialQuantity < OutwardQuantity) {
                    this.toastrService.error('Insufficient Batch/Serials' + ' : ' + batchNumber);
                    return false;

                }
            }

            
            const sum: number = this.getDistinctArray().reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }

        return true;
    }
    getDistinctArray(): StockBatchSerials[] {

        let batches: StockBatchSerials[] = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        return batches;
    }


    onDimensionChange() {

        if (this.MeasurementType == 1) {

            if (Number(this.batchModel.OutwardLength) > Number(this.batchModel.Length))
                this.batchModel.OutwardLength = this.batchModel.Length;
            if (Number(this.batchModel.OutwardWidth) > Number(this.batchModel.Width))
                this.batchModel.OutwardWidth = this.batchModel.Width;
            this.batchModel.OutwardQuantity = (Number(this.batchModel.OutwardLength) * Number(this.batchModel.OutwardWidth));


        }
        else {
            if (Number(this.batchModel.OutwardLength) > Number(this.batchModel.Length))
                this.batchModel.OutwardLength = this.batchModel.Length;
            if (Number(this.batchModel.OutwardWidth) > Number(this.batchModel.Width))
                this.batchModel.OutwardWidth = this.batchModel.Width;
            if (Number(this.batchModel.OutwardHeight) > Number(this.batchModel.Height))
                this.batchModel.OutwardHeight = this.batchModel.Height;

            this.batchModel.OutwardQuantity = (Number(this.batchModel.OutwardLength) * Number(this.batchModel.OutwardWidth) * Number(this.batchModel.OutwardHeight));
        }

    }

    onQuantityChange(data: StockBatchSerials) {

        let qty: number = Number(data.OutwardQuantity)
        if (qty > 0) {
            if (data.OutwardQuantity > data.BalanceQuantity)
                data.OutwardQuantity = data.BalanceQuantity;
            if (data.OutwardQuantity > this.batchSerialQuantity)
                data.OutwardQuantity = this.batchSerialQuantity;
            if (data.OutwardQuantity > data.BalanceQuantity)
                data.OutwardQuantity = data.BalanceQuantity;

            data.OutwardWeight = this.Weight * data.OutwardQuantity;

            data.BaseQuantity = data.BalanceQuantity - data.OutwardQuantity;
        }
        if (this.IsSerialRequired) {
            let qty: number = Number(data.OutwardQuantity)
            let item = this.model.filter(x => x.StockBatchInwardID === data.StockBatchInwardID && x.IsSelected == true);
            if (item) {
                if (item.length > qty)
                    for (let i = qty; i < item.length; i++) {
                        item[i].IsSelected = false;
                        item[i].OutwardQuantity = qty;
                        item[i].OutwardWeight = item[i].OutwardQuantity * this.Weight;
                    }
            }
            let BatcheSerials = this.model.filter(x => x.StockBatchInwardID === data.StockBatchInwardID);
            if (BatcheSerials && BatcheSerials.length > 0)
                for (let i = 0; i < BatcheSerials.length; i++) {
                    BatcheSerials[i].OutwardQuantity = qty;
                    BatcheSerials[i].OutwardWeight = BatcheSerials[i].OutwardQuantity * this.Weight;
                    if (this.isAutoSerials)
                        if (i < qty)
                            BatcheSerials[i].IsSelected = true;
                }

        }
    }

    onAutoSerialsClick() {
        
        let BatcheSerials = this.serialItems;
        if (BatcheSerials && BatcheSerials.length > 0) {

            let qty: number = 0;

            if (this.serialRequiredOnly)
                qty = Number(this.batchSerialQuantity);
            else
                qty = Number(BatcheSerials[0].OutwardQuantity);


            for (let i = 0; i < BatcheSerials.length; i++)
                if (i < qty && this.isAutoSerials) {
                    BatcheSerials[i].IsSelected = true;
                    BatcheSerials[i].OutwardQuantity = qty;
                    BatcheSerials[i].OutwardWeight = BatcheSerials[i].OutwardQuantity * this.Weight;
                }

                else {
                    BatcheSerials[i].IsSelected = false;
                    BatcheSerials[i].OutwardQuantity = qty;
                    BatcheSerials[i].OutwardWeight = BatcheSerials[i].OutwardQuantity * this.Weight;
                }


            setTimeout(() => {
                this.serialItems = [];
                this.serialItems = BatcheSerials;
            });
        }
    }

    loadMeasurements() {

        this.batchItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.StockBatchOutwardID.indexOf('NewBarCode') == -1 && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;
      
    }

    onAddToDimensionClick(data: StockBatchSerials) {
        
        if (this.model == undefined || this.model.length == 0)
            return;
        let qty = Number(data.BalanceQuantity);
        if (isNaN(qty))
            qty = 0;
        if (qty == 0) {
            this.toastrService.error('Please enter quantity');
            return false;
        }
        this.IsSerialShow = true;
        this.isAutoSerials = false;
        this.lblBatchNumber = data.BatchNumber;

        this.IsSerialShow = true;

        this.batchModel = Object.assign({}, data);

        this.loadBatchDimension(this.batchModel.StockBatchInwardID);
    }

    loadBatchDimension(stockBatchInwardID) {

        this.dimensionItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        setTimeout(() => {
            let dimensionItems = this.model.filter(x => x.StockBatchInwardID === stockBatchInwardID && x.StockBatchOutwardID.indexOf('NewBarCode') != -1);
            if (dimensionItems && dimensionItems.length > 0)
                this.dimensionItems = dimensionItems;

        });

    }

    onNewBatchClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewBatchForm');
        if (ok) {

            const qty: number = Number(this.batchModel.OutwardQuantity);
            const baseQty: number = Number(this.batchModel.BalanceQuantity);

            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                return false;
            }

            //here check existing qty
            const existingQty: number = (qty + this.getExistingBatchQty());
            if (existingQty > baseQty || existingQty > this.batchSerialQuantity) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }
            this.batchModel.StockBatchOutwardID = (this.generateUUID() + '-NewBarCode');
            //this.batchModel.BaseQuantity = baseQty;
            this.batchModel.OutwardQuantity = qty;
            this.batchModel.OutwardWeight = this.Weight * qty;
            this.batchModel.ProductCode = this.ProductCode;
            const batches = Object.assign({}, this.batchModel);

            if (!this.IsSerialRequired)
                this.model.push(batches);

            this.dimensionItems.push(batches);

            this.batchModel.OutwardHeight = 0;
            this.batchModel.OutwardLength = 0;
            this.batchModel.OutwardQuantity = 0;
            this.batchModel.OutwardWeight = 0;
            this.batchModel.OutwardWidth = 0;

            this.scrollBatchDiv();
        }
    }

    getExistingBatchQty(): number {
        let sum: number = 0;
        if (this.IsMeasureRequired)
            sum = this.dimensionItems.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
        else
            sum = this.batchItems.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
        return sum;
    }
    onRemoveBatchClick(stockBatchOutwardID: string) {

        this.model = this.model.filter(x => x.StockBatchOutwardID != stockBatchOutwardID);
        this.dimensionItems = this.dimensionItems.filter(x => x.StockBatchOutwardID != stockBatchOutwardID);
    }
}
