import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { ILoyaltyRepository } from "./ILoyaltyRepository";
import { Loyalty } from "src/app/models/LoyaltyManagement/loyalty.model";

@Injectable({ providedIn: 'root' })
export class LoyaltyRepository extends GenericRepository<any> implements ILoyaltyRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Loyalty', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    GetLoyalty(currentDate: string): Observable<ResponseModel> {
        return this.Get('GetLoyalty/' + currentDate);
    }

    GetLoyaltyRewardRec(code: string): Observable<ResponseModel> {
        code = code.split('/').join('~');
        if (code == "") {
            code = "|";
        }
        return this.Get('GetLoyaltyRewardRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string, currentDate: string): Observable<ResponseModel> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        if (currentDate == "") {
            currentDate = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText + '/' + currentDate);
    }

    SaveRec(item: Loyalty): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }

}