import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { ResOrders } from "src/app/models/Restaurant/ResOrders.model";
import { IOrdersRepository } from "./IOrdersRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class OrdersRepository extends GenericRepository<ResOrders> implements IOrdersRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Orders', environment.urlAddress);
    }

    GetAll(skip: number, next: number, filterText: string): Observable<any> {

        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/Orders/SelectAll' + '/' + skip + '/' + next + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    } 

    GetAllForKitchen(goDownCode: string, lastOrderTime: any, lastPendingOrderTime: any, timeFilter: number, latestDate: any, ascDesc: number): Observable<any> {
        if (goDownCode == "") {
            goDownCode = "|";
        }
        return this._http
            .get(this.url + 'api/Orders/SelectAllForKitchen' + '/' + goDownCode + '/' + lastOrderTime + '/' + lastPendingOrderTime + '/' + timeFilter + '/' + latestDate + '/' + ascDesc, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllInProgressOrders(goDownCode: string, lastOrderTime: any): Observable<any> {
        if (goDownCode == "") {
            goDownCode = "|";
        }
        return this._http
            .get(this.url + 'api/Orders/SelectAllOrderForCustomer' + '/' + goDownCode + '/' + lastOrderTime, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }


    LoadRec(ordersCode: string, fiscalSpanID: number): Observable<any> {
        return this._http
            .get(this.url + 'api/Orders/GetRec' + '/' + ordersCode+ '/' + fiscalSpanID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    } 

    UpdateStatus(orderCode: string, completionTime: string, updationTime: string, status: number, timeRequired: number): Observable<any> {
        if (completionTime != "") {
            
            completionTime = completionTime.replace("/", "|");
            completionTime = completionTime.replace("/", "|");
        }
        else if(completionTime == null || completionTime == ""){
            
            completionTime = "|";
        }

        if(updationTime == null || updationTime == ""){
            updationTime = "|";
        }
        return this._http
        .get(this.url + 'api/Orders/UpdateStatus' + '/' + orderCode + '/' + completionTime+ '/' + updationTime+ '/' + status+ '/' + timeRequired, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
