import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { environment } from "src/environments/environment";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { ICategoryIngredientRepository } from "./ICategoryIngredientRepository";


@Injectable(
    {
        providedIn:'root'
    }
)

export class CategoryIngredientRepository extends GenericRepository<any> implements ICategoryIngredientRepository {

    url: string = environment.urlAddress;

    constructor(protected _http: HttpClient) {
        super(_http, 'api/CategoryIngredient', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText)
    }

    SaveRec(item: any): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item);
    }

    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code);
    }

}