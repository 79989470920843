import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { IStockRepository } from "./IStockRepository";
import { OpeningStock } from "src/app/models/Stock/opening-stock.model";
@Injectable(
    {
        providedIn: 'root'
    }
)

export class StockRepository extends GenericRepository<OpeningStock> implements IStockRepository {

  SelectProductsWithGodownCode(godownCode: string, productCode: string="") {
    return this._http.get('api/Stock/SelectProductsWithGodownCode' + '/' + godownCode + '/' + productCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {

    super(_http, 'api/Stock', environment.urlAddress);
  }
 
}
