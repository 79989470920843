﻿import { SmartAppUtilities } from "src/app/models/common/getjsonfrom-controls-service-model";

declare var $: any;
// function to act as a class
export function getDecimalCellEditor() {
    function NumericCellEditor() { }
    NumericCellEditor.prototype.init = function(params) {
        this.focusAfterAttached = params.cellStartedEdit;
        this.eInput = document.createElement("input");
        this.eInput.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
        // this.eInput.style.width = "100%";
        // this.eInput.style.height = "100%";
        this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
        var that = this;
        this.eInput.addEventListener("keypress", function(event) {
            if (!isKeyPressedDecimal(event)) {
                that.eInput.focus();
                if (event.preventDefault)
                    event.preventDefault();
            }
        });
    };
    NumericCellEditor.prototype.getGui = function() {
        return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function() {
        if (this.focusAfterAttached) {
            this.eInput.focus();
        }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function() {
        return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function() { };
    NumericCellEditor.prototype.getValue = function() {
        return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function() {
        var eInput = this.getGui();
        eInput.focus();
    };
    NumericCellEditor.prototype.focusOut = function() {
    };
    return NumericCellEditor;
}

function isKeyPressedDecimal(event) {
    var charCode = getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    if (charStr == "." && event.target.value.indexOf(".") < 0 && event.target.value.length > 0) {
        return true;
    }
    else {
        return isCharNumeric(charStr);
    }
}

export function getPriceCellEditor() {
    function PriceCellEditor() { }
    PriceCellEditor.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
        //this.eInput.setAttribute("priceInput", "");
        // this.eInput.setAttribute("priceInput");
        this.eInput.value = params.value;

        this.eInput.addEventListener("keydown", function (event) {
            let e = <KeyboardEvent>event;
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+X
                (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();

            }

        });

        this.eInput.addEventListener("change", function () {
            params.data.PriceOriginal = this.value;
            console.log(params.data.PriceOriginal)
        });
    };
    PriceCellEditor.prototype.getGui = function () {
        return this.eInput;
    };
    PriceCellEditor.prototype.afterGuiAttached = function () {
        if (this.focusAfterAttached) {
            this.eInput.focus();
        }
    };
    PriceCellEditor.prototype.isCancelBeforeStart = function () {
        return this.cancelBeforeStart;
    };
    PriceCellEditor.prototype.isCancelAfterEnd = function () { };
    PriceCellEditor.prototype.getValue = function () {
        return this.eInput.value;
    };
    PriceCellEditor.prototype.focusIn = function () {
        var eInput = this.getGui();
        eInput.focus();
    };
    PriceCellEditor.prototype.focusOut = function () {
    };

    return PriceCellEditor;
}

export function checkForMeasurement(params) {    
    
    var isMeasurementExist = false;
    var isBatchData = false;    
    params.api.forEachNode((node) => {
        if (node.data.IsMeasurementRequired) {
            isMeasurementExist = true;
        }
        if (node.data.IsBatchRequired || node.data.IsSerialNumberRequired) {
            isBatchData = true;
        }     
    });
    if (isMeasurementExist) {
        params.columnApi.setColumnsVisible(["SubQuantity", "Height", "Width", "Length"], true);
    }
    else {
        params.columnApi.setColumnsVisible(["SubQuantity", "Height", "Width", "Length"], false);
    }
    if (isMeasurementExist || isBatchData) {
        params.columnApi.setColumnsVisible(["BatchSerial"], true);
    }
    else {
        params.columnApi.setColumnsVisible(["BatchSerial"], false);
    }
}
export function showHideTaxFields(params: any, enableProductTax: boolean, isReturn: boolean = false, enableGlobalTax = false) {
    let smartUtilities = new SmartAppUtilities(null);;
    let IsTaxCodeExists = false;
    let isGlobalTaxExist = false
    params.api.forEachNode((node) => {

        if (node.data.Tax1Code || node.data.Tax2Code) {
            IsTaxCodeExists = true;
        }

        if (isReturn && node.data.GlobalTax1Code || node.data.GlobalTax2Code) {
            isGlobalTaxExist = true;
        }

    });

    IsTaxCodeExists = !enableProductTax ? false : IsTaxCodeExists;

    setTimeout(() => {
        params.columnApi.setColumnsVisible(["Taxes"], IsTaxCodeExists);
    }, 100);


    if (isReturn) {
        isGlobalTaxExist = !enableGlobalTax ? false : isGlobalTaxExist;
        setTimeout(() => {
            params.columnApi.setColumnsVisible(["GlobalTaxes"], isGlobalTaxExist);
        }, 100);

    }

    // setTimeout(() => {
    //     smartUtilities.autoSizeAll(params.columnApi);
    // }, 200);

}
export function showHideTaxFieldsFA(params: any, enableProductTax: boolean, isReturn: boolean = false, enableGlobalTax = false) {
    let smartUtilities = new SmartAppUtilities(null);;
    let IsTaxCodeExists = false;
    //let isGlobalTaxExist = false
    //params.api.forEachNode((node) => {

    //    if (node.data.Tax1Code || node.data.Tax2Code) {
    //        IsTaxCodeExists = true;
    //    }

    //    //if (isReturn && node.data.GlobalTax1Code || node.data.GlobalTax2Code) {
    //    //    isGlobalTaxExist = true;
    //    //}

    //});

    IsTaxCodeExists = !enableProductTax ? false : enableProductTax;

    setTimeout(() => {
        params.columnApi.setColumnsVisible(["TaxGroupID"], IsTaxCodeExists);
        params.columnApi.setColumnsVisible(["Taxes"], IsTaxCodeExists);
    }, 100);

    //setTimeout(() => {
    //    smartUtilities.autoSizeAll(params.columnApi);
    //}, 200);

}
export function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement('input');
      this.eInput.value = params.value;
      this.eInput.classList.add('ag-input');
      this.eInput.style.height = '100%';
      $(this.eInput).datepicker({ dateFormat: 'dd/mm/yy' });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }

export function getNumericCellEditor() {
    function NumericCellEditor() { }
    NumericCellEditor.prototype.init = function (params) {
        //this.focusAfterAttached = params.cellStartedEdit;
        this.eInput = document.createElement("input");
        this.eInput.className = "ag-input-text-wrapper ag-cell-edit-input text-right";
        // this.eInput.style.width = "100%";
        // this.eInput.style.height = "100%";
        this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
        var that = this;
        this.eInput.addEventListener("keypress", function (event) {
            if (!isKeyPressedNumeric(event)) {
                that.eInput.focus();
                if (event.preventDefault) event.preventDefault();
            }
        });
    };
    NumericCellEditor.prototype.getGui = function () {
        return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
        if (this.focusAfterAttached) {
            this.eInput.focus();
        }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
        return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () { };
    NumericCellEditor.prototype.getValue = function () {
        return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
        var eInput = this.getGui();
        eInput.focus();
    };
    NumericCellEditor.prototype.focusOut = function () {
    };
    return NumericCellEditor;
}
function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}


function isKeyPressedNumeric(event) {
    var charCode = getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}
function getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which === "undefined" ? event.keyCode : event.which;
}

export function getBooleanEditor() {
    // function to act as a class
    function BooleanEditor() { }

    // gets called once before the renderer is used
    BooleanEditor.prototype.init = function (params) {
        // create the cell
        this.eInput = document.createElement('div');
        this.eInput.setAttribute("class", "form-check d-inline");

        if (params.value !== "" || params.value !== undefined || params.value !== null) {
            var checkedStatus = params.value ? "checked" : "";
            var input = document.createElement('input');
            input.type = "checkbox";
            input.checked = params.value;
            this.eInput.setAttribute("cb-value", params.value);

            input.addEventListener('click', (event) => {
                params.value = !params.value;
                this.eInput.setAttribute("cb-value", params.value);
            });

            var inputlabel = document.createElement('label');
            inputlabel.setAttribute("class", "chk");
            inputlabel.appendChild(input);

            var inputSpan = document.createElement('span');
            inputSpan.setAttribute("class", "checkmark");
            inputlabel.appendChild(inputSpan);


            this.eInput.innerHTML = '';
            this.eInput.appendChild(inputlabel);
        }

        /*
        var value = params.value;
        
        this.eInput = document.createElement('input');
        this.eInput.type = 'checkbox';
        this.eInput.checked = value;
        this.eInput.value = value;    */
    };

    // gets called once when grid ready to insert the element
    BooleanEditor.prototype.getGui = function () {

        return this.eInput;
    };

    // focus and select can be done after the gui is attached
    BooleanEditor.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        //this.eInput.select();
    };

    // returns the new value after editing
    BooleanEditor.prototype.getValue = function () {
        return getToBoolean(this.eInput.getAttribute("cb-value"));
        //return this.eInput.checked;
    };

    // any cleanup we need to be done here
    BooleanEditor.prototype.destroy = function () {
        // but this example is simple, no cleanup, we could
        // even leave this method out as it's optional
    };

    // if true, then this editor will appear in a popup
    BooleanEditor.prototype.isPopup = function () {
        // and we could leave this method out also, false is the default
        return false;
    };

    return BooleanEditor;
}

export function booleanCellRenderer(params) {
    var value = getToBoolean(params.value) ? 'checked' : 'unchecked';
    var disable = GetDisabledValue(params.isDisabled);
    //return '<input disabled type="checkbox" ' + value + '/>';
    return '<div class="form-check d-inline"><label class="chk"><input '+disable+' type="checkbox" ' + value + '/><span class="checkmark"></span></label></div>';
}

function getToBoolean(value) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}
function GetDisabledValue(value) {

    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return 'disabled';
        case 0:
        case "0":
        case false:
        case 'false':
            return '';
        default:
            return 'disabled';
    }
}

export function isSixColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var isSixColumn = displayedColumns[6] === params.column;
    return isSixColumn;
}

export function pickExistingRowNodeAtIndex(gridApi, index) {
    var allItems = [];
    gridApi.forEachLeafNode(function (rowNode) {
        allItems.push(rowNode);
    });
    if (allItems.length === 0) {
        return;
    }
    var result = allItems[index];
    return result;
}
