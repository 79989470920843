
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { SalesAgent } from "../../models/SalesAgent/sales-agent-model";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IPurchasePostingSetupRepository } from "./IPurchasePostingSetup";
import { PurchasePostingSetup } from "src/app/models/PurchasePostingSetup/purchase-posting-setup-model";

@Injectable()
export class PurchasePostingSetupRepository extends GenericRepository<PurchasePostingSetup> implements IPurchasePostingSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/purchasepostingsetup/', environment.urlAddress);
    }
    GetRec(godownCode: string, paymentType: number): Observable<any> {
        return this.GetById('GetRec/' + godownCode + '/' + paymentType).pipe(map(this.extractData), catchError(this.handleError));
    }
}
