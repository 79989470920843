import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CompanyProfile } from "../../models/CompanyProfile/company-profile.model";
import { ICompanyProfileRepository } from "./ICompanyProfileRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class CompanyProfileRepository extends GenericRepository<CompanyProfile> implements ICompanyProfileRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/CompanyProfile', environment.urlAddress);
    }

    GetRec(): Observable<any> {
        return this._http
            .get(this.url + 'api/CompanyProfile/Select', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectForZid(): Observable<ResponseModel> {
        return this.Get('SelectForZid').pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectWithFiscalSpan(): Observable<ResponseModel> {
        return this.Get('SelectWithFiscalSpan').pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCompanyProfile(companyProfile: CompanyProfile): Observable<ResponseModel> {
        return this.PostData('/SaveCompanyProfile', companyProfile).pipe(map(this.extractData), catchError(this.handleError));
    }
    CreateRenewelRequest(): Observable<any> {
        return this.Get('CreateRenewelRequest')
    }
}
