import { Injectable, Injector } from "@angular/core";
import { BuyerRepository } from "../../repositories/buyer-repository/BuyerRepository";
import { LoginRepository } from "../../repositories/login-repository/LoginRepository";
import { VendorRepository } from "../../repositories/vendor-repository/VendorRepository";
import { CollectionAgentRepository } from "../../repositories/collection-agent-repository/CollectionAgent";
import { SalesAgentRepository } from "../../repositories/sales-agent-repository/salesAgent";
import { UnitOfMeasurementRepository } from "../../repositories/unit-of-measurement-repository/UnitOfMeasurementRepository";
import { PriceSetupRepository } from "../../repositories/price-setup-repository/PriceSetupRepository";
import { CommonRepository } from "src/app/repositories/common-repository/CommonRepository";
import { UOMGroupRepository } from "src/app/repositories/unit-of-measurement-group-repository/UOMGroupRepository";
import { SalesRegionRepository } from "src/app/repositories/sales-region-repository/SalesRegionRepository";
import { BuyerGroupSetupRepository } from "src/app/repositories/buyer-group-setup-repository/BuyerGroupSetupRepository";
import { ProductGroupDiscountSetupRepository } from "src/app/repositories/product-group-discount-setup-repository/ProductGroupDiscountSetupRepository";
import { TaxSetupRepository } from "../../repositories/tax-setup-repository/TaxSetupRepository";
import { TaxRateMasterRepository } from "../../repositories/tax-rate-master-repository/TaxRateMasterRepository";
import { PurchasePostingSetupRepository } from "src/app/repositories/purchase-posting-setup-repository/PurchasePostingSetup";
import { ProcurementCodeSetupRepository } from "src/app/repositories/procurement-code-setup-repository/ProcurementCodeSetupRepository";
import { ProcurementPostingSetupRepository } from "src/app/repositories/procurement-posting-setup-repository/ProcurementPostingSetupRepository";
import { StartingNumberSetupRepository } from "src/app/repositories/starting-number-setup-repository/starting-number-setup-repository";
import { GodownCodeSetupRepository } from "src/app/repositories/godown-code-setup-repository/GodownCodeSetupRepository";
import { SalesPostingSetupRepository } from "src/app/repositories/sales-posting-setup-repository/SalesPostingSetup";
import { GlobalSetupsRepository } from "src/app/repositories/global-setups-repository/GlobalSetupsRepository";
import { SalesCreditControlsRepository } from "src/app/repositories/sales-credit-controls-repository/SalesCreditControlsRepository";
import { GodownRepository } from "src/app/repositories/godown-repository/GodownRepository";
import { ProductCardMasterRepositoryService } from "src/app/repositories/product-card-master-repository/product-card-master-repository.service";
import { ProductCardMasterCategoryRepositoryService } from "src/app/repositories/product-card-master-repository/Product-Card-Master-Category";
import { StockRepository } from "src/app/repositories/stock-repository/StockRepository";
import { PurchaseOrderRepository } from "src/app/repositories/purchase-order-repository/PurchaseOrderRepository";
import { PurchaseQuotationRepository } from "src/app/repositories/purchase-quotation-repository/PurchaseQuotationRepository";
import { PurchaseInvoiceRepository } from "src/app/repositories/purchase-Invoice-repository/PurchaseInvoiceRepository";
import { SaleQuotationRepository } from "../../repositories/sale-quotation-repository/SaleQuotationRepository";
import { SaleOrderRepository } from "src/app/repositories/sale-order-repository/SaleOrderRepository";
import { SaleInvoiceRepository } from "src/app/repositories/sale-Invoice-repository/SaleInvoiceRepository";
import { PurchaseReturnRepository } from "src/app/repositories/purchase-return-repository/PurchaseReturnRepository";
import { CompanyProfileRepository } from "../../repositories/company-profile-repository/CompanyProfileRepository";
import { SaleReturnRepository } from "src/app/repositories/sale-Return-repository/SaleReturnRepository";
import { SaleCollectionsRepository } from "src/app/repositories/sale-collections-repository/SaleCollectionsRepository";
import { PurchasePaymentRepository } from "src/app/repositories/purchase-payment-repository/PurchasePaymentRepository";
import { StockInternalUseRepository } from "../../repositories/stock-internal-use/StockInternalUseRepository";
import { StockAdjustmentRepository } from "../../repositories/stock-adjustment-repository/StockAdjustmentRepository";
import { StockTransferRepository } from "src/app/repositories/stock-transfer/StockTransferRepository";
import { StockDamageRepository } from "../../repositories/stock-damage/StockDamageRepository";
import { CountersRepository } from "../../repositories/counters-repository/CountersRepository";
import { UsersRepository } from "../../repositories/users-repository/UsersRepository";
import { ProcurementReportRepository } from "../../repositories/report-setup-repository/ProcurementReportRepository";
import { PrinterGroupRepository } from "../../repositories/printer-group-repository/PrinterGroupRepository";
import { ProcurementPrintReportRepository } from "../../repositories/print-report-repository/ProcurementPrintReportRepository";
import { ReceiptSetupRepository } from "../../repositories/receipt-setup/ReceiptSetupRepository";
import { ProductGroupsRepository } from "../../repositories/product-groups-repository/ProductGroupsRepository";
import { WeighScaleSetupRepository } from "../../repositories/weigh-scale-setup-repository/WeighScaleSetupRepository";
import { ReportViewerRepository } from "src/app/repositories/report-viewer-repository/ReportViewerRepository";
import { SalesInvoiceHistoryRepository } from "../../repositories/sales-invoice-history-repository/SalesInvoiceHistoryRepository";
import { POSDashboardRepository } from "../../repositories/pos-dashboard-repository/POSDashboardRepository";
import { SalesGroupRepository } from "../../repositories/sales-group-repository/SalesGroupRepository";
import { RolesRepository } from "src/app/repositories/roles-repository/RolesRepository";
import { ErpUserRepository } from "src/app/repositories/erp-user-repository/ErpUserRepository";
import { FiscalSpansRepository } from "../../repositories/fiscal-spans/FiscalSpansRepository";
import { AccessRightsRepository } from "src/app/repositories/AccessRightsRepository/AccessRightsRepository";
import { ChestStyleRepository } from "../../repositories/chest-style-repository/ChestStyleRepository";
import { CollarStyleRepository } from "../../repositories/collar-style-repository/CollarStyleRepository";
import { HandStyleRepository } from "../../repositories/hand-style-repository/HandStyleRepository";
import { PocketStyleRepository } from "../../repositories/pocket-style-repository/PocketStyleRepository";
import { ShirtStyleRepository } from "../../repositories/shirt-style-repository/ShirtStyleRepository";
import { TailorTypeRepository } from "../../repositories/tailor-type-repository/TailorTypeRepository";
import { BarcodeLableSetupRepository } from "src/app/repositories/barcode-label-setup-repository/BarcodeLableSetupRepository";
import { TaxesRepository } from "src/app/repositories/taxes-repository/TaxesRepository";
import { TaxGroupRepository } from "src/app/repositories/tax-group-repository/TaxGroupRepository";
import { CardsRepository } from "../../repositories/banking/cards-repository/CardsRepository";
import { BanksRepository } from "../../repositories/banking/banks-repository/BanksRepository";
import { CardRateSetupRepository } from "../../repositories/banking/card-rate-setup-repository/CardRateSetupRepository";
import { PaymentTermRepository } from "../../repositories/payment-term-repository/PaymentTermRepository";
import { CurrencyRepository } from "../../repositories/currency-repository/CurrencyRepository";
import { DashboardRepository } from "src/app/repositories/dashboard-repositories/DashboardRepository";
import { NamingConventionsRepository } from "../../repositories/naming-conventions-repository/NamingConventionsRepository";
import { POSStringsRepository } from "../../repositories/pos-strings-repository/POSStringsRepository";
import { CommissionSetupRepository } from "../../repositories/commission-setup-repository/CommissionSetupRepository";
import { ZIDRepository } from "../../repositories/zid-repository/ZIDRepository";
import { ChannelPricesRepository } from "../../repositories/channel-prices-repository/ChannelPricesRepository";
import { BuyerSMSRepository } from "../../repositories/procurement/buyer-sms-repository/BuyerSMSRepository";
import { AddOnGroupRepository } from "../../repositories/add-on-group-repository/AddOnGroupRepository";
import { LoyaltyCardRepository } from "src/app/repositories/procurement/loyaltyCardRepository/LoyaltyCardRepository";
import { PartnerServicesRepository } from "../../repositories/partner-services-repository/PartnerServicesRepository";
import { LoyaltyRepository } from "src/app/repositories/procurement/loyaltyRepository/LoyaltyRepository";
import { LoyaltyTransactionRepository } from "src/app/repositories/procurement/loyaltyTransactionRepository/LoyaltyTransactionRepository";
import { CategoryIngredientRepository } from "src/app/repositories/category-ingredient-repository/CategoryIngredientRepository";
import { PartnerChatRepository } from "src/app/repositories/partner-chat-repository/PartnerChatRepository";
import { PaymentMethodRepository } from "src/app/repositories/procurement/paymentMethodRepository/PaymentMethodRepository";
import { POSOrderHistoryRepository } from "src/app/repositories/pos-order-history-repository/POSOrderHistoryRepository";
import { ActivityTracingRepository } from "src/app/repositories/activity-tracing-repository/ActivityTracingRepository";
import { ProductSizeRepository } from "src/app/repositories/product-size-repository/ProductSizeRepository";
import { ProductColorRepository } from "src/app/repositories/product-color-repository/ProductColorRepository";
import { ClientSignUpRepository } from "src/app/repositories/client-signup-repository copy/ClientSignupRepository";
import { BridgeServerConfigurationRepository } from "src/app/repositories/bridge-server-configuration-repository/BridgeServerConfigurationRepository";
import { AutoPurchaseOrderRepository } from "src/app/repositories/auto-purchase-order-repository/AutoPurchaseOrderRepository";
import { ProductLocationRepository } from "src/app/repositories/product-location-repository/ProductLocationRepository";
import { ProductCompany } from "src/app/models/ProductCompany/product-company";
import { ProductCompanyRepository } from "src/app/repositories/product-company-repository/ProductCompanyRepository";
import { StockBatchInwardRepository } from "src/app/repositories/stock-batch-inward-repository/StockBatchInwardRepository";
import { GoodsReceivedReceiptRepository } from "src/app/repositories/goods-received-receipt-repository/GoodsReceivedReceiptRepository";
import { GoodDeliveryNoteRepository } from "src/app/repositories/good-delivery-note-repository/GoodDeliveryNoteRepository";


@Injectable()
export class BusinessServices {

    constructor(private injector: Injector) { }

    private _buyerService: BuyerRepository;
    public get buyerService(): BuyerRepository {
        if (!this._buyerService) {
            this._buyerService = this.injector.get(BuyerRepository);
        }
        return this._buyerService;
    }

    private _loginService: LoginRepository;
    public get loginService(): LoginRepository {
        if (!this._loginService) {
            this._loginService = this.injector.get(LoginRepository);
        }
        return this._loginService;
    }

    private _signupService: ClientSignUpRepository;
    public get signupService(): ClientSignUpRepository {
        if (!this._signupService) {
            this._signupService = this.injector.get(ClientSignUpRepository);
        }
        return this._signupService;
    }

    private _dashboardService: DashboardRepository;
    public get dashboardService(): DashboardRepository {
        if (!this._dashboardService) {
            this._dashboardService = this.injector.get(DashboardRepository);
        }
        return this._dashboardService;
    }

    private _vendorService: VendorRepository;
    public get vendorService(): VendorRepository {
        if (!this._vendorService) {
            this._vendorService = this.injector.get(VendorRepository);
        }
        return this._vendorService;
    }
    private _collectionAgentService: CollectionAgentRepository;
    public get collectionAgentService(): CollectionAgentRepository {
        if (!this._collectionAgentService) {
            this._collectionAgentService = this.injector.get(CollectionAgentRepository);
        }
        return this._collectionAgentService;
    }

    private _salesAgentService: SalesAgentRepository;
    public get salesAgentService(): SalesAgentRepository {
        if (!this._salesAgentService) {
            this._salesAgentService = this.injector.get(SalesAgentRepository);
        }
        return this._salesAgentService;
    }

    private _unitOfMeasurementService: UnitOfMeasurementRepository;
    public get unitOfMeasurementService(): UnitOfMeasurementRepository {
        if (!this._unitOfMeasurementService) {
            this._unitOfMeasurementService = this.injector.get(UnitOfMeasurementRepository);
        }
        return this._unitOfMeasurementService;
    }

    private _productCompanyService: ProductCompanyRepository;
    public get productCompanyService(): ProductCompanyRepository {
        if (!this._productCompanyService) {
            this._productCompanyService = this.injector.get(ProductCompanyRepository);
        }
        return this._productCompanyService;
    }

    private _productLocationService: ProductLocationRepository;
    public get productLocationService(): ProductLocationRepository {
        if (!this._productLocationService) {
            this._productLocationService = this.injector.get(ProductLocationRepository);
        }
        return this._productLocationService;
    }

    private _taxesRepository: TaxesRepository;
    public get taxesService(): TaxesRepository {
        if (!this._taxesRepository) {
            this._taxesRepository = this.injector.get(TaxesRepository);
        }
        return this._taxesRepository;
    }

    private _uomGroupService: UOMGroupRepository;
    public get uomGroupService(): UOMGroupRepository {
        if (!this._uomGroupService) {
            this._uomGroupService = this.injector.get(UOMGroupRepository);
        }
        return this._uomGroupService;
    }

    private _priceSetupService: PriceSetupRepository;
    public get priceSetupService(): PriceSetupRepository {
        if (!this._priceSetupService) {
            this._priceSetupService = this.injector.get(PriceSetupRepository);
        }
        return this._priceSetupService;
    }

    private _paymentTermService: PaymentTermRepository;
    public get paymentTermService(): PaymentTermRepository {
        if (!this._paymentTermService) {
            this._paymentTermService = this.injector.get(PaymentTermRepository);
        }
        return this._paymentTermService;
    }

    private _buyerGroupSetupService: BuyerGroupSetupRepository;
    public get buyerGroupSetupService(): BuyerGroupSetupRepository {
        if (!this._buyerGroupSetupService) {
            this._buyerGroupSetupService = this.injector.get(BuyerGroupSetupRepository);
        }
        return this._buyerGroupSetupService;
    }

    private _commonService: CommonRepository;
    public get commonService(): CommonRepository {
        if (!this._commonService) {
            this._commonService = this.injector.get(CommonRepository);
        }
        return this._commonService;
    }
    private _salesRegionService: SalesRegionRepository;
    public get salesRegionService(): SalesRegionRepository {
        if (!this._salesRegionService) {
            this._salesRegionService = this.injector.get(SalesRegionRepository);
        }
        return this._salesRegionService;
    }
    private _roductGroupDiscountSetupRepository: ProductGroupDiscountSetupRepository;
    public get productGroupDiscountSetupRepository(): ProductGroupDiscountSetupRepository {
        if (!this._roductGroupDiscountSetupRepository) {
            this._roductGroupDiscountSetupRepository = this.injector.get(ProductGroupDiscountSetupRepository);
        }
        return this._roductGroupDiscountSetupRepository;
    }

    private _taxGroupRepository: TaxGroupRepository;
    public get taxGroupRepository(): TaxGroupRepository {
        if (!this._roductGroupDiscountSetupRepository) {
            this._taxGroupRepository = this.injector.get(TaxGroupRepository);
        }
        return this._taxGroupRepository;
    }

    private _taxSetupRepository: TaxSetupRepository;
    public get taxSetupRepository(): TaxSetupRepository {
        if (!this._taxSetupRepository) {
            this._taxSetupRepository = this.injector.get(TaxSetupRepository);
        }
        return this._taxSetupRepository;
    }

    private _taxRateMasterRepository: TaxRateMasterRepository;
    public get taxRateMasterRepository(): TaxRateMasterRepository {
        if (!this._taxRateMasterRepository) {
            this._taxRateMasterRepository = this.injector.get(TaxRateMasterRepository);
        }
        return this._taxRateMasterRepository;
    }

    private _procurementCodeSetupRepository: ProcurementCodeSetupRepository;
    public get procurementCodeSetupRepository(): ProcurementCodeSetupRepository {
        if (!this._procurementCodeSetupRepository) {
            this._procurementCodeSetupRepository = this.injector.get(ProcurementCodeSetupRepository);
        }
        return this._procurementCodeSetupRepository;
    }

    private _procurementPostingSetupRepository: ProcurementPostingSetupRepository;
    public get procurementPostingSetupRepository(): ProcurementPostingSetupRepository {
        if (!this._procurementPostingSetupRepository) {
            this._procurementPostingSetupRepository = this.injector.get(ProcurementPostingSetupRepository);
        }
        return this._procurementPostingSetupRepository;
    }

    private _purchasePostingSetupRepository: PurchasePostingSetupRepository;
    public get purchasePostingSetupRepository(): PurchasePostingSetupRepository {
        if (!this._purchasePostingSetupRepository) {
            this._purchasePostingSetupRepository = this.injector.get(PurchasePostingSetupRepository);
        }
        return this._purchasePostingSetupRepository;
    }

    private _salesPostingSetupRepository: SalesPostingSetupRepository;
    public get salesPostingSetupRepository(): SalesPostingSetupRepository {
        if (!this._salesPostingSetupRepository) {
            this._salesPostingSetupRepository = this.injector.get(SalesPostingSetupRepository);
        }
        return this._salesPostingSetupRepository;
    }

    private startingNumberSetupRepository: StartingNumberSetupRepository;
    public get StartingNumberSetupManager(): StartingNumberSetupRepository {
        if (!this.startingNumberSetupRepository) {
            this.startingNumberSetupRepository = this.injector.get(StartingNumberSetupRepository);
        }
        return this.startingNumberSetupRepository;
    }

    private _godownCodeSetupRepository: GodownCodeSetupRepository;
    public get godownCodeSetupRepository(): GodownCodeSetupRepository {
        if (!this._godownCodeSetupRepository) {
            this._godownCodeSetupRepository = this.injector.get(GodownCodeSetupRepository);
        }
        return this._godownCodeSetupRepository;
    }

    private _globalSetupsRepository: GlobalSetupsRepository;
    public get globalSetupsRepository(): GlobalSetupsRepository {
        if (!this._globalSetupsRepository) {
            this._globalSetupsRepository = this.injector.get(GlobalSetupsRepository);
        }
        return this._globalSetupsRepository;
    }

    private _salesCreditControlsRepository: SalesCreditControlsRepository;
    public get salesCreditControlsRepository(): SalesCreditControlsRepository {
        if (!this._salesCreditControlsRepository) {
            this._salesCreditControlsRepository = this.injector.get(SalesCreditControlsRepository);
        }
        return this._salesCreditControlsRepository;
    }

    private _godownRepository: GodownRepository;
    public get godownRepository(): GodownRepository {
        if (!this._godownRepository) {
            this._godownRepository = this.injector.get(GodownRepository);
        }
        return this._godownRepository;
    }

    private _productCardMasterRepository: ProductCardMasterRepositoryService;
    public get productCardRepository(): ProductCardMasterRepositoryService {
        if (!this._productCardMasterRepository) {
            this._productCardMasterRepository = this.injector.get(ProductCardMasterRepositoryService);
        }
        return this._productCardMasterRepository;
    }

    private _stockRepository: StockRepository;
    public get StockRepository(): StockRepository {
        if (!this._stockRepository) {
            this._stockRepository = this.injector.get(StockRepository);
        }
        return this._stockRepository;
    }

    private _productCardMasterCategoryRepository: ProductCardMasterCategoryRepositoryService;
    public get productCardCategoryRepository(): ProductCardMasterCategoryRepositoryService {
        if (!this._productCardMasterCategoryRepository) {
            this._productCardMasterCategoryRepository = this.injector.get(ProductCardMasterCategoryRepositoryService);
        }
        return this._productCardMasterCategoryRepository;
    }

    private _purchaseQuotationRepository: PurchaseQuotationRepository;
    public get purchaseQuotationRepository(): PurchaseQuotationRepository {
        if (!this._purchaseQuotationRepository) {
            this._purchaseQuotationRepository = this.injector.get(PurchaseQuotationRepository);
        }
        return this._purchaseQuotationRepository;
    }

    private _purchaseOrderRepository: PurchaseOrderRepository;
    public get purchaseOrderRepository(): PurchaseOrderRepository {
        if (!this._purchaseOrderRepository) {
            this._purchaseOrderRepository = this.injector.get(PurchaseOrderRepository);
        }
        return this._purchaseOrderRepository;
    }

    private _purchaseInvoiceRepository: PurchaseInvoiceRepository;
    public get purchaseInvoiceRepository(): PurchaseInvoiceRepository {
        if (!this._purchaseInvoiceRepository) {
            this._purchaseInvoiceRepository = this.injector.get(PurchaseInvoiceRepository);
        }
        return this._purchaseInvoiceRepository;
    }

    private _autoPurchaseOrderRepository: AutoPurchaseOrderRepository;
    public get autoPurchaseOrderRepository(): AutoPurchaseOrderRepository {
        if (!this._autoPurchaseOrderRepository) {
            this._autoPurchaseOrderRepository = this.injector.get(AutoPurchaseOrderRepository);
        }
        return this._autoPurchaseOrderRepository;
    }

    private _goodsReceivedReceiptRepository: GoodsReceivedReceiptRepository;
    public get goodsReceivedReceiptRepository(): GoodsReceivedReceiptRepository {
        if (!this._goodsReceivedReceiptRepository) {
            this._goodsReceivedReceiptRepository = this.injector.get(GoodsReceivedReceiptRepository);
        }
        return this._goodsReceivedReceiptRepository;
    }

    private _saleQuotationRepository: SaleQuotationRepository;
    public get saleQuotationRepository(): SaleQuotationRepository {
        if (!this._saleQuotationRepository) {
            this._saleQuotationRepository = this.injector.get(SaleQuotationRepository);
        }
        return this._saleQuotationRepository;
    }

    private _saleOrderRepository: SaleOrderRepository;
    public get saleOrderRepository(): SaleOrderRepository {
        if (!this._saleOrderRepository) {
            this._saleOrderRepository = this.injector.get(SaleOrderRepository);
        }
        return this._saleOrderRepository;
    }

    private _saleInvoiceRepository: SaleInvoiceRepository;
    public get saleInvoiceRepository(): SaleInvoiceRepository {
        if (!this._saleInvoiceRepository) {
            this._saleInvoiceRepository = this.injector.get(SaleInvoiceRepository);
        }
        return this._saleInvoiceRepository;
    }

    private _goodDeliveryNoteRepository: GoodDeliveryNoteRepository;
    public get goodDeliveryNoteRepository(): GoodDeliveryNoteRepository {
        if (!this._goodDeliveryNoteRepository) {
            this._goodDeliveryNoteRepository = this.injector.get(GoodDeliveryNoteRepository);
        }
        return this._goodDeliveryNoteRepository;
    }

    private _purchaseReturnRepository: PurchaseReturnRepository;
    public get purchaseReturnRepository(): PurchaseReturnRepository {
        if (!this._purchaseReturnRepository) {
            this._purchaseReturnRepository = this.injector.get(PurchaseReturnRepository);
        }
        return this._purchaseReturnRepository;
    }

    private _purchasePaymentRepository: PurchasePaymentRepository;
    public get purchasePaymentRepository(): PurchasePaymentRepository {
        if (!this._purchasePaymentRepository) {
            this._purchasePaymentRepository = this.injector.get(PurchasePaymentRepository);
        }
        return this._purchasePaymentRepository;
    }

    private _companyProfileRepository: CompanyProfileRepository;
    public get companyProfileRepository(): CompanyProfileRepository {
        if (!this._companyProfileRepository) {
            this._companyProfileRepository = this.injector.get(CompanyProfileRepository);
        }
        return this._companyProfileRepository;
    }
    private _saleReturnRepository: SaleReturnRepository;
    public get saleReturnRepository(): SaleReturnRepository {
        if (!this._saleReturnRepository) {
            this._saleReturnRepository = this.injector.get(SaleReturnRepository);
        }
        return this._saleReturnRepository;
    }
    private _saleCollectionRepository: SaleCollectionsRepository;
    public get saleCollectionRepository(): SaleCollectionsRepository {
        if (!this._saleCollectionRepository) {
            this._saleCollectionRepository = this.injector.get(SaleCollectionsRepository);
        }
        return this._saleCollectionRepository;
    }

    private _stockInternalUseRepository: StockInternalUseRepository;
    public get stockInternalUseRepository(): StockInternalUseRepository {
        if (!this._stockInternalUseRepository) {
            this._stockInternalUseRepository = this.injector.get(StockInternalUseRepository);
        }
        return this._stockInternalUseRepository;
    }

    private _stockAdjustmentRepository: StockAdjustmentRepository;
    public get stockAdjustmentRepository(): StockAdjustmentRepository {
        if (!this._stockAdjustmentRepository) {
            this._stockAdjustmentRepository = this.injector.get(StockAdjustmentRepository);
        }
        return this._stockAdjustmentRepository;
    }

    private _stockBatchInwardRepository: StockBatchInwardRepository;
    public get stockBatchInwardRepository(): StockBatchInwardRepository {
        if (!this._stockBatchInwardRepository) {
            this._stockBatchInwardRepository = this.injector.get(StockBatchInwardRepository);
        }
        return this._stockBatchInwardRepository;
    }

    private _stockTransferRepository: StockTransferRepository;
    public get stockTransferRepository(): StockTransferRepository {
        if (!this._stockTransferRepository) {
            this._stockTransferRepository = this.injector.get(StockTransferRepository);
        }
        return this._stockTransferRepository;
    }

    private _stockDamageRepository: StockDamageRepository;
    public get stockDamageRepository(): StockDamageRepository {
        if (!this._stockDamageRepository) {
            this._stockDamageRepository = this.injector.get(StockDamageRepository);
        }
        return this._stockDamageRepository;
    }

    private _countersRepository: CountersRepository;
    public get countersRepository(): CountersRepository {
        if (!this._countersRepository) {
            this._countersRepository = this.injector.get(CountersRepository);
        }
        return this._countersRepository;
    }

    private _usersRepository: UsersRepository;
    public get usersRepository(): UsersRepository {
        if (!this._usersRepository) {
            this._usersRepository = this.injector.get(UsersRepository);
        }
        return this._usersRepository;
    }
    private _procurementReportRepository: ProcurementReportRepository;
    public get procurementReportRepository(): ProcurementReportRepository {
        if (!this._procurementReportRepository) {
            this._procurementReportRepository = this.injector.get(ProcurementReportRepository);
        }
        return this._procurementReportRepository;
    }

    private _reportViewerRepository: ReportViewerRepository;
    public get reportViewerRepository(): ReportViewerRepository {
        if (!this._reportViewerRepository) {
            this._reportViewerRepository = this.injector.get(ReportViewerRepository);
        }
        return this._reportViewerRepository;
    }

    private _printerGroupRepository: PrinterGroupRepository;
    public get printerGroupRepository(): PrinterGroupRepository {
        if (!this._printerGroupRepository) {
            this._printerGroupRepository = this.injector.get(PrinterGroupRepository);
        }
        return this._printerGroupRepository;
    }

    private _procurementPrintReportRepository: ProcurementPrintReportRepository;
    public get procurementPrintReportRepository(): ProcurementPrintReportRepository {
        if (!this._procurementPrintReportRepository) {
            this._procurementPrintReportRepository = this.injector.get(ProcurementPrintReportRepository);
        }
        return this._procurementPrintReportRepository;
    }

    private _productGroupsRepository: ProductGroupsRepository;
    public get productGroupsRepository(): ProductGroupsRepository {
        if (!this._productGroupsRepository) {
            this._productGroupsRepository = this.injector.get(ProductGroupsRepository);
        }
        return this._productGroupsRepository;
    }

    private _receiptSetupRepository: ReceiptSetupRepository;
    public get receiptSetupRepository(): ReceiptSetupRepository {
        if (!this._receiptSetupRepository) {
            this._receiptSetupRepository = this.injector.get(ReceiptSetupRepository);
        }
        return this._receiptSetupRepository;
    }

    private _weighScaleSetupRepository: WeighScaleSetupRepository;
    public get weighScaleSetupRepository(): WeighScaleSetupRepository {
        if (!this._weighScaleSetupRepository) {
            this._weighScaleSetupRepository = this.injector.get(WeighScaleSetupRepository);
        }
        return this._weighScaleSetupRepository;
    }

    private _salesInvoiceHistoryRepository: SalesInvoiceHistoryRepository;
    public get salesInvoiceHistoryRepository(): SalesInvoiceHistoryRepository {
        if (!this._salesInvoiceHistoryRepository) {
            this._salesInvoiceHistoryRepository = this.injector.get(SalesInvoiceHistoryRepository);
        }
        return this._salesInvoiceHistoryRepository;
    }

    private _posDashboardRepository: POSDashboardRepository;
    public get posDashboardRepository(): POSDashboardRepository {
        if (!this._posDashboardRepository) {
            this._posDashboardRepository = this.injector.get(POSDashboardRepository);
        }
        return this._posDashboardRepository;
    }

    private _rolesRepository: RolesRepository;

    public get rolesRepository(): RolesRepository {
        if (!this._rolesRepository) {
            this._rolesRepository = this.injector.get(RolesRepository);
        }
        return this._rolesRepository;
    }
    private _salesGroupRepository: SalesGroupRepository;
    public get salesGroupRepository(): SalesGroupRepository {
        if (!this._salesGroupRepository) {
            this._salesGroupRepository = this.injector.get(SalesGroupRepository);
        }
        return this._salesGroupRepository;
    }
    private _erpUserRepository: ErpUserRepository;
    public get erpUserRepository(): ErpUserRepository {
        if (!this._erpUserRepository) {
            this._erpUserRepository = this.injector.get(ErpUserRepository);
        }
        return this._erpUserRepository;
    }

    private _fiscalSpansRepository: FiscalSpansRepository;
    public get fiscalSpansRepository(): FiscalSpansRepository {
        if (!this._fiscalSpansRepository) {
            this._fiscalSpansRepository = this.injector.get(FiscalSpansRepository);
        }
        return this._fiscalSpansRepository;
    }

    private _accessRightsRepository: AccessRightsRepository;
    public get accessRightsRepository(): AccessRightsRepository {
        if (!this._accessRightsRepository) {
            this._accessRightsRepository = this.injector.get(AccessRightsRepository);
        }
        return this._accessRightsRepository;
    }

    private _chestStyleRepository: ChestStyleRepository;
    public get chestStyleRepository(): ChestStyleRepository {
        if (!this._chestStyleRepository) {
            this._chestStyleRepository = this.injector.get(ChestStyleRepository);
        }
        return this._chestStyleRepository;
    }

    private _collarStyleRepository: CollarStyleRepository;
    public get collarStyleRepository(): CollarStyleRepository {
        if (!this._collarStyleRepository) {
            this._collarStyleRepository = this.injector.get(CollarStyleRepository);
        }
        return this._collarStyleRepository;
    }

    private _handStyleRepository: HandStyleRepository;
    public get handStyleRepository(): HandStyleRepository {
        if (!this._handStyleRepository) {
            this._handStyleRepository = this.injector.get(HandStyleRepository);
        }
        return this._handStyleRepository;
    }

    private _pocketStyleRepository: PocketStyleRepository;
    public get pocketStyleRepository(): PocketStyleRepository {
        if (!this._pocketStyleRepository) {
            this._pocketStyleRepository = this.injector.get(PocketStyleRepository);
        }
        return this._pocketStyleRepository;
    }

    private _shirtStyleRepository: ShirtStyleRepository;
    public get shirtStyleRepository(): ShirtStyleRepository {
        if (!this._shirtStyleRepository) {
            this._shirtStyleRepository = this.injector.get(ShirtStyleRepository);
        }
        return this._shirtStyleRepository;
    }

    private _tailorTypeRepository: TailorTypeRepository;
    public get tailorTypeRepository(): TailorTypeRepository {
        if (!this._tailorTypeRepository) {
            this._tailorTypeRepository = this.injector.get(TailorTypeRepository);
        }
        return this._tailorTypeRepository;
    }

    private _barcodeLableSetupRepository: BarcodeLableSetupRepository;
    public get barcodeLableSetupRepository(): BarcodeLableSetupRepository {
        if (!this._barcodeLableSetupRepository) {
            this._barcodeLableSetupRepository = this.injector.get(BarcodeLableSetupRepository);
        }
        return this._barcodeLableSetupRepository;
    }

    private _cardsRepository: CardsRepository;
    public get cardsRepository(): CardsRepository {
        if (!this._cardsRepository) {
            this._cardsRepository = this.injector.get(CardsRepository);
        }
        return this._cardsRepository;
    }
    private _banksRepository: BanksRepository;
    public get banksRepository(): BanksRepository {
        if (!this._banksRepository) {
            this._banksRepository = this.injector.get(BanksRepository);
        }
        return this._banksRepository;
    }
    private _cardRateSetupRepository: CardRateSetupRepository;
    public get cardRateSetupRepository(): CardRateSetupRepository {
        if (!this._cardRateSetupRepository) {
            this._cardRateSetupRepository = this.injector.get(CardRateSetupRepository);
        }
        return this._cardRateSetupRepository;
    }

    private _currencyRepository: CurrencyRepository;
    public get currencyRepository(): CurrencyRepository {
        if (!this._currencyRepository) {
            this._currencyRepository = this.injector.get(CurrencyRepository);
        }
        return this._currencyRepository;
    }

    private _namingConventionsRepository: NamingConventionsRepository;
    public get namingConventionsRepository(): NamingConventionsRepository {
        if (!this._namingConventionsRepository) {
            this._namingConventionsRepository = this.injector.get(NamingConventionsRepository);
        }
        return this._namingConventionsRepository;
    }

    private _POSStringsRepository: POSStringsRepository;
    public get posStringsRepository(): POSStringsRepository {
        if (!this._POSStringsRepository) {
            this._POSStringsRepository = this.injector.get(POSStringsRepository);
        }
        return this._POSStringsRepository;
    }
    private _POSOrderHistory: POSOrderHistoryRepository;
    public get posOrderHistoryService(): POSOrderHistoryRepository {
        if (!this._POSOrderHistory) {
            this._POSOrderHistory = this.injector.get(POSOrderHistoryRepository);
        }
        return this._POSOrderHistory;
    }

    private _commissionSetupService: CommissionSetupRepository;
    public get commissionSetupService(): CommissionSetupRepository {
        if (!this._commissionSetupService) {
            this._commissionSetupService = this.injector.get(CommissionSetupRepository);
        }
        return this._commissionSetupService;
    }

    private _productSizeService: ProductSizeRepository;
    public get productSizeService(): ProductSizeRepository {
        if (!this._productSizeService) {
            this._productSizeService = this.injector.get(ProductSizeRepository);
        }
        return this._productSizeService;
    }

    private _productColorService: ProductColorRepository;
    public get productColorService(): ProductColorRepository {
        if (!this._productColorService) {
            this._productColorService = this.injector.get(ProductColorRepository);
        }
        return this._productColorService;
    }

    private _zidRepository: ZIDRepository;
    public get zidService(): ZIDRepository {
        if (!this._zidRepository) {
            this._zidRepository = this.injector.get(ZIDRepository);
        }
        return this._zidRepository;
    }

    private _channelPricesRepository: ChannelPricesRepository;
    public get channelPricesRepository(): ChannelPricesRepository {
        if (!this._channelPricesRepository) {
            this._channelPricesRepository = this.injector.get(ChannelPricesRepository);
        }
        return this._channelPricesRepository;
    }

    private _buyerSMSRepository: BuyerSMSRepository;
    public get buyerSMSService(): BuyerSMSRepository {
        if (!this._buyerSMSRepository) {
            this._buyerSMSRepository = this.injector.get(BuyerSMSRepository);
        }
        return this._buyerSMSRepository;
    }

    private _addOnGroupRepository: AddOnGroupRepository;
    public get addOnGroupRepository(): AddOnGroupRepository {
        if (!this._addOnGroupRepository) {
            this._addOnGroupRepository = this.injector.get(AddOnGroupRepository);
        }
        return this._addOnGroupRepository;
    }

    private _loyaltyCardRepository: LoyaltyCardRepository;
    public get loyaltyCardRepository(): LoyaltyCardRepository {
        if (!this._loyaltyCardRepository) {
            this._loyaltyCardRepository = this.injector.get(LoyaltyCardRepository);
        }
        return this._loyaltyCardRepository;
    }


    private _PaymentMethodRepository: PaymentMethodRepository;
    public get paymentMethodRepository(): PaymentMethodRepository {
        if (!this._PaymentMethodRepository) {
            this._PaymentMethodRepository = this.injector.get(PaymentMethodRepository);
        }
        return this._PaymentMethodRepository;
    }


    private _PartnerServicesRepository: PartnerServicesRepository;
    public get partnerServicesRepository(): PartnerServicesRepository {
        if (!this._PartnerServicesRepository) {
            this._PartnerServicesRepository = this.injector.get(PartnerServicesRepository);
        }
        return this._PartnerServicesRepository;
    }

    private _loyaltyRepository: LoyaltyRepository;
    public get loyaltyRepository(): LoyaltyRepository {
        if (!this._loyaltyRepository) {
            this._loyaltyRepository = this.injector.get(LoyaltyRepository);
        }
        return this._loyaltyRepository;
    }

    private _loyaltyTransactionRepository: LoyaltyTransactionRepository;
    public get loyaltyTransactionRepository(): LoyaltyTransactionRepository {
        if (!this._loyaltyTransactionRepository) {
            this._loyaltyTransactionRepository = this.injector.get(LoyaltyTransactionRepository);
        }
        return this._loyaltyTransactionRepository;
    }

    private _lategoryIngredientRepository: CategoryIngredientRepository;
    public get categoryIngredientService(): CategoryIngredientRepository {
        if (!this._loyaltyRepository) {
            this._lategoryIngredientRepository = this.injector.get(CategoryIngredientRepository);
        }
        return this._lategoryIngredientRepository;
    }

    private _partnerChatRepository: PartnerChatRepository;
    public get partnerChatService(): PartnerChatRepository {
        if (!this._partnerChatRepository) {
            this._partnerChatRepository = this.injector.get(PartnerChatRepository);
        }
        return this._partnerChatRepository;
    }
    private _activityTracingRepository: ActivityTracingRepository;
    public get activityTracingService(): ActivityTracingRepository {
        if (!this._activityTracingRepository) {
            this._activityTracingRepository = this.injector.get(ActivityTracingRepository);
        }
        return this._activityTracingRepository;
    }

    private _bridgeServerConfigurationRepository: BridgeServerConfigurationRepository;
    public get bridgeServerConfigurationRepository(): BridgeServerConfigurationRepository {
        if (!this._bridgeServerConfigurationRepository) {
            this._bridgeServerConfigurationRepository = this.injector.get(BridgeServerConfigurationRepository);
        }
        return this._bridgeServerConfigurationRepository;
    }
}