import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

declare var $: any;
@Component({
    selector: 'app-buttom-action-bar',
    templateUrl: "./buttom-action-bar.component.html"
})
export class ButtomActionBarComponent implements OnInit, AfterViewInit {

    updateAllowed: boolean = true;
    addNewAllowed: boolean = true;
    deleteAllowed: boolean = true;

    constructor(router: Router, route: ActivatedRoute) {
        route.data.subscribe(data => {
            this.setOperationAccess(data["PageID"])
        });
    }

    private setOperationAccess(pageID): void {

        if (!pageID) return;

        let rolepermissionObject = JSON.parse(localStorage.getItem('roleOperationsPermission') || '[]')

        if (rolepermissionObject.length > 0) {

            let permissions = rolepermissionObject.filter(x => x.PageID == pageID)

            if (permissions.length > 0) {

                if (permissions[0].PermissionObj && permissions[0].PermissionObj.length > 0) {
                    let addNeweObj = permissions[0].PermissionObj.filter(x => x.OperationID == pageID + '01');
                    if (addNeweObj && addNeweObj.length > 0)
                        this.addNewAllowed = addNeweObj[0].IsAllowed;
                    else
                        this.addNewAllowed = false;

                    let updateObj = permissions[0].PermissionObj.filter(x => x.OperationID == pageID + '02');

                    if (updateObj && updateObj.length > 0)
                        this.updateAllowed = updateObj[0].IsAllowed;
                    else
                        this.updateAllowed = false;

                    let deleteObj = permissions[0].PermissionObj.filter(x => x.OperationID == pageID + '03');
                    if (deleteObj && deleteObj.length > 0)
                        this.deleteAllowed = deleteObj[0].IsAllowed;
                    else
                        this.deleteAllowed = false;

                }
                else {
                    console.log("Operation not found");
                    console.log(permissions);
                    this.deleteAllowed = false;
                    this.updateAllowed = false;
                    this.addNewAllowed = false;
                }
            }
            else {
                console.log(pageID + " : id not found")
            }
        }


    }

    ngAfterViewInit(): void {
        window.dispatchEvent(new Event('resize'));
    }
    ngOnInit(): void {

        $(window).on('resize', function () {

            if ($(window).innerWidth() >= 768) {

                $('.dtBtn').removeClass('d-none');
                $('.mBtn').addClass('d-none');
            }

            if ($(window).innerWidth() <= 767) {

                $('.dtBtn').addClass('d-none');
                $('.mBtn').removeClass('d-none');
            }
        });
    }

}
