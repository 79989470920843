import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable(
  {
    providedIn: "root"
  }
)

@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // let global= new Globals()

    let datePipe = new DatePipe("en-US"); //this.global.cultureCode);
    if (value) {
      if (args)
        value = datePipe.transform(value, args);
      else
        value = datePipe.transform(value, 'dd/MM/yyyy');
    }
    else {
      return "";
    }
    return value;
  }
}
