import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { AccountMaster, COAImportSave } from "../../../models/Finance/AccountMaster/account-master.model";
import { IAccountMasterRepository } from "./IAccountMasterRepository";

@Injectable()
export class AccountMasterRepository extends GenericRepository<any> implements IAccountMasterRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/accountmaster', environment.urlAddress);
  }   
    SaveRec(item: AccountMaster): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item)
    }
    LoadAllRecForCOA(onPageLoad: boolean, accountCode: string,accountType: string): Observable<ResponseModel> {
        return this.GetById('/GetAllRecForCOA/' + onPageLoad + '/' +accountCode + '/' +accountType)
    }
    LoadAllRec(searchKey: string, accountType: string): Observable<ResponseModel> {
        return this.GetById('/GetAllRec/' + searchKey + '/' + accountType)
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code)
    }
    LoadByKey(code: string, searchByCode: boolean): Observable<ResponseModel> {
        return this.GetById('/LoadByKey/' + code + '/' + searchByCode)
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code)
    }
    GetAccountChildrenCount(code: string): Observable<ResponseModel> {
        return this.GetById('/GetAccountChildrenCount/' + code)
    }
    GetTransactionsCount(code: string): Observable<ResponseModel> {
        return this.GetById('/GetTransactionsCount/' + code)
    }
    GetVendorsAccountes(): Observable<ResponseModel> {
        return this.GetById('/GetVendorsAccountes/');
    }
    GetBuyersAccountes(): Observable<ResponseModel> {
        return this.GetById('/GetBuyersAccountes/');
    }
   
    SaveImportRec(importSaveData: COAImportSave): Observable<ResponseModel> {
        return this.PostData('/SaveImportRec', importSaveData);
    }
}