import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { TaxSetup } from "src/app/models/TaxSetup/taxsetup.model";
import { ITaxSetupRepository } from "./ITaxSetupRepository";
@Injectable()
export class TaxSetupRepository extends GenericRepository<TaxSetup> implements ITaxSetupRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/TaxSetup', environment.urlAddress);
  }

  GetRec(TaxType: number,Setting?:boolean): Observable<any> {    
    return this._http
      .get(this.url + 'api/TaxSetup/Select' + '/' + TaxType + '/' + Setting, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
}
