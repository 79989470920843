import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPurchaseReturnRepository } from "./IPurchaseReturnRepository";
import { PurchaseReturns } from "src/app/models/purchase-return/purchase-return.model";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()
export class PurchaseReturnRepository extends GenericRepository<PurchaseReturns> implements IPurchaseReturnRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PurchaseReturn/', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/PurchaseReturn/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/PurchaseReturn/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PurchaseOrder: string) {
        return this.Delete('/DeleteRec/' + PurchaseOrder).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCode: string, paymentType: number, vendorCode: string, isTaxRefundable: boolean, isTaxByVendor: boolean): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode + '/' + paymentType + '/' + vendorCode + '/' + isTaxRefundable + '/' + isTaxByVendor, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectVendorReturns(currentPage: number, recordsPerPage: number, vendorCode: string): Observable<any> {
        return this.GetById('SelectVendorReturns' + '/' + currentPage + '/' + recordsPerPage + '/' + vendorCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}