export class UserModel {
    constructor() {
        this.IsSalesAgentLinked = false;
        this.AllowedToChangeDateOnPurchaseInvoice = false;
        this.IsActive = false;
        this.CanAccessDashboard = false;
        this.DashboardAccess = false;
        this.RoleID = 0;
        this.DisplayLastCostPriceButton = false;
        this.AllowedToChangeDateOnSalesInvoice = false;
        this.DisplayLastSalePriceButton = false;
        this.DisplayCostPriceButton = false;
        this.IsCostPriceDisplayOnStockTransfer = false;
        this.IsInserting = true;
        this.SelectionType = ''
        this.Value = ''
        this.DisplayProductCreationButtonOnSales = false;
        this.DisplayProductCreationButtonOnPurchase = false;
    }
    id: number; // just for showing row number.
    UserID: number;
    LoginID: string;
    RoleID: number;
    RoleName: number;
    UserName: string;
    LoginTicket: string;
    FullName: string;
    EmailAddress: string;
    IsInserting: boolean;
    Mobile: string;
    Password: string;
    ConfirmPassword: string;
    CreationDate: string;
    LastLoginDate: string;
    LastPasswordChangeDate: string;
    GoDownCode: string;
    AllowedSalesAgents: string;
    IsActive: boolean;
    CanAccessDashboard: boolean;
    Theme: string;
    CultureCode: string;
    IsSalesAgentLinked: boolean;
    DashboardAccess: boolean;
    DefaultPage: string;
    DeviceTicket: string;
    AllowedToChangeDateOnPurchaseInvoice: boolean;
    DisplayLastCostPriceButton: boolean;
    AllowedToChangeDateOnSalesInvoice: boolean;
    DisplayLastSalePriceButton: boolean;
    DisplayCostPriceButton: boolean;
    IsCostPriceDisplayOnStockTransfer: boolean; 
    DisplayProductCreationButtonOnSales: boolean;
    DisplayProductCreationButtonOnPurchase: boolean;
    IsAccessToManagerApp: Boolean;
    SelectionType: string;
    Value: string;
}