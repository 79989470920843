import { Component, EventEmitter, Output } from '@angular/core';
import { StockBatchSerials } from '../../models/common/getjsonfrom-controls-service-model';
import { ToastrService } from 'ngx-toastr';
import { updateGridSerials } from 'src/app/services/common-methods/common-methods-exports';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
declare var $: any;
@Component({
    selector: 'app-batch-serial-outwards',
    templateUrl: './batch-serial-outwards.component.html',
})
export class BatchSerialOutwardsComponent {
    @Output() saveRecord = new EventEmitter<any>();

    public actualAvailableBatches: StockBatchSerials[] = [];
    public actualAvailableSerials: StockBatchSerials[] = [];
    public availableBatches: StockBatchSerials[] = [];
    public availableSerials: StockBatchSerials[] = [];
    public selectedBatches: StockBatchSerials[] = [];
    public selectedSerials: StockBatchSerials[] = [];
    public selectedItem: StockBatchSerials = new StockBatchSerials();

    public stockBatchOutwardID: string = '';
    private batchRequiredOnly: boolean = false;
    private serialRequiredOnly: boolean = false;

    public model: StockBatchSerials[] = [];
    public batchModel: StockBatchSerials = new StockBatchSerials();
    public serialModel: StockBatchSerials = new StockBatchSerials();
    public serialItems: StockBatchSerials[] = [];
    public batchItems: StockBatchSerials[] = [];
    public isEnabled: boolean = false;
    public isAutoSerials: boolean = true;
    public serialPrefix: string;
    public batchLabel: string = '';
    public indexer: number = 1;
    public batchNumber: string = '';
    public expiryDate: string = '';
    public sellingPrice: number = null;
    private _serialNumber: number = 0;

    public _isSerialShow: boolean = false; 
    public _isSelected: boolean = false;

    private _productCode: string = '';
    private _productName: string = '';
    private _unitName: string = '';
    private _godownName: string = '';
    private _quantity: number = 0;
    private _isBatchRequired: boolean = false;
    private _isSerialRequired: boolean = false;
    private _isMeasureRequired: boolean = false;
    private _isMfgRequired: boolean = false;
    private _isExpRequired: boolean = false;
    private _measurementType: number = 0;
    private _weight: number = 0;
    private _onProcess: any;
    private _params: any;
    private _smartUtilities: any;
    private _toastrService: any;
    private _loaderService: any;
    public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };

    get serialNumber(): number {
        return this._serialNumber;
    }
    set serialNumber(value: number) {
        this._serialNumber = value;
    }

    get productCode(): string {
        return this._productCode;
    }
    set productCode(value: string) {
        this._productCode = value;
    }
    get productName(): string {
        return this._productName;
    }
    set productName(value: string) {
        this._productName = value;
    }
    get unitName(): string {
        return this._unitName;
    }
    set unitName(value: string) {
        this._unitName = value;
    }
    get godownName(): string {
        return this._godownName;
    }
    set godownName(value: string) {
        this._godownName = value;
    }
    get quantity(): number {
        return this._quantity;
    }
    set quantity(value: number) {
        this._quantity = value;
    }
    get isBatchRequired(): boolean {
        return this._isBatchRequired;
    }
    set isBatchRequired(value: boolean) {
        this._isBatchRequired = value;
    }
    get isSerialRequired(): boolean {
        return this._isSerialRequired;
    }
    set isSerialRequired(value: boolean) {
        this._isSerialRequired = value;
    }
    get isMeasureRequired(): boolean {
        return this._isMeasureRequired;
    }
    set isMeasureRequired(value: boolean) {
        this._isMeasureRequired = value;
    }
    get isMfgRequired(): boolean {
        return this._isMfgRequired;
    }
    set isMfgRequired(value: boolean) {
        this._isMfgRequired = value;
    }
    get isExpRequired(): boolean {
        return this._isExpRequired;
    }
    set isExpRequired(value: boolean) {
        this._isExpRequired = value;
    }
    get measurementType(): number {
        return this._measurementType;
    }
    set measurementType(value: number) {
        this._measurementType = value;
    }
    get weight(): number {
        return this._weight;
    }
    set weight(value: number) {
        this._weight = value;
    }
    get params(): any {
        return this._params;
    }
    set params(value: any) {
        this._params = value;
    }
    get smartUtilities(): any {
        return this._smartUtilities;
    }
    set smartUtilities(value: any) {
        this._smartUtilities = value;
    }

    get onProcess(): any {
        return this._onProcess;
    }
    set onProcess(value: any) {
        this._onProcess = value;
    }
    get loaderService(): any {
        return this._loaderService;
    }
    set loaderService(value: any) {
        this._loaderService = value;
    }
    constructor(private toastrService: ToastrService, private translate: TranslatePipe) {
        $('.outwardbatchserialmodal .close').click(function () {
            $('.outwardbatchserialmodal').hide({ direction: 'right' }, 'slow');
        });
        // this.actualAvailableBatches.push(new StockBatchSerials('1', '', 'batch1'))
        // this.actualAvailableBatches.push(new StockBatchSerials('2', '', 'batch2'))
        // this.actualAvailableBatches.push(new StockBatchSerials('3', '', 'batch2'))
        // this.actualAvailableBatches.push(new StockBatchSerials('4', '', 'serial'))
        // this.actualAvailableBatches.push(new StockBatchSerials('5', '', 'item'))
        // this.actualAvailableBatches.push(new StockBatchSerials('6', '', 'laptop'))
        // this.actualAvailableBatches.push(new StockBatchSerials('7', '', 'soap'))
        this.availableBatches = Object.assign([], this.actualAvailableBatches);
    }

    onAddBatchClick() {
        var element = document.getElementsByClassName('bg-info');
        if (element.length > 0) {
            this.selectedBatches.push(this.selectedItem);
            this.availableBatches = this.availableBatches.filter(x => x.StockBatchOutwardID != this.selectedItem.StockBatchOutwardID);
        }
    }
    onRemoveBatchClick() {
        var element = document.getElementsByClassName('bg-info');
        if (element.length > 0) {
            this.availableBatches.push(this.selectedItem);
            this.selectedBatches = this.selectedBatches.filter(x => x.StockBatchOutwardID != this.selectedItem.StockBatchOutwardID);
        }
    }
    toggleDetails(event, item: StockBatchSerials): void {

        //if (!this._isSerialRequired)
        //    return;
        // var elements = document.getElementsByClassName('bg-info');
        // while (elements.length > 0) {
        //     elements[0].classList.remove('bg-info');
        // }
        // if (event.target.type == 'text')
        //     event.target.parentElement.parentElement.classList.add('bg-info');
        // else
        //     event.target.parentElement.classList.add('bg-info');


        this.selectedItem = item;
        setTimeout(() => {

            //this._isSerialShow = true;
            //this.isAutoSerials = true;
            //this.serialPrefix = item.BatchNumber;
            //this.batchLabel = item.BatchNumber;
            //this.indexer = 1;
            //this.loadBatchSerials(item.StockBatchOutwardID);
            //let OutwardQuantity: number = Number(item.OutwardQuantity);
            //const BatcheSerials = this.model.filter(x => x.StockBatchOutwardID === item.StockBatchOutwardID);
            //this.addSerialInitialRows(item.StockBatchOutwardID, item.BatchNumber, (OutwardQuantity - BatcheSerials.length), OutwardQuantity);
        }, 100);
    }
    onBatchSearchkeyUp(e) {     
        if (e.target.value !== '') {
            let found = [];
            found = this.actualAvailableBatches.filter(function (element) {
                return element.BatchNumber.toLowerCase().includes(e.target.value.toLowerCase());
            });
            this.availableBatches = found;
        }
        else if (e.target.value == '' || e.target.value == null) {
            this.availableBatches = this.actualAvailableBatches;
        }
    }

    ///////////////////////Added by Muneeb///////////////

    addBatchInModel() {
        let totalQuantity = null;
        let batchQuantity = 0;

        this.availableBatches.forEach(element => {
            batchQuantity += element.BalanceQuantity;
        });

        for (let i = 0; i < this.availableBatches.length; i++) {
            if (this.availableBatches[i].OutwardQuantity > 0) {
                var isvalid = true;
                if (this.batchItems.length > 0) {
                    var alreadyExists = this.batchItems.find(x => x.StockBatchInwardID == this.availableBatches[i].StockBatchInwardID);
                    if (alreadyExists) {
                        this.batchItems.filter(x => x.StockBatchInwardID == alreadyExists.StockBatchInwardID && x.SerialNumber == alreadyExists.SerialNumber).forEach(element => {
                            debugger
                            element.OutwardQuantity = Number(this.availableBatches[i].OutwardQuantity);
                            totalQuantity += Number(this.availableBatches[i].OutwardQuantity);
                            isvalid = false;
                        });
                    }
                }

                if (isvalid) {
                    debugger
                    let item: StockBatchSerials = new StockBatchSerials();
                    item.StockBatchOutwardID = (this.generateUUID());
                    item.StockBatchInwardID = this.availableBatches[i].StockBatchInwardID;
                    // item.InwardRecordID = this.availableBatches[i].InwardRecordID;
                    item.BatchNumber = String(this.availableBatches[i].BatchNumber);
                    item.SerialNumber = String(this.serialNumber);
                    item.InwardQuantity = Number(this.availableBatches[i].InwardQuantity)
                    item.OutwardQuantity = Number(this.availableBatches[i].OutwardQuantity);
                    item.SellingPrice = this.availableBatches[i].SellingPrice;
                    item.BalanceQuantity = (Number(this.availableBatches[i].InwardQuantity) - Number(this.availableBatches[i].OutwardQuantity));
                    item.BaseQuantity = Number(this.availableBatches[i].InwardQuantity);
                    item.ExpiryDate = this.availableBatches[i].ExpiryDate;
                    item.ManufactureDate = this.availableBatches[i].ManufactureDate;
                    item.ProductCode = this._productCode;
                    totalQuantity += Number(this.availableBatches[i].OutwardQuantity);
                    item.InwardDate = this.availableBatches[i].InwardDate;
                    item.CostPrice = this.availableBatches[i].CostPrice;
                    item.TotalRemainingBalance = (Number(this.availableBatches[i].TotalRemainingBalance) - Number(this.availableBatches[i].OutwardQuantity));
                    const batches = Object.assign({}, item);
                    if (!this._isSerialRequired)
                        this.model.push(batches);

                    if (isvalid) {
                        this.batchItems.push(batches);
                    }

                    isvalid = false;
                }
            }
        }

        if(this.quantity == totalQuantity){
            $('.outwardbatchserialmodal').hide({ direction: 'right' }, 'slow');
            if (this.saveRecord) {
                this.saveRecord.next(null);
              }
        }
        else{
            if(this.quantity > batchQuantity){
                this.batchItems = [];
                this.toastrService.error(this.translate.transform("3866"));
                $('.outwardbatchserialmodal').hide({ direction: 'right' }, 'slow');
            }
            else{
                this.toastrService.error(this.translate.transform("3867"));
                return false;
            }
        }
    }

    onCancelClick() {
        $('.outwardbatchserialmodal').hide({ direction: 'right' }, 'slow');
    }

    onQuantityChange(item) {
        if(item.StockBatchOutwardID){
            item.TotalRemainingBalance = Number(item.BalanceQuantity);
        }
        if(Number(item.OutwardQuantity) <= 0){
            item.OutwardQuantity = 0;
            this.toastrService.error(this.translate.transform("3108"));
            return false;
        }
        if(Number(item.TotalRemainingBalance) < Number(item.OutwardQuantity)){
            item.OutwardQuantity = 0;
            this.toastrService.error(this.translate.transform("3868"));
            return false;
        }
        
        if(Number(this.quantity) < Number(item.OutwardQuantity)){
            item.OutwardQuantity = 0;
            this.toastrService.error(this.translate.transform("3868"));
            return false;
        }
    }

    generateUUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    datePicker() {
        $('.DateTextBox').off().removeData().datepicker({ dateFormat: 'dd/mm/yy' });
    }
    
    openModel(){
        $('.outwardbatchserialmodal').animate({ width: "toggle" });
        this.stockBatchOutwardID = (this.generateUUID());
        if(this.batchItems.length > 0){
            if(this.availableBatches.length > 0){
                this.availableBatches.forEach(element => {
                    var alreadyExists = this.batchItems.find(x =>x .StockBatchInwardID == element.StockBatchInwardID);
                    if(alreadyExists){
                        this.batchItems.filter(x => x.StockBatchInwardID == alreadyExists.StockBatchInwardID).forEach(batch => {
                            element.OutwardQuantity = Number(batch.OutwardQuantity);
                            element.BalanceQuantity = Number(element.OutwardQuantity);
                        });
                    }
                });
            }
        }

        setTimeout(() => {
            this.datePicker();
        }, 100) 
        // else{
        //     this.batchItems = [];
        // }
    }
}
