import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IAreaRepository } from "./IAreaRepository";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Area } from "src/app/models/Restaurant/Area.model";

@Injectable()
export class AreasRepository extends GenericRepository<Area> implements IAreaRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Areas', environment.urlAddress);
    }

    GetAll(skip: number, next: number): Observable<any> {
        return this._http
            .get(this.url + 'api/Areas/SelectAll' + '/' + skip + '/' + next, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(areaCode: string, cultureCode: string): Observable<any> {
        return this._http
            .get(this.url + 'api/Areas/Select' + '/' + areaCode + '/' + cultureCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(areaCode: string) {
        return this._http.delete(this.url + 'api/Areas/DeleteRec/' + areaCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: Area) {
        return this._http.post(this.url + 'api/Areas/SaveRec', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
