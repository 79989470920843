import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ISalesCreditControlsRepository } from "./ISalesCreditControlsRepository";

@Injectable()
export class SalesCreditControlsRepository extends GenericRepository<any> implements ISalesCreditControlsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/salescreditcontrols/', environment.urlAddress);
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http
            .get(this.url + 'api/salescreditcontrols/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(creditControlID: string) {
        return this.GetById('/LoadRec/' + creditControlID).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetRecByBuyerOrSaleAgent(buyerCode: string, salesAgentCode: string): Observable<any> {

        if (buyerCode == "") {
            buyerCode = "|";
        }
        if (salesAgentCode == "") {
            salesAgentCode = "|";
        }

        return this._http
            .get(this.url + 'api/salescreditcontrols/GetRecByBuyerOrSaleAgent' + '/' + buyerCode + '/' + salesAgentCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(detailID: number) {
        return this.Delete('/DeleteRec/' + detailID).pipe(map(this.extractData), catchError(this.handleError));
    }

}


 
  

