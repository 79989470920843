
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'debitCredit'
})
export class DebitCreditPipe implements PipeTransform {

  transform(value: any): any {
    debugger
    // Check if the value is a number or a string that can be converted to a number
    let sanitizedValue = value.toString().replace(/,/g, '');
    let numericValue = parseFloat(sanitizedValue);

    if (!isNaN(numericValue)) {
      let ValueWithCrDr = '';
      if (numericValue > 0) {
        ValueWithCrDr = `${value} Dr`;
      } else if (numericValue < 0) {
        let positiveString = value.toString().replace(/-/g, '');
        ValueWithCrDr = `${positiveString} Cr`;
      } else { // Zero Case
        return value;
      }
      return ValueWithCrDr;
    }

    // If it's not a number, return the original value unchanged
    return value;
  }
}

