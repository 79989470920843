import { Pipe, PipeTransform } from '@angular/core';
import { findArrayColumnSum } from 'src/app/services/common-methods/common-methods-exports';
import { ValueFormatter } from 'src/app/value-formatters/value-formatter';

@Pipe({
  name: 'ArraySumPipe'
})
export class ArraySumPipe implements PipeTransform {

  constructor(private vf: ValueFormatter) {  }

  transform(value: any[], args?: string): any {
    if(args){
      return this.vf.amountFormat(findArrayColumnSum(value,args))
    }
    return this.vf.amountFormat(0)
  }

}
