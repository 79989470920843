import { Pipe, PipeTransform } from '@angular/core';
import { Globals } from 'src/environments/Globals';
import { TranslateServiceLocal } from 'src/app/services/translateservice/translate.service.local';

@Pipe({
  name: 'translate',
  //pure:false
})
export class TranslatePipe implements PipeTransform {

  constructor(private transService: TranslateServiceLocal, private glb: Globals) { }

  transform(key: string): string {
    return this.transService.getItem(key)
  }
 
  // transform(key: string, filter?: string): string {

  //   if (filter) {
  //     return "{{ " + (this.translate.data[key] || key) + " | " + filter + " }}";
  //   }
  //   else {
  //     if (this.translate.data && this.translate.data.length > 0) {
  //       let newData = this.translate.data.find(x => x.Key == this.glb.cultureCode).Value;          
  //       return newData[key] || key;
  //     }
  //     return key;
  //   }    
  // }



}
