import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";

import { ITravelExpenseRepository } from "./ITravelExpenseRepository";
import { TrainingTravelExpense } from "src/app/models/HR/TravelExpenses/training-travel-expenses";


@Injectable({ providedIn: 'root' })
export class TravelExpenseRepository extends GenericRepository<any> implements ITravelExpenseRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/TravelExpense', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<ResponseModel> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText );
    }

    SaveRec(item: TrainingTravelExpense): Observable<any> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }

    // ApproveExpenses(expenseNumber: string, approvedBy: string): Observable<ResponseModel> {
    //     return this.Get('ApproveExpenses/' + expenseNumber + '/' + approvedBy);
    // }

    // SelectAccountsToPost(item: Array<Expenses>, paymentMode: string, expenseNumbers: string): Observable<ResponseModel> {
    //     return this.PostData('/SelectAccountsToPost/' + paymentMode + '/' + expenseNumbers, item);
    // }

    // PostExpenses(item: Array<any>, postedBy: string, expenseNumbers: string): Observable<ResponseModel> {
    //     return this.PostData('/PostExpenses/' + postedBy + '/' + expenseNumbers, item);
    // }

    
}