import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { UOMGroup } from "src/app/models/UOMGroup/uomgroup.model";
import { IUOMGroupRepository } from "./IUOMGroupRepository";

@Injectable()
export class UOMGroupRepository extends GenericRepository<UOMGroup> implements IUOMGroupRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/UOMGroup', environment.urlAddress);
  }
  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
      .get(this.url + 'api/UOMGroup/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  DeleteRec(UOMGroupCode: string) {
    return this._http.delete(this.url + 'api/UOMGroup/DeleteRec/' + UOMGroupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  SelectGroupUnits(UOMGroupCode: string) {   
    return this._http.get(this.url + 'api/UOMGroup/SelectGroupUnits/' + UOMGroupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  SaveGroupDetails(item: UOMGroup) {    
    return this._http.post(this.url + 'api/UOMGroup/SaveGroupDetails',item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  SaveGroupNames(item: UOMGroup) {    
    return this._http.post(this.url + 'api/UOMGroup/SaveGroupNames',item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

}
