import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BanksModel } from "../../../models/Banking/Banks/banks.model";
import { IBanksRepository } from "./IBanksRepository";

@Injectable(
    { providedIn: 'root' }
)
export class BanksRepository extends GenericRepository<BanksModel> implements IBanksRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/banks', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage)
    }
    DeleteRec(code: string): Observable<any> {
        return this.Delete('DeleteRec/' + code);
    }
    //GetRec(code: string = ""): Observable<any> {
    //    return this.Get('GetRec/' + code);
    //}
}
