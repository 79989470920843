import { Component, AfterViewInit, OnDestroy } from "@angular/core";
declare var $: any;
@Component({
    selector: 'app-layout',
    templateUrl: './ecommerce-auth-layout.html'
}) 
export class EcommerceAuthLayoutComponent implements AfterViewInit, OnDestroy {

    ngOnDestroy(): void {
        $("body").removeClass("ec");
    }

    ngAfterViewInit(): void { 
        $("body").addClass("ec");
    } 
}
