import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { UnitGridPopupComponent } from "./unit-grid-popup.component";

@Injectable({
  providedIn: 'root'
})
export class UnitGridPopupService {

  constructor(private _bsModalService: BsModalService) { }

  public showConfirmationModal(params,code) {    
    const modal = this._bsModalService.show(UnitGridPopupComponent, { class: 'modal-full modal-dialog-centered' });

    (<UnitGridPopupComponent>modal.content).showConfirmationModal(params,code);

    (<UnitGridPopupComponent>modal.content).onClose.subscribe(result => {
      if (result === true) {
        // when pressed Yes
      } else if (result === false) {
        // when pressed No
      } else {
        // When closing the modal without no or yes
      }
    });
  }

}
