import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { ITaxGroupRepository } from "./ITaxGroupRepository";
import { TaxGroup } from "src/app/models/tax-group/tax-group.model";

@Injectable(
    {
        providedIn:'root'
    }
)
export class TaxGroupRepository extends GenericRepository<TaxGroup> implements ITaxGroupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/TaxGroup', environment.urlAddress);
    }    

    GetAllRec(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }

    DeleteRec(code: string) {
        return this.Delete('/DeleteRec/' + code);
    }

    SaveRec(item: TaxGroup) {
        return this.PostData('/SaveRec', item);
    }

    LoadRec(code: string): Observable<any> {
        return this.GetById('/LoadRec/' + code);
    }

    LoadNavRec(navType: number, currentIndex: number): Observable<any> {
        return this.GetById('/LoadNavRec/' + navType + '/' + currentIndex);
    }
    
    GetTaxCalculationMethods(taxItemOrGlobal?: number, taxType?: number): Observable<any> {
        return this.GetById('/GetTaxCalculationMethods/' + taxItemOrGlobal + '/' + taxType);
    }

    GetAllTaxes(taxLevel?:number): Observable<any> {
        return this.Get('/SelectAllTaxGroups/' + taxLevel)
    }

    TaxGroupSearch(taxLevel:number=null): Observable<any> {
        return this.Get('/TaxGroupSearch/' + taxLevel)
    }
}
