import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Counters } from "../../models/Counters/counters.model";
import { ICountersRepository } from "./ICountersRepository";

@Injectable()
export class CountersRepository extends GenericRepository<Counters> implements ICountersRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Counters/', environment.urlAddress);
    }
    
    GetAllRec(): Observable<any> {
        return this._http
            .get(this.url + 'api/Counters/GetAllRec', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));        
    }
    LoadRec(CounterCode: string): Observable<any> {
        return this.GetById('/GetRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/Counters/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(CounterCode: string) {
        return this.Delete('/DeleteRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    CounterReset(CounterCode: string) {
        return this._http.get(this.url + 'api/Counters/CounterReset' + '/' + CounterCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LogoutForcefully(counter: Counters): Observable<any> {
        return this.PostData('/LogoutForcefully', counter).pipe(map(this.extractData), catchError(this.handleError));
    }
    
}