import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentSearchViewModel } from "src/app/models/common/document-search-model";
import { IAutoPurchaseOrderRepository } from "./IAutoPurchaseOrderRepository";
import { AutoPurchaseOrder } from "src/app/models/auto-purchase-order/auto-purchase-order.model";
@Injectable()
export class AutoPurchaseOrderRepository extends GenericRepository<AutoPurchaseOrder> implements IAutoPurchaseOrderRepository {


    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/autopurchaseorder', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/autopurchaseorder/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllForDashBoard (currentPage: number, recordsPerPage: number, godownCode: string ='', vendorCode:string='', DateFrom:string='',DateTo:string='', statusId: number): Observable<any>{
        godownCode = godownCode!='' ? godownCode : 'null';
        vendorCode = vendorCode!='' ? vendorCode : 'null';
        return this._http
            .get(this.url + 'api/autopurchaseorder/SelectAllForDashBoard' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode + '/' + DateFrom + '/' + DateTo+ '/' + vendorCode + '/' + statusId, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/autopurchaseorder/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PurchaseOrder: string) {
        return this.Delete('/DeleteRec/' + PurchaseOrder).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchPurchaseOrder(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/autopurchaseorder/SearchForPurchaseInvoice', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SearchAllPurchaseOrder(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/autopurchaseorder/SearchForPurchaseInvoice/1', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessPurchaseOrder(autoOrderNumber: string): Observable<any> {
        return this.GetById('/ProcessPurchaseOrder/' + autoOrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    } 

    SearchOrderNumber(SearchOrder: any): Observable<any> {
        return this._http.post(this.url + 'api/autopurchaseorder/SearchOrderNumber', SearchOrder, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    CreateAutoProjection(goDownCode: string, vendorCode: string): Observable<any> {
        return this._http.post(this.url + 'api/autopurchaseorder/CreateAutoProjection' + '/' + goDownCode + '/' + vendorCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}