import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IExpensePostingSetupRepository } from "./IExpensePostingSetupRepository";
import { ExpensePostingSetup } from "src/app/models/Finance/Expenses/expense-setup.model";


@Injectable({providedIn:'root'})

export class ExpensePostingSetupRepository extends GenericRepository<any> implements IExpensePostingSetupRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/ExpenseSetup', environment.urlAddress);
    } 

    Select(accountType: number): Observable<ResponseModel> {
        return this.Get('Select/' + accountType);
    }
    
    SaveRec(item: Array<ExpensePostingSetup>): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }



    // GetRec(code: string): Observable<ResponseModel> {
    //     return this.Get('GetRec/' + code);
    // }


    // SaveRec(item: Expenses): Observable<ResponseModel> {
    //     return this.PostData('/SaveRec/', item);
    // }
   
}