export class EmployeeTasks {
    RowNum: number;
    IsAutoNumbering: boolean;
    IsInserting: boolean;
    TaskCode: number;
    EmployeeCode: string;
    Task: any;
    StartDate: any;
    EndDate: any;
    TaskType: string;
    TaskTypeName: string;
    StatusName: string;
    BuyerName: string;
    BuyerCode: string;
    ProjectCode: string;
    Phone: string;
    ProjectName: string;
    Status: number;
    TaskStatusCode: string;
    Notes: any;
    Action: string;
    RowIndex: number;
}
