import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { VoucherModel } from "src/app/models/Vouchers/voucher.model";
import { IVoucherRepository } from "./IVoucherRepository";

@Injectable(
    {
        providedIn: "root"
    }
)

export class VoucherRepository extends GenericRepository<VoucherModel> implements IVoucherRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Voucher/', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string, voucherMode: number): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/Voucher/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText + '/' + voucherMode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadNavRec(navType: number, currentIndex: number, voucherMode: number): Observable<any> {
        return this.Get("NavRec/" + navType + "/" + currentIndex + "/" + voucherMode + "/");
    }

    LoadRec(vendorCode: string, voucherMode: number): Observable<any> {
        return this.Get("LoadRec/" + vendorCode + "/" + voucherMode);
    }

    SaveRec(voucherObj: VoucherModel): Observable<any> {
        return this.PostData("SaveRec/", voucherObj);
    }

    DeleteRec(voucherNumber: string, voucherMode: number, ): Observable<any> {
        return this.Delete("DeleteRec/" + voucherNumber + "/" + voucherMode);
    }
}