import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})

export class HeaderComponent implements OnInit, AfterViewInit {

    constructor() { } 

    ngAfterViewInit() {
        $(document.body).on('click', '.dropdown-menu li', function (event) {

        var $target = $(event.currentTarget);

        $target.closest('.btn-group')
            .find('[data-bind="label"]').text($target.text())
            .end()
            .children('.dropdown-toggle').dropdown('toggle');

        return false;  

        });
    }

    ngOnInit() {
    }
}
