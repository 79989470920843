import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IBuyerSMSRepository } from "./IBuyerSMSRepository";
import { BuyerSMS } from "../../../models/BuyerSMS/buyer-sms.model";
import { BuyerSMSSetup } from "../../../models/BuyerSMSSetup/buyer-smssetup.model";


@Injectable()
export class BuyerSMSRepository extends GenericRepository<BuyerSMS> implements IBuyerSMSRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/BuyerSMS', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number, smsType: number, filterText: string, searchNo: string, searchDate: string): Observable<any> {
        searchDate = searchDate.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + smsType + '/' + filterText + '/' + (searchNo ? searchNo : null) + '/' + (searchDate ? searchDate : null))
    }
    SaveRec(item: BuyerSMS): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveSetup(item: BuyerSMSSetup): Observable<ResponseModel> {
        return this._http.post(this.url + 'api/BuyerSMS/SaveSetup', item, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadSetupRec(): Observable<ResponseModel> {
        return this.GetById('/GetSetupRec').pipe(map(this.extractData), catchError(this.handleError));
    }
}