﻿/*
 * added by Yameen 05-01-2020
 * For  account cell
 */
import { Component, ViewContainerRef, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

declare var $: any;
@Component({
    selector: 'account-code-cell ',
    //encapsulation: ViewEncapsulation.None,
    //host: {
     //   style: `position: relative;
					//left: 0px; 
					//top: 0px;
					//background-color: transparant;
					//background: white;
					//` },
    template: `
<input #account type="text" container="body" autocomplete="off" [(ngModel)]="value" placeholder="Account Code"
                                           [typeahead]="accountData"
                                           typeaheadOptionField="AccountName"
                                           [typeaheadMinLength]="0"
                                           [typeaheadScrollable]="true"
                                           [typeaheadOptionsInScrollableView]="10"
                                           (typeaheadOnSelect)="onAccountChange($event.item.AccountCode)"/>

`,

})
export class AccountCodeEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;
    public accountData:any;
    @ViewChild('account', { read: ViewContainerRef }) public input;
    
    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        if (params.colDef.cellEditorParams.accountData && params.colDef.cellEditorParams.accountData.length > 0)
            this.accountData = params.colDef.cellEditorParams.accountData;
        else
            this.accountData = [];
        this.input.element.nativeElement.className = "ag-input-text-wrapper ag-cell-edit-input";
       
        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart = false;      
        $('.ag-row').removeClass('ag-row-account');
        $("[row-index=" + params.rowIndex + "]").addClass('ag-row-account')
    }
    getValue(): any {
        $('.ag-row').removeClass('ag-row-account');
        return this.value;
    }
    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }
    isCancelAfterEnd(): boolean { // will reject the number if spicfy the condition
        //return this.value > 1000000;
        return false;
    };
    ngAfterViewInit() {// dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
         // this.input.element.nativeElement.focus();
    }
    focusIn(): void {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement
    }
    onAccountChange(value) {
       
        this.input.element.nativeElement.value = value;
        this.value = value;
        this.input.element.nativeElement.select();
        
    }
    focusOut(): void {
       
    }
    isPopup(): boolean {
        return false;
    }
}