import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StringsDeliveryType } from "../../models/StringsDeliveryType/strings-delivery-type.model";
import { IPOSDashboardRepository } from "./IPOSDashboardRepository";
import { CurrencyNotes } from "../../models/CurrencyNotes/currency-notes.model";
import { DeviceSettings } from "../../models/DeviceSettings/device-settings.model";
import { CustomerDisplaySetup } from "../../models/DeviceSettings/customer-display-setup.model";
import { CashDrawer } from "../../models/CashDrawer/cash-drawer.model";
import { PaidInOut } from "../../models/PaidInOut/paid-in-out.model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class POSDashboardRepository extends GenericRepository<any> implements IPOSDashboardRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/POSDashboard', environment.urlAddress);
    }
 
    GetAllDeliveryTypes(): Observable<any> {
        return this._http
            .get(this.url + 'api/POSDashboard/SelectAllDeliveryTypes', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAllCashDrawer(): Observable<any> {
        return this._http
            .get(this.url + 'api/POSDashboard/GetAllCashDrawer', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    

    GetAllInitialSettings(): Observable<any> {
        return this._http
            .get(this.url + 'api/POSDashboard/GetAllInitialSettings', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveDeliveryTypeRec(item: StringsDeliveryType): Observable<any> {
        return this.PostData('/SaveDeliveryTypeRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteDeliveryTypeRec(ID: number) {
        return this._http.delete(this.url + 'api/POSDashboard/DeleteDeliveryTypeRec/' + ID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCurrencyNoteRec(item: CurrencyNotes): Observable<any> {
        return this.PostData('/SaveCurrencyNoteRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteCurrencyNoteRec(ID: number) {
        return this._http.delete(this.url + 'api/POSDashboard/DeleteCurrencyNoteRec/' + ID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveDeviceSettingsRec(item: DeviceSettings): Observable<any> {
        return this.PostData('/SaveDeviceSettingsRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCustomerDisplaySetupRec(item: CustomerDisplaySetup): Observable<any> {
        return this.PostData('/SaveCustomerDisplaySetupRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteCustomerDisplaySetupRec(ID: number) {
        return this._http.delete(this.url + 'api/POSDashboard/DeleteCustomerDisplaySetupRec/' + ID, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveCashDrawerRec(item: CashDrawer): Observable<any> {
        return this.PostData('/SaveCashDrawerRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    SavePaidInOutRec(item: PaidInOut): Observable<any> {
        return this.PostData('/SavePaidInOutRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
}