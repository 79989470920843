import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { ITaskTypesRepository } from "./ITaskTypesRepository";
import { environment } from "../../../../environments/environment";
import { TaskTypes } from "../../../models/HR/TaskTypes/task-types";

@Injectable()
export class TaskTypesRepository extends GenericRepository<TaskTypes> implements ITaskTypesRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/TaskTypes', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: TaskTypes): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}