﻿/*
 * added by Yameen 16-11-2019
 * --==========Format display style for reports=============
 * [f-bold] 100.000000
 *  --=======================
 */
import { Pipe, PipeTransform, ElementRef } from '@angular/core';
declare var $: any;
@Pipe({
    name: 'styleFormat'
})
export class StyleFormatPipe implements PipeTransform {

    transform(input: any, elem: any = null): any {
        
        if (!input || !elem) return "";

        if (input.indexOf("[f-bold]") !== -1) {
            input = input.replace("[f-bold]", ""); // remove tag;
            $(`#${elem}`).css("font-weight", "bold");
        }

        if (input.indexOf("[f-lbHeading]") !== -1) {
            input = input.replace("[f-lbHeading]", ""); // remove tag;
            $(`#${elem}`).css("background-color", "#f2f1f2");
        }

        if (input.indexOf("[f-lbSuccess]") !== -1) {
            input = input.replace("[f-lbSuccess]", ""); // remove tag;
            $(`#${elem}`).html('');
            $(`#${elem}`).append("<span class='badge badge-success ml-1 arml-1'>" + input + '</span>');
        }
        else if (input.indexOf("[f-lbWarning]") !== -1) {
            input = input.replace("[f-lbWarning]", ""); // remove tag;
            $(`#${elem}`).html('');
            $(`#${elem}`).append("<span class='badge badge-primary ml-1 arml-1'>" + input + '</span>');
        }
        else if (input.indexOf("[f-lbDanger]") !== -1) {
            input = input.replace("[f-lbDanger]", ""); // remove tag;
            $(`#${elem}`).html('');
            $(`#${elem}`).append("<span class='badge badge-danger ml-1 arml-1'>" + input + '</span>');
        }
        else if (input.indexOf("[fc-") !== -1) {
            input = input.replace("[fc-", ""); // remove tag;
            input = input.replace("]", ""); // remove tag;
            var string = input.split("-", 2);
            input = string[1];
            $(`.${elem}`).css("background-color", string[0]);
        }

        return input;

    }
}
