import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISaleInvoiceRepository } from "./ISaleInvoiceRepository";
import { SaleInvoice } from "../../models/SaleInvoice/sale-invoice.model";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { TransactionPosting } from "../../models/common/getjsonfrom-controls-service-model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class SaleInvoiceRepository extends GenericRepository<SaleInvoice> implements ISaleInvoiceRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/saleinvoice', environment.urlAddress);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {        
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/SaleInvoice/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessInvoice(code: string, fiscalSpanID: string): Observable<any> {
        return this.GetById('/ProcessInvoice/' + code + '/' + fiscalSpanID).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessInvoiceSearch(invoiceSearchObj: any): Observable<any> {
        return this._http.post(this.url + 'api/SaleInvoice/SaleInvoiceSearch', invoiceSearchObj, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    //SelectPostingSetup(postingSetup: any, godownCode: string, paymentType: number, cardChargeType: string, vendorCode: string): Observable<ResponseModel> {
    //    return this.PostData('/SelectPostingSetup' + '/' + godownCode + '/' + paymentType + '/' + cardChargeType + '/' + vendorCode, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    //}
    GetLastSalePrice(productCode: string, productType: number, buyerCode: string): Observable<ResponseModel> {
        return this.GetById('/GetLastSalePrice' + '/' + productCode + '/' + productType + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(): Observable<ResponseModel> {
        return this.PostData('/SaveRec', null).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectSaleData(item: SaleInvoice): Observable<ResponseModel> {
        return this.PostData('/CollectSaleData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectPostingData(item: any): Observable<ResponseModel> {
        return this.PostData('/CollectPostingData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingAccountSetup(isAccountLinked: number): Observable<ResponseModel> {
        return this.GetById('/SelectPostingAccountSetup' + '/' + isAccountLinked).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerial(inwardDocument: string, godownCode: string, inwardRecordID: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerial/' + inwardDocument + '/' + godownCode + '/' + inwardRecordID).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerialByProduct(inwardDocument: string, productCode: string, godownCode: string, inwardRecordID: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerialByProduct/' + inwardDocument + '/' + productCode + '/' + godownCode + '/' + inwardRecordID).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectBuyerInvoices(currentPage: number, recordsPerPage: number, buyerCode: string): Observable<any> {
        return this.GetById('SelectBuyerInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectGodownInvoices(currentPage: number, recordsPerPage: number, godownCode: string, DateFrom:string='',DateTo:string=''): Observable<any> {
        godownCode = godownCode!='' ? godownCode : 'null';
        return this.GetById('SelectGodownInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode+ '/' + DateFrom + '/' + DateTo).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetQRCode(model: any): Observable<any> {
        return this.PostData('/GetQRCode/',model);
    }
}