export class TaskRating {
    TaskCode: number;
    RatingID: number;
    Rating: number;
    Email: string;
    Comment: string;
    DomainName: string;
    ConnectionString: string;
    FiscalSpanID: number;
    Task:string;
    Notes:string;
}
