import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { PriceSetup } from "src/app/models/PriceSetup/pricesetup.model";
import { IPriceSetupRepository } from "./IPriceSetupRepository";

@Injectable()
export class PriceSetupRepository extends GenericRepository<PriceSetup> implements IPriceSetupRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/PriceSetup', environment.urlAddress);
  }

  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
      .get(this.url + 'api/PriceSetup/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  DeleteRec(PriceSetupCode: string) {
    return this._http.delete(this.url + 'api/PriceSetup/DeleteRec/' + PriceSetupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  SavePriceNames(item: PriceSetup) {    
    return this._http.post(this.url + 'api/PriceSetup/SavePriceNames',item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }

  GetAllForDD(): Observable<any> {
    return this._http
      .get(this.url + 'api/PriceSetup/GetAllForDD', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  
}
