import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { Globals } from '../../../environments/Globals';
import { CommonConstant } from '../../shared/constent/applicationcodes';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader/LoaderService';
import { AuthService } from '../../views/authentication/authentication-helpers/auth.service';
import { Subscription, timer, interval } from 'rxjs';
import { HostListener } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-layout',
  templateUrl: './admin.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  UserName: string;
  RoleName: string;
  allowedMenues: any[]
  public scrollbarOptions = { axis: 'yx', theme: 'minimal' };
  const = new CommonConstant();

  public sidebarMinimized = true;
  // private changes: MutationObserver;
  public element: HTMLElement;
  loggedInCompanyID: number;
  commonConst = new CommonConstant();

  countDown: Subscription;
  timeCounter = 30;
  tick = 1000;

  showServices: boolean = false;
  constructor(private as: AuthService, private glb: Globals, public notify: ToastrService, public loader: LoaderService) {

    if (window.location.origin.includes('demo.finexcloud.com'))
      this.showServices = true;


    this.loggedInCompanyID = this.glb.getDataFromLocalStorage(this.commonConst.LoggedInCompanyID);

    let menu = glb.getDataFromLocalStorage(this.const.AllowedMenus)
    if (menu) {
      menu = JSON.parse(menu);
      this.allowedMenues = this.parseFlatArrayToTreeArray(menu);


      // console.log(this.allowedMenues);

      setTimeout(() => {
        // Mobile Menu
        $(document).find('.mobile-menu-trigger').click(function () {
          $('.menu-and-user').toggleClass('active_menu');
        });

        $(document).find('.menuoverlay').click(function () {
          $('.menu-and-user').removeClass('active_menu');
        });

        $(document).find('.menuclose a').click(function () {
          $('.menu-and-user').removeClass('active_menu');
        });

        $(document).find('.has-sub-menu>a').click(function () {
          $(this).parent().siblings().removeClass('active');
          $(this).parent().toggleClass('active');
        });

        $(document).find('.has-sub-menu .sub-menu li a').click(function () {
          $(this).parents('.has-sub-menu ').removeClass('active');
        });

        $(document).find('.has-sub-menu .closemenu').click(function () {
          // console.log('close button clicked');
          $(this).parents('.has-sub-menu ').removeClass('active');
        });

        $(document).find('.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a').click(
          function () {
            $('.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a')
              .removeClass('lNavSelected');
            $(this).addClass('lNavSelected');
          });

      },
        100);

    }
    else {
      this.notify.error("No menus found. <br/> please login again.");
      this.logout();
    }

  }



  ngAfterViewInit(): void {

    //$("select.cLang").change(function () {
    //  var value = $(this).children("option:selected").val();
    //  $('html').attr('dir', value)
    //});
    //$("select.cLang").change();    

    $('.os-dropdown-trigger').mouseover(function () {
      $(this).addClass('over');
    });
    $('.os-dropdown-trigger').mouseleave(function () {
      $(this).removeClass('over');
    });

    $('[type="checkbox"]').focus(function () {
      $(this).closest('.chk').css('box-shadow', '0 0 1px 1px #047bf8');
    });
    $('[type="checkbox"]').blur(function () {
      $(this).closest('.chk').css('box-shadow', 'none');
    });
    $('[type="checkbox"]').click(function () {
      $(this).closest('.chk').css('box-shadow', 'none');
    });


    // menu close
    $(".closemenu").click(function () {
      $(this).parent().parent().parent().removeClass('active');
    });

    // Apps Navigation
    $('.openAppNav,.closeAppNav').click(function () {
      $('.apps-navigation').fadeToggle();
    });
    $(document).on('keyup', function (evt) {
      if (evt.keyCode == 27) {
        $('.apps-navigation').fadeOut();
      }
    });

    // Table Add Row
    $('.addTargetBtn2').click(function () {
      var willAdded = true;
      var counter = 0;
      $("#tblBody input").each(function () {
        if ($(this).val() == "") {
          alert("Please enter " + $(this).data("jsname"));
          $(this).focus();
          willAdded = false;
          return false;
        }
        counter = parseInt($(this).data("index"));
      });

      if (willAdded) {
        counter++;
        if (counter == 1) {
          $('.wStripped').append('<tbody id="tblBody"><tr><td class="text-center">' + counter + ' <input type="hidden" id="SalesAgentTargetID" value="' + counter + '"></td><td><div class="input-group"><input type="text" class="form-control AmountTextBox Numeric Textbox_EntryReset" placeholder="Sales Target" id="SalesTarget" data-jsname="Sales Target" data-index="' + counter + '"></div></td><td><div class="input-group"><input type="text" class="form-control DecimalTextBox" id="SalesCommission" data-jsname="Sales Commission" placeholder="Sales Commission" data-index="' + counter + '"></div></td><td class="row-actions"><button class="danger closeTarget rowDel" disabled><i class="os-icon os-icon-ui-15 fs20"></i></button></td></tr></tbody>');
        }
        else {
          $('.wStripped tbody').append('<tr><td class="text-center">' + counter + ' <input type="hidden" id="SalesAgentTargetID" value="' + counter + '"></td><td><div class="input-group"><input type="text" class="form-control AmountTextBox Numeric Textbox_EntryReset" placeholder="Sales Target" id="SalesTarget" data-jsname="Sales Target" data-index="' + counter + '"></div></td><td><div class="input-group"><input type="text" class="form-control DecimalTextBox" id="SalesCommission" data-jsname="Sales Commission" placeholder="Sales Commission" data-index="' + counter + '"></div></td><td class="row-actions"><button class="danger closeTarget rowDel"><i class="os-icon os-icon-ui-15 fs20"></i></button></td></tr>');
        }
      }

      $('.closeTarget').click(function () {
        $(this).closest('tr').remove();
      });
    });
    $('.closeTarget').click(function () {
      $(this).closest('tr').remove();
    });
  }

  sub: any;
  ngOnInit(): void {

    this.UserName = localStorage.getItem('userName') || 'User Name';
    this.RoleName = localStorage.getItem('roleName') || 'Role Name';
    this.glb.resetSessionTimeOut();

    this.sub = interval(30000)
      .subscribe((val) => {
        let time = this.glb.getSessionTime();        
        //console.log( 'browser session time:' + time)
        let serverTime = this.glb.getServerSessionTime();

        //console.log('server session time:' + serverTime)

        if (time < 60) {

          this.showSessionTime();
        }

        if (serverTime <= 30) {          
          this.as.resetServerSessionTime();
          this.glb.resetServerSessionTimeOut()
        }

      });

  }

  parseFlatArrayToTreeArray(list: any): any[] {

    let treeArray: any[] = [];

    treeArray = list.filter(x => x.PageLevel == 0 && x.IsParent == 1);

    treeArray.forEach((element) => {
      element.Visible = false;
      element.Child = list.filter(x => x.ParentID === element.PageID && x.PageLevel == 1)

      element.Child.forEach((childElement) => {
        childElement.Child = list.filter(x => x.ParentID === childElement.PageID && x.PageLevel == 2);
        if (childElement.Child && childElement.Child.length > 0)
          element.Visible = true;
      });
    });

    return treeArray;
  }

  showSessionTime() {

    var id = '#dialog';

    //Get the screen height and width
    var maskHeight = $(document).height();
    var maskWidth = $(window).width();

    //Set heigth and width to mask to fill up the whole screen
    $('#mask').css({ 'width': maskWidth, 'height': maskHeight });

    //transition effect 
    $('#mask').fadeIn(500);
    $('#mask').fadeTo("slow", 0.9);

    //var winH = $(window).height();
    //var winW = $(window).width();

    //$(id).css('top', winH / 2 - $(id).height() / 2);
    //$(id).css('left', winW / 2 - $(id).width() / 2);

    $(id).fadeIn(2000);
    this.timeCounter = 30;
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.timeCounter
      if (this.timeCounter == 0) {
        this.clearSessionTime();
      }
      return this.timeCounter;
    });
  }

  resetSessionTime() {

    if (this.countDown)
      this.countDown.unsubscribe();

    this.as.resetServerSessionTime();

    this.glb.resetSessionTimeOut();

    $('#mask').hide();
    $('.window').hide();
  }

  clearSessionTime() {
    $('#mask').hide();
    $('.window').hide();

    if (this.countDown)
      {
        this.countDown.closed = true        
        this.countDown.unsubscribe();
      }

    if (this.sub)
      this.sub.unsubscribe();

    // this.logout();
  }

  logout() {
    this.as.logout();
  }
  ngOnDestroy(): void {
    if (this.countDown)
      this.countDown.unsubscribe();

    if (this.sub)
      this.sub.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardKeyDownEvent(event: KeyboardEvent) {
    this.glb.resetSessionTimeOut();
  }

  @HostListener('document:click', ['$event'])
  clicked() {
    this.glb.resetSessionTimeOut();
  }

}
