import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { Vendor, VendorImport } from "../../models/Vendor/vendor.model";
import { IVendorRepository } from "./IVendorRepository";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/SharedModels/response.model";
@Injectable()
export class VendorRepository extends GenericRepository<Vendor> implements IVendorRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {

        super(_http, 'api/vendor', environment.urlAddress);
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/vendor/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetVendorAddress(vendorCode: string, addressType: string) {
        return this._http.get(this.url + 'api/vendor/VendorAddress' + '/' + vendorCode + '/' + addressType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(vendorCode: string, isAddress: boolean, isName: boolean, isGraph: boolean=false): Observable<any> {
        return this.GetById('/GetRec/' + vendorCode + '/' + isAddress + '/' + isName + '/' + isGraph).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(salesAgentCode: string) {
        return this.Delete('/DeleteRec/' + salesAgentCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    FilterRecords(rowNumber: string, pageSize: string, navType: string, vendorCode: string): Observable<ResponseModel> {
        return this.GetById('/FilterRecords/' + rowNumber + '/' + pageSize + '/' + navType + '/' + vendorCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAllVendorCodes(): Observable<any> {
        return this._http.get('api/vendor/GetAllVendorCodes', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveVendorsImportRec(ImportVendorsSaveData: Array<VendorImport>): Observable<any> {
        return this._http.post('api/vendor/SaveVendorsImportRec', ImportVendorsSaveData, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('/SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));;
    }
}
