import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IDeductionCategoryRepository } from "./IDeductionCategoryRepository";
import { DeductionCategory } from "src/app/models/HR/DeductionCategory/deduction-category";


@Injectable({ providedIn: 'root' })
export class DeductionCategoryRepository extends GenericRepository<any> implements IDeductionCategoryRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/DeductionCategory', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<ResponseModel> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText );
    }

    SaveRec(item: DeductionCategory): Observable<any> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }
    
}