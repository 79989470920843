import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

// import { AuthGuard } from './services/authguard/AuthGuard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AgGridModule } from 'ag-grid-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// User Defined
//Place All Modules here
import { AppRoutingModule } from './app-routing.module';
// Place All Components here
import { AppComponent } from './app.component';
import { P404Component } from './views/error/404.component';
import { POSTailorLayoutComponent } from './layouts/postailor/postailor-layout.component';
// Place All Services Here
import { TranslateService } from './services/translateservice/translate.service';
import { BusinessServices } from './services/singleton/business-services';
import { SmartAppUtilities } from './models/common/getjsonfrom-controls-service-model';
import { ImplementOperationToolbar } from './services/btnservice/toptoolbar-implementation';
import { ConfirmationDialogServices } from './shared/confirmation-dialog/confirmation-dialog.service';
import { FinanceBusinessService } from './services/finance/finance-business.service';

// Place All Repositories here
import { CommonRepository } from './repositories/common-repository/CommonRepository';
import { LoginRepository } from './repositories/login-repository/LoginRepository';
import { BuyerRepository } from './repositories/buyer-repository/BuyerRepository';
import { CollectionAgentRepository } from './repositories/collection-agent-repository/CollectionAgent';
import { PriceSetupRepository } from './repositories/price-setup-repository/PriceSetupRepository';
import { SalesAgentRepository } from './repositories/sales-agent-repository/salesAgent';
import { VendorRepository } from './repositories/vendor-repository/VendorRepository';
import { UnitOfMeasurementRepository } from './repositories/unit-of-measurement-repository/UnitOfMeasurementRepository';
import { UOMGroupRepository } from './repositories/unit-of-measurement-group-repository/UOMGroupRepository';
import { BuyerGroupSetupRepository } from './repositories/buyer-group-setup-repository/BuyerGroupSetupRepository';
import { SalesRegionRepository } from './repositories/sales-region-repository/SalesRegionRepository';
import { ProductGroupDiscountSetupRepository } from './repositories/product-group-discount-setup-repository/ProductGroupDiscountSetupRepository';
import { TaxSetupRepository } from './repositories/tax-setup-repository/TaxSetupRepository';
import { TaxRateMasterRepository } from './repositories/tax-rate-master-repository/TaxRateMasterRepository';
import { ProcurementCodeSetupRepository } from './repositories/procurement-code-setup-repository/ProcurementCodeSetupRepository';
import { ProcurementPostingSetupRepository } from './repositories/procurement-posting-setup-repository/ProcurementPostingSetupRepository';
import { GodownCodeSetupRepository } from './repositories/godown-code-setup-repository/GodownCodeSetupRepository';
import { GlobalSetupsRepository } from './repositories/global-setups-repository/GlobalSetupsRepository';
import { SalesCreditControlsRepository } from './repositories/sales-credit-controls-repository/SalesCreditControlsRepository';
import { StockAdjustmentRepository } from './repositories/stock-adjustment-repository/StockAdjustmentRepository';
import { CountersRepository } from './repositories/counters-repository/CountersRepository';
import { UsersRepository } from './repositories/users-repository/UsersRepository';
import { ProcurementReportRepository } from './repositories/report-setup-repository/ProcurementReportRepository';
import { PrinterGroupRepository } from './repositories/printer-group-repository/PrinterGroupRepository';
import { ProcurementPrintReportRepository } from './repositories/print-report-repository/ProcurementPrintReportRepository';
import { ReceiptSetupRepository } from './repositories/receipt-setup/ReceiptSetupRepository';
import { PurchasePostingSetupRepository } from './repositories/purchase-posting-setup-repository/PurchasePostingSetup';
import { StartingNumberSetupRepository } from './repositories/starting-number-setup-repository/starting-number-setup-repository';
import { SalesPostingSetupRepository } from './repositories/sales-posting-setup-repository/SalesPostingSetup';
import { GodownRepository } from './repositories/godown-repository/GodownRepository';
import { ProductCardMasterCategoryRepositoryService } from './repositories/product-card-master-repository/Product-Card-Master-Category';
import { PurchaseOrderRepository } from './repositories/purchase-order-repository/PurchaseOrderRepository';
import { SaleOrderRepository } from './repositories/sale-order-repository/SaleOrderRepository';
import { PurchaseReturnRepository } from './repositories/purchase-return-repository/PurchaseReturnRepository';
import { CompanyProfileRepository } from './repositories/company-profile-repository/CompanyProfileRepository';
import { SaleReturnRepository } from './repositories/sale-Return-repository/SaleReturnRepository';
import { SaleCollectionsRepository } from './repositories/sale-collections-repository/SaleCollectionsRepository';
import { StockInternalUseRepository } from './repositories/stock-internal-use/StockInternalUseRepository';
import { StockDamageRepository } from './repositories/stock-damage/StockDamageRepository';
import { ProductGroupsRepository } from './repositories/product-groups-repository/ProductGroupsRepository';
import { WeighScaleSetupRepository } from './repositories/weigh-scale-setup-repository/WeighScaleSetupRepository';
import { AccountCodeSetupRepository } from './repositories/finance/account-code-setup-repository/AccountCodeSetupRepository';
import { AccountMasterRepository } from './repositories/finance/account-master-repository/AccountMasterRepository';
import { AccountMasterBalanceRepository } from './repositories/finance/account-master-balance-repository/AccountMasterBalanceRepository';
import { AccountBookSetupRepository } from './repositories/finance/account-book-setup-repository/AccountBookSetupRepository';
import { ReceiptsRepository } from './repositories/finance/receipts-repository/ReceiptsRepository';
import { PaymentsRepository } from './repositories/finance/payments-repository/PaymentsRepository';
import { DebitNotesRepository } from './repositories/finance/debit-notes-repository/DebitNotesRepository';
import { CreditNotesRepository } from './repositories/finance/credit-notes-repository/CreditNotesRepository';
import { JournalVoucherRepository } from './repositories/finance/journal-voucher-repository/JournalVoucherRepository';
import { TransactionViewerRepository } from './repositories/finance/transaction-viewer-repository/TransactionViewerRepository';
import { ChestStyleRepository } from './repositories/chest-style-repository/ChestStyleRepository';
import { CollarStyleRepository } from './repositories/collar-style-repository/CollarStyleRepository';
import { HandStyleRepository } from './repositories/hand-style-repository/HandStyleRepository';
import { PocketStyleRepository } from './repositories/pocket-style-repository/PocketStyleRepository';
import { ShirtStyleRepository } from './repositories/shirt-style-repository/ShirtStyleRepository';
import { TailorTypeRepository } from './repositories/tailor-type-repository/TailorTypeRepository';
import { ProductCompanyRepository } from './repositories/product-company-repository/ProductCompanyRepository';


//Global Variables
import { Globals } from '../environments/Globals';
import { TopToolBarOptions } from './services/btnservice/toptoolbar-options';
import { TranslatePipe } from './shared/custom-pipes/translate-pipe/translate.pipe';
import { ConfirmationModalComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { MultiTablesNamesServices } from './shared/multi-tables-names/multi-tables-names.service';
import { MultiTablesNamesModalComponent } from './shared/multi-tables-names/multi-tables-names.component';
import { UnitGridPopupService } from './shared/unit-grid-popup/unit-grid-popup.service';
import { UnitGridPopupComponent } from './shared/unit-grid-popup/unit-grid-popup.component';
import { AgGridCheckboxCellComponent } from './shared/ag-grid-checkbox-cell/ag-grid-checkbox-cell.component';
import { AgGridDropdownComponent } from './shared/ag-grid-dropdown/ag-grid-dropdown.component';
import { AddNewButtonSalesTargetComponent } from './shared/add-new-button-sales-target/add-new-button-sales-target.component';
import { GridDeleteButtonComponent } from './shared/grid-delete-button/grid-delete-button.component';
import { TablesNamesRenderer } from './shared/ag-grid-names-popup/agGrid-names-renderer';
import { AgGridNamesAddComponent } from './shared/ag-grid-names-add/ag-grid-names-add.component';
import { GridSaveButtonComponent } from './shared/grid-save-button/grid-save-button.component';
import { AgGridUnitsPopupComponent } from './shared/ag-grid-units-popup/ag-grid-units-popup.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AutoCompleteComponent } from './shared/smartapp-angular';
import { AgGridCheckboxComponent } from './shared/ag-grid-checkbox/ag-grid-checkbox.component';
import { AmountEditorComponent } from './cell-editor-components/amount-editor-component';
import { CurrencyEditorComponent } from './cell-editor-components/currency-editor-component';
import { IntegerEditorComponent } from './cell-editor-components/integer-editor-component';
import { NumericEditorComponent } from './cell-editor-components/numeric-editor-component';
import { PriceEditorComponent } from './cell-editor-components/price-editor-component';
import { QuantityEditorComponent } from './cell-editor-components/quantity-editor-component';
import { ValueFormatter } from './value-formatters/value-formatter';
import { SanitizeValueFormatter } from './value-formatters/sanitize-value-formatter';
import { AgGridButtonRendererComponent } from './shared/ag-grid-button-renderer/button-renderer.component';
import { AgGridUserActionButtonsComponent } from './shared/ag-grid-user-action-buttons/ag-grid-user-action-buttons.component';
import { ProductQuantityInGodownsComponent } from './shared/product-quantity-in-godowns/product-quantity-in-godowns.component';
import { AccountCodeEditorComponent } from './cell-editor-components/account-code-editor-component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { FiscalSpansRepository } from './repositories/fiscal-spans/FiscalSpansRepository';
import { SalesGroupRepository } from './repositories/sales-group-repository/SalesGroupRepository';
import { DropdownRenderComponent } from './shared/dropdown-render-component/dropdown-render-component';
import { P401Component } from './views/error/401.component';
import { DropdownRenderInGivenValuesComponent } from './shared/dropdown-render-in-given-values-component/dropdown-render-in-given-values-component';
import { BtnBarcodeRendererComponent } from './shared/btn-barcode-renderer/btn-barcode-renderer.component';
import { StockBatchSerialInwardsComponent } from './shared/stock-batch-serial-inwards/stock-batch-serial-inwards.component';
import { BtnBarcodeOutwardsRendererComponent } from './shared/btn-barcode-outward-renderer/btn-barcode-outward-renderer.component';
import { StockBatchSerialOutwardsComponent } from './shared/stock-batch-serial-outwards/stock-batch-serial-outwards.component';
import { BtnSaleBarcodeRendererComponent } from './shared/btn-sale-barcode-renderer/btn-sale-barcode-renderer.component';
import { SaleStockBatchSerialInwardsComponent } from './shared/sale-stock-batch-serial-inwards/sale-stock-batch-serial-inwards.component';
import { BtnSaleBarcodeOutwardsRendererComponent } from './shared/btn-sale-barcode-outward-renderer/btn-sale-barcode-outward-renderer.component';
import { SaleStockBatchSerialOutwardsComponent } from './shared/sale-stock-batch-serial-outwards/sale-stock-batch-serial-outwards.component';
import { TestPageComponent } from './views/procurement/test-folder/test-page/test-page.component';
import { PaymentTermRepository } from './repositories/payment-term-repository/PaymentTermRepository';
import { CurrencyRepository } from './repositories/currency-repository/CurrencyRepository';
import { NamingConventionsRepository } from './repositories/naming-conventions-repository/NamingConventionsRepository';
import { InvoicePostingSetupRepository } from './repositories/invoice-posting-setup-repository/InvoicePostingSetup';
import { IncomingPaymentsRepository } from './repositories/finance/incoming-payments-repository/IncomingPaymentsRepository';
import { OutgoingPaymentsRepository } from './repositories/finance/outgoing-payments-repository/OutgoingPaymentsRepository';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { TaxDropdownRenderComponent } from './shared/tax-dropdown-render-component/tax-dropdown-render-component';
import { InvoiceRepository } from './repositories/finance/invoice-repository/InvoiceRepository';
import { POSStringsRepository } from './repositories/pos-strings-repository/POSStringsRepository';
import { InvoiceCollectionRepository } from './repositories/finance/invoice-collection-repository/InvoiceCollectionRepository';
import { PLBSClassNamesRepository } from './repositories/plbs-class-names-repository/PLBSClassNamesRepository';
import { LoginComponent } from './views/authentication/login/login.component';
import { SharedModule } from './shared/shared.module';
import { POSLayoutComponent } from './layouts/pos/pos-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { CommissionSetupRepository } from './repositories/commission-setup-repository/CommissionSetupRepository';

import { EcommerceClientLayoutComponent } from './layouts/ecommerce-client/ecommerce-client-layout.component';
import { HeaderComponent } from './layouts/ecommerce-client/partial/header/header.component';
import { FooterComponent } from './layouts/ecommerce-client/partial/footer/footer.component';
import { HeaderCartComponent } from './layouts/ecommerce-client/partial/header-cart/header-cart.component';
import { HeaderAuthComponent } from './layouts/ecommerce-client-auth/header-auth/header-auth.component';
import { EcommerceAuthLayoutComponent } from './layouts/ecommerce-client-auth/ecommerce-auth-layout.component';
import { SidebarCartComponent } from './layouts/ecommerce-client/partial/sidebar-cart/sidebar-cart.component';
import { StoresRepository } from './repositories/ecommerce-repositories/stores-repository/StoresRepository';
import { RiderRepository } from './repositories/rider-repository/Rider';
import { DeliveryChargesRepository } from './repositories/delivery-charges-repository/DeliveryChargesRepository';
import { ShipmentsRepository } from './repositories/shipments-repository/Shipments';
import { ZIDRepository } from './repositories/zid-repository/ZIDRepository';
import { ChannelPricesRepository } from './repositories/channel-prices-repository/ChannelPricesRepository';
import { BuyerSMSRepository } from './repositories/procurement/buyer-sms-repository/BuyerSMSRepository';
import { AnalyticGroupsRepository } from './repositories/analytic-groups-repository/AnalyticGroupsRepository';
import { AddOnGroupRepository } from './repositories/add-on-group-repository/AddOnGroupRepository';
//import { ProductComponent } from './views/ecommerce-client/product/product/product.component';

//---------------HR--------------------
import { BranchRepository } from './repositories/HR/branch-repository/BranchRepository';
import { DepartmentRepository } from './repositories/HR/department-repository/DepartmentRepository';
import { DesignationRepository } from './repositories/HR/designation-repository/DesignationRepository';
import { GradeRepository } from './repositories/HR/grade-repository/GradeRepository';
import { NationalityRepository } from './repositories/HR/nationality-repository/NationalityRepository';
import { ReligionRepository } from './repositories/HR/religion-repository/ReligionRepository';
import { CityRepository } from './repositories/HR/city-repository/CityRepository';
import { AreaRepository } from './repositories/HR/area-repository/AreaRepository';
import { AssetTypeRepository } from './repositories/HR/asset-type-repository/AssetTypeRepository';
import { InsuranceRepository } from './repositories/HR/insurance-repository/InsuranceRepository';
import { OfficialDocumentRepository } from './repositories/HR/official-document-repository/OfficialDocumentRepository';
import { EmployeeRepository } from './repositories/HR/employee-repository/EmployeeRepository';
import { EmployeeNoticeRepository } from './repositories/HR/employee-notes-repository/EmployeeNoticeRepository';
import { EmployeeDependentRepository } from './repositories/HR/employee-dependent-repository/EmployeeDependentRepository';
import { LeavesTypeRepository } from './repositories/HR/leaves-type-repository/LeavesTypeRepository';
import { WeekendStructureRepository } from './repositories/HR/weekend-structure-repository/EmployeeDependentRepository';
import { EmployeeContractRepository } from './repositories/HR/employee-contract-repository/EmployeeContractRepository';
import { PayGroupRepository } from './repositories/HR/pay-groups-repository/PayGroupRepository';
import { SalaryTaxLevelRepository } from './repositories/HR/salary-tax-level-repository/SalaryTaxLevelRepository';
import { DeductionStructureRepository } from './repositories/HR/deduction-structure-repository/DeductionStructureRepository';
import { AllowanceStructureRepository } from './repositories/HR/allowance-structure-repository/AllowanceStructureRepository';
import { PayrollRepository } from './repositories/HR/payroll-repository/PayrollRepository';
import { PayrollPostingSetupRepository } from './repositories/HR/payroll-posting-setup-repository/PayrollPostingSetupRepository';
import { AttendanceRepository } from './repositories/HR/attendance-repository/AttendanceRepository';
import { VacationStructureRepository } from './repositories/HR/vacation-structure-repository/VacationStructureRepository';
import { EmployeeLeaveRepository } from './repositories/HR/employee-leave-repository/EmployeeLeaveRepository';
import { ReturnFromLeaveRepository } from './repositories/HR/return-from-leave-repository/ReturnFromLeaveRepository';
import { PublicHolidayRepository } from './repositories/HR/public-holiday-repository/PublicHolidayRepository';
import { AssetsEntryRepository } from './repositories/HR/assets-entry-repository/AssetsEntryRepository';
import { EOSBReasonRepository } from './repositories/HR/EOSBReasonRepository/EOSBReasonRepository';
import { EOSBRepository } from './repositories/HR/eosb-repository/EOSBRepository';
import { WithdrawlRepository } from './repositories/HR/withdrawl-repository/WithdrawlRepository';
import { PayrollSettingRepository } from './repositories/HR/payroll-setting-repository/PayrollSettingRepository';
import { LetterHeadRepository } from './repositories/HR/letter-head-repository/LetterHeadRepository';
import { LoanRepository } from './repositories/HR/loan-repository/LoanRepository';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptorService } from './views/authentication/authentication-helpers/http-request-interceptor.service';
import { AccountTransferRepository } from './repositories/finance/account-transfer-repository/AccountTransferRepository';
import { AgmCoreModule } from '@agm/core';
import { TaskTypesRepository } from './repositories/HR/task-types-repository/TaskTypesRepository';
import { TasksRepository } from './repositories/HR/task-repository/TaskRepository';
import { ProjectsRepository } from './repositories/HR/projects-repository/ProjectRepository';
import { EmployeeTasks } from './models/HR/EmployeeTasks/employee-tasks';
import { TaskRatingRepository } from './repositories/HR/task-rating-repository/TaskRatingRepository';
import { TaskStatusRepository } from './repositories/HR/task-status-repository/TaskStatusRepository';
import { ProductSizeRepository } from './repositories/product-size-repository/ProductSizeRepository';
import { ProductColorRepository } from './repositories/product-color-repository/ProductColorRepository';
import { TableRepository } from './repositories/Restaurant/Table-repository/TableRepository';
import { Res_CountersRepository } from './repositories/Restaurant/Table-repository/res-counters-repository/Res_CountersRepository';
import { ResUsersRepository } from './repositories/Restaurant/resusers-repository/ResUsersRepository';
import { ResStringsRepository } from './repositories/Restaurant/res-strings-repository/POSStringsRepository';
import { ResReceiptSetupRepository } from './repositories/Restaurant/res-receipt-setup/ReceiptSetupRepository';
import { ResProductGroupsRepository } from './repositories/Restaurant/res-product-groups-repository/ResProductGroupsRepository';
import { ResPrinterGroupRepository } from './repositories/Restaurant/res-printer-group-repository/PrinterGroupRepository';
import { AreasRepository } from './repositories/Restaurant/araes-repository/AreaRepository';
import { OrderTakerRepository } from './repositories/Restaurant/order-taker-repository/OrderTakerRepository';
import { OrdersRepository } from './repositories/Restaurant/orders-repository/OrdersRepository';
import { ResOrderHistoryRepository } from './repositories/Restaurant/res-order-history-repository/ResOrderHistoryRepository';

import { KitchenComponent } from './views/kitchen/kitchen.component';
import { CustomerDisplayComponent } from './views/customer-display/customer-display.component';
import { LoaderService } from './services/loader/LoaderService';
import { MenuSetupRepository } from './repositories/Restaurant/menu-setup-repository/MenuSetupRepository';
import { ClientSignupComponent } from './views/authentication/client-signup/client-signup.component';
import { BridgeServerConfiguration } from './models/BridgeServerConfiguration/BridgeServerConfiguration';
import { BridgeServerConfigurationRepository } from './repositories/bridge-server-configuration-repository/BridgeServerConfigurationRepository';
import { AutoPurchaseOrderRepository } from './repositories/auto-purchase-order-repository/AutoPurchaseOrderRepository';
import { ChequeBookConfigurationRepository } from './repositories/finance/cheque-book-configuration-repository/ChequeBookConfigurationRepository';
import { ProductLocationRepository } from './repositories/product-location-repository/ProductLocationRepository';
import { FundsTransferRepository } from './repositories/finance/funds-transfer-repository/FundsTransferRepository';
import { StockBatchInwardRepository } from './repositories/stock-batch-inward-repository/StockBatchInwardRepository';

@NgModule({
    declarations: [
        AppComponent,
        P404Component,
        P401Component,
        POSTailorLayoutComponent,
        EcommerceClientLayoutComponent,
        EcommerceAuthLayoutComponent,
        ConfirmationModalComponent,
        MultiTablesNamesModalComponent,
        UnitGridPopupComponent,
        AgGridCheckboxCellComponent,
        AgGridDropdownComponent,
        AddNewButtonSalesTargetComponent,
        GridDeleteButtonComponent,
        TablesNamesRenderer,
        AgGridNamesAddComponent,
        GridSaveButtonComponent,
        AgGridUnitsPopupComponent,
        AutoCompleteComponent,
        TestPageComponent,
        AgGridCheckboxComponent,
        AmountEditorComponent,
        CurrencyEditorComponent,
        IntegerEditorComponent,
        NumericEditorComponent,
        PriceEditorComponent,
        QuantityEditorComponent,
        AgGridButtonRendererComponent,
        AgGridUserActionButtonsComponent,
        ProductQuantityInGodownsComponent,
        AccountCodeEditorComponent,
        DropdownRenderComponent,
        DropdownRenderInGivenValuesComponent,
        BtnBarcodeRendererComponent,
        StockBatchSerialInwardsComponent,
        BtnBarcodeOutwardsRendererComponent,
        StockBatchSerialOutwardsComponent,
        BtnSaleBarcodeRendererComponent,
        SaleStockBatchSerialInwardsComponent,
        BtnSaleBarcodeOutwardsRendererComponent,
        SaleStockBatchSerialOutwardsComponent,
        TaxDropdownRenderComponent,
        LoginComponent,
        ClientSignupComponent,
        POSLayoutComponent,
        AuthLayoutComponent,
        HeaderComponent,
        FooterComponent,
        HeaderCartComponent,
        HeaderAuthComponent,
        SidebarCartComponent, 
        KitchenComponent,
        CustomerDisplayComponent 
        // EmployeeTaskRatingComponent
    ],
     
    entryComponents: [
        AgGridCheckboxComponent,
        ConfirmationModalComponent,
        MultiTablesNamesModalComponent,
        GridDeleteButtonComponent,
        AddNewButtonSalesTargetComponent,
        UnitGridPopupComponent,
        AgGridUnitsPopupComponent,
        TablesNamesRenderer,
        AgGridNamesAddComponent,
        GridSaveButtonComponent,
        AgGridCheckboxCellComponent,
        AgGridDropdownComponent,
        ProductQuantityInGodownsComponent,
        AgGridUserActionButtonsComponent,
        AgGridButtonRendererComponent,
        DropdownRenderComponent,
        DropdownRenderInGivenValuesComponent,
        BtnBarcodeRendererComponent,
        StockBatchSerialInwardsComponent,
        BtnBarcodeOutwardsRendererComponent,
        StockBatchSerialOutwardsComponent,
        BtnSaleBarcodeRendererComponent,
        SaleStockBatchSerialInwardsComponent,
        BtnSaleBarcodeOutwardsRendererComponent,
        SaleStockBatchSerialOutwardsComponent,
        TaxDropdownRenderComponent,
        // BsModalService
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PerfectScrollbarModule,
        BrowserAnimationsModule,
        NgxTypeaheadModule,
        SharedModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            //progressBar: true,
            closeButton: true,
            enableHtml: true
        }),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        TypeaheadModule.forRoot(),
        AppRoutingModule,
        NgxBarcodeModule,
        ModalModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDvAwW_k2B3e_iyjgluh2q6XXJ1jGDSE3g',
            libraries: ['places'],
            language: 'en'
        }),

        AgGridModule.withComponents([
            AutoCompleteComponent,
            AmountEditorComponent,
            CurrencyEditorComponent,
            IntegerEditorComponent,
            NumericEditorComponent,
            PriceEditorComponent,
            QuantityEditorComponent,
            AccountCodeEditorComponent,
            NgxTypeaheadModule
        ])
    ],
    providers: [
        BusinessServices
        , FinanceBusinessService
        , CommonRepository
        , ImplementOperationToolbar
        , TopToolBarOptions
        , LoginRepository
        , BuyerRepository
        , FiscalSpansRepository
        , CollectionAgentRepository
        , SalesAgentRepository
        , SalesRegionRepository
        , VendorRepository
        , UnitOfMeasurementRepository
        , ProductLocationRepository
        , ProductGroupDiscountSetupRepository
        , UOMGroupRepository
        , CurrencyRepository
        , NamingConventionsRepository
        , POSStringsRepository
        , PriceSetupRepository
        , CommissionSetupRepository
        , TaxSetupRepository
        , CompanyProfileRepository
        , TaxRateMasterRepository
        , BuyerGroupSetupRepository
        , ProcurementPostingSetupRepository
        , GodownCodeSetupRepository
        , GlobalSetupsRepository
        , SalesCreditControlsRepository
        , ProcurementCodeSetupRepository
        , PurchasePostingSetupRepository
        , SalesPostingSetupRepository
        , ProductSizeRepository
        , ProductColorRepository
        , SmartAppUtilities
        , EmployeeTasks
        , TranslateService
        , Globals
        , TranslatePipe
        , GodownRepository
        , ConfirmationDialogServices
        , MultiTablesNamesServices
        , UnitGridPopupService
        , BsModalService
        , StartingNumberSetupRepository
        , ProductCardMasterCategoryRepositoryService
        , PurchaseOrderRepository
        , AutoPurchaseOrderRepository
        , SaleOrderRepository
        , PurchaseReturnRepository
        , SaleReturnRepository
        , SaleCollectionsRepository
        , ValueFormatter
        , SanitizeValueFormatter
        , StockInternalUseRepository
        , StockAdjustmentRepository
        , StockBatchInwardRepository
        , StockDamageRepository
        , CountersRepository
        , Res_CountersRepository
        , UsersRepository
        , ResUsersRepository
        , ProcurementReportRepository
        , PrinterGroupRepository
        , ChestStyleRepository
        , HandStyleRepository
        , PocketStyleRepository
        , ShirtStyleRepository
        , ShipmentsRepository
        , TailorTypeRepository
        , StoresRepository
        , RiderRepository
        , DeliveryChargesRepository
        , CollarStyleRepository
        , ProcurementPrintReportRepository
        , ProductGroupsRepository
        , ReceiptSetupRepository
        , ResStringsRepository
        , ResReceiptSetupRepository
        , WeighScaleSetupRepository
        , AccountCodeSetupRepository
        , AccountMasterRepository
        , PLBSClassNamesRepository
        , AccountMasterBalanceRepository
        , InvoicePostingSetupRepository
        , AccountBookSetupRepository
        , ReceiptsRepository
        , PaymentsRepository
        , PaymentTermRepository
        , DebitNotesRepository
        , CreditNotesRepository
        , JournalVoucherRepository
        , TransactionViewerRepository
        , SalesGroupRepository
        , IncomingPaymentsRepository
        , OutgoingPaymentsRepository
        , FundsTransferRepository
        , InvoiceRepository
        , InvoiceCollectionRepository
        , BranchRepository
        , DepartmentRepository
        , TaskTypesRepository
        , TaskStatusRepository
        , TasksRepository
        , ProjectsRepository
        , DesignationRepository
        , GradeRepository
        , NationalityRepository
        , ReligionRepository
        , CityRepository
        , AreaRepository
        , AssetTypeRepository
        , InsuranceRepository
        , OfficialDocumentRepository
        , EmployeeRepository
        , EmployeeNoticeRepository
        , EmployeeDependentRepository
        , LeavesTypeRepository
        , WeekendStructureRepository
        , EmployeeContractRepository
        , PayGroupRepository
        , SalaryTaxLevelRepository
        , DeductionStructureRepository
        , AllowanceStructureRepository
        , PayrollRepository
        , PayrollPostingSetupRepository
        , AttendanceRepository
        , VacationStructureRepository
        , EmployeeLeaveRepository
        , ReturnFromLeaveRepository
        , PublicHolidayRepository
        , AssetsEntryRepository
        , ZIDRepository
        , EOSBReasonRepository
        , EOSBRepository
        , WithdrawlRepository
        , PayrollSettingRepository
        , ResProductGroupsRepository
        , ResPrinterGroupRepository
        , ResOrderHistoryRepository
        , AreasRepository
        , OrderTakerRepository
        , OrdersRepository
        , LetterHeadRepository
        , ChannelPricesRepository
        , LoanRepository
        , BuyerSMSRepository
        , AnalyticGroupsRepository
        , AddOnGroupRepository
        , TaskRatingRepository
        , AccountTransferRepository
        , TableRepository
        , MenuSetupRepository
        ,BridgeServerConfigurationRepository
        ,ProductCompanyRepository
        , { provide: OWL_DATE_TIME_LOCALE, useValue: "en-GB" },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptorService, multi: true, deps: [Globals,LoaderService] }
        //  , {
        //      provide: APP_INITIALIZER,
        //      useFactory: setupTranslateFactory,
        //      deps: [
        //          TranslateService,
        //          Globals
        //      ],
        //      multi: true
        //  }

    ],

    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: []

})
export class AppModule {

}
// COMMENTED BY HAMZA REQUIREMENT CHANGE
//// this will called on application start to get translation strings.
// export function setupTranslateFactory(service: TranslateService, global: Globals): Function {
//     return () => {
//         console.log('Translation Called.')
//         // service.use('en-US');
//         service.use(global.cultureCode);
//     }
// }
