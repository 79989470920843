import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { TaskStatus } from "src/app/models/HR/TaskStatus/task-status";
import { ITaskStatusRepository } from "./ITaskStatusRepository";

@Injectable()
export class TaskStatusRepository extends GenericRepository<TaskStatus> implements ITaskStatusRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/TaskStatus', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {      
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: TaskStatus): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}