import { Component, OnInit } from '@angular/core';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader/LoaderService';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styles: []
})
export class TestPageComponent implements OnInit {

  constructor(private businessService: BusinessServices,private notify:ToastrService,private loader:LoaderService) { }
  key: string = "";

  ngOnInit() {
    this.businessService.commonService.GetDates('08', '2019').subscribe((data: ResponseModel) => {
      console.log(data)
    });
  }
  
  cacheValue() {
    this.businessService.commonService.CachedValueCheck('AddValuesInCache').subscribe((data: ResponseModel) => {
      console.log(data)
    })
  }

  retrieveCachedValue() {
    this.businessService.commonService.CachedValueCheck('GetValueFromCache', this.key).subscribe((data: ResponseModel) => {
      console.log(data)
    });
  }
  CreateLanguageFiles(){
    this.loader.display(true)
    this.businessService.commonService.SetStringsInCache().subscribe((data:ResponseModel)=>{
      if(data._statusCode == 200)
      this.notify.success(data._message);
      else
      this.notify.error(data._message)
      this.loader.display(false)
    })
  }
}
