import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { IClientSignupRepository } from "./IClientSignupRepository";
import { SignUp } from "src/app/models/Login/SignUp.model";
import { ResponseModel } from "src/app/models/SharedModels/response.model";

@Injectable()
export class ClientSignUpRepository extends GenericRepository<SignUp> implements IClientSignupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/account', environment.urlAddress);
    }
    
    ClientSignUp(signup: SignUp): Observable<ResponseModel> {
        return this.PostData('/SignUp', signup).pipe(map(this.extractData), catchError(this.handleError));
    }
}
