
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Shipments } from "../../models/ECommerceModels/Shipments/shipments-model";
import { IShipmentsRepository } from "./IShipments";


@Injectable()
export class ShipmentsRepository extends GenericRepository<Shipments> implements IShipmentsRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {

        super(_http, 'api/shipments/', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/shipments/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(salesAgentCode: string, isAddress: boolean, isName: boolean): Observable<any> {
        return this.GetById('GetRec/' + salesAgentCode + '/' + isName).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(salesAgentCode: string) {
        return this.Delete('/DeleteRec/' + salesAgentCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}
