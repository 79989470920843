import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CollarStyle } from "../../models/CollarStyle/collartstyle.model";
import { ICollarStyleRepository } from "./ICollarStyleRepository";

@Injectable()
export class CollarStyleRepository extends GenericRepository<CollarStyle> implements ICollarStyleRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/CollarStyle', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/CollarStyle/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(CollarStyleCode: string) {
        return this._http.delete(this.url + 'api/CollarStyle/DeleteRec/' + CollarStyleCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveStyleNames(item: CollarStyle) {
        return this._http.post(this.url + 'api/CollarStyle/SaveStyleNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
