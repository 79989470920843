import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subject } from 'rxjs';
import { UnitGridPopupService } from "../unit-grid-popup/unit-grid-popup.service";

@Component({
  selector: 'app-ag-grid-units-popup',
  templateUrl: './ag-grid-units-popup.component.html'  
})
export class AgGridUnitsPopupComponent implements ICellRendererAngularComp {
  private params: any;
  private mood: string;
  public name: string;
  public body: string;
  public title: string;
  public onClose: Subject<boolean>;
  public active: boolean = false;
  constructor(private unitGridPopupService: UnitGridPopupService) {

  }
  agInit(params: any): void {
    this.params = params;
    this.setMood(params);
    this.onClose = new Subject
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }

  private setMood(params) {
    this.mood = params.value;
    this.name = this.mood;
  };
  onShowPopup(code) {
    this.unitGridPopupService.showConfirmationModal(this.params,code);
  }

}
