import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { NamingConventions } from "../../models/NamingConventions/naming-conventions.model";
import { INamingConventionsRepository } from "./INamingConventionsRepository";

@Injectable()
export class NamingConventionsRepository extends GenericRepository<NamingConventions> implements INamingConventionsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/NamingConventions', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/NamingConventions/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(smartAppStringID: number): Observable<any> {
        return this.GetById('/GetRec/' + smartAppStringID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveRec(item: NamingConventions): Observable<any> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }     
}
