import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Res_Counters } from "src/app/models/Restaurant/Res_Counters.model";
import { GenericRepository } from "src/app/repositories/generic-repository/GenericRepository";
import { IRes_CountersRepository } from "./IRes_CountersRepository";

@Injectable()
export class Res_CountersRepository extends GenericRepository<Res_Counters> implements IRes_CountersRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Res_Counters/', environment.urlAddress);
    }
    
    GetAllRec(): Observable<any> {
        return this._http
            .get(this.url + 'api/Res_Counters/GetAllRec', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));        
    }
    LoadRec(CounterCode: string): Observable<any> {
        return this.GetById('/GetRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/Res_Counters/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(CounterCode: string) {
        return this.Delete('/DeleteRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    CounterReset(CounterCode: string) {
        return this._http.get(this.url + 'api/Res_Counters/CounterReset' + '/' + CounterCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LogoutForcefully(counter: Res_Counters): Observable<any> {
        return this.PostData('/LogoutForcefully', counter).pipe(map(this.extractData), catchError(this.handleError));
    }
    
}