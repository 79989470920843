
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ISalesPostingSetupRepository } from "./ISalesPostingSetup";
import { SalesPostingSetup } from "src/app/models/SalesPostingSetup/sales-posting-setup-model";

@Injectable()
export class SalesPostingSetupRepository extends GenericRepository<SalesPostingSetup> implements ISalesPostingSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/salespostingsetup/', environment.urlAddress);
    }
    GetRec(godownCode: string, paymentType: number): Observable<any> {
        return this.GetById('GetRec/' + godownCode + '/' + paymentType).pipe(map(this.extractData), catchError(this.handleError));
    }
}
