import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { GoodDeliveryNote } from "src/app/models/GoodDeliveryNote/good-delivery-note.model";
import { IGoodDeliveryNoteRepository } from "./IGoodDeliveryNoteRepository";
import { DocumentSearchViewModel } from "src/app/models/common/document-search-model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class GoodDeliveryNoteRepository extends GenericRepository<GoodDeliveryNote> implements IGoodDeliveryNoteRepository { 

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/GoodDeliveryNote', environment.urlAddress);
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {        
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/GoodDeliveryNote/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetLastSalePrice(productCode: string, productType: number, buyerCode: string): Observable<ResponseModel> {
        return this.GetById('/GetLastSalePrice' + '/' + productCode + '/' + productType + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(): Observable<ResponseModel> {
        return this.PostData('/SaveRec', null).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectSaleData(item: GoodDeliveryNote): Observable<ResponseModel> {
        return this.PostData('/CollectSaleData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectPostingData(item: any): Observable<ResponseModel> {
        return this.PostData('/CollectPostingData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingAccountSetup(isAccountLinked: number): Observable<ResponseModel> {
        return this.GetById('/SelectPostingAccountSetup' + '/' + isAccountLinked).pipe(map(this.extractData), catchError(this.handleError));
    }
   
    SelectBuyerInvoices(currentPage: number, recordsPerPage: number, buyerCode: string): Observable<any> {
        return this.GetById('SelectBuyerInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    SearchGoodDeliveryNote(searchModel: DocumentSearchViewModel): Observable<any> {
        return this._http.post(this.url + 'api/GoodDeliveryNote/GoodDeliveryNoteSearch', searchModel, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectGodownInvoices(currentPage: number, recordsPerPage: number, godownCode: string, DateFrom:string='',DateTo:string=''): Observable<any> {
        godownCode = godownCode!='' ? godownCode : 'null';
        return this.GetById('SelectGodownInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode+ '/' + DateFrom + '/' + DateTo).pipe(map(this.extractData), catchError(this.handleError));
    }

}