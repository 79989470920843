import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SaleReturns } from "src/app/models/SaleReturns/sale-returns.model";
import { ISaleReturnRepository } from "./ISaleReturnRepository";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()

export class SaleReturnRepository extends GenericRepository<SaleReturns> implements ISaleReturnRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/salesreturn', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/salesreturn/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    
    SaveRec(): Observable<ResponseModel> {
        return this.PostData('/SaveRec', null).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectSaleData(item: SaleReturns): Observable<ResponseModel> {
        return this.PostData('/CollectSaleData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    CollectPostingData(item: any): Observable<ResponseModel> {
        return this.PostData('/CollectPostingData', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingAccountSetup(isAccountLinked: number): Observable<ResponseModel> {
        return this.GetById('/SelectPostingAccountSetup' + '/' + isAccountLinked).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectBuyerReturns(currentPage: number, recordsPerPage: number, buyerCode: string): Observable<any> {
        return this.GetById('SelectBuyerReturns' + '/' + currentPage + '/' + recordsPerPage + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}