
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { ISalesAgentRepository } from "./ISalesAgent";
import { SalesAgent } from "../../models/SalesAgent/sales-agent-model";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class SalesAgentRepository extends GenericRepository<SalesAgent> implements ISalesAgentRepository {


    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {

        super(_http, 'api/salesagent/', environment.urlAddress);
    }
    GetSalesAgentAddress(salesAgentCode: string, addressType: string) {
        return this._http.get(this.url + 'api/salesagent/SalesAgentAddress' + '/' + salesAgentCode + '/' + addressType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetSalesAgentTargets(salesAgentCode: string) {
        return this._http.get(this.url + 'api/salesagent/SalesAgentTarget' + '/' + salesAgentCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/salesagent/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadRec(salesAgentCode: string, isAddress: boolean, isName: boolean): Observable<any> {
        return this.GetById('/GetRec/' + salesAgentCode + '/' + isAddress + '/' + isName).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(salesAgentCode: string) {
        return this.Delete('/DeleteRec/' + salesAgentCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetSalesAgentCommission(params: string) {
        return this._http.get(this.url + 'api/salesagent/SalesAgentCommission' + '/' + params, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }
}
