import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SmartAppUtilities } from 'src/app/models/common/getjsonfrom-controls-service-model';
import { CompanyProfile } from 'src/app/models/CompanyProfile/company-profile.model';
import { EmployeeTasks } from 'src/app/models/HR/EmployeeTasks/employee-tasks';
import { TaskRating } from 'src/app/models/HR/TaskRating/task-rating';
import { HrBusinessService } from 'src/app/services/hr/hr-business.service';
import { LoaderService } from 'src/app/services/loader/LoaderService';

@Component({
  selector: 'app-employee-task-rating',
  templateUrl: './employee-task-rating.component.html'
})
export class EmployeeTaskRatingComponent implements OnInit, AfterViewInit {
  model: TaskRating = new TaskRating();
  employeeTaskModel: EmployeeTasks = new EmployeeTasks();
  companyProfile: CompanyProfile = new CompanyProfile();
  smartUtilities = new SmartAppUtilities(this.toastrService);
  name: string = "";

  constructor(private toastrService: ToastrService, private loaderService: LoaderService, private hrBusinessService: HrBusinessService, private route: ActivatedRoute) { }
    ngAfterViewInit(){ 
      $("body").addClass("ratingbody");
    }
  ngOnInit() {
     
    this.name = this.route.snapshot.queryParamMap.get("url");
    var parameters = atob(this.name).split("::");
    if(parameters[0].startsWith("http://")){
      this.model.DomainName = parameters[0].replace("http://", "");
    }
    if(parameters[0].startsWith("https://")){
      this.model.DomainName = parameters[0].replace("https://", "");
    }
    // this.model.DomainName = this.model.DomainName.replace(":55555", "");
    this.model.TaskCode = Number(parameters[1]);
    this.model.FiscalSpanID = Number(parameters[2]);


    this.loaderService.display(true);
    this.hrBusinessService.taskRatingService.GetCompanyInfo(this.model.DomainName, this.model.FiscalSpanID).subscribe(data => {
      
        if (data != null) {
            if (data._obj != null) {
                this.companyProfile = data._obj;
                this.companyProfile.MediaContents = this.companyProfile.MediaContentType + "," + this.companyProfile.MediaContents;
                
                var connectinString = btoa(this.companyProfile.ZidConsumerKey);
                this.hrBusinessService.taskRatingService.GetTaskInfo(this.model.TaskCode, this.model.FiscalSpanID, this.companyProfile.CultureCode, connectinString).subscribe(record => {
                  
                  if (record != null) {
                    if (record._obj != null) {
                        this.employeeTaskModel = record._obj;
                    }
                  }
                  // console.log(this.companyProfile, "company Profile");
                  // console.log(this.employeeTaskModel, "task info");
                });
            }
        }
    });


 

    this.loaderService.display(false);

  }

  clickOnStar(rating: number){
    
    if(rating == 1){
      $("#onestar").removeClass("btn-grey");
      $("#twostar").removeClass("btn-grey");
      $("#threestar").removeClass("btn-grey");
      $("#fourstar").removeClass("btn-grey");
      $("#fivestar").removeClass("btn-grey");

      $("#onestar").addClass("btn-primary");
      $("#twostar").addClass("btn-grey");
      $("#threestar").addClass("btn-grey");
      $("#fourstar").addClass("btn-grey");
      $("#fivestar").addClass("btn-grey");
    }
    else if(rating == 2){
      $("#onestar").removeClass("btn-grey");
      $("#twostar").removeClass("btn-grey");
      $("#threestar").removeClass("btn-grey");
      $("#fourstar").removeClass("btn-grey");
      $("#fivestar").removeClass("btn-grey");
      $("#onestar").addClass("btn-primary");
      $("#twostar").addClass("btn-primary");
      $("#threestar").addClass("btn-grey");
      $("#fourstar").addClass("btn-grey");
      $("#fivestar").addClass("btn-grey");
    }
    else if(rating == 3){
      $("#onestar").removeClass("btn-grey");
      $("#twostar").removeClass("btn-grey");
      $("#threestar").removeClass("btn-grey");
      $("#fourstar").removeClass("btn-grey");
      $("#fivestar").removeClass("btn-grey");
      $("#onestar").addClass("btn-primary");
      $("#twostar").addClass("btn-primary");
      $("#threestar").addClass("btn-primary");
      $("#fourstar").addClass("btn-grey");
      $("#fivestar").addClass("btn-grey");
    }
    else if(rating == 4){
      $("#onestar").removeClass("btn-grey");
      $("#twostar").removeClass("btn-grey");
      $("#threestar").removeClass("btn-grey");
      $("#fourstar").removeClass("btn-grey");
      $("#fivestar").removeClass("btn-grey");
      $("#onestar").addClass("btn-primary");
      $("#twostar").addClass("btn-primary");
      $("#threestar").addClass("btn-primary");
      $("#fourstar").addClass("btn-primary");
      $("#fivestar").addClass("btn-grey");
    }
    else if(rating == 5){
      $("#onestar").removeClass("btn-grey");
      $("#twostar").removeClass("btn-grey");
      $("#threestar").removeClass("btn-grey");
      $("#fourstar").removeClass("btn-grey");
      $("#fivestar").removeClass("btn-grey");
      $("#onestar").addClass("btn-primary");
      $("#twostar").addClass("btn-primary");
      $("#threestar").addClass("btn-primary");
      $("#fourstar").addClass("btn-primary");
      $("#fivestar").addClass("btn-primary");
    }
    this.model.Rating = rating;
  }

  SaveRating(){
    
    if(this.model.Rating > 0){
      this.loaderService.display(true);
      this.model.ConnectionString = btoa(this.companyProfile.ZidConsumerKey);
      this.hrBusinessService.taskRatingService.SaveRec(this.model).subscribe((data: any) => {
        if (data && data._statusCode === 200) {
          this.toastrService.success(data._message);
        }
        else {
          this.toastrService.error(data._message);
        }
        this.loaderService.display(false);
      });
    }
    else{
      this.toastrService.error("Rating is required / التصنيف مطلوب");
    }
  }

}
