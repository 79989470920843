import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from "src/app/shared/custom-pipes/translate-pipe/translate.pipe";
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { SmartAppUtilities } from '../../models/common/getjsonfrom-controls-service-model';
declare var $: any;

@Component({
    selector: 'app-master-names-same-table',
    templateUrl: './master-names-same-table.component.html'
})


export class MasterNamesSameTableComponent implements OnInit, AfterViewInit {
    Languages: any;

    @Input()
    TableName: string;
    @Input()
    CodeColumn: string;
    @Input()
    NameColumn: string;
    namesInOtherLanguages: string = '';
    constructor(private businessService: BusinessServices,
        private touster: ToastrService,
        private translatePipe: TranslatePipe,
        private loaderService: LoaderService,
        private smartUtilities: SmartAppUtilities) {
    }

    ngOnInit(): void {

        var element = document.getElementById('ShowDropDown');

        element.onclick = () => {

            let isRecordInView: boolean = document.getElementById("IsNamesRecordInView").getAttribute('value') === "true";

            if (!isRecordInView) {

                let code: string = (<HTMLCollectionOf<HTMLInputElement>>document.getElementsByClassName("txtPrimaryKey"))
                    .item(0).value;
                    let rowIndex: string="";

                    if(document.getElementById("RowIndex"))
                        rowIndex= document.getElementById("RowIndex").getAttribute('value');
                    else
                    rowIndex='Wherehouse'; // this line is use for wherehouse load names 

                let isUpdating: boolean = !(rowIndex === "" || rowIndex === null) && code !== "";

                if (isUpdating) {                    
                    this.loaderService.display(true);
                    this.businessService.commonService.LoadMasterNamesSameTable(code, this.CodeColumn, this.NameColumn, this.TableName).subscribe((data: any) => {
                        this.loaderService.display(false);
                        if (data) {

                            if (data._statusCode === 200) {

                                if (data._obj)
                                    this.smartUtilities.SetJSONToControls($("#NamesFormContainer"), $.parseJSON(data._obj)[0]);

                                document.getElementById("IsNamesRecordInView").setAttribute("value", "true");
                            }
                        } else
                            this.touster.error(this.translatePipe.transform("66"));

                    });
                }
            }
        }
    }

    ngAfterViewInit(): void {
        
        this.businessService.commonService.LoadLocalLanguages().subscribe((data: any) => {            
            if (data) {

                if (data._statusCode === 200) {
                    this.Languages = data._obj;                    
                } else
                    this.touster.error(this.translatePipe.transform("66"));

            } else
                this.touster.error(this.translatePipe.transform("66"));

        });
    }

    SaveNameInHiddenField(): void {

        let namesString: string = '';
        let inputElements: any = $('#NamesFormContainer input');

        inputElements.each((index, elementValue) => {

            namesString += elementValue.getAttribute('id') + '-<|>-' + elementValue.value;

            if (index < inputElements.length - 1)
                namesString += '<-|->';
        });

        $('#MultiNames').val(namesString);
        this.namesInOtherLanguages = namesString;
    }
}
 