import { Component, OnInit, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StockBatchSerials } from '../../models/common/getjsonfrom-controls-service-model';
declare var $: any;
@Component({
    selector: 'app-stock-batch-serial-inwards',
    templateUrl: './stock-batch-serial-inwards.component.html',
})
export class StockBatchSerialInwardsComponent implements OnInit {

    params: any;
    smartUtilities: any;
    toastrService: any;
    onProcess: any;
    IsSerialShow: boolean = false;
    ProductCode: string = '';
    IsBatchRequired: boolean = false;
    IsSerialRequired: boolean = false;
    IsMeasureRequired: boolean = false;
    IsMfgRequired: boolean = false;
    IsExpRequired: boolean = false;
    MeasurementType: number = 0;
    Quantity: number = 0;
    documentNumber: string = '';
    batchRequiredOnly: boolean = false;
    serialRequiredOnly: boolean = false;
    batchSerialQuantity: number = 0;
    Weight: number = 0;
    model: StockBatchSerials[] = [];
    batchModel: StockBatchSerials = new StockBatchSerials();
    serialModel: StockBatchSerials = new StockBatchSerials();
    serialItems: StockBatchSerials[] = [];
    batchItems: StockBatchSerials[] = [];
    isEnabled: boolean = false;
    isAutoSerials: boolean = true;
    serialPrefix: string;
    indexer: number = 1;
    constructor(public bsModalRef: BsModalRef, private el: ElementRef) {

    }
  

    ngOnInit() {
        
        if (this.IsBatchRequired && this.IsSerialRequired)
            this.IsSerialShow = false;
        else
            this.IsSerialShow = this.IsSerialRequired;

        if ((this.IsBatchRequired || this.IsMeasureRequired) && !this.IsSerialRequired)
            this.batchRequiredOnly = true;
        else
            this.batchRequiredOnly = false;

        if (this.IsSerialRequired && (!this.IsBatchRequired && !this.IsMeasureRequired)) {
            this.serialRequiredOnly = true;

            //db also
            if (this.model.length > 0) {

                this.serialItems = this.model;
                this.serialModel.StockBatchInwardID = this.serialItems[0].StockBatchInwardID;
                this.serialModel.BatchNumber = this.serialItems[0].BatchNumber;
                this.refreshSerialsQty();

            }
            else
                this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');

            this.serialModel.InwardQuantity = this.batchSerialQuantity;
            this.serialModel.InwardWeight = this.Weight * this.batchSerialQuantity;
            this.serialModel.ProductCode = this.ProductCode;
            this.isAutoSerials = false;

        }
        else
            this.serialRequiredOnly = false;

        if ((this.IsBatchRequired || this.IsMeasureRequired)) {

            if (this.model.length > 0) {
                this.loadBatches();
                if (this.IsSerialRequired) {
                    setTimeout(() => {

                        const element: HTMLElement = document.getElementsByClassName('btnSerialToBatch')[0] as HTMLElement;
                        if (element)
                            element.click();
                    }, 100);

                }
            }
        }
        setTimeout(() => {
            this.smartUtilities.EnableDisableFormElement("#BatchDiv", this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
            this.smartUtilities.EnableDisableElement('#btn-process', this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
        }, 100);

    }

    onCancelClick() {
        this.bsModalRef.hide();
    }
    onProcessClick() {

        if (!this.validateBatchSerialsCount())
            return;

        if (this.onProcess instanceof Function) {

            const params = {
                params: this.params,
                value: this.model,
            }
            this.onProcess(params);
        }
        this.bsModalRef.hide();
    }

    scrollSerialDiv() {
        const objDiv = this.el.nativeElement.querySelector('#serial-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }

    scrollBatchDiv() {
        const objDiv = this.el.nativeElement.querySelector('#batch-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }
    refreshSerialsQty() {

        for (var i = 0; i < this.model.length; i++) {

            this.model[i].InwardQuantity = this.batchSerialQuantity;
            this.model[i].InwardWeight = this.model[i].InwardQuantity * this.Weight;
        }
    }
    loadBatches() {

        this.batchItems = [];
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;

    }

    loadBatchSerials(stockBatchInwardID) {

        this.serialItems = [];
        if (this.model == undefined || this.model.length == 0)
            return;
        const BatcheSerials = this.model.filter(x => x.StockBatchInwardID === stockBatchInwardID);
        if (BatcheSerials && BatcheSerials.length > 0)
            this.serialItems = BatcheSerials;
    }

    onNewBatchClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewBatchForm');
        if (ok) {

            const qty: number = Number(this.batchModel.InwardQuantity);
            const baseQty: number = Number(this.batchSerialQuantity);
            const mfgDate: string = $('#ManufactureDate').val();
            const expDate: string = $('#ExpiryDate').val();
            //If serial required against this item we will not let it enter fraction values
            if (this.IsSerialRequired && (qty % 1 != 0)) {
                this.toastrService.error("Fraction in quanity is not allowed in serial enabled product");
                return false;
            }
            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                return false;
            }

            //here check existing qty
            const existingQty: number = (qty + this.getExistingBatchQty());
            if (existingQty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            //Compare dates to check if mfg date is greater than exp date
            if (this.IsMfgRequired || this.IsExpRequired) {

                var dateMfg: any, dateExp: any;

                if (mfgDate != '' && mfgDate != undefined)
                    dateMfg = this.processDate(mfgDate);

                if (expDate != '' && expDate != undefined)
                    dateExp = this.processDate(expDate);

                if (this.IsMfgRequired && this.IsMfgRequired) {

                    if (dateMfg >= dateExp) {
                        this.toastrService.error('Expiry/End Date Must be Greater than the Start Date');
                        return false;
                    }
                }
            }

            this.batchModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            this.batchModel.ManufactureDate = mfgDate;
            this.batchModel.ExpiryDate = expDate;
            //this.batchModel.BaseQuantity = baseQty;
            this.batchModel.InwardQuantity = qty;
            this.batchModel.InwardWeight = this.Weight * qty;
            this.batchModel.ProductCode = this.ProductCode;
            const batches = Object.assign({}, this.batchModel);

            if (!this.IsSerialRequired)
                this.model.push(batches);

            this.batchItems.push(batches);

            this.batchModel = new StockBatchSerials();

            this.scrollBatchDiv();
            this.IsSerialShow = false;
        }
    }

    onNewSerialClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewSerialForm');
        if (ok) {

            let qty = Number(this.serialModel.InwardQuantity);

            if (isNaN(qty))
                qty = 0;

            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                this.serialModel.SerialNumber = '';
                return false;
            }
            let alreadyAddedSerials = 0;
            if (this.model != undefined && this.model.length > 0)
                alreadyAddedSerials = this.model.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID).length;
            if (alreadyAddedSerials >= qty) {
                this.toastrService.error('Limit exceeds');
                this.serialModel.SerialNumber = '';
                return false;
            }


            let serialArray = this.model.filter(x => x.SerialNumber.toLowerCase() === this.serialModel.SerialNumber.toLowerCase())

            if (serialArray.length > 0) {
                this.toastrService.error('Serial number already exists');
                this.serialModel.SerialNumber = '';
                return false;
            }

            const serials = Object.assign({}, this.serialModel);
            this.model.push(serials);
            this.serialModel.SerialNumber = '';
            this.loadBatchSerials(this.serialModel.StockBatchInwardID);
            this.scrollSerialDiv();

        }
    }

    onSerialToBatchClick(data: StockBatchSerials) {

        this.IsSerialShow = true;
        this.serialModel.StockBatchInwardID = data.StockBatchInwardID;
        this.serialModel.BatchNumber = data.BatchNumber;
        this.serialModel.InwardQuantity = data.InwardQuantity;
        //this.serialModel.BaseQuantity = data.BaseQuantity;
        this.serialModel.InwardWeight = data.InwardWeight;
        this.serialModel.ProductCode = data.ProductCode;
        this.isAutoSerials = true;
        this.serialPrefix = data.BatchNumber;
        this.indexer = 1;

        this.loadBatchSerials(this.serialModel.StockBatchInwardID);

        const BatcheSerials = this.model.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID);
        if (BatcheSerials && BatcheSerials.length == 0 && this.isAutoSerials)
            this.autoProcessClick();
    }



    onRemoveBatchClick(stockBatchInwardID: string) {

        this.model = this.model.filter(x => x.StockBatchInwardID != stockBatchInwardID);
        this.batchItems = this.batchItems.filter(x => x.StockBatchInwardID != stockBatchInwardID);
        this.IsSerialShow = false;
    }

    onRemoveSerialClick(serialNumber: string) {

        this.model = this.model.filter(x => x.SerialNumber != serialNumber);
        this.serialItems = this.serialItems.filter(x => x.SerialNumber != serialNumber);
    }

    getExistingBatchQty(): number {
        const sum: number = this.batchItems.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
        return sum;
    }

    processDate(date) {

        const parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    generateUUID() {

        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    validateBatchSerialsCount() {
        
        if (this.model == undefined) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.model.length == 0) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.batchRequiredOnly) {
            const sum: number = this.model.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.serialRequiredOnly) {
            if (this.batchSerialQuantity !== this.model.length) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.IsBatchRequired && this.IsSerialRequired) {

            for (let batches of this.model) {

                const stockBatchInward: string = batches.StockBatchInwardID
                const batchNumber: string = batches.BatchNumber;
                let InwardQuantity: number = Number(batches.InwardQuantity);
                if (isNaN(InwardQuantity))
                    InwardQuantity = 0;

                const result = this.model.filter(x => x.StockBatchInwardID === stockBatchInward);

                if (result.length < InwardQuantity) {
                    this.toastrService.error('Insufficient Batch/Serials' + ' : ' + batchNumber);
                    return false;

                }
            }


            const sum: number = this.getDistinctArray().reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }

        return true;
    }

    getDistinctArray(): StockBatchSerials[] {

        let batches: StockBatchSerials[] = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        return batches;
    }

    autoProcessClick() {

        if (this.serialPrefix && this.indexer && this.serialModel.InwardQuantity) {

            const ok: boolean = this.smartUtilities.ValidateForm('#autoModal');
            if (ok) {


                let item = Object.assign({}, this.serialModel);

                let qty = Number(item.InwardQuantity);

                if (isNaN(qty))
                    qty = 0;

                if (qty == 0) {
                    this.toastrService.error('Please enter quantity');
                    item.SerialNumber = '';
                    return false;
                }
                let alreadyAddedSerials = 0;
                if (this.model != undefined && this.model.length > 0)
                    alreadyAddedSerials = this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID).length;
                if (alreadyAddedSerials >= qty) {
                    this.toastrService.error('Limit exceeds');
                    item.SerialNumber = '';
                    return false;
                }
                qty = qty - alreadyAddedSerials;
                //let serialPrefix: string = this.serialPrefix == '' ? '' : (this.serialPrefix + ' - ');
                let serialPrefix: string = this.serialPrefix;
                for (var i = 0; i < qty; i++) {

                    let serialNumber = (serialPrefix + this.indexer);
                    item.SerialNumber = serialNumber;
                    let serialArray = this.model.filter(x => x.SerialNumber.toLowerCase() === item.SerialNumber.toLowerCase())

                    if (serialArray.length > 0) {
                        this.toastrService.error('Serial number already exists');
                        item.SerialNumber = '';
                        break;
                    }

                    const serials = Object.assign({}, item);
                    this.model.push(serials);
                    item.SerialNumber = '';
                    this.indexer++;
                }
                this.indexer = 1;
                this.loadBatchSerials(item.StockBatchInwardID);
                setTimeout(() => {
                    this.scrollSerialDiv();
                });
            }



        }
        $('#autoModal').modal('hide');
    }

    onAutoSerialsClick() {

        if (this.isAutoSerials)
            this.autoProcessClick();
        else {
            this.model = this.model.filter(x => x.StockBatchInwardID != this.serialModel.StockBatchInwardID);
            this.serialItems = this.serialItems.filter(x => x.StockBatchInwardID != this.serialModel.StockBatchInwardID);
        }

    }

    onDimensionChange() {

        if (this.MeasurementType == 1)
            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width));
        else
            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width) * Number(this.batchModel.Height));
    }
}
