import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { IProductCompanyRepository} from "./IProductCompanyRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ProductCompany } from "src/app/models/ProductCompany/product-company";

@Injectable()
export class ProductCompanyRepository extends GenericRepository<ProductCompany> implements IProductCompanyRepository {
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
    super(_http, 'api/ProductCompanies', environment.urlAddress);
  }
  GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductCompanies/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
  DeleteRec(code: string): Observable<any> {
    return this._http
      .delete(this.url + 'api/ProductCompanies/DeleteRec' + '/' + code, this.httpOptions)
      .pipe(map(this.extractData), catchError(this.handleError));
  }
  SearchProductComapnies(): Observable<any> {
    return this._http
      .get(this.url + 'api/ProductCompanies/SearchProductCompanies', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
  }
}
