import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { LoyaltyCard } from "src/app/models/LoyaltyManagement/loyalty-card.model";
import { ILoyaltyCardRepository } from "./ILoyaltyCardRepository";

@Injectable({ providedIn: 'root' })
export class LoyaltyCardRepository extends GenericRepository<any> implements ILoyaltyCardRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/LoyaltyCard', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: number): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<ResponseModel> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }

    SaveRec(item: LoyaltyCard): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: number): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }

    SelectLoyaltyCardInformationForBuyer(godowncode: string, buyerCode: string, currentDate: string): Observable<ResponseModel> {
        return this.Get('SelectLoyaltyCardInformationForBuyer/' + godowncode + '/' + buyerCode + '/' + currentDate);
    }

}