import { OnInit, OnDestroy, ElementRef, Renderer2, Component, Inject, Input,AfterViewInit } from "@angular/core";
import { Replace } from "../replace";
import { AppBreadcrumbService } from "./breadcrumb.service";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
  })
export  class AppBreadcrumbComponent implements OnInit, OnDestroy,AfterViewInit {
    ngAfterViewInit(): void {
        $('.crumbMenu').click(function () {
            var link = $(this).html();
            if (link.toLowerCase() == 'configuration') {
                $('#commentBox-100000').click();
            } 
            else if (link.toLowerCase() == 'finance') {
                $('#commentBox-200000').click();
            } 
            else if (link.toLowerCase() == 'procurement') {
                $('#commentBox-400000').click();
            } 
            else if (link.toLowerCase() == 'production') {
                $('#commentBox-600000').click();
            }
            else if (link.toLowerCase() == 'pos') {
                $('#commentBox-700000').click();
            }
        })
    }
    private document;
    private renderer;
    service: AppBreadcrumbService;
    el: ElementRef;
    @Input()
    fixed: boolean;
    breadcrumbs: any;
    private fixedClass;

    pageid = ['100000', '20000', '300000', '40000']; 

    constructor(@Inject(DOCUMENT) document: any, renderer: Renderer2, service: AppBreadcrumbService, el: ElementRef, private router: Router){
        this.document=document;
        this.renderer = renderer;
        this.service = service;
        this.el = el;
        this.fixedClass = 'breadcrumb-fixed';

        router.events.subscribe((val) => {                        
            if (this.breadcrumbs != null) {                
                this.breadcrumbs = this.service.breadcrumbs;                   
            }
        });

    }
    ngOnInit(): void {
        Replace(this.el);
        this.isFixed(this.fixed);
        this.breadcrumbs = this.service.breadcrumbs;
        // console.log(this.breadcrumbs);
    };
    ngOnDestroy(): void{
        this.renderer.removeClass(this.document.body, this.fixedClass);
    };
    isFixed(fixed?: boolean): void{
        if (fixed) {
            this.renderer.addClass(this.document.body, this.fixedClass);
        }
    };
}
