import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { IMenuSetupRepository } from "./IMenuSetupRepository";
import { MenuSetup } from "src/app/models/ECommerceModels/menus.model";

@Injectable()
export class MenuSetupRepository extends GenericRepository<any> implements IMenuSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/MenuSetups/', environment.urlAddress);
    }


    SelectAll(): Observable<ResponseModel> {        
        return this.Get('SelectAll');
    }

    SaveRec(item: MenuSetup): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }

}