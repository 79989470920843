import { Component, OnInit, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StockBatchSerials } from '../../models/common/getjsonfrom-controls-service-model';
declare var $: any;
@Component({
    selector: 'app-stock-batch-serial-outwards',
    templateUrl: './sale-stock-batch-serial-outwards.component.html',
})
export class SaleStockBatchSerialOutwardsComponent implements OnInit {

    params: any;
    smartUtilities: any;
    toastrService: any;
    onProcess: any;
    IsSerialShow: boolean = false;
    ProductCode: string = '';
    IsBatchRequired: boolean = false;
    IsSerialRequired: boolean = false;
    IsMeasureRequired: boolean = false;
    IsMfgRequired: boolean = false;
    IsExpRequired: boolean = false;
    MeasurementType: number = 0;
    Quantity: number = 0;
    documentNumber: string = '';
    batchRequiredOnly: boolean = false;
    serialRequiredOnly: boolean = false;
    batchSerialQuantity: number = 0;
    Weight: number = 0;
    model: StockBatchSerials[] = [];
    batchModel: StockBatchSerials = new StockBatchSerials();
    serialItems: StockBatchSerials[] = [];
    batchItems: StockBatchSerials[] = [];
    dimensionItems: StockBatchSerials[] = [];
    isEnabled: boolean = false;
    isAutoSerials: boolean = true;
    lblBatchNumber: string = '';

    constructor(public bsModalRef: BsModalRef, private el: ElementRef) {

    }


    ngOnInit() {
         debugger
        if (this.IsBatchRequired && this.IsSerialRequired)
            this.IsSerialShow = false;
        else
            this.IsSerialShow = this.IsSerialRequired;

        if ((this.IsBatchRequired || this.IsMeasureRequired) && !this.IsSerialRequired)
            this.batchRequiredOnly = true;
        else
            this.batchRequiredOnly = false;

        if (this.IsSerialRequired && (!this.IsBatchRequired && !this.IsMeasureRequired)) {
            this.serialRequiredOnly = true;

            //db also
            if (this.model.length > 0) {
                this.serialItems = this.model;               
            }

            this.isAutoSerials = true;
            // this.onAutoSerialsClick();
        }
        else
            this.serialRequiredOnly = false;

        debugger
        if ((this.IsBatchRequired)) {

            if (this.model.length > 0) {
                this.loadBatches();
                if (this.IsSerialRequired) {
                    setTimeout(() => {

                        const element: HTMLElement = document.getElementsByClassName('btnSerialToBatch')[0] as HTMLElement;
                        if (element)
                            element.click();
                    }, 100);

                }
            }
        }
        if ((this.IsMeasureRequired)) {

            if (this.model.length > 0) {
                this.loadMeasurements();
                setTimeout(() => {

                    const element: HTMLElement = document.getElementsByClassName('btnBatchToDimension')[0] as HTMLElement;
                    if (element)
                        element.click();
                }, 100);
            }
        }
        setTimeout(() => {
            this.smartUtilities.EnableDisableFormElement("#BatchDiv", this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
            this.smartUtilities.EnableDisableElement('#btn-process', this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
        }, 100);

    }

    onCancelClick() {
        this.bsModalRef.hide();
    }
    onProcessClick() {

        if (!this.validateBatchSerialsCount())
            return;

        if (this.onProcess instanceof Function) {

            const params = {
                params: this.params,
                value: this.model,
            }
            console.log(this.model);
            this.onProcess(params);
        }
        this.bsModalRef.hide();
    }

    scrollBatchDiv() {
        const objDiv = this.el.nativeElement.querySelector('#batch-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }

    loadBatches() {
        debugger
        this.batchItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchOutwardID === e.StockBatchOutwardID && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;

        if (!this.IsMeasureRequired) {
            debugger
            let Qty: number = this.batchSerialQuantity;
            let existingQty: number = this.getExistingBatchQty();

            if (Qty != existingQty)//means update qty
                if (batches.length > 0) {
                    for (let i = 0; i < batches.length; i++) {
                        
                        let BalanceQuantity = Number(batches[i].BalanceQuantity);
                        let InwardQuantity = Number(batches[i].InwardQuantity);

                        if (BalanceQuantity <= Qty)
                            InwardQuantity = BalanceQuantity;
                        else
                            InwardQuantity = Qty;

                        batches[i].InwardQuantity = InwardQuantity;
                        batches[i].BaseQuantity = BalanceQuantity - InwardQuantity;
                        batches[i].InwardWeight = batches[i].InwardQuantity * this.Weight;

                        Qty -= Number(InwardQuantity);
                    }

                }
        }
    }

    loadBatchSerials(stockBatchOutwardID) {

        this.serialItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        setTimeout(() => {
           
            let BatcheSerials = [];
            if (this.serialRequiredOnly)
                BatcheSerials = this.model;
            else
                BatcheSerials = this.model.filter(x => x.StockBatchOutwardID === stockBatchOutwardID);
            if (BatcheSerials && BatcheSerials.length > 0)
                this.serialItems = BatcheSerials;

        },100);

    }

    onSerialToBatchClick(data: StockBatchSerials) {
        
        if (this.model == undefined || this.model.length == 0)
            return;
        let qty = Number(data.InwardQuantity);
        if (isNaN(qty))
            qty = 0;
        if (qty == 0) {
            this.toastrService.error('Please enter quantity');
            return false;
        }
        this.IsSerialShow = true;
        this.isAutoSerials = true;
        this.lblBatchNumber = data.BatchNumber;
        this.serialItems = new Array<StockBatchSerials>();
        let BatcheSerials = this.model.filter(x => x.StockBatchOutwardID === data.StockBatchOutwardID);
        if (BatcheSerials && BatcheSerials.length > 0)
            this.serialItems = BatcheSerials;
        this.onAutoSerialsClick();
    }


    onAddSerialClick(data: StockBatchSerials) {
        
        let ok: boolean = data.IsSelected;
        if (ok) {
            let qty = 0;
            if (this.serialRequiredOnly)
                qty = Number(this.batchSerialQuantity);
            else
                qty = Number(data.InwardQuantity);
            if (isNaN(qty))
                qty = 0;
            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                let item = this.model.find(x => x.SerialNumber === data.SerialNumber);
                if (item)
                    item.IsSelected == false;
                data.IsSelected = false;
                this.loadBatchSerials(data.StockBatchOutwardID);
                return false;
            }
            let alreadyAddedSerials = 0;
            if (this.model != undefined && this.model.length > 0)
                alreadyAddedSerials = this.model.filter(x => x.IsSelected == true).length - 1;
            if (alreadyAddedSerials >= qty) {
                this.toastrService.error('Limit exceeds');
                let item = this.model.find(x => x.SerialNumber === data.SerialNumber);
                if (item)
                    item.IsSelected == false;
                data.IsSelected = false;
                this.loadBatchSerials(data.StockBatchOutwardID);
                return false;
            }
            let item = this.model.find(x => x.SerialNumber === data.SerialNumber);
            if (item)
                item.IsSelected == true;
            this.loadBatchSerials(data.StockBatchOutwardID);
        }
        else {
            let item = this.model.find(x => x.SerialNumber === data.SerialNumber);
            if (item)
                item.IsSelected == false;
            this.loadBatchSerials(data.StockBatchOutwardID);

        }

    }

    generateUUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    validateBatchSerialsCount() {
        debugger
        if (this.model == undefined) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.model.length == 0) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }

        if (this.batchRequiredOnly) {

            const sum: number = this.model.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.serialRequiredOnly) {
            this.refreshSerialsQty();
            if (this.batchSerialQuantity !== this.model.filter(x => x.IsSelected == true).length) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.IsBatchRequired && this.IsSerialRequired) {
            debugger
            for (let batches of this.model) {
                debugger
                const stockBatchInward: string = batches.StockBatchOutwardID
                const batchNumber: string = batches.BatchNumber;
                let InwardQuantity: number = Number(batches.InwardQuantity);
                if (isNaN(InwardQuantity))
                    InwardQuantity = 0;

                const result = this.model.filter(x => x.StockBatchOutwardID === stockBatchInward && x.IsSelected == true);

                // if (result.length < InwardQuantity) {
                if(this.batchSerialQuantity < InwardQuantity){
                    this.toastrService.error('Insufficient Batch/Serials' + ' : ' + batchNumber);
                    return false;

                }
            }


            const sum: number = this.getDistinctArray().reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }

        return true;
    }
    getDistinctArray(): StockBatchSerials[] {

        let batches: StockBatchSerials[] = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchOutwardID === e.StockBatchOutwardID && t.ProductCode === e.ProductCode)) === i;
        });
        return batches;
    }


    onDimensionChange() {
        
        if (this.MeasurementType == 1) {

            if (Number(this.batchModel.Length) > Number(this.batchModel.OutwardLength))
                this.batchModel.Length = this.batchModel.OutwardLength;
            if (Number(this.batchModel.Width) > Number(this.batchModel.OutwardWidth))
                this.batchModel.Width = this.batchModel.OutwardWidth;
            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width));


        }
        else {
            if (Number(this.batchModel.Length) > Number(this.batchModel.OutwardLength))
                this.batchModel.Length = this.batchModel.OutwardLength;
            if (Number(this.batchModel.Width) > Number(this.batchModel.OutwardWidth))
                this.batchModel.Width = this.batchModel.OutwardWidth;
            if (Number(this.batchModel.Height) > Number(this.batchModel.OutwardHeight))
                this.batchModel.Height = this.batchModel.OutwardHeight;

            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width) * Number(this.batchModel.Height));
        }

    }

    onQuantityChange(data: StockBatchSerials) {

        let qty: number = Number(data.InwardQuantity)
        if (qty > 0) {
            if (data.InwardQuantity > data.BalanceQuantity)
                data.InwardQuantity = data.BalanceQuantity;
            if (data.InwardQuantity > this.batchSerialQuantity)
                data.InwardQuantity = this.batchSerialQuantity;
            if (data.InwardQuantity > data.BalanceQuantity)
                data.InwardQuantity = data.BalanceQuantity;

            data.InwardWeight = this.Weight * data.InwardQuantity;
            data.BaseQuantity = data.BalanceQuantity - data.InwardQuantity;
        }
        if (this.IsSerialRequired) {
            let qty: number = Number(data.InwardQuantity)
            let item = this.model.filter(x => x.StockBatchOutwardID === data.StockBatchOutwardID && x.IsSelected == true);
            if (item) {
                if (item.length > qty)
                    for (let i = qty; i < item.length; i++) {
                        item[i].IsSelected = false;
                        item[i].InwardQuantity = qty;
                        item[i].InwardWeight = item[i].InwardQuantity * this.Weight;
                    }
            }
            let BatcheSerials = this.model.filter(x => x.StockBatchOutwardID === data.StockBatchOutwardID);
            if (BatcheSerials && BatcheSerials.length > 0)
                for (let i = 0; i < BatcheSerials.length; i++) {
                    BatcheSerials[i].InwardQuantity = qty;
                    BatcheSerials[i].InwardWeight = BatcheSerials[i].InwardQuantity * this.Weight;
                    if (this.isAutoSerials)
                        if (i < qty)
                            BatcheSerials[i].IsSelected = true;
                }

        }
    }

    onAutoSerialsClick() {
        
        let BatcheSerials = this.serialItems;
        if (BatcheSerials && BatcheSerials.length > 0) {

            let qty: number = 0;

            if (this.serialRequiredOnly)
                qty = Number(this.batchSerialQuantity);
            else
                qty = Number(BatcheSerials[0].OutwardQuantity);


            for (let i = 0; i < BatcheSerials.length; i++)
                if (i < qty && this.isAutoSerials) {
                    BatcheSerials[i].IsSelected = true;
                    if (!this.serialRequiredOnly) {
                        BatcheSerials[i].InwardQuantity = qty;
                        BatcheSerials[i].InwardWeight = BatcheSerials[i].InwardQuantity * this.Weight;
                    }
                    
                }

                else {
                    BatcheSerials[i].IsSelected = false;
                    if (!this.serialRequiredOnly) {
                        BatcheSerials[i].InwardQuantity = qty;
                        BatcheSerials[i].InwardWeight = BatcheSerials[i].InwardQuantity * this.Weight;
                    }
                }

            setTimeout(() => {
                this.serialItems = BatcheSerials;
            });
        }
    }

    loadMeasurements() {

        this.batchItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchOutwardID === e.StockBatchOutwardID && t.StockBatchInwardID.indexOf('NewBarCode') == -1 && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;

    }

    onAddToDimensionClick(data: StockBatchSerials) {
        
        if (this.model == undefined || this.model.length == 0)
            return;
        let qty = Number(data.BalanceQuantity);
        if (isNaN(qty))
            qty = 0;
        if (qty == 0) {
            this.toastrService.error('Please enter quantity');
            return false;
        }
        this.IsSerialShow = true;
        this.isAutoSerials = false;
        this.lblBatchNumber = data.BatchNumber;

        this.IsSerialShow = true;

        this.batchModel = Object.assign({}, data);

        this.loadBatchDimension(this.batchModel.StockBatchOutwardID);
    }

    loadBatchDimension(stockBatchOutwardID) {

        this.dimensionItems = new Array<StockBatchSerials>();
        if (this.model == undefined || this.model.length == 0)
            return;
        setTimeout(() => {
            let dimensionItems = this.model.filter(x => x.StockBatchOutwardID === stockBatchOutwardID && x.StockBatchInwardID.indexOf('NewBarCode') != -1);
            if (dimensionItems && dimensionItems.length > 0)
                this.dimensionItems = dimensionItems;

        });

    }

    onNewBatchClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewBatchForm');
        if (ok) {

            const qty: number = Number(this.batchModel.InwardQuantity);
            const baseQty: number = Number(this.batchModel.BalanceQuantity);

            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                return false;
            }

            //here check existing qty
            const existingQty: number = (qty + this.getExistingBatchQty());
            if (existingQty > baseQty || existingQty > this.batchSerialQuantity) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }
            this.batchModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            //this.batchModel.BaseQuantity = baseQty;
            this.batchModel.InwardQuantity = qty;
            this.batchModel.InwardWeight = this.Weight * qty;
            this.batchModel.ProductCode = this.ProductCode;
            const batches = Object.assign({}, this.batchModel);

            if (!this.IsSerialRequired)
                this.model.push(batches);

            this.dimensionItems.push(batches);

            this.batchModel.Height = 0;
            this.batchModel.Length = 0;
            this.batchModel.InwardQuantity = 0;
            this.batchModel.InwardWeight = 0;
            this.batchModel.Width = 0;

            this.scrollBatchDiv();
        }
    }

    getExistingBatchQty(): number {
        let sum: number = 0;
        if (this.IsMeasureRequired)
            sum = this.dimensionItems.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
        else
            sum = this.batchItems.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
        return sum;
    }
    onRemoveBatchClick(stockBatchInwardID: string) {

        this.model = this.model.filter(x => x.StockBatchInwardID != stockBatchInwardID);
        this.dimensionItems = this.dimensionItems.filter(x => x.StockBatchInwardID != stockBatchInwardID);
    }
    refreshSerialsQty() {

        for (var i = 0; i < this.model.length; i++) {

            this.model[i].InwardQuantity = this.model.filter(x => x.StockBatchInwardID === this.model[i].StockBatchInwardID && x.IsSelected == true).length;
            this.model[i].InwardWeight = this.model[i].InwardQuantity * this.Weight;
        }
        console.log(this.model);
    }
}
