import { GenericRepository } from "../generic-repository/GenericRepository";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { IGlobalSetupsRepository } from "./IGlobalSetupsRepository";
import { GlobalSetups, GlobalSetupsPostViewModel } from "src/app/models/GlobalSetups/global-setups";

@Injectable()
export class GlobalSetupsRepository extends GenericRepository<any> implements IGlobalSetupsRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/globalsetups', environment.urlAddress);
    }

    GetFilteredGlobalSetups(setupName: string = '', pageID: number = null) {
        return this.Get('GetFilteredGlobalSetups/' + setupName + '/' + pageID);
    }

    GetFilteredGlobalSetupsByPost(glb: GlobalSetupsPostViewModel) {
        return this.PostData('/GetFilteredGlobalSetups/',glb);
    }

    CheckProductExistsAgainstType(type:string){
        return  this.Get('ProductExistsAgainstType/'+type);
    }
}
