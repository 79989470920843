import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { HrBusinessService } from 'src/app/services/hr/hr-business.service';
import { EmployeeTasks } from 'src/app/models/HR/EmployeeTasks/employee-tasks';
import { CommonConstant } from '../constent/applicationcodes';
import { Globals } from 'src/environments/Globals';
import { CategoryIngredient } from 'src/app/models/CantegoryIngredient/category-ingredient';
import { BusinessServices } from 'src/app/services/singleton/business-services';
declare var $: any;

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationModalComponent implements OnInit {
  commonconstant = new CommonConstant();
  public active: boolean = false;
  public params: any;
  public event: any;
  public serviceName: any;
  public code: string;
  public isAgGridDelete: boolean = false;
  public body: string;
  public title: string;
  public isDeleteDialogue: boolean = false;
  private methodName: string = '';
  private updateStatements: any = null;
  private param1: any = null;
  private param2: any = null;  
  public IsSendMessage: boolean = false;
  public taskCode: string = "";
  public notes: string = "";
  public mobile: string = "";
  public rowDataIngredients: Array<CategoryIngredient> = [];

  public onClose: Subject<boolean>;
  documentObject: any = $(document);
  row: any;  
  public constructor(private _bsModalRef: BsModalRef, private toastrService: ToastrService, private translate: TranslatePipe, private hrBusinessService: HrBusinessService
    , private globals: Globals, private businessService: BusinessServices) { }

  public ngOnInit(): void {
    this.onClose = new Subject
  }

  public showConfirmationModal(title: string, body: string, e?: any, params?: any, _isAgGridDelete?: boolean, serviceName?: any, code?: any): void {
    this.title = title;
    this.body = body;
    this.active = true;
    this.event = e;
    this.params = params;
    this.isAgGridDelete = _isAgGridDelete;
    this.serviceName = serviceName;
    this.code = code;
  }

  public showDeleteConfirmationModal(title: string, body: string, serviceName: any, code: string,methodName:string="",updateStatement:any = null,param1:any,param2:any): void {
    this.title = this.translate.transform(title);
    this.body = this.translate.transform(body);
    this.active = true;        
    this.serviceName = serviceName;
    this.code = code;
    this.methodName = methodName == ""?"":methodName + "/";
    this.updateStatements = updateStatement;
    this.isDeleteDialogue = true;
    this.param1 = param1;
    this.param1 = param2;
  }

  public showConfirmationMsgSendModal(title: string, body: string, e?: any, taskCode?: string, notes?: string, mobile?: string): void {
    this.title = title;
    this.body = body;
    this.active = true;
    this.event = e;
    this.taskCode = taskCode;
    this.notes = notes;
    this.mobile = mobile;
  }

  public showConfirmationUpdateCategoryModal(title: string, body: string, e?: any, rowDataIngredients?: any): void {
    this.title = title;
    this.body = body;
    this.active = true;
    this.event = e;
    this.rowDataIngredients = rowDataIngredients;
  }

public onDelete():void{ 
  this.active = false;
  this.taskCode = this.taskCode;
  this.serviceName.Delete(this.methodName+this.code).subscribe(data => {
    this.isDeleteDialogue = false;
    if (data && data._statusCode && data._statusCode == 200) {
      this.onClose.next(true);
      this.toastrService.success(this.translate.transform(data._message));      
      this._bsModalRef.hide();
      if(this.updateStatements)
        {
          if(this.param1 && this.param2)
          {this.updateStatements(this.param1,this.param2);}
          else if(this.param1)
          {this.updateStatements(this.param1);}
          else{
            this.updateStatements();
          }
        }
    }
    else {
      this.onClose.next(false);
      if(data._message)
        this.toastrService.error(this.translate.transform(data._message));
      else
        this.toastrService.error("Something went wrong with your delete method settings.");

      this._bsModalRef.hide();
    }
  });
}

  public onConfirm(): void {
    debugger
    this.active = false;
    if (this.isAgGridDelete && (this.serviceName != null || this.serviceName != undefined)) {
      this.row = [this.event.rowData];

      if (this.code == "TaxGroupID") {
        this.serviceName.Delete(this.event.rowData[this.code], this.event.rowData.TaxType).subscribe(data => {
          if (data._statusCode != null && data._statusCode == 200) {
            this.onClose.next(true);
            this.toastrService.success(this.translate.transform(data._message));
            this.params.api.updateRowData({ remove: this.row });
            this._bsModalRef.hide();
          }
          else {
            this.onClose.next(false);
            this.toastrService.error(this.translate.transform(data._message));
            this._bsModalRef.hide();
          }
        });
      } else if (this.code === "OfficialDocumentCode" || this.code === "AttendanceCode" || this.code === "EmployeeSalaryCode") {
          this.serviceName.DeleteRec(this.params[this.code]).subscribe(data => {
              if (data._statusCode != null && data._statusCode == 200) {
                  this.onClose.next(true);
                  this.toastrService.success(this.translate.transform(data._message));
                  this._bsModalRef.hide();
              }
              else {
                  this.onClose.next(false);
                  this.toastrService.error(this.translate.transform(data._message));
                  this._bsModalRef.hide();
              }
          });
      }
      else {
        this.serviceName.DeleteRec(this.event.rowData[this.code]).subscribe(data => {
          if (data._statusCode != null && data._statusCode == 200) {
            this.onClose.next(true);
            this.toastrService.success(this.translate.transform(data._message));
            this.params.api.updateRowData({ remove: this.row });
            this._bsModalRef.hide();
          }
          else {
            this.onClose.next(false);
            this.toastrService.error(this.translate.transform(data._message));
            this._bsModalRef.hide();
          }
        });
      }
    } else if (this.isAgGridDelete) {
      this.onClose.next(true);
      this.row = [this.event.rowData];
      this.params.api.updateRowData({ remove: this.row });
      this._bsModalRef.hide();
    }
    else if(this.taskCode){
      
      var employeeTask = new EmployeeTasks(); 
      employeeTask.TaskCode = Number(this.taskCode);
      employeeTask.Phone = this.mobile;
      employeeTask.Task = this.notes;

      var url = this.taskCode + "::" + this.globals.getDataFromLocalStorage(this.commonconstant.CurrentFiscalSpanID);
      employeeTask.TaskStatusCode = window.location.origin;

      employeeTask.TaskStatusCode = btoa(employeeTask.TaskStatusCode + "::" + url);
      employeeTask.Notes = window.location.origin;
      this.hrBusinessService.tasksService.SendMessage(employeeTask).subscribe(data => {
        if (data._obj != null && data._statusCode === 200) {
          this.toastrService.success(this.translate.transform(data._message));
        }
        else {
          this.toastrService.error(this.translate.transform(data._message));
        }
        this._bsModalRef.hide();
      });
    }
    else if(this.rowDataIngredients.length > 0){
      this.rowDataIngredients.forEach(element => {
        element.InsertAllowed = true;
      });
      this.businessService.categoryIngredientService.SaveRec(this.rowDataIngredients).subscribe(data => {
        if (data._statusCode === 200) {
          this.toastrService.success(this.translate.transform(data._message));
        }
        else if ((data._message != null && data._statusCode === 500) || (data._message != null && data._statusCode === 404)) {
          this.toastrService.error(this.translate.transform(data._message));
        }
      });
    }
    else {
      this.documentObject.find('.btnDeleteContent').trigger('click');
      this._bsModalRef.hide();
    }
  }

  public onCancel(): void {
    this.active = false;
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.active = false;
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
