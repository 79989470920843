﻿export class ReportViewer {
    constructor() {
        this.isReportViewerShow = false;
        this.reportHeaderLabel = "";
        this.reportHeaderCCR = ""; 
        this.items = [];
    }
    isReportViewerShow: Boolean; 
    reportHeaderLabel: string;
    reportHeaderCCR: string;
    items: Array<any>;
}
export class ReportParamsModel {
    constructor() {
        this.Flag = "";
        this.StoreProcedure = "";
        this.ReportParameter = "";
        this.RowIndexStart = 0;
        this.RowIndexEnd = 0;
        this.Parameter = "";
    }
    StoreProcedure: string;
    ReportParameter: string;
    Flag: string;
    RowIndexStart: number;
    RowIndexEnd: number;
    Parameter: string;
}
export class ReportFilter {
    constructor() {
        this.Col1 = "";
        this.Col2 = "";
        this.Col3 = "";
        this.Col4 = "";
        this.Col5 = "";
        this.Col6 = "";
        this.Col7 = "";
        this.Col8 = "";
        this.Col9 = "";
        this.Col10 = "";
        this.Col11 = "";
        this.Col12 = "";
        this.Col13 = "";
        this.Col14 = "";
        this.Col15 = "";
        this.Col16 = "";
        this.Skip = 0;
        this.Next = 0;
        this.PageNumber = "";
        this.ReportParameter = "";
        this.TaxEnabled = false;
        this.Flag = "";
        this.ReportName = "";
        this.Theader = [];
        this.UseTransalation = true;
        
    }
    Col1: string;
    Col2: string;
    Col3: string;
    Col4: string;
    Col5: string;
    Col6: string;
    Col7: string;
    Col8: string;
    Col9: string;
    Col10: string;
    Col11: string;
    Col12: string;
    Col13: string;
    Col14: string;
    Col15: string;
    Col16: string;
    ReportParameter: string;
    PageNumber: string;
    PageSize: string;
    TaxEnabled: boolean;
    Flag: string;
    ReportName: string;
    Theader: Array<any>;
    UseTransalation:boolean;
    Skip: number;
    Next: number;
  
}
