﻿import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[PhoneNumberValidator]'
})
export class PhoneNumberValidatorDirective {

  private regex: RegExp = /^\+?[0-9]\d{1,14}$/; // + at start and then 2 to 14 digits only (not spaces, hyphens, or parentheses.)

  constructor(private el: ElementRef) { }

  @HostListener('focusout')
  onFocusOut() {
    // Clear the input field if the phone number is not valid
    if (this.el.nativeElement.value && !String(this.el.nativeElement.value).match(this.regex)) {
      this.el.nativeElement.value = '';
    }
  }

}