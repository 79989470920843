import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

import { BusinessServices } from 'src/app/services/singleton/business-services';
import { UOMGroupDetails } from 'src/app/models/UOMGroup/uomgroup-details.model';

import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/environments/Globals';
import { AddNewButtonSalesTargetComponent } from '../add-new-button-sales-target/add-new-button-sales-target.component';
import { GridDeleteButtonComponent } from '../grid-delete-button/grid-delete-button.component';
import { ConfirmationDialogServices } from '../confirmation-dialog/confirmation-dialog.service';
import { UOMGroup } from 'src/app/models/UOMGroup/uomgroup.model';
import { Subject } from 'rxjs';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
import { getDecimalCellEditor, getNumericCellEditor } from '../ag-grid-generic-editors/GenericCellEditor';
import { findArrayMax } from 'src/app/services/common-methods/common-methods-exports';
declare var $: any;

@Component({
    selector: 'app-unit-grid-popup',
    templateUrl: './unit-grid-popup.component.html'
})
export class UnitGridPopupComponent implements OnInit {

    private gridColumnApi;
    private rowSelection;
    private enableRTL: boolean = false;
    private gridApi;
    public components: any;
    public UOMItems: any[];
    public UOMItemForDropDown: any[];
    public selectedUOM: string = "";
    public selectedGroup: string = "";
    public selectedUOMName: string = "";
    public frameworkComponents: any;
    public defaultColDef: any;
    public selectedCode: string;
    groupDetails: UOMGroup;
    uomGroupDetail: UOMGroupDetails;
    public active: boolean = false;
    //Languages: any;
    public params: any;
    public parentParams: any;
    public isAgGridDelete: boolean = false;
    public body: string;
    public title: string;
    public onClose: Subject<boolean>;
    documentObject: any = $(document);
    selectedRow: number = 0;
    UOMFirstCode: string = "";
    newRecordIndex: number = 1;
    UomGroupInUse:boolean = false;
    columnDefs = [
        // {
        //     headerName: '', minWidth: 70, maxWidth: 70, field: "RowNum", cellClass: 'rowNum', headerComponent: 'addNewButtonSalesTargetComponent', headerClass: 'bdr-0 newgray',
        // },
        {
            headerName: this.translate.transform('80'), minWidth: 150, maxWidth: 150, field: 'SerialNumber', headerClass: 'text-right newgray',
            cellClass: 'text-right', sortable: false, filter: false, editable: false, cellEditor: "numericCellEditor",
            valueGetter: function (params) {
                params.data.SerialNumber = params.node.rowIndex + 1;// updating serial number from row index.
                return params.data.SerialNumber;
            },
        },
        {
            headerName: this.translate.transform('81'), minWidth: 200, maxWidth: 200, field: 'UnitFragment', headerClass: 'text-right newgray',
            cellClass: 'text-right', sortable: false, filter: false, editable: true, cellEditor: "decimalCellEditor"
        },
        {
            headerName: this.translate.transform('72'), headerClass: 'newgray', field: 'UOMCode', minWidth: 310, width: 680, sortable: false, filter: false, cellClass: 'dropdown greycol',
            cellRenderer: params => {
                var eDropDown = document.createElement('select');
                let oldSelectedVal = ""                ;
                eDropDown.setAttribute("id", "ddPrice");                
                eDropDown.setAttribute("class", "form-trans form-control square custom-select");
                this.UOMItemForDropDown.forEach(function (val) {
                    if (params.data.UOMCode == val.UOMCode) {
                        eDropDown.innerHTML += "<option value = " + val.UOMCode + " selected>" + val.Name + "</option>";
                        oldSelectedVal = params.data.UOMCode;

                    }
                    else {
                        eDropDown.innerHTML += "<option value = " + val.UOMCode + ">" + val.Name + "</option>";
                    }
                });
                eDropDown.addEventListener('change', (e) => {
                    let selectedValue = (<HTMLInputElement>e.target).value
                    if(!this.UomGroupInUse){
                        params.node.setDataValue(params.colDef,selectedValue);
                        this.UOMItemForDropDown = this.UOMItems.filter(x=>x.UOMCode !== selectedValue);
                    }
                    else{
                        (<HTMLInputElement>e.target).value = oldSelectedVal
                        params.node.setDataValue(params.colDef,oldSelectedVal);
                    }                    
                });
                return eDropDown;
            },
        },
        {
            headerName: this.translate.transform('52'),
            headerClass: 'table-action newgray2',
            cellClass: 'actionCell text-center', 
            cellRenderer: 'gridDeleteButton', cellRendererParams: {
                onDeleteClick: this.gridDeleteEvent.bind(this),
                hideEdit: true
            },
            editable: false, minWidth: 60, maxWidth: 60, width: 60, colId: "Action"
        }
    ];

    private rowData: any[] = undefined;

    public constructor(
        private _bsModalRef: BsModalRef, private businessService: BusinessServices, private translate: TranslatePipe,
        private toastrService: ToastrService, private globalVar: Globals, public confirmationDialogServices: ConfirmationDialogServices
    ) {
        this.rowSelection = "single";
        this.enableRTL = globalVar.isRTL;
        this.components = {
            numericCellEditor: getNumericCellEditor(),
            decimalCellEditor: getDecimalCellEditor()
        };
        this.frameworkComponents = {
            addNewButtonSalesTargetComponent: AddNewButtonSalesTargetComponent,
            gridDeleteButton: GridDeleteButtonComponent,
        };
        
    }

    public ngOnInit(): void {
        this.onClose = new Subject;
    }

    onChange(value) {
        this.selectedUOM = value;
        this.selectedUOMName = this.UOMItems.find(x => x.UOMCode === this.selectedUOM).Name;

        var itemsToUpdate = [];
        this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
            var data = rowNode.data;
            data.BaseUOMCode = value;
            itemsToUpdate.push(data);
        });
        var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    }

    onCellClicked(params) {
        if (!this.UomGroupInUse) {
            if (params.rowIndex == 0 && params.colDef.field == "UnitFragment") {
                this.gridApi.stopEditing();
            }
        }
        else {
            this.toastrService.error(this.translate.transform('2416'));
        }
    }

    onCellFocused(params) {
        if (!this.UomGroupInUse) {
            if (params.rowIndex == 0 && params.column.colId == "UnitFragment") {
                this.gridApi.stopEditing();
            }
        }
        else {
            this.toastrService.error(this.translate.transform('2416'));
        }
    }
 
    onGridReady(params) {
        this.params = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        params.api.sizeColumnsToFit();

        params.api.sizeColumnsToFit();

        window.addEventListener("resize", function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        }); 

        if (this.globalVar.isRTL) {
            this.gridColumnApi.setColumnPinned("Action", "left");
        }

        if (this.rowData == null) {
            this.gridApi.setRowData([]);
        }
    }

    public showConfirmationModal(params, code) {

        if (code == "") {
            this.toastrService.error("Please enter first unit of measurement");
            this.active = false;
            this.onClose.next(false);
            this._bsModalRef.hide();
            return;
        }

        this.parentParams = params;
        this.selectedGroup = this.translate.transform('82') + " - " + params.node.data.GroupName;

        this.selectedCode = code;
        
        this.businessService.uomGroupService.SelectGroupUnits(code).subscribe((data: any) => {
            if (data._obj != null || data._obj != undefined) {
                if (data._obj.length > 0 && data._obj[0].dtUOM) {
                    this.UOMItems = data._obj[0].dtUOM;
                    this.UOMItemForDropDown = this.UOMItems;
                    this.UOMFirstCode = this.UOMItems[0].UOMCode;
                    this.selectedUOM = this.UOMItems[0].UOMCode;
                    this.selectedUOMName = this.UOMItems.find(x => x.UOMCode === this.selectedUOM).Name;

                    if (data._obj[0].UOMGroupCode != null) {
                        this.rowData = data._obj;
                        this.UomGroupInUse = data._obj[0]['UOMGroupInUse'];
                        this.newRecordIndex = this.rowData.length;
                        this.newRecordIndex++;
                    }
                    else {
                        this.rowData = null;
                    }
                    this.active = true;
                }
                else {
                    this.toastrService.error("Please enter first unit of measurement");
                    this.active = false;
                    this.onClose.next(false);
                    this._bsModalRef.hide();
                }
            }
            else {
                this.toastrService.error("Please enter first unit of measurement");
                this.active = false;
                this.onClose.next(false);
                this._bsModalRef.hide();
            }
        });
    }

    public onConfirm(): void {
        this.active = false;
        this.onClose.next(true);
    }

    gridDeleteEvent(e) {

        if (!this.UomGroupInUse) {
            if (e.rowIndex == 0) {
                this.toastrService.error("You cannot delete first record.");
                return;
            }
            this.confirmationDialogServices.showConfirmationModal(e, this.params, true);
        }
        else{
            this.toastrService.error(this.translate.transform('2416'));
        }

    }
  
    gridAddEvent() {

        if (this.gridApi.getEditingCells().length > 0) {
            this.toastrService.info(this.translate.transform('2412'));
            return false;
        }

        let unitFragment = 1;
        let count = this.gridApi.getDisplayedRowCount();
        if (count > 0) {
            let array:any[] = [];
            this.gridApi.forEachNode((node) => {
                array.push(node.data)
            });
            let maxArray =  findArrayMax(array,'UnitFragment');
            unitFragment = Number(maxArray['UnitFragment']) + 1;
        }

        var newRecord = {
            RowNum: this.newRecordIndex,
            UOMGroupCode: this.selectedCode,
            UOMCode: this.UOMFirstCode,
            SerialNumber: 0,
            UnitFragment: unitFragment,
            IsInserting: true,
        };

        this.newRecordIndex++;        
        var res = this.gridApi.updateRowData({ add: [newRecord] });    

        this.selectedRow = count;
        this.gridApi.selectIndex(count, false, false);
        this.gridApi.setFocusedCell(count, 'UnitFragment');
        this.gridApi.startEditingCell({
            rowIndex: count,
            colKey: 'UnitFragment',
        });
    }
    // Added By Hamza
    onCellEditingStopped(newObj: any) {
        console.log(newObj)
        if (newObj.colDef.field == "UnitFragment") {
            let array: any[] = [];
            this.gridApi.forEachNode((node) => {
                if (!(node.data.SerialNumber == newObj.data.SerialNumber && node.data.UnitFragment == newObj.data.UnitFragment))
                    array.push(node.data)
            });
            if (array && array.length > 0) {

                let maxArray = findArrayMax(array, 'UnitFragment');
                let unitFragment = Number(maxArray['UnitFragment'])

                if (Number(newObj.data.UnitFragment) <= unitFragment) {
                    this.toastrService.error(this.translate.transform('2410'))
                    this.gridApi.setFocusedCell(newObj.rowIndex, 'UnitFragment');
                    this.gridApi.startEditingCell({
                        rowIndex: newObj.rowIndex,
                        colKey: 'UnitFragment',
                    });
                    return false;
                }
            }
        }
    }

    public onSave(): void {
        this.gridApi.stopEditing();
        this.groupDetails = new UOMGroup();
        this.groupDetails.DetailsTable = new Array<UOMGroupDetails>();
        var errorExist = false;
        var uomCodes = new Array<string>();
        this.gridApi.forEachNode((node) => {
            if (!uomCodes.includes(node.data.UOMCode)) {
                uomCodes.push(node.data.UOMCode);
                this.uomGroupDetail = new UOMGroupDetails();
                this.uomGroupDetail.UOMGroupCode = this.selectedCode;
                this.uomGroupDetail.UOMCode = node.data.UOMCode;
                this.uomGroupDetail.SerialNumber = node.data.SerialNumber;
                this.uomGroupDetail.UnitFragment = node.data.UnitFragment;
                this.groupDetails.DetailsTable.push(this.uomGroupDetail);
            }
            else {
                errorExist = true;                
            }
        });

        if (!errorExist) {
            this.groupDetails.UOMGroupCode = this.selectedCode;
            this.businessService.uomGroupService.SaveGroupDetails(this.groupDetails).subscribe((data: any) => {
                if (data._obj != null || data._obj != undefined) {
                    this.active = false;
                    this.onClose.next(false);
                    this._bsModalRef.hide();
                    this.toastrService.success(this.translate.transform(data._message));
                }
            });
            this.parentParams.node.data.BaseUOMCode = this.selectedUOM;
        }
        else { 
            this.toastrService.error("Duplicate units of measurements are not allowed");
        }

    }

    public onCancel(): void {
        this.active = false;
        this.onClose.next(false);
        this._bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.active = false;
        this.onClose.next(null);
        this._bsModalRef.hide();
    }

}

