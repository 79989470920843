import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, timer } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ResOrders } from 'src/app/models/Restaurant/ResOrders.model';
import { findArrayMax, sortArray } from 'src/app/services/common-methods/common-methods-exports';
import { RestaurantService } from 'src/app/services/restaurant/restaurant.service';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { ApplicationCodes, CommonConstant } from 'src/app/shared/constent/applicationcodes';
import { DateFormatterPipe } from 'src/app/shared/custom-pipes/date-formatter.pipe/date-formatter.pipe';
import { TranslatePipe } from 'src/app/shared/custom-pipes/translate-pipe/translate.pipe';
import { Globals } from 'src/environments/Globals';

@Component({
  selector: 'app-customer-display',
  templateUrl: './customer-display.component.html',
  styleUrls: ['./customer-display.component.css']
})
export class CustomerDisplayComponent implements OnInit,OnDestroy {

  pendingOrdersList = new Array<ResOrders>();
  InProgressOrdersList = new Array<ResOrders>();
  completedOrdersList = new Array<ResOrders>();
  domainURL: string = '';
  godownData: any[];
  goDowmCode: any = "";
  goDownName: any = "";
  applicationCodes = new ApplicationCodes();


  subscription: Subscription;
  intervalSubscription: Subscription;
  commonconstant = new CommonConstant();


  constructor(private globals: Globals, private toastrService: ToastrService, private translate: TranslatePipe,
    private restaurantService: RestaurantService, private businessService: BusinessServices,private dateFormat:DateFormatterPipe) {
    this.domainURL = window.location.origin;
  }


  ngOnInit() {

    this.bindInProgressOrdersListings(this.goDowmCode);

    this.businessService.commonService.Get(this.applicationCodes.GodownTableName).subscribe((data: any) => {
      this.godownData = data.filter(x => x.TblName == this.applicationCodes.GodownTableName);
    });

    setInterval(() => {
      this.countDownTime();
    }, 1000);
  }

  onGoDownChange(value) {

    var selectedItem = this.godownData.find(x => x.code == value);
    if (selectedItem == null || selectedItem == undefined)
      selectedItem = this.godownData.find(x => x.name.toLowerCase() == value.toLowerCase());
    if (selectedItem) {
      this.goDowmCode = selectedItem.code;
      this.goDownName = selectedItem.name;
    }
    else {
      this.goDowmCode = "";
      this.goDownName = "";
      }
      this.bindInProgressOrdersListings(this.goDowmCode);
  }


  bindInProgressOrdersListings(goDowmCode: string) {
    let date = new Date()
    date = this.dateFormat.transform(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), 0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss');

    this.restaurantService.ordersRepository.GetAllInProgressOrders(goDowmCode,date).subscribe(data => {
      if (data._statusCode === 200 && data._obj && data._obj.length > 0) {

        let pendingOrders = data._obj.filter(x => x.Status == 1)
        if (pendingOrders && pendingOrders.length > 0)
          sessionStorage.setItem(this.commonconstant.Last_Pending_Order_Time_kitchen, findArrayMax(pendingOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
        else {
          sessionStorage.setItem(this.commonconstant.Last_Pending_Order_Time_kitchen, date.toString())
        }

        let progressOrders = data._obj.filter(x => x.Status == 2);
          if (progressOrders && progressOrders.length > 0) {
            sessionStorage.setItem(this.commonconstant.Last_Order_Time_Customer, findArrayMax(progressOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
          }
          else {
            sessionStorage.setItem(this.commonconstant.Last_Order_Time_Customer, date.toString())
          }
          this.pendingOrdersList = pendingOrders;
        this.InProgressOrdersList = progressOrders;
        this.completedOrdersList = data._obj.filter(x => x.Status == 3);
    
      }
      else {
        this.pendingOrdersList = [];
        this.InProgressOrdersList = [];
        this.completedOrdersList = [];
      }
    });

    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();


      this.intervalSubscription = timer(10000, 10000).subscribe(x => {

      this.subscription = this.restaurantService.ordersRepository.GetAllInProgressOrders(goDowmCode, this.getLastOrderTime())
        .subscribe(data => {


          if (data._statusCode === 200 && data._obj && data._obj.length > 0) {

            this.pendingOrdersList = data._obj.filter(x => x.Status == 1);

            let progressOrders = data._obj.filter(x => x.Status == 2);

            if (progressOrders && progressOrders.length > 0) {

              sessionStorage.setItem(this.commonconstant.Last_Order_Time_Customer, findArrayMax(data._obj.filter(x => x.Status == 2), 'OrderRecordTimeStamp').OrderRecordTimeStamp)
              data._obj.filter(x => x.Status == 2).forEach(element => {
                let orderIndex = this.InProgressOrdersList.findIndex(x => x.OrderCode == element.OrderCode)
                if (orderIndex != -1) {
                  this.InProgressOrdersList[orderIndex] = element
                }
                else {
                  this.InProgressOrdersList.push(element)
                }
              });
            }            
            this.completedOrdersList = data._obj.filter(x => x.Status == 3)
          }
        });
    });
  }

  getLastOrderTime(): any {

    let orderTime:any = sessionStorage.getItem(this.commonconstant.Last_Order_Time_Customer)

    if (!orderTime) {
      orderTime = new Date();
      orderTime = this.dateFormat.transform(new Date(orderTime.getFullYear(), orderTime.getMonth(), orderTime.getDate(), 0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss');
    }
    return orderTime;
  }

  countDownTime() {

    if (this.InProgressOrdersList != null) {
      this.InProgressOrdersList.filter(x => x.Status == 2).forEach(element => {
        var currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
        var orderTime = currentDate + ' ' + element.OrderTime;
        var times = new Date(orderTime);
        var tiiimes = times.setMinutes(times.getMinutes() + element.TimeRequired);
        var timeREquired = new Date(tiiimes).getTime();
        var totalTime = Math.floor(timeREquired - new Date().getTime());

        element.Minutes = Math.floor((totalTime % (1000 * 60 * 60)) / (1000 * 60));

        if (element.Minutes <= 0) {
          element.Minutes = 0;
        }
      });
    }
  }

  ngOnDestroy(): void {

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();
  }

  SortInProgressListing() {
    return sortArray(this.InProgressOrdersList, 'OrderRecordTimeStamp').reverse();
  }
  SortPendingListing() {
    return sortArray(this.pendingOrdersList, 'OrderRecordTimeStamp').reverse();
  }

}
