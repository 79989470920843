import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { StockBatchSerials } from "../../models/common/getjsonfrom-controls-service-model";
@Component({
    selector: 'btn-barcode-renderer',
    template: `
              <input type="button" class="ag-barcode no_enable" (click)="ShowBatchSerialInwardsDialogue()" value="Batch Serial"> 
              `

})
export class BtnBarcodeRendererComponent implements ICellRendererAngularComp {

    params: any;
    //bsModalRef = BsModalRef;
    //modalService: any;
    toastrService: any;
    ProductCode: string = '';
    IsBatchSerial: boolean = false;
    IsBatchRequired: boolean = false;
    IsSerialRequired: boolean = false;
    IsMeasureRequired: boolean = false;
    IsMfgRequired: boolean = false;
    IsExpRequired: boolean = false;
    MeasurementType: number = 0;
    Quantity: number = 0;
    Weight: number = 0;
    documentNumber: string = '';
    inwardRecordID: string = '';
    goDownCode: string = '';
    stockBatchSerialInwards: StockBatchSerials[] = [];
    isEnabled: boolean = false;
    //_path: any;
    isBatchSerialEnabled: boolean = true;
    addFromStock: boolean = false;
    agInit(params: any): void {
        this.params = params;
        //this.bsModalRef = this.params.bsModalRef;
        //this.modalService = this.params.modalService;
        this.toastrService = this.params.toastrService;
        //this._path = this.params.path;
    }

    refresh(params?: any): boolean {
        return true;
    }


    ShowBatchSerialInwardsDialogue() {

        if (this.params.onBarcodeClick instanceof Function) {
            let item = { isEnabled: false, documentNumber: '', goDownCode: '', isBatchSerialEnabled: true, addFromStock: false, params: null };
            item.params = this.params;
            item = this.params.onBarcodeClick(item);
            this.isEnabled = item.isEnabled;
            this.documentNumber = item.documentNumber;
            this.goDownCode = item.goDownCode;
            this.isBatchSerialEnabled = item.isBatchSerialEnabled;
            this.addFromStock = item.addFromStock;
            
        }
        $("#BatchSerialsModal").animate({
            width: "toggle"
        });
        return;
        // if ((this.isBatchSerialEnabled && this.addFromStock) || !this.isBatchSerialEnabled)
        //     return true;

        // if (!this.goDownCode) {
        //     this.toastrService.info("Please select GoDownCode first.");
        //     return;
        // }
        // if (!this.params.node.data.ProductCode) {
        //     this.toastrService.info("Please select product first.");
        //     return;
        // }
        // if (this._path == undefined) {
        //     this.toastrService.info("Path missing");
        //     return;
        // }

        // this.inwardRecordID = this.params.node.data[this.params.inwardRecordID];
        // this.stockBatchSerialInwards = Object.assign([], this.params.node.data.BatchSerialDetails);


        // if (this.stockBatchSerialInwards.length > 0) {
        //     this.loadModal();
        // }
        // else {

        //     this._path.SelectStockBatchSerial(this.documentNumber, this.goDownCode, this.inwardRecordID).subscribe((data: any) => {

        //         if (data._statusCode == 200 && data._obj) {
        //             this.stockBatchSerialInwards = data._obj;
        //         }
        //         this.loadModal();
        //     });
        // }
    }

    //loadModal() {

    //    this.IsMeasureRequired = this.params.node.data.IsMeasurementRequired;
    //    if (this.IsMeasureRequired) {
    //        this.params.node.gridApi.stopEditing();
    //        this.params.node.gridApi.setFocusedCell(this.params.node.rowIndex, 'BatchSerial');
    //        this.params.node.gridApi.startEditingCell({
    //            rowIndex: this.params.node.rowIndex,
    //            colKey: 'BatchSerial',
    //        });
    //    }

    //    this.ProductCode = this.params.node.data.ProductCode;
    //    this.IsBatchRequired = this.params.node.data.IsBatchRequired;
    //    this.IsSerialRequired = this.params.node.data.IsSerialNumberRequired;

    //    this.IsMfgRequired = this.params.node.data.IsManufactureDateRequired;
    //    this.IsExpRequired = this.params.node.data.IsExpiryDateRequired;
    //    this.MeasurementType = this.params.node.data.MeasurementType;
    //    this.Quantity = this.params.node.data.Quantity;
    //    this.Weight = this.params.node.data.Weight;
    //    if (this.IsMeasureRequired) {
    //        if (this.MeasurementType == 1) {
    //            this.Quantity = Number(this.params.node.data.Length) * Number(this.params.node.data.Width) * Number(this.params.node.data.SubQuantity);
    //        }
    //        else {
    //            this.Quantity = Number(this.params.node.data.Height) * Number(this.params.node.data.Width) * Number(this.params.node.data.Length) * Number(this.params.node.data.SubQuantity);
    //        }
    //    }
    //    let _fraction = Number(this.params.node.data.UnitFragment);
    //    if (isNaN(_fraction))
    //        _fraction = 0;

    //    let totalQty = (this.Quantity * _fraction);

    //    const initialState = {
    //        params: this.params,
    //        smartUtilities: this.params.smartUtilities,
    //        toastrService: this.params.toastrService,
    //        onProcess: this.params.onProcess,
    //        ProductCode: this.ProductCode,
    //        IsBatchRequired: this.IsBatchRequired,
    //        IsSerialRequired: this.IsSerialRequired,
    //        IsMeasureRequired: this.IsMeasureRequired,
    //        IsMfgRequired: this.IsMfgRequired,
    //        IsExpRequired: this.IsExpRequired,
    //        MeasurementType: this.MeasurementType,
    //        batchSerialQuantity: totalQty,
    //        Weight: this.Weight,
    //        model: this.stockBatchSerialInwards,
    //        isEnabled: this.isEnabled

    //    };
    //    $("#BatchSerialsModal").animate({
    //        width: "toggle"
    //    });

    //    //this.bsModalRef = this.modalService.show(StockBatchSerialInwardsComponent, {
    //    //    initialState,
    //    //    class: 'modal-lg modal-centered',
    //    //    backdrop: 'static',
    //    //    keyboard: false,
    //    //});
    //}

}

