import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { TransferAccount } from "../../../models/Finance/TransferAccount/transfer-account.model";
import { IAccountTransferRepository } from "./IAccountTransferRepository";

@Injectable()
export class AccountTransferRepository extends GenericRepository<TransferAccount> implements IAccountTransferRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/accountTransfer', environment.urlAddress);
  }   

    GetAccountForTransfer(): Observable<ResponseModel> {
        return this.GetById('/GetAccountForTransfer/').pipe(map(this.extractData), catchError(this.handleError));
    }

    UpdateAccountsForTransfer(item: TransferAccount): Observable<ResponseModel> {
        return this.PostData('/UpdateAccountsForTransfer/', item).pipe(map(this.extractData), catchError(this.handleError));
    }
}