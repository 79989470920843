import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { ChequesModel } from "src/app/models/Finance/Cheques/ChequeRegister/cheque.model";
import { IChequesRepository } from "./IChequesRepository";

@Injectable(
    {
        providedIn:'root'
    }
)
export class ChequesRepository extends GenericRepository<ChequesModel> implements IChequesRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Cheques', environment.urlAddress);
    }

    GetAllRec(currentPage: number, recordsPerPage: number, filterText: string, status: number): Observable<any> {

        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText + '/' + status);
    }

    DeleteRec(code: string) {
        return this.Delete('/DeleteRec/' + '/'+ code);
    }

    SaveRec(item: ChequesModel) {
        return this.PostData('/SaveRec', item);
    }

    LoadRec(code: string, status: string): Observable<any> {
        return this.GetById('/LoadRec/' + code + '/' + status );
    }

    LoadNavRec(navType: number, currentIndex: number, status: string): Observable<any> {
        return this.GetById('/LoadNavRec/' + navType + '/' + currentIndex + '/' + status);
    }
    searchByDocumentNumber(docNumber:string,processType: string, searchProcessed: boolean = true): Observable<any> {
        return this.GetById('/SearchByDocumentNumber/' + docNumber + '/' + processType);
    }

    GetRecByChequeBookNumber(chequeBookNumber:string): Observable<any> {
        return this.GetById('/GetRecByChequeBookNumber/' + chequeBookNumber);
    }
}
