import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResPrinterGroup } from "src/app/models/Restaurant/resprintergroup.model";
import { IResPrinterGroupRepository } from "./IPrinterGroupRepository";
import { environment } from "src/environments/environment";

@Injectable()
export class ResPrinterGroupRepository extends GenericRepository<ResPrinterGroup> implements IResPrinterGroupRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResPrinterGroup', environment.urlAddress); 
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/ResPrinterGroup/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(PrinterGroupCode: string) {
        return this._http.delete(this.url + 'api/ResPrinterGroup/DeleteRec/' + PrinterGroupCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveGroupNames(item: ResPrinterGroup) {
        return this._http.post(this.url + 'api/ResPrinterGroup/SaveGroupNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
