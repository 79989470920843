import { Component, OnInit, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StockBatchSerials } from '../../models/common/getjsonfrom-controls-service-model';
declare var $: any;
@Component({
    selector: 'app-stock-batch-serial-inwards',
    templateUrl: './sale-stock-batch-serial-inwards.component.html',
})
export class SaleStockBatchSerialInwardsComponent implements OnInit {

    params: any;
    smartUtilities: any;
    toastrService: any;
    onProcess: any;
    IsSerialShow: boolean = false;
    ProductCode: string = '';
    IsBatchRequired: boolean = false;
    IsSerialRequired: boolean = false;
    IsMeasureRequired: boolean = false;
    IsMfgRequired: boolean = false;
    IsExpRequired: boolean = false;
    MeasurementType: number = 0;
    Quantity: number = 0;
    documentNumber: string = '';
    batchRequiredOnly: boolean = false;
    serialRequiredOnly: boolean = false;
    batchSerialQuantity: number = 0;
    Weight: number = 0;
    outwardModel: StockBatchSerials[] = [];
    InwardModel: StockBatchSerials[] = [];
    batchModel: StockBatchSerials = new StockBatchSerials();
    serialModel: StockBatchSerials = new StockBatchSerials();
    serialItems: StockBatchSerials[] = [];
    batchItems: StockBatchSerials[] = [];
    dimensionModel: StockBatchSerials = new StockBatchSerials();
    dimensionItems: StockBatchSerials[] = [];
    existingItems: StockBatchSerials[] = [];
    isEnabled: boolean = false;
    isAutoSerials: boolean = true;
    serialPrefix: string;
    indexer: number = 1;
    isUpdateMode: boolean = false;
    TotalRemainingBalance: number = 0;
    IsNewSerialFormShow: boolean = false;
    IsNewBatchFormShow: boolean = false;
    IsNewDimensionShow: boolean = false;
    commonService: any;
    allowSaleMinusStock: boolean = true;
    newRecordAllowed: boolean = false;
    constructor(public bsModalRef: BsModalRef, private el: ElementRef) {

    }


    ngOnInit() {
        
        if (this.outwardModel.length > 0)
            this.existingItems = Object.assign([], this.outwardModel)//copy data into temporary array
        else
            this.existingItems = Object.assign([], this.InwardModel)//copy data into temporary array

        if (this.InwardModel.length > 0)
            this.TotalRemainingBalance = this.InwardModel[0].TotalRemainingBalance;
        else if (this.InwardModel.length == 0 && this.outwardModel.length > 0)
            this.TotalRemainingBalance = this.outwardModel[0].TotalRemainingBalance;
        else
            this.TotalRemainingBalance = 0;
        if (this.IsBatchRequired && this.IsSerialRequired)
            this.IsSerialShow = false;
        else
            this.IsSerialShow = this.IsSerialRequired;

        if ((this.IsBatchRequired || this.IsMeasureRequired) && !this.IsSerialRequired)
            this.batchRequiredOnly = true;
        else
            this.batchRequiredOnly = false;

        if (this.IsSerialRequired && (!this.IsBatchRequired && !this.IsMeasureRequired)) {
            this.serialRequiredOnly = true;
            if ((this.TotalRemainingBalance < this.batchSerialQuantity) && this.newRecordAllowed) {
                this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
                this.IsNewSerialFormShow = true;
            }
            //db also
            if (this.InwardModel.length > 0 || this.outwardModel.length > 0) {
                this.isAutoSerials = true;
                if (!this.isUpdateMode) {
                    if (this.outwardModel.length == 0)
                        for (var i = 0; i < this.batchSerialQuantity; i++) {
                            if (this.InwardModel[i]) {
                                this.outwardModel.push(this.InwardModel[i]);
                            }

                        }
                }
                else
                    this.serialItems = this.outwardModel;


                //Remove extra serials from array
                //const batchSerailsArray = this.outwardModel;
                for (var i = this.batchSerialQuantity; i < this.outwardModel.length; i++) {
                    this.outwardModel.splice(i, 1);
                    i--;
                }
                
                this.serialItems = this.outwardModel;
                if (this.TotalRemainingBalance < this.batchSerialQuantity)
                    this.serialModel.OutwardQuantity = this.batchSerialQuantity - this.serialItems.length;
                else
                    this.serialModel.OutwardQuantity = this.batchSerialQuantity;
                this.serialModel.OutwardWeight = this.Weight * this.serialModel.OutwardQuantity;
                this.refreshSerialsQty();
                if (this.batchSerialQuantity > this.serialItems.length) {
                    //load from db
                    this.loadAutoSerialOnly();

                }


            }
            else {
                this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
                this.serialModel.OutwardQuantity = this.batchSerialQuantity - this.serialItems.length;
                this.serialModel.OutwardWeight = this.Weight * this.serialModel.OutwardQuantity;
                this.isAutoSerials = false;
            }

            this.serialModel.ProductCode = this.ProductCode;


        }
        else
            this.serialRequiredOnly = false;

        if ((this.IsBatchRequired || this.IsMeasureRequired)) {

            if ((this.IsBatchRequired && this.TotalRemainingBalance < this.batchSerialQuantity) && this.newRecordAllowed)
                this.IsNewBatchFormShow = true;
            else
                this.IsNewBatchFormShow = false;

            if (this.IsMeasureRequired  && this.newRecordAllowed)
                this.IsNewBatchFormShow = true;

            if (this.InwardModel.length > 0 || this.outwardModel.length > 0) {
                this.loadBatches();
                this.isAutoSerials = false;
                this.IsSerialShow = false;

            }
        }
        setTimeout(() => {
            this.smartUtilities.EnableDisableFormElement("#BatchDiv", this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
            this.smartUtilities.EnableDisableElement('#btn-process', this.isEnabled);
            this.smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
        }, 100);

    }

    onCancelClick() {
        this.bsModalRef.hide();
    }
    onProcessClick() {

        if (!this.validateBatchSerialsCount())
            return;

        if (this.onProcess instanceof Function) {

            const params = {
                params: this.params,
                value: this.outwardModel,
            }
            this.onProcess(params);
        }
        this.bsModalRef.hide();
    }

    scrollSerialDiv() {
        const objDiv = this.el.nativeElement.querySelector('#serial-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }

    scrollBatchDiv() {
        const objDiv = this.el.nativeElement.querySelector('#batch-inner');
        if (objDiv)
            objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }
    refreshSerialsQty() {

        for (var i = 0; i < this.outwardModel.length; i++) {
            this.outwardModel[i].OutwardQuantity = this.outwardModel.filter(x => x.StockBatchInwardID === this.outwardModel[i].StockBatchInwardID).length;
            this.outwardModel[i].OutwardWeight = this.outwardModel[i].OutwardQuantity * this.Weight;
        }
    }

    loadBatches() {
        
        this.batchItems = [];
        if (this.InwardModel.length == 0 && this.outwardModel.length == 0)
            return;
        let batches = this.InwardModel.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        let OutwardBatches = this.outwardModel.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode && t.StockBatchInwardID.indexOf('NewBarCode') != -1)) === i;
        });

        if (OutwardBatches && OutwardBatches.length > 0)
            for (let item of OutwardBatches)
                batches.push(item);

        if (batches && batches.length > 0)
            this.batchItems = batches;

        if (this.IsBatchRequired && !this.IsSerialRequired && !this.IsMeasureRequired)
            this.outwardModel = Object.assign([], this.batchItems);

        if (!this.isUpdateMode) {
            if (!this.IsMeasureRequired) {
                let Qty: number = this.batchSerialQuantity;
                const existingQty: number = this.getExistingBatchQty();

                if (Qty != existingQty)//means update qty
                    if (batches.length > 0) {
                        for (let i = 0; i < batches.length; i++) {

                            let BalanceQuantity = Number(batches[i].BalanceQuantity);
                            let OutwardQuantity = Number(batches[i].OutwardQuantity);

                            if (BalanceQuantity <= Qty)
                                OutwardQuantity = BalanceQuantity;
                            else
                                OutwardQuantity = Qty;

                            batches[i].OutwardQuantity = OutwardQuantity;
                            batches[i].BaseQuantity = BalanceQuantity - OutwardQuantity;
                            batches[i].OutwardWeight = batches[i].OutwardQuantity * this.Weight;

                            Qty -= Number(OutwardQuantity);
                        }

                    }
            }
        }



    }

    loadBatchSerials(stockBatchInwardID) {
        
        this.serialItems = [];
        if (this.outwardModel == undefined || this.outwardModel.length == 0)
            return;
        let BatcheSerials = []
        if (this.serialRequiredOnly)
            BatcheSerials = this.outwardModel;
        else
            BatcheSerials = Object.assign([], this.outwardModel.filter(x => x.StockBatchInwardID === stockBatchInwardID));
        setTimeout(() => {
            if (BatcheSerials && BatcheSerials.length > 0)
                this.serialItems = BatcheSerials;
        })
    }

    onQuantityChange(data: StockBatchSerials) {
        
        let qty: number = Number(data.OutwardQuantity)
        if (qty > 0) {
            if (data.OutwardQuantity > data.BalanceQuantity)
                data.OutwardQuantity = data.BalanceQuantity;
            if (data.OutwardQuantity > this.batchSerialQuantity)
                data.OutwardQuantity = this.batchSerialQuantity;
            if (data.OutwardQuantity > data.BalanceQuantity)
                data.OutwardQuantity = data.BalanceQuantity;

            data.OutwardWeight = this.Weight * data.OutwardQuantity;

            data.BaseQuantity = data.BalanceQuantity - data.OutwardQuantity;

        }
        if (this.IsSerialRequired) {
            this.serialModel.StockBatchInwardID = data.StockBatchInwardID;
            this.serialModel.BatchNumber = data.BatchNumber;
            this.serialModel.OutwardQuantity = data.OutwardQuantity;
            this.serialModel.OutwardWeight = this.Weight * data.OutwardQuantity;
            this.serialModel.ProductCode = data.ProductCode;

            this.isAutoSerials = true;

            this.onAutoSerialsClick();


        }
    }

    onNewBatchClick() {
        
        if (!this.allowSaleMinusStock)
            return false;

        const ok: boolean = this.smartUtilities.ValidateForm('#NewBatchForm');
        if (ok) {

            const qty: number = Number(this.batchModel.OutwardQuantity);
            const baseQty: number = Number(this.batchSerialQuantity);
            const mfgDate: string = $('#ManufactureDate').val();
            const expDate: string = $('#ExpiryDate').val();
            //If serial required against this item we will not let it enter fraction values
            if (this.IsSerialRequired && (qty % 1 != 0)) {
                this.toastrService.error("Fraction in quanity is not allowed in serial enabled product");
                return false;
            }
            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                return false;
            }

            //here check existing qty
            const existingQty: number = (qty + this.getExistingBatchQty());
            if (existingQty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            //Compare dates to check if mfg date is greater than exp date
            if (this.IsMfgRequired || this.IsExpRequired) {

                var dateMfg: any, dateExp: any;

                if (mfgDate != '' && mfgDate != undefined)
                    dateMfg = this.processDate(mfgDate);

                if (expDate != '' && expDate != undefined)
                    dateExp = this.processDate(expDate);

                if (this.IsMfgRequired && this.IsMfgRequired) {

                    if (dateMfg >= dateExp) {
                        this.toastrService.error('Expiry/End Date Must be Greater than the Start Date');
                        return false;
                    }
                }
            }

            this.batchModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            this.batchModel.ManufactureDate = mfgDate;
            this.batchModel.ExpiryDate = expDate;
            this.batchModel.BalanceQuantity = qty;
            this.batchModel.BaseQuantity = 0;
            this.batchModel.IsNewAdded = true;
            this.batchModel.OutwardQuantity = qty;
            this.batchModel.OutwardWeight = this.Weight * qty;
            this.batchModel.ProductCode = this.ProductCode;

            if (this.IsMeasureRequired) {
                this.batchModel.Length = this.batchModel.OutwardLength;
                this.batchModel.Width = this.batchModel.OutwardWidth;
                this.batchModel.Height = this.batchModel.OutwardHeight;
                this.batchModel.InwardQuantity = this.batchModel.OutwardQuantity;


            }

            const batches = Object.assign({}, this.batchModel);

            if (!this.IsSerialRequired) {
                this.outwardModel.push(batches);
            }


            this.batchItems.push(batches);

            this.batchModel = new StockBatchSerials();

            this.scrollBatchDiv();
            this.IsSerialShow = false;
        }
    }

    onNewSerialClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewSerialForm');
        if (ok) {

            let qty = Number(this.serialModel.OutwardQuantity);

            if (isNaN(qty))
                qty = 0;

            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                this.serialModel.SerialNumber = '';
                return false;
            }

            let alreadyAddedSerials = 0;
            if (this.outwardModel != undefined && this.outwardModel.length > 0)
                alreadyAddedSerials = this.outwardModel.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID).length;
            if (alreadyAddedSerials >= qty) {
                this.toastrService.error('Limit exceeds');
                this.serialModel.SerialNumber = '';
                return false;
            }


            let serialArray = this.outwardModel.filter(x => x.SerialNumber.toLowerCase() === this.serialModel.SerialNumber.toLowerCase())

            if (serialArray.length > 0) {
                this.toastrService.error('Serial number already exists');
                this.serialModel.SerialNumber = '';
                return false;
            }
            //if (this.InwardModel != undefined && this.InwardModel.length > 0)
            //    if (this.serialRequiredOnly)
            //        alreadyAddedSerials = this.outwardModel.length;
            //    else
            //        alreadyAddedSerials = this.outwardModel.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID).length;
            //if (alreadyAddedSerials < this.TotalRemainingBalance) {
            //    let item = this.InwardModel.find(x => x.SerialNumber.toLowerCase() === this.serialModel.SerialNumber.toLowerCase());
            //    if (item == null || item === undefined) {
            //        this.toastrService.error('Invalid Serial number');
            //        this.serialModel.SerialNumber = '';
            //        return false;
            //    }

            //}

            this.serialModel.IsNewAdded = true;

            const serials = Object.assign({}, this.serialModel);
            this.outwardModel.push(serials);
            this.existingItems.push(serials);
            //if (this.TotalRemainingBalance == 0)
            //    this.newAddedItems.push(serials);

            this.serialModel.SerialNumber = '';
            this.loadBatchSerials(this.serialModel.StockBatchInwardID);
            this.scrollSerialDiv();

        }
    }

    onSerialToBatchClick(data: StockBatchSerials) {
        

        //let qty = Number(data.OutwardQuantity);

        //if (isNaN(qty))
        //    qty = 0;

        //if (qty == 0) {
        //    this.toastrService.error('Please enter quantity');
        //    return false;
        //}

        this.IsSerialShow = true;
        this.serialModel.StockBatchInwardID = data.StockBatchInwardID;
        this.serialModel.BatchNumber = data.BatchNumber;
        this.serialModel.OutwardQuantity = data.OutwardQuantity;
        this.serialModel.OutwardWeight = this.Weight * data.OutwardQuantity;
        this.serialModel.ProductCode = data.ProductCode;
        this.isAutoSerials = true;
        this.IsNewSerialFormShow = false;
        if (data.IsNewAdded) {
            this.isAutoSerials = false;
            this.IsNewSerialFormShow = true;
            this.loadBatchSerials(this.serialModel.StockBatchInwardID);
        }
        else {
            this.onAutoSerialsClick();
        }

        //
        //const BatcheSerials = this.InwardModel.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID);
        //if (BatcheSerials.length == 0) {
        //    if (this.TotalRemainingBalance < this.batchSerialQuantity) {
        //        this.IsNewSerialFormShow = true;
        //        this.isAutoSerials = false;
        //    }
        //}

    }



    onRemoveBatchClick(stockBatchInwardID: string) {
        
        if (stockBatchInwardID.indexOf('NewBarCode') != -1) {
            this.outwardModel = this.outwardModel.filter(x => x.StockBatchInwardID !== stockBatchInwardID);
            this.batchItems = this.batchItems.filter(x => x.StockBatchInwardID !== stockBatchInwardID);
            this.dimensionItems = this.dimensionItems.filter(x => x.StockBatchInwardID !== stockBatchInwardID);
            this.IsSerialShow = false;
        }
        else {
            this.outwardModel = this.outwardModel.filter(x => x.TrackID !== stockBatchInwardID);
            this.batchItems = this.batchItems.filter(x => x.TrackID !== stockBatchInwardID);
            this.dimensionItems = this.dimensionItems.filter(x => x.TrackID !== stockBatchInwardID);
        }


    }

    onRemoveSerialClick(serialNumber: string) {

        this.outwardModel = this.outwardModel.filter(x => x.SerialNumber !== serialNumber);
        this.serialItems = this.serialItems.filter(x => x.SerialNumber !== serialNumber);
    }

    getExistingBatchQty(): number {

        let sum: number = 0;
        if (this.IsBatchRequired)
            sum = this.batchItems.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
        else
            sum = this.outwardModel.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
        return sum;
    }

    processDate(date) {

        const parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    generateUUID() {

        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    validateBatchSerialsCount() {
        
        if (this.outwardModel == undefined) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.outwardModel.length == 0) {
            this.toastrService.error('Insufficient Batch/Serials');
            return false;
        }
        if (this.batchRequiredOnly) {
            const sum: number = this.outwardModel.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.serialRequiredOnly) {
            if (this.batchSerialQuantity !== this.outwardModel.length) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }
        else if (this.IsBatchRequired && this.IsSerialRequired) {

            for (let batches of this.batchItems) {

                const stockBatchInward: string = batches.StockBatchInwardID
                const batchNumber: string = batches.BatchNumber;
                let OutwardQuantity: number = Number(batches.OutwardQuantity);
                if (isNaN(OutwardQuantity))
                    OutwardQuantity = 0;

                const result = this.outwardModel.filter(x => x.StockBatchInwardID === stockBatchInward);

                if (result.length < OutwardQuantity) {
                    this.toastrService.error('Insufficient Batch/Serials' + ' : ' + batchNumber);
                    return false;

                }
            }


            const sum: number = this.getDistinctArray().reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);
            if (this.batchSerialQuantity !== sum) {
                this.toastrService.error('Insufficient Batch/Serials');
                return false;
            }
        }

        return true;
    }
    getDistinctArray(): StockBatchSerials[] {

        let batches: StockBatchSerials[] = this.batchItems.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        return batches;
    }



    onAutoSerialsClick() {

        
        this.IsNewSerialFormShow = false;
        if (this.isAutoSerials) {

            if (this.serialModel.OutwardQuantity) {

                this.serialItems = [];

                if (this.serialRequiredOnly) {

                    this.outwardModel = [];

                    for (var i = 0; i < this.serialModel.OutwardQuantity; i++) {//1st check from existing array
                        if (this.existingItems[i])
                            this.outwardModel.push(this.existingItems[i]);
                        //if (this.isUpdateMode) {
                        //    if (this.existingItems[i])
                        //        this.outwardModel.push(this.existingItems[i]);
                        //}
                        //else {
                        //    if (this.InwardModel[i])
                        //        this.outwardModel.push(this.InwardModel[i]);
                        //}

                    }
                    this.serialItems = this.outwardModel;
                    this.scrollSerialDiv();
                    this.refreshSerialsQty();
                    if (this.serialModel.OutwardQuantity > this.serialItems.length) {
                        //load from db
                        this.loadAutoSerialOnly();

                    }
                }
                else {


                    //let items = Object.assign([], this.outwardModel.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID));
                    //if (this.isUpdateMode)
                    let items = Object.assign([], this.existingItems.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID));

                    this.outwardModel = this.outwardModel.filter(x => x.StockBatchInwardID !== this.serialModel.StockBatchInwardID);//Clear


                    for (var i = 0; i < this.serialModel.OutwardQuantity; i++) {//1st check from existing array
                        if (items[i]) {
                            items[i].OutwardQuantity = this.serialModel.OutwardQuantity;
                            items[i].OutwardWeight = this.serialModel.OutwardWeight;
                            this.outwardModel.push(items[i]);
                            this.serialItems.push(items[i]);
                        }
                    }
                    this.scrollBatchDiv();

                    //Remove extra serials from array
                    const batchSerailsArray = this.outwardModel;
                    for (var i = this.serialModel.OutwardQuantity; i < items.length; i++) {

                        for (var k = this.serialModel.OutwardQuantity; k < batchSerailsArray.length; k++) {
                            if (batchSerailsArray[k].StockBatchInwardID == items[i].StockBatchInwardID) {
                                batchSerailsArray.splice(k, 1);
                                k--;
                            }
                        }
                    }
                    if (this.serialModel.OutwardQuantity > this.serialItems.length) {
                        //load from db
                        
                        const stockBatchInwardID = this.serialModel.StockBatchInwardID;
                        const qty: number = this.serialModel.OutwardQuantity - this.serialItems.length;
                        const excludedSerials: string[] = items.map(({ SerialNumber }) => SerialNumber);
                        this.commonService.SelectStockBatchSerialAuto(stockBatchInwardID, qty, excludedSerials).subscribe((data: any) => {
                            
                            if (data._statusCode == 200 && data._obj) {
                                let items: StockBatchSerials[] = data._obj;
                                for (var i = 0; i < items.length; i++) {
                                    items[i].ProductCode = this.serialModel.ProductCode;
                                    items[i].OutwardQuantity = this.serialModel.OutwardQuantity;
                                    items[i].OutwardWeight = this.serialModel.OutwardWeight;
                                    this.outwardModel.push(items[i]);
                                    this.serialItems.push(items[i]);
                                    //if (this.TotalRemainingBalance < this.batchSerialQuantity)
                                    //    this.IsNewSerialFormShow = true;
                                }
                                this.scrollBatchDiv();
                            }
                        });
                    }

                }
            }
        }
        else {
            if (this.serialRequiredOnly) {
                this.outwardModel = [];
                if ((this.TotalRemainingBalance < this.batchSerialQuantity) && this.newRecordAllowed)
                    this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');

                this.serialModel.OutwardQuantity = this.batchSerialQuantity;
                this.serialModel.OutwardWeight = this.Weight * this.serialModel.OutwardQuantity;
                //this.serialModel.StockBatchInwardID = this.serialItems[this.serialItems.length-1].StockBatchInwardID;


            }
            else
                this.outwardModel = this.outwardModel.filter(x => x.StockBatchInwardID !== this.serialModel.StockBatchInwardID);
            this.serialItems = [];
            if (this.newRecordAllowed)
            this.IsNewSerialFormShow = true;
        }

    }

    loadAutoSerialOnly() {
        
        let batches: StockBatchSerials[] = this.InwardModel.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0) {
            const stockBatchInwardID = batches.map(({ StockBatchInwardID }) => StockBatchInwardID);
            const qty: number = this.batchSerialQuantity - this.serialItems.length;
            const excludedSerials: string[] = this.serialItems.map(({ SerialNumber }) => SerialNumber);
            this.commonService.SelectStockBatchSerialAuto(stockBatchInwardID, qty, excludedSerials).subscribe((data: any) => {
                
                if (data._statusCode == 200 && data._obj) {
                    let items: StockBatchSerials[] = data._obj;
                    for (var i = 0; i < items.length; i++) {
                        this.outwardModel.push(items[i]);
                    }
                    this.serialItems = this.outwardModel;

                }
                this.refreshSerialsQty();

                if (this.newRecordAllowed) {
                    if (this.TotalRemainingBalance < this.batchSerialQuantity)
                        this.serialModel.OutwardQuantity = this.batchSerialQuantity - this.serialItems.length;
                    else
                        this.serialModel.OutwardQuantity = this.batchSerialQuantity;
                    this.serialModel.OutwardWeight = this.Weight * this.serialModel.OutwardQuantity;

                    this.IsNewSerialFormShow = false;
                    if (this.TotalRemainingBalance < this.batchSerialQuantity) {
                        this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
                        this.IsNewSerialFormShow = true;
                    }
                }
                
                this.scrollSerialDiv();
               
            });
        }
    }
    onAddToDimensionClick(data: StockBatchSerials) {
        
        let qty = Number(data.BalanceQuantity);
        if (isNaN(qty))
            qty = 0;
        if (qty == 0) {
            this.toastrService.error('Please enter quantity');
            return false;
        }
        this.IsSerialShow = true;
        this.isAutoSerials = false;
        this.dimensionModel.BatchNumber = data.BatchNumber;
        if (data.IsNewAdded)
            this.IsNewDimensionShow = false;
        else
            this.IsNewDimensionShow = true;

        this.dimensionModel = Object.assign({}, data);

        this.loadBatchDimension(this.dimensionModel.StockBatchInwardID);
    }

    loadBatchDimension(stockBatchInwardID) {
        
        this.dimensionItems = new Array<StockBatchSerials>();
        if (this.outwardModel == undefined || this.outwardModel.length == 0)
            return;
        setTimeout(() => {
            let dimensionItems = this.outwardModel.filter(x => x.StockBatchInwardID === stockBatchInwardID);
            if (dimensionItems && dimensionItems.length > 0) {
                for (let i = 0; i < this.dimensionItems.length; i++)
                    dimensionItems[i].TrackID = this.generateUUID();
                this.dimensionItems = dimensionItems;

            }


        });

    }

    onBatchDimensionChange() {

        if (this.MeasurementType == 1)
            this.batchModel.OutwardQuantity = (Number(this.batchModel.OutwardLength) * Number(this.batchModel.OutwardWidth));
        else
            this.batchModel.OutwardQuantity = (Number(this.batchModel.OutwardLength) * Number(this.batchModel.OutwardWidth) * Number(this.batchModel.OutwardHeight));
    }
    onDimensionChange() {

        if (this.MeasurementType == 1) {

            if (Number(this.dimensionModel.OutwardLength) > Number(this.dimensionModel.Length))
                this.dimensionModel.OutwardLength = this.dimensionModel.Length;
            if (Number(this.dimensionModel.OutwardWidth) > Number(this.dimensionModel.Width))
                this.dimensionModel.OutwardWidth = this.dimensionModel.Width;
            this.dimensionModel.OutwardQuantity = (Number(this.dimensionModel.OutwardLength) * Number(this.dimensionModel.OutwardWidth));


        }
        else {
            if (Number(this.dimensionModel.OutwardLength) > Number(this.dimensionModel.Length))
                this.dimensionModel.OutwardLength = this.dimensionModel.Length;
            if (Number(this.dimensionModel.OutwardWidth) > Number(this.dimensionModel.Width))
                this.dimensionModel.OutwardWidth = this.dimensionModel.Width;
            if (Number(this.dimensionModel.OutwardHeight) > Number(this.dimensionModel.Height))
                this.dimensionModel.OutwardHeight = this.dimensionModel.Height;

            this.dimensionModel.OutwardQuantity = (Number(this.dimensionModel.OutwardLength) * Number(this.dimensionModel.OutwardWidth) * Number(this.dimensionModel.OutwardHeight));
        }

    }

    onNewDimensionClick() {
        
        const ok: boolean = this.smartUtilities.ValidateForm('#NewDimensionForm');
        if (ok) {

            const qty: number = Number(this.dimensionModel.OutwardQuantity);
            const baseQty: number = Number(this.dimensionModel.BalanceQuantity);

            if (qty == 0) {
                this.toastrService.error('Please enter quantity');
                return false;
            }

            //here check existing qty
            let existingQty: number = (qty + this.getExistingDimensionQty());
            if (existingQty > baseQty) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }

            existingQty = (qty + this.getExistingBatchQty());
            if (existingQty > this.batchSerialQuantity) {
                this.toastrService.error('Batch Outward quantity can not be greater than available quantity');
                return false;
            }

            this.dimensionModel.TrackID = this.generateUUID();
            this.dimensionModel.OutwardQuantity = qty;
            this.dimensionModel.OutwardWeight = this.Weight * qty;
            this.dimensionModel.ProductCode = this.ProductCode;
            const batches = Object.assign({}, this.dimensionModel);


            this.outwardModel.push(batches);

            this.dimensionItems.push(batches);

            this.dimensionModel.OutwardHeight = 0;
            this.dimensionModel.OutwardLength = 0;
            this.dimensionModel.OutwardQuantity = 0;
            this.dimensionModel.OutwardWeight = 0;
            this.dimensionModel.OutwardWidth = 0;

            this.scrollBatchDiv();
        }
    }

    getExistingDimensionQty(): number {

        let sum: number = this.dimensionItems.reduce((prev, curr) => prev + Number(curr.OutwardQuantity), 0);       
        return sum;
    }
}
