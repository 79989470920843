﻿/*
 * added by Yameen 02-01-2020
 * to handle numeric formats
 */
import { Injectable } from "@angular/core";
import { NumericPipe } from "../pipes/numeric.pipe";
import { NullableNumericPipe } from "../pipes/nullable-numeric.pipe";

@Injectable()

export class ValueFormatter {

    constructor(private numericPipe: NumericPipe, private nullableNumericPipe: NullableNumericPipe) { }

    priceFormat = (value: any): string => this.numericPipe.transform(value, 1);
    amountFormat = (value: any): string => this.numericPipe.transform(value, 2);
    quantityFormat = (value: any): string => this.numericPipe.transform(value, 3);
    currencyFormat = (value: any): string => this.numericPipe.transform(value, 4);
    taxFormat = (value: any): string => this.numericPipe.transform(value, 5);
    numericFormat = (value: any): string => this.numericPipe.transform(value, 5);

    cellPriceFormat = (params: any): string => this.numericPipe.transform(params.value, 1);
    cellAmountFormat = (params: any): string => this.numericPipe.transform(params.value, 2);
    cellQuantityFormat = (params: any): string => this.numericPipe.transform(params.value, 3);
    cellCurrencyFormat = (params: any): string => this.numericPipe.transform(params.value, 4);
    cellTaxFormat = (params: any): string => this.numericPipe.transform(params.value, 5);

    /*
    * added by Yameen 12-05-2020
    * TODO: handle case where value == "" to return "", i.e used only placeholder intead of label in textbox - reference import modal
    */

    nullablePriceFormat = (value: any): string => this.nullableNumericPipe.transform(value, 1);
    nullableAmountFormat = (value: any): string => this.nullableNumericPipe.transform(value, 2);
    nullableQuantityFormat = (value: any): string => this.nullableNumericPipe.transform(value, 3);
    nullableCurrencyFormat = (value: any): string => this.nullableNumericPipe.transform(value, 4);
    nullableTaxFormat = (value: any): string => this.nullableNumericPipe.transform(value, 5);

    nullableCellPriceFormat = (params: any): string => this.nullableNumericPipe.transform(params.value, 1);
    nullableCellAmountFormat = (params: any): string => this.nullableNumericPipe.transform(params.value, 2);
    nullableCellQuantityFormat = (params: any): string => this.nullableNumericPipe.transform(params.value, 3);
    nullableCellCurrencyFormat = (params: any): string => this.nullableNumericPipe.transform(params.value, 4);
    nullableCellTaxFormat = (params: any): string => this.nullableNumericPipe.transform(params.value, 5);
}