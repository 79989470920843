
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { InvoicePostingSetup } from "../../models/InvoicePostingSetup/invoice-posting-setup-model";
import { IInvoicePostingSetupRepository } from "./IInvoicePostingSetup";

@Injectable()
export class InvoicePostingSetupRepository extends GenericRepository<InvoicePostingSetup> implements IInvoicePostingSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/invoicePostingSetup/', environment.urlAddress);
    }
    GetRec(paymentType: number): Observable<any> {
        return this.GetById('GetRec/' + paymentType).pipe(map(this.extractData), catchError(this.handleError));
    }
}
