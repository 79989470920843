import { Buyer, BuyerImport } from "../../models/Buyer/buyer.model";
import { IBuyerRepository } from "./IBuyerRepository";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable(
    {
        providedIn:'root'
    }
)
export class BuyerRepository extends GenericRepository<Buyer> implements IBuyerRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/buyer/', environment.urlAddress);
    }
    GetBuyerAddress(buyerCode: string, addressType: string) {
        return this._http
            .get(this.url + 'api/buyer/BuyerAddress' + '/' + buyerCode + '/' + addressType, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http
            .get(this.url + 'api/buyer/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(buyerCode: string, isAddress: boolean, isName: boolean, isGraph: boolean = false): Observable<any> {
        return this.GetById('/GetRec/' + buyerCode + '/' + isAddress + '/' + isName + '/' + isGraph).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetRecWithCodeOrPhone(Code: string): Observable<any> {
        return this.GetById('/GetRecWithCodeOrPhone/' + Code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(buyerCode: string) {
        return this.Delete('/DeleteRec/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    FilterRecords(rowNumber: string, pageSize: string, navType: string, buyerrCode: string): Observable<ResponseModel> {
        return this.GetById('/FilterRecords/' + rowNumber + '/' + pageSize + '/' + navType + '/' + buyerrCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAllBuyerCodes(): Observable<any> {
        return this._http.get('api/buyer/GetAllBuyerCodes', this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveBuyersImportRec(ImportBuyersSaveData: Array<BuyerImport>): Observable<any> {
        return this._http.post('api/buyer/SaveBuyersImportRec', ImportBuyersSaveData, this.httpOptions)
            .pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('/SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));;
    }
}


 
  

