import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResProductGroups } from "src/app/models/Restaurant/resproductgroups.model";
import { IResProductGroupsRepository } from "./IResProductGroupsRepository";
import { environment } from "src/environments/environment";
@Injectable()
export class ResProductGroupsRepository extends GenericRepository<ResProductGroups> implements IResProductGroupsRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ResProductGroups/', environment.urlAddress);
    }
    LoadRec(ProductGroupCode: string): Observable<any> {
        return this.GetById('/GetRec/' + ProductGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/ResProductGroups/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(ProductGroupCode: string) {
        return this.Delete('/DeleteRec/' + ProductGroupCode).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAll(): Observable<any> {
        return this._http.get(this.url + 'api/ResProductGroups/GetAll', this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}