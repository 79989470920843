import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { IChequeBookConfigurationRepository } from "./IChequeBookConfigurationRepository";
import { ChequeBookModel } from "src/app/models/Finance/Cheques/ChequeBookConfiguration/cheque-book.model";
import { map, catchError } from "rxjs/operators";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class ChequeBookConfigurationRepository extends GenericRepository<any> implements IChequeBookConfigurationRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ChequeBookConfiguration', environment.urlAddress);
    }

    GetAllRec(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {

        if (filterText == "") {
            filterText = "|";
        }
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText);
    }

    DeleteRec(code: string) {
        return this.Delete('/DeleteRec/' + code);
    }

    SaveRec(item: ChequeBookModel) {
        return this.PostData('/SaveRec', item);
    }

    LoadRec(code: string): Observable<any> {
        return this.GetById('/GetRec/' + code);
    }

    LoadNavRec(navType: number, currentIndex: number): Observable<any> {
        return this.GetById('/LoadNavRec/' + navType + '/' + currentIndex);
    }

    GetAllForSearch(): Observable<any> {
        return this._http
          .get(this.url + 'api/ChequeBookConfiguration/GetAllForSearch' + '/' , this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
      }
}