import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { Expenses } from "src/app/models/Finance/Expenses/expenses.model";
import { IExpensesRepository } from "./IExpensesRepository";

@Injectable({ providedIn: 'root' })
export class ExpensesRepository extends GenericRepository<any> implements IExpensesRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Expenses', environment.urlAddress);
    }

    NavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.Get('NavRec/' + navType + '/' + currentIndex);
    }

    GetRec(code: string): Observable<ResponseModel> {
        return this.Get('GetRec/' + code);
    }

    SelectAll(currentPage: number, recordsPerPage: number, filterText: string, expenseStatus: number = null): Observable<ResponseModel> {

        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }

        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage + '/' + filterText + '/' + expenseStatus);
    }

    SaveRec(item: Expenses): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }

    ApproveExpenses(expenseNumber: string, approvedBy: string): Observable<ResponseModel> {
        return this.Get('ApproveExpenses/' + expenseNumber + '/' + approvedBy);
    }

    SelectAccountsToPost(item: Array<Expenses>, paymentMode: string, expenseNumbers: string): Observable<ResponseModel> {
        return this.PostData('/SelectAccountsToPost/' + paymentMode + '/' + expenseNumbers, item);
    }

    PostExpenses(item: Array<any>, postedBy: string, expenseNumbers: string): Observable<ResponseModel> {
        return this.PostData('/PostExpenses/' + postedBy + '/' + expenseNumbers, item);
    }

    
}