﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PartnerChatModel } from "src/app/models/PartnerServices/PartnerChatModel";
import { environment } from "../../../environments/environment";
import { ResponseModel } from "../../models/SharedModels/response.model";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { IPartnerChatRepository } from "./IPartnerChatRepository";

@Injectable({ providedIn: 'root' })
export class PartnerChatRepository extends GenericRepository<PartnerChatModel> implements IPartnerChatRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PartnerChat', environment.urlAddress);
    }

    SelectAllChat(receiverID: number, requestID: any): Observable<ResponseModel> {
        return this.Get('/SelectAllChat/' + receiverID + "/" + requestID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectAllChatNotification(receiverID: number): Observable<ResponseModel> {
        return this.Get('/SelectAllChatNotification/' + receiverID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SendMessage(model: PartnerChatModel): Observable<any> {
        return this.PostData('/SendMessage', model).pipe(map(this.extractData), catchError(this.handleError));
    }
}
