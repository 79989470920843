import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPurchaseInvoiceRepository } from "./IPurchaseInvoiceRepository";
import { PurchaseInvoice } from "src/app/models/PurchaseInvoice/purchase-invoice.model";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable(
    {
        providedIn: "root"
    }
)

export class PurchaseInvoiceRepository extends GenericRepository<PurchaseInvoice> implements IPurchaseInvoiceRepository {


    SelectPendingInvoice(vendorCode: string): Observable<any> {
        return this.Get("SelectPendingInvoice/" + vendorCode);
    }

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PurchaseInvoice', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/PurchaseInvoice/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllForDashBoard (currentPage: number, recordsPerPage: number, godownCode: string ='', vendorCode:string='' , DateFrom:string='',DateTo:string=''): Observable<any>{
        godownCode = godownCode!='' ? godownCode : 'null';
        return this._http
            .get(this.url + 'api/PurchaseInvoice/SelectAllForDashBoard' + '/' + currentPage + '/' + recordsPerPage + '/' + godownCode + '/' + DateFrom + '/' + DateTo + '/' + vendorCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/PurchaseInvoice/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessInvoice(code: string): Observable<any> {
        return this.GetById('/ProcessInvoice/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessInvoiceForGRR(code: string): Observable<any> {
        return this.GetById('/ProcessingForGRR/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }

    PurchaseInvoiceWithOrderSearch(DocumentNumber: string , documentType:number): Observable<any> {
        return this.GetById('/PurchaseInvoiceWithOrderSearch/' + DocumentNumber + '/' + documentType).pipe(map(this.extractData), catchError(this.handleError));
    }

    ProcessInvoiceSearch(invoiceSearchObj: any): Observable<any> {
        return this._http.post(this.url + 'api/PurchaseInvoice/PurchaseInvoiceSearch', invoiceSearchObj, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    ProcessInvoiceSearchForGRR(invoiceSearchObj: any): Observable<any> {
        return this._http.post(this.url + 'api/PurchaseInvoice/PurchaseInvoiceSearchForGRR', invoiceSearchObj, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    ProcessAllInvoiceSearch(invoiceSearchObj: any): Observable<any> {
        return this._http.post(this.url + 'api/PurchaseInvoice/PurchaseInvoiceSearch/1', invoiceSearchObj, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, godownCode: string, paymentType: number, vendorCode: string, isTaxRefundable: boolean, isTaxByVendor: boolean, processNumber?: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + godownCode + '/' + paymentType + '/' + vendorCode + '/' + isTaxRefundable + '/' + isTaxByVendor + '/' + processNumber, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectStockBatchSerial(inwardDocument: string, godownCode: string, inwardRecordID: string): Observable<any> {
        return this.GetById('/SelectStockBatchSerial/' + inwardDocument + '/' + godownCode + '/' + inwardRecordID).pipe(map(this.extractData), catchError(this.handleError));
    }

    SelectVendorInvoices(currentPage: number, recordsPerPage: number, vendorCode: string): Observable<any> {
        return this.GetById('SelectVendorInvoices' + '/' + currentPage + '/' + recordsPerPage + '/' + vendorCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    CheckBatchSerialAlreadyExist(serialNumber: string): Observable<any> {
        return this._http.get(this.url + 'api/PurchaseInvoice/CheckBatchSerialAlreadyExist' + '/' + serialNumber, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    PurchaseInvoiceWithOrderSearchAndGRR(DocumentNumber: string , documentType:number): Observable<any> {
        return this.GetById('/PurchaseInvoiceWithOrderSearchAndGRR/' + DocumentNumber + '/' + documentType).pipe(map(this.extractData), catchError(this.handleError));
    }
}