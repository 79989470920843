import { Component, EventEmitter, Output } from '@angular/core';
import { StockBatchSerials, StockSerialBatch } from '../../models/common/getjsonfrom-controls-service-model';
import { ToastrService } from 'ngx-toastr';
import { updateGridSerials } from 'src/app/services/common-methods/common-methods-exports';
import { ResponseModel } from 'src/app/models/SharedModels/response.model';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { TranslatePipe } from '../custom-pipes/translate-pipe/translate.pipe';
declare var $: any;
@Component({
    selector: 'app-batch-serial-inwards',
    templateUrl: './batch-serial-inwards.component.html',
})
export class BatchSerialInwardsComponent { 
    @Output() saveRecord = new EventEmitter<any>();
    private batchRequiredOnly: boolean = false;
    private serialRequiredOnly: boolean = false;

    public model: StockBatchSerials[] = [];
    public batchModel: StockBatchSerials = new StockBatchSerials();
    public serialModel: StockBatchSerials = new StockBatchSerials();
    public serialItems: StockSerialBatch[] = [];
    public batchItems: StockBatchSerials[] = [];
    public batchRecords: StockBatchSerials[] = [];
    public isEnabled: boolean = false;
    public isAutoSerials: boolean = true;
    public serialPrefix: string;
    public batchLabel: string = '';
    public indexer: number = 1;
    public batchNumber: string = '';
    public costPrice: number = null;
    public expiryDate: string = '';
    public stockBatchInwardID: string = '';
    public sellingPrice: number = null;
    public autoGeneratedSerialNumber: string = '';
    public autoGeneratedSerialNumberStartFromToEnd: string = '';
    public inwardQuantity: number = null;
    public showGrid: boolean = false;
    public showFields: boolean = true;
    public jsonObject: any;
    public _isSerialShow: boolean = false;
    public _isSelected: boolean = false;
    public isUpdate: boolean = false;
    public isOpenAlready: boolean = false;

    private _serialNumber: number = 0;
    private _productCode: string = '';
    private _productName: string = '';
    private _unitName: string = '';
    private _godownName: string = '';
    private _quantity: number = 0;
    private _isBatchRequired: boolean = false;
    private _isSerialRequired: boolean = false;
    private _isMeasureRequired: boolean = false;
    private _isMfgRequired: boolean = false;
    private _isExpRequired: boolean = false;
    private _measurementType: number = 0;
    private _weight: number = 0;
    private _onProcess: any;
    private _params: any;
    private _smartUtilities: any;
    
    // private _toastrService: any;

    public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };

    get serialNumber(): number {
        return this._serialNumber;
    }
    set serialNumber(value: number) {
        this._serialNumber = value;
    }

    get productCode(): string {
        return this._productCode;
    }
    set productCode(value: string) {
        this._productCode = value;
    }
    get productName(): string {
        return this._productName;
    }
    set productName(value: string) {
        this._productName = value;
    }
    get unitName(): string {
        return this._unitName;
    }
    set unitName(value: string) {
        this._unitName = value;
    }
    get godownName(): string {
        return this._godownName;
    }
    set godownName(value: string) {
        this._godownName = value;
    }
    get quantity(): number {
        return this._quantity;
    }
    set quantity(value: number) {
        this._quantity = value;
    }
    get isBatchRequired(): boolean {
        return this._isBatchRequired;
    }
    set isBatchRequired(value: boolean) {
        this._isBatchRequired = value;
    }
    get isSerialRequired(): boolean {
        return this._isSerialRequired;
    }
    set isSerialRequired(value: boolean) {
        this._isSerialRequired = value;
    }
    get isMeasureRequired(): boolean {
        return this._isMeasureRequired;
    }
    set isMeasureRequired(value: boolean) {
        this._isMeasureRequired = value;
    }
    get isMfgRequired(): boolean {
        return this._isMfgRequired;
    }
    set isMfgRequired(value: boolean) {
        this._isMfgRequired = value;
    }
    get isExpRequired(): boolean {
        return this._isExpRequired;
    }
    set isExpRequired(value: boolean) {
        this._isExpRequired = value;
    }
    get measurementType(): number {
        return this._measurementType;
    }
    set measurementType(value: number) {
        this._measurementType = value;
    }
    get weight(): number {
        return this._weight;
    }
    set weight(value: number) {
        this._weight = value;
    }
    get params(): any {
        return this._params;
    }
    set params(value: any) {
        this._params = value;
    }
    get smartUtilities(): any {
        return this._smartUtilities;
    }
    set smartUtilities(value: any) {
        this._smartUtilities = value;
    }
    // get toastrService(): any {
    //     return this._toastrService;
    // }
    // set toastrService(value: any) {
    //     this._toastrService = value;
    // }
    get onProcess(): any {
        return this._onProcess;
    }
    set onProcess(value: any) {
        this._onProcess = value;
    }
    constructor(private toastrService: ToastrService, private businessService: BusinessServices, private translate: TranslatePipe) {
        $('.batchserialmodal .close').click(function () {
            $('.batchserialmodal').hide({ direction: 'right' }, 'slow');
        });
    }
    onInit() {
        let qty = Number(this._quantity);
        if (qty < 5)
            qty = 5;
        if (this._isBatchRequired && this._isSerialRequired)
            this._isSerialShow = false;
        else
            this._isSerialShow = this._isSerialRequired;

        if ((this._isBatchRequired || this._isMeasureRequired) && !this._isSerialRequired)
            this.batchRequiredOnly = true;
        else
            this.batchRequiredOnly = false;
        if (this._isSerialRequired && (!this._isBatchRequired && !this._isMeasureRequired)) {
            this.serialRequiredOnly = true;
            //db also
            if (this.model.length > 0) {
                this.serialItems = this.model;
                this.serialModel.StockBatchInwardID = this.serialItems[0].StockBatchInwardID;
                this.serialModel.BatchNumber = this.serialItems[0].BatchNumber;
                this.refreshSerialsQty();

            }
            //else
            //    this.serialModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            else {                
                this.addSerialInitialRows('', '', qty, qty);
            }
            //this.serialModel.InwardQuantity = this.batchSerialQuantity;
            //this.serialModel.InwardWeight = this._weight * this.batchSerialQuantity;
            //this.serialModel.ProductCode = this.productCode;
            //this.isAutoSerials = false;

        }
        else
            this.serialRequiredOnly = false;
        if ((this._isBatchRequired || this._isMeasureRequired)) {
            if (this.model.length > 0) {
                //this.loadBatches();
                if (this._isSerialRequired) {
                    //setTimeout(() => {

                    //    const element: HTMLElement = document.getElementsByClassName('btnSerialToBatch')[0] as HTMLElement;
                    //    if (element)
                    //        element.click();
                    //}, 100);

                }
            }
            else {

                this.addBatchInitialRows(5);
            }
        }
        setTimeout(() => {
            //this._smartUtilities.EnableDisableFormElement("#BatchDiv", this.isEnabled);
            //this._smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
            //this._smartUtilities.EnableDisableElement('#btn-process', this.isEnabled);
            //this._smartUtilities.EnableDisableFormElement("#BatchSerialsDiv", this.isEnabled);
        }, 100);
    }


    datePicker() {
        // $("input#ManufactureDate").datepicker();
        // $("input#ExpiryDate").datepicker();

         $('.DateTextBox').off().removeData().datepicker({ dateFormat: 'dd/mm/yy' });
    }

    AddNewBatchRow() { //Used in Grid
        setTimeout(() => {
            this.addBatchInitialRows(1);          
        }, 100);
    }
    //Used in Grid
    addBatchInitialRows(qty: number = 0) {
        let item: StockBatchSerials = new StockBatchSerials();
        var serialNumber = 1;
        for (var i = 0; i < qty; i++) {
            item.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            item.BatchNumber = '';
            item.SerialNumber = String(serialNumber);
            item.InwardQuantity = qty;
            //item.InwardWeight = this._weight * qty;
            item.ProductCode = this._productCode;
            const batches = Object.assign({}, item);
            if (!this._isSerialRequired)
                this.model.push(batches);
            this.batchItems.push(batches);
            Number(serialNumber++);
        }
        setTimeout(() => {
            this.datePicker();
        }, 100) 
    }
     //Used in Grid
    RefereshBatches(item: StockBatchSerials) {
        
        if (!this._isSerialRequired)
            return
        setTimeout(() => {
            
            this.serialPrefix = item.BatchNumber;
            this.batchLabel = item.BatchNumber;
            this.loadBatchSerials(item.StockBatchInwardID);
            let inwardQuantity: number = Number(item.InwardQuantity);
            let BatcheSerials = Object.assign([], this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID));
            if (BatcheSerials.length > 0) {
                let Qty: number = (BatcheSerials.length - inwardQuantity);

                if (BatcheSerials.length > inwardQuantity) {
                 
                    BatcheSerials.splice((inwardQuantity - 1), Qty);
                    this.serialItems.splice((inwardQuantity - 1), Qty);
                    this.model = this.model.filter(x => x.StockBatchInwardID != item.StockBatchInwardID);

                    for (var i = 0; i < BatcheSerials.length; i++) {
                        // update current element Qty
                        BatcheSerials[i].InwardQuantity = inwardQuantity;
                        BatcheSerials[i].InwardWeight = inwardQuantity * this._weight;
                    }
                    this.model.concat(BatcheSerials);
                }
                else {
                    for (var i = 0; i < this.model.length; i++) {
                        if (this.model[i].StockBatchInwardID === item.StockBatchInwardID) {
                            // update current element Qty
                            this.model[i].InwardQuantity = Number(item.InwardQuantity);
                            this.model[i].InwardWeight = this.model[i].InwardQuantity * this._weight;
                        }
                    }
                }
            }
            if (Number(inwardQuantity - BatcheSerials.length) > 0)
                this.addSerialInitialRows(item.StockBatchInwardID, item.BatchNumber, (inwardQuantity - BatcheSerials.length), inwardQuantity);
            console.log(this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID));
            console.log(this.serialItems);
            console.log(this.model);
        })
    }




    onProcessClick() { //used to add to procceed button       
        if (!this.validateBatchSerialsCount())
           return;

        if (this._onProcess instanceof Function) {

            const params = {
                params: this._params,
                value: this.model,
            }
            this._onProcess(params);
        }
        $('.batchserialmodal').hide({ direction: 'right' }, 'slow');
    }
    refreshSerialsQty() {
        
        for (var i = 0; i < this.model.length; i++) {

            this.model[i].InwardQuantity = this._quantity;
            this.model[i].InwardWeight = this.model[i].InwardQuantity * this._weight;
        }
    }
    loadBatches() {
        this.batchItems = [];
        if (this.model == undefined || this.model.length == 0)
            return;
        let batches = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        if (batches && batches.length > 0)
            this.batchItems = batches;

    }

    loadBatchSerials(stockBatchInwardID) {
        
        this.serialItems = [];
        if (this.model == undefined || this.model.length == 0)
            return;
        const BatcheSerials = this.model.filter(x => x.StockBatchInwardID === stockBatchInwardID);
        if (BatcheSerials && BatcheSerials.length > 0)
            this.serialItems = BatcheSerials;
    }

    onNewBatchClick() {        
        
        const ok: boolean = this._smartUtilities.ValidateForm('#NewBatchForm');
        if (ok) {
            
            const qty: number = Number(this.batchModel.InwardQuantity);
            const baseQty: number = Number(this._quantity);
            const mfgDate: string = $('#ManufactureDate').val();
            const expDate: string = $('#ExpiryDate').val();
            //If serial required against this item we will not let it enter fraction values
            if (this._isSerialRequired && (qty % 1 != 0)) {
                this.toastrService.error("Fraction in quanity is not allowed in serial enabled product");
                return false;
            }
            if (qty == 0) {
                this.toastrService.error(this.translate.transform("1488"));
                return false;
            }

            //here check existing qty
            const existingQty: number = (qty + this.getExistingBatchQty());
            if (existingQty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            if (qty > baseQty) {
                this.toastrService.error('Batch Inward quantity can not be greater than available quantity');
                return false;
            }

            //Compare dates to check if mfg date is greater than exp date
            if (this._isMfgRequired || this._isExpRequired) {

                var dateMfg: any, dateExp: any;

                if (mfgDate != '' && mfgDate != undefined)
                    dateMfg = this.processDate(mfgDate);

                if (expDate != '' && expDate != undefined)
                    dateExp = this.processDate(expDate);

                if (this._isMfgRequired && this._isMfgRequired) {

                    if (dateMfg >= dateExp) {
                        this.toastrService.error('Expiry/End Date Must be Greater than the Start Date');
                        return false;
                    }
                }
            }

            this.batchModel.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
            this.batchModel.ManufactureDate = mfgDate;
            this.batchModel.ExpiryDate = expDate;
            //this.batchModel.BaseQuantity = baseQty;
            this.batchModel.InwardQuantity = qty;
            this.batchModel.InwardWeight = this._weight * qty;
            this.batchModel.ProductCode = this._productCode;
            const batches = Object.assign({}, this.batchModel);

            if (!this._isSerialRequired)
                this.model.push(batches);

            this.batchItems.push(batches);

            this.batchModel = new StockBatchSerials();
            this._isSerialShow = false;
        }
    }

    onNewSerialClick() {        
        const ok: boolean = this._smartUtilities.ValidateForm('#NewSerialForm');
        if (ok) {

            let qty = Number(this.serialModel.InwardQuantity);

            if (isNaN(qty))
                qty = 0;

            if (qty == 0) {
                this.toastrService.error(this.translate.transform("1488"));
                this.serialModel.SerialNumber = '';
                return false;
            }
            let alreadyAddedSerials = 0;
            if (this.model != undefined && this.model.length > 0)
                alreadyAddedSerials = this.model.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID).length;
            if (alreadyAddedSerials >= qty) {
                this.toastrService.error('Limit exceeds');
                this.serialModel.SerialNumber = '';
                return false;
            }


            let serialArray = this.model.filter(x => x.SerialNumber.toLowerCase() === this.serialModel.SerialNumber.toLowerCase())

            if (serialArray.length > 0) {
                this.toastrService.error('Serial number already exists');
                this.serialModel.SerialNumber = '';
                return false;
            }

            const serials = Object.assign({}, this.serialModel);
            this.model.push(serials);
            this.serialModel.SerialNumber = '';
            this.loadBatchSerials(this.serialModel.StockBatchInwardID);

        }
    }

    onSerialToBatchClick(data: StockBatchSerials) {

        this._isSerialShow = true;
        this.serialModel.StockBatchInwardID = data.StockBatchInwardID;
        this.serialModel.BatchNumber = data.BatchNumber;
        this.serialModel.InwardQuantity = data.InwardQuantity;
        //this.serialModel.BaseQuantity = data.BaseQuantity;
        this.serialModel.InwardWeight = data.InwardWeight;
        this.serialModel.ProductCode = data.ProductCode;
        this.isAutoSerials = true;
        this.serialPrefix = data.BatchNumber;
        this.indexer = 1;

        this.loadBatchSerials(this.serialModel.StockBatchInwardID);

        const BatcheSerials = this.model.filter(x => x.StockBatchInwardID === this.serialModel.StockBatchInwardID);
        if (BatcheSerials && BatcheSerials.length == 0 && this.isAutoSerials)
            this.autoProcessClick();
    }

    onRemoveBatchClick(stockBatchInwardID: string) {
        setTimeout(() => {
        this.model = this.model.filter(x => x.StockBatchInwardID != stockBatchInwardID);
        let item = this.batchItems.find(x => x.StockBatchInwardID == stockBatchInwardID);
        if (item) {
            item.BatchNumber = '';
            item.SerialNumber = '';
            item.InwardQuantity = 0;
        }
            this._isSerialShow = false;
        }, 100);
    }

    onRemoveSerialClick(item: StockBatchSerials) {       
        setTimeout(() => {
            this.model = this.model.filter(x => x.SerialNumber != item.SerialNumber);
            if (item) {
                item.SerialNumber = '';
            }

            this.model.push(item);
        }, 100);
    }
  
    getExistingBatchQty(): number {
        const sum: number = this.batchItems.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
        return sum;
    }

    processDate(date) {

        const parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    generateUUID() {

        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    validateBatchSerialsCount() {        
        let item = this.model.filter(x => x.SerialNumber != '' || x.BatchNumber != '');

        if (item == undefined) {
            this.toastrService.error(this.translate.transform("1554"));
            return false;
        }
        if (item.length == 0) {
            this.toastrService.error(this.translate.transform("1554"));
            return false;
        }
        if (this.batchRequiredOnly) {
            const sum: number = item.reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this._quantity !== sum) {
                this.toastrService.error(this.translate.transform("1554"));
                return false;
            }
        }
        else if (this.serialRequiredOnly) {
            if (this._quantity !== item.length) {
                this.toastrService.error(this.translate.transform("1554"));
                return false;
            }
        }
        else if (this._isBatchRequired && this._isSerialRequired) {

            for (let batches of item) {

                const stockBatchInward: string = batches.StockBatchInwardID
                const batchNumber: string = batches.BatchNumber;
                let InwardQuantity: number = Number(batches.InwardQuantity);
                if (isNaN(InwardQuantity))
                    InwardQuantity = 0;

                const result = item.filter(x => x.StockBatchInwardID === stockBatchInward);

                if (result.length < InwardQuantity) {
                    this.toastrService.error(this.translate.transform("1554") + ' : ' + batchNumber);
                    return false;

                }
            }


            const sum: number = this.getDistinctArray().reduce((prev, curr) => prev + Number(curr.InwardQuantity), 0);
            if (this._quantity !== sum) {
                this.toastrService.error(this.translate.transform("1554"));
                return false;
            }
        }

        return true;
    }

    getDistinctArray(): StockBatchSerials[] {

        let batches: StockBatchSerials[] = this.model.filter((e, i, arr) => {
            return arr.indexOf(arr.find(t => t.StockBatchInwardID === e.StockBatchInwardID && t.ProductCode === e.ProductCode)) === i;
        });
        return batches;
    }

    autoProcessClick() {

        if (this.serialPrefix && this.indexer && this.serialModel.InwardQuantity) {

            const ok: boolean = this._smartUtilities.ValidateForm('#autoModal');
            if (ok) {


                let item = Object.assign({}, this.serialModel);

                let qty = Number(item.InwardQuantity);

                if (isNaN(qty))
                    qty = 0;

                if (qty == 0) {
                    this.toastrService.error(this.translate.transform("1488"));
                    item.SerialNumber = '';
                    return false;
                }
                let alreadyAddedSerials = 0;
                if (this.model != undefined && this.model.length > 0)
                    alreadyAddedSerials = this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID).length;
                if (alreadyAddedSerials >= qty) {
                    this.toastrService.error('Limit exceeds');
                    item.SerialNumber = '';
                    return false;
                }
                qty = qty - alreadyAddedSerials;
                //let serialPrefix: string = this.serialPrefix == '' ? '' : (this.serialPrefix + ' - ');
                let serialPrefix: string = this.serialPrefix;
                for (var i = 0; i < qty; i++) {

                    let serialNumber = (serialPrefix + this.indexer);
                    item.SerialNumber = serialNumber;
                    let serialArray = this.model.filter(x => x.SerialNumber.toLowerCase() === item.SerialNumber.toLowerCase())

                    if (serialArray.length > 0) {
                        this.toastrService.error('Serial number already exists');
                        item.SerialNumber = '';
                        break;
                    }

                    const serials = Object.assign({}, item);
                    this.model.push(serials);
                    item.SerialNumber = '';
                    this.indexer++;
                }
                this.indexer = 1;
                this.loadBatchSerials(item.StockBatchInwardID);
            }



        }
        $('#autoModal').modal('hide');
    }

    onAutoSerialsClick() {

        if (this.isAutoSerials)
            this.autoProcessClick();
        else {
            this.model = this.model.filter(x => x.StockBatchInwardID != this.serialModel.StockBatchInwardID);
            this.serialItems = this.serialItems.filter(x => x.StockBatchInwardID != this.serialModel.StockBatchInwardID);
        }

    }

    onDimensionChange() {

        if (this._measurementType == 1)
            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width));
        else
            this.batchModel.InwardQuantity = (Number(this.batchModel.Length) * Number(this.batchModel.Width) * Number(this.batchModel.Height));
    }


    ///////////////////////Added by Muneeb///////////////

    toggleDetails(event, item: StockBatchSerials): void {//Used when click on batch number
        
        if (!this._isSerialRequired)
            return;
        // var elements = document.getElementsByClassName('bg-info');
        // while (elements.length > 0) {
        //     elements[0].classList.remove('bg-info');
        // }
        // if (event.target.type == 'text')
        //     event.target.parentElement.parentElement.classList.add('bg-info');
        // else
        //     event.target.parentElement.classList.add('bg-info');
        setTimeout(() => {
            
            this._isSerialShow = true;
            this.isAutoSerials = true;
            this.serialPrefix = item.BatchNumber;
            this.batchLabel = item.BatchNumber;
            this.indexer = 1;
            this.addSerialInitialRows(item.StockBatchInwardID, item.BatchNumber)
            // this.loadBatchSerials(item.StockBatchInwardID);
            // let inwardQuantity: number = Number(item.InwardQuantity);
            // const BatcheSerials = this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID);
            // this.addSerialInitialRows(item.StockBatchInwardID, item.BatchNumber, (inwardQuantity - BatcheSerials.length), inwardQuantity);
        }, 100);
    }

    autoClick() {//Used in serial process button
        const ok: boolean = this._smartUtilities.ValidateForm('#autoModal');
        if (ok) {
            setTimeout(() => {

                // this.businessService.purchaseInvoiceRepository.CheckBatchSerialAlreadyExist(String(this.indexer)).subscribe((data: ResponseModel) => {
                //     if (data._statusCode == 200 && data._obj) {
                      
        
                //     } else {
                //        this.toastrService.error(data._message);
                //     }
                // });
                for (var i = 0; i < this.serialItems.length; i++) {
                    this.serialItems[i].SerialNumber = this.serialPrefix + Number(this.indexer);
                    this.indexer = Number(this.indexer) + 1;
                }
                this.indexer = 1;
            }, 100);
        }
    }

    addBatchInModel() { 
        
        var isvalid = true;

        if(!this.batchNumber){
            this.toastrService.error(this.translate.transform("3863"));
            isvalid = false;
            return false;
        }

        if(this.quantity <= 0){
            this.toastrService.error(this.translate.transform("3108"));
            isvalid = false;
            return false;
        }

        if(!$("#ExpiryDate").val()){
            this.toastrService.error(this.translate.transform("3862"));
            isvalid = false;
            return false;
        }

        if(!$("#ManufactureDate").val()){
            this.toastrService.error(this.translate.transform("3861"));
            isvalid = false;
            return false;
        }

        if(Number(this.inwardQuantity) <= 0){
            this.toastrService.error(this.translate.transform("3108"));
            isvalid = false;
            return false;
        }
        
        if(Number(this.sellingPrice) <= 0){
            this.toastrService.error(this.translate.transform("3860"));
            isvalid = false;
            return false;
        }

        if(Number(this.costPrice) <= 0){
            this.toastrService.error(this.translate.transform("3865"));
            isvalid = false;
            return false;
        }

        

        let totalQuantity = null;
        this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber)).forEach(element => {
            totalQuantity += Number(element.InwardQuantity);
        });

        totalQuantity += Number(this.inwardQuantity);
        if(totalQuantity > Number(this.quantity)){
            this.toastrService.error(this.translate.transform("3864"));
            isvalid = false;
            return false;
        }

        let item: StockBatchSerials = new StockBatchSerials();
        item.StockBatchInwardID = (this.generateUUID());
        item.BatchNumber = this.batchNumber;
        item.SerialNumber = String(this.serialNumber);
        item.InwardQuantity = Number(this.inwardQuantity);
        item.SellingPrice = this.sellingPrice;
        item.BalanceQuantity = Number(this.inwardQuantity);
        item.BaseQuantity = Number(this.inwardQuantity);
        item.ExpiryDate = $("#ExpiryDate").val();
        item.ManufactureDate = $("#ManufactureDate").val();
        item.ProductCode = this._productCode;
        item.CostPrice = this.costPrice;
        const batches = Object.assign({}, item);
        if (!this._isSerialRequired)
            this.model.push(batches);

        if(isvalid){
            this.batchItems.push(batches);
            this.batchRecords = this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber));
            this.showGrid = true;
        }

        let baseQuantity = 0;
        this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber)).forEach(element => {
            baseQuantity += Number(element.InwardQuantity);
        });
        
        if(baseQuantity >= this.quantity){
            
            this.showFields = false;
            if (this.saveRecord) {
                this.saveRecord.next(null);
            }
        }
        else
            this.showFields = true;

        this.batchNumber = "";
        this.inwardQuantity = null;

        // if (this._onProcess instanceof Function) {

        //     const params = {
        //         value: this.batchItems,
        //     }
        //     this._onProcess(params);
        // }

    }

    onQuantityChange(){
        
        if(this.batchItems.length > 0){
            
            let baseQuantity = null;

            if(Number(this.inwardQuantity) <= 0){
                this.toastrService.error(this.translate.transform("1058"));
            }

            this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber)).forEach(element => {

                baseQuantity += Number(element.InwardQuantity);
            });

            baseQuantity += Number(this.inwardQuantity);
            if(baseQuantity > Number(this.quantity)){
                this.toastrService.error(this.translate.transform("3864"));
            }
        }
        else{

            if(Number(this.inwardQuantity) <= 0){
                this.toastrService.error(this.translate.transform("1058"));
            }

            if(this.inwardQuantity > Number(this.quantity)){
                this.toastrService.error(this.translate.transform("3864"));
            }
        }
    }

    openModel(){ 
        $(".batchserialmodal").animate({ width: "toggle" });
        this.stockBatchInwardID = (this.generateUUID() + '-NewBarCode');
        
        if(this.batchItems.length > 0){
            
            this.batchRecords = this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber));
            let baseQuantity = null;
            this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber)).forEach(element => {
                baseQuantity += Number(element.InwardQuantity);
            });

            if(baseQuantity >= Number(this.quantity)) 
                this.showFields = false;
            else{
                this.showFields = true;
                this.isOpenAlready = false;
            }
        }
        else{
            
            this.batchRecords = null;
            this.showFields = true;
            this.showGrid = false;
            this.isOpenAlready = false;
        }

        if(!this.isOpenAlready){
            setTimeout(() => {
                this.datePicker();
                this.isOpenAlready = true;
            }, 100) 
        }
    }

    onCancelClick() {
        
        if(this.batchItems.filter(x => x.ProductCode == this.productCode && x.SerialNumber == String(this.serialNumber)).length > 0){
            let totalQuantity = 0;
            this.batchItems.forEach(element => {
                totalQuantity +=  element.InwardQuantity
            });
    
            if(this.quantity > totalQuantity){
                this.toastrService.error(this.translate.transform("1554"));
            }
            else{
                $('.batchserialmodal').hide({ direction: 'right' }, 'slow');
            }
        }
        else{
            $('.batchserialmodal').hide({ direction: 'right' }, 'slow');
            $('#Batch' + this.serialNumber).text("Select").removeClass("badge-success").addClass("badge-primary");
        }
    }

    
    deleteProduct(data: any){
        const index = this.batchItems.findIndex(x => x.ProductCode == data.ProductCode && x.CostPrice == data.CostPrice && x.BatchNumber == data.BatchNumber && x.SerialNumber == data.SerialNumber);
        const selectedIndex = this.batchRecords.findIndex(x => x.ProductCode == data.ProductCode && x.CostPrice == data.CostPrice && x.BatchNumber == data.BatchNumber && x.SerialNumber == data.SerialNumber);
        if(index !== -1)
            this.batchItems.splice(index, 1);

        if(selectedIndex !== -1)
            this.batchRecords.splice(selectedIndex, 1);

        let baseQuantity = 0;
        if(this.batchItems.length > 0){
            this.batchItems.forEach(element => {
                baseQuantity += element.InwardQuantity;
            });
        }
        else{
            this.showGrid = false;
        }
       

        if(baseQuantity >= this.quantity)
        this.showFields = false;
        else{
            this.showFields = true;
            setTimeout(() => {
                this.datePicker();
                this.isOpenAlready = true;
            }, 100) 
        }

        if(this.batchRecords.length == 0){
            $('#Batch' + this.serialNumber).text("Select").removeClass("badge-success").addClass("badge-primary");
        }
    }

    addSerialInitialRows(stockBatchInwardID: string = '', batchNumber: string = '', rows: number = 0, qty: number = 0) {
        // let item: StockBatchSerials = new StockBatchSerials();
        // item.StockBatchInwardID = stockBatchInwardID;
        // if (stockBatchInwardID == '')
        //     item.StockBatchInwardID = (this.generateUUID() + '-NewBarCode');
        // for (var i = 0; i < rows; i++) {
        //     item.BatchNumber = batchNumber;
        //     item.SerialNumber = ''
        //     item.InwardQuantity = qty;
        //     item.InwardWeight = this._weight * qty;
        //     item.ProductCode = this._productCode;
        //     const serials = Object.assign({}, item);
        //     this.model.push(serials);
        // }
        // this.serialItems = this.model.filter(x => x.StockBatchInwardID === item.StockBatchInwardID);

        let item: StockSerialBatch = new StockSerialBatch();
        item.StockBatchInwardID = stockBatchInwardID;
        if (stockBatchInwardID == '')
            item.StockBatchInwardID = (this.generateUUID());
        
        item.BatchNumber = batchNumber;
        item.SerialNumber = ''
        const serials = Object.assign({}, item);
        this.serialItems.push(serials);
    }

   
}
