import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISaleCollectionsRepository } from "./ISaleCollectionsRepository";
import { SalesCollections } from "src/app/models/sale-collection/sale-collections.model";
import { ResponseModel } from "../../models/SharedModels/response.model";

@Injectable()

export class SaleCollectionsRepository extends GenericRepository<SalesCollections> implements ISaleCollectionsRepository {


    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/SalesCollections', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/SalesCollections/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(OrderNumber: string): Observable<any> {
        return this.GetById('/GetRec/' + OrderNumber).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadSaleInvoice(buyerCode: string): Observable<any> {
        return this.GetById('/LoadSaleInvoice/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }

    LoadSaleInvoiceBuBuyerOrSalesAgent(buyerCode: string, salesAgentCode: string): Observable<any> {
        if (buyerCode == "") {
            buyerCode = "|";
        }
        if (salesAgentCode == "") {
            salesAgentCode = "|";
        }
        return this._http
            .get(this.url + 'api/SalesCollections/LoadSaleInvoiceBuBuyerOrSalesAgent' + '/' + buyerCode + '/' + salesAgentCode , this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetNavRec(navType: number, currentIndex: number): Observable<any> {
        return this._http.get(this.url + 'api/SalesCollections/NavRec' + '/' + navType + '/' + currentIndex, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(PurchaseOrder: string) {
        return this.Delete('/DeleteRec/' + PurchaseOrder).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, paymentType: number, vendorCode: string, collectorCode: string, godownCode?: string): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + paymentType + '/' + vendorCode + '/' + collectorCode + '/' + godownCode, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectBuyerPayments(currentPage: number, recordsPerPage: number, buyerCode: string): Observable<any> {
        return this.GetById('SelectBuyerPayments' + '/' + currentPage + '/' + recordsPerPage + '/' + buyerCode).pipe(map(this.extractData), catchError(this.handleError));
    }
}