import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Table } from "src/app/models/Restaurant/Table.model";
import { ITableRepository } from "./ITableRepository";
import { ResponseModel } from "src/app/models/SharedModels/response.model";
import { catchError, map } from "rxjs/operators";

@Injectable(
)
export class TableRepository extends GenericRepository<Table> implements ITableRepository {
    
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/Tables', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this.Get('SelectAll' + '/' + currentPage + '/' + recordsPerPage)
    }

    SaveRec(item: Table): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(code: string): Observable<any> {
        return this.Delete('DeleteRec/' + code);
    }
    
}
