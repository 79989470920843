import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IEmployeeCustodyRepository } from "./IEmployeeCustodyRepository";
import { EmployeeCustody } from "../../../models/HR/EmployeeCustody/employee-custody.model";

@Injectable(
    {
        providedIn:'root'
    }
)
export class EmployeeCustodyRepository extends GenericRepository<EmployeeCustody> implements IEmployeeCustodyRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/employeecustody', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/employeecustody/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: EmployeeCustody): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/LoadRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadHistoryRec(code: string): Observable<ResponseModel> {
        return this.GetById('/LoadHistoryRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    SelectPostingSetup(postingSetup: any, paymentType: number, cardChargeType: number): Observable<ResponseModel> {
        return this.PostData('/SelectPostingSetup' + '/' + paymentType + '/' + cardChargeType, postingSetup).pipe(map(this.extractData), catchError(this.handleError));
    }

    GetAssetsByEmployee(employeeCode:string):Observable<any>
    {
        return this.GetById('/GetAssetsByEmployee/' + employeeCode);
    }
}