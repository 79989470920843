import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ITailorTypeRepository } from "./ITailorTypeRepository";
import { TailorType } from "../../models/TailorType/TailorType.model";

@Injectable()
export class TailorTypeRepository extends GenericRepository<TailorType> implements ITailorTypeRepository {
    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/TailorType', environment.urlAddress);
    }

    GetAll(currentPage: number, recordsPerPage: number): Observable<any> {
        return this._http
            .get(this.url + 'api/TailorType/SelectAll' + '/' + currentPage + '/' + recordsPerPage, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    DeleteRec(TailorTypeCode: string) {
        return this._http.delete(this.url + 'api/TailorType/DeleteRec/' + TailorTypeCode, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

    SaveStyleNames(item: TailorType) {
        return this._http.post(this.url + 'api/TailorType/SaveStyleNames', item, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }

}
