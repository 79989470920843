import { formatDate } from '@angular/common';
import { SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { element } from '@angular/core/src/render3/instructions';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs/internal/observable/timer';
import { Subscription } from 'rxjs/internal/Subscription';
import { ResOrders } from 'src/app/models/Restaurant/ResOrders.model';
import { findArrayMax, sortArray } from 'src/app/services/common-methods/common-methods-exports';
import { RestaurantService } from 'src/app/services/restaurant/restaurant.service';
import { BusinessServices } from 'src/app/services/singleton/business-services';
import { ApplicationCodes, CommonConstant } from 'src/app/shared/constent/applicationcodes';
import { DateFormatterPipe } from 'src/app/shared/custom-pipes/date-formatter.pipe/date-formatter.pipe';
import { TranslatePipe } from 'src/app/shared/custom-pipes/translate-pipe/translate.pipe';
import { Globals } from 'src/environments/Globals';
declare var $: any;

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.css']
})

export class KitchenComponent implements OnInit, OnDestroy {
  pendingOrdersList = new Array<ResOrders>();
  // pendingOrdersList = Observable<Array<ResOrders>>;
  inProgressOrdersList = new Array<ResOrders>();
  completedOrdersList = new Array<ResOrders>();
  commonconstant = new CommonConstant();
  model: ResOrders = new ResOrders();
  godownData: any[];
  timeName: string = "";
  timeValue: number = -1440;
  ascDesc: number = 1;

  goDowmCode: any = "";
  goDownName: any = "";
  printTime: any = "";
  CompanyLogo: any = "";
  vatNumber: string = "";
  CompanyAddress: string = "";
  companyname: string = "";
  screen: string = '2';
  showMainContent: Boolean = false;
  OrderCode: string = '';
  CompletionTime: string = '';
  Status: number = null;
  TimeRequired: number = null;
  domainURL: string = '';
  applicationCodes = new ApplicationCodes();

  subscription: Subscription;
  intervalSubscription: Subscription;
  companySymbol: string = '';

  notifications: Array<Notification> = new Array<Notification>();
  totalUnreadNotifications: number = 0;
  constructor(private globals: Globals, private toastrService: ToastrService, private translate: TranslatePipe,
    private restaurantService: RestaurantService, private businessService: BusinessServices, private dateFormat: DateFormatterPipe) {
    this.domainURL = window.location.origin;
  }
 



  ngOnInit() {
    
    this.bindOrdersListings(this.goDowmCode, this.timeValue);

    this.businessService.commonService.Get(this.applicationCodes.GodownTableName).subscribe((data: any) => {
      this.godownData = data.filter(x => x.TblName == this.applicationCodes.GodownTableName);
    });

    $(document).click(function () {
      $("#notfidrop").hide();
    });

    $(".bellicon").click(function (e) {
      e.stopPropagation();
      $("#notfidrop").show();
    });

    $("body").addClass("kitchenbody");

    this.updateNotificationsListFromStorage()

    if (this.globals.getDataFromLocalStorage(this.commonconstant.CompanyName) != null && this.globals.getDataFromLocalStorage(this.commonconstant.CompanyName) != "") {
      this.companyname = this.globals.getDataFromLocalStorage(this.commonconstant.CompanyName);
    }

    if (this.globals.getDataFromLocalStorage(this.commonconstant.CompanyAddress) != null && this.globals.getDataFromLocalStorage(this.commonconstant.CompanyAddress) != "") {
      this.CompanyAddress = this.globals.getDataFromLocalStorage(this.commonconstant.CompanyAddress);
    }

    if (this.globals.getDataFromLocalStorage(this.commonconstant.CompanyLogo) != null && this.globals.getDataFromLocalStorage(this.commonconstant.CompanyLogo) != ",") {
      this.CompanyLogo = this.globals.getDataFromLocalStorage(this.commonconstant.CompanyLogo);
    }

    if (this.globals.getDataFromLocalStorage(this.commonconstant.CompanyCurrencySymbol)) {
      this.companySymbol = this.globals.getDataFromLocalStorage(this.commonconstant.CompanyCurrencySymbol);
    }

    this.vatNumber = this.globals.getDataFromLocalStorage(this.commonconstant.CompanyVatNumber);

    setInterval(() => {
      this.countDownTime();
    }, 1000);
  }

  /* modal box plus minus toggle */
  quantity: number = 0;
  i = 0;
  plus() {
    if (this.i != 0) {
      this.i++;
      this.quantity = this.i
    }
    else {
      this.quantity = 1;
      this.i = 1;
    }
  }
  minus() {
    if (this.i != 1) {
      this.i--;
      this.quantity = this.i
    }
  }

  closePopUp() {
    this.i = 0;
    this.quantity = 0;
  }

  toggle() {
    if (this.screen == '1') {
      this.showMainContent = true;
    }
    else {
      this.showMainContent = false;
    }
  }

  onGoDownChange(value) {
    var selectedItem = this.godownData.find(x => x.code == value);
    if (selectedItem == null || selectedItem == undefined)
      selectedItem = this.godownData.find(x => x.name.toLowerCase() == value.toLowerCase());
    if (selectedItem) {
      this.goDowmCode = selectedItem.code;
      this.goDownName = selectedItem.name;
    }
    else {
      this.goDowmCode = "";
      this.goDownName = "";
    }
    this.bindOrdersListings(this.goDowmCode, this.timeValue);
  }

  onTimeFilterChange(){    
    this.bindOrdersListings(this.goDowmCode, this.timeValue);
  }

  bindOrdersListings(goDownCode: string, timeValue: number) {
    
    let date = new Date()
    date = this.dateFormat.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss');
    let latestDate = this.dateFormat.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    
    
    this.restaurantService.ordersRepository.GetAllForKitchen(goDownCode, date, date, timeValue, latestDate, this.ascDesc).subscribe(data => {
      
      if (data._statusCode === 200 && data._obj && data._obj.length > 0) {

        let pendingOrders = data._obj.filter(x => x.Status == 1)
        if (pendingOrders && pendingOrders.length > 0)
          sessionStorage.setItem(this.commonconstant.Last_Pending_Order_Time_kitchen, findArrayMax(pendingOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
        else {
          sessionStorage.setItem(this.commonconstant.Last_Pending_Order_Time_kitchen, date.toString())
        }


        let progressOrders = data._obj.filter(x => x.Status == 2)
        if (progressOrders && progressOrders.length > 0) {
          sessionStorage.setItem(this.commonconstant.Last_Order_Time_Kitchen, findArrayMax(progressOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
        }
        else {
          sessionStorage.setItem(this.commonconstant.Last_Order_Time_Kitchen, date.toString())
        }

        this.pendingOrdersList = pendingOrders;
        this.inProgressOrdersList = progressOrders;
        this.completedOrdersList = data._obj.filter(x => x.Status == 3);

        console.log(this.sortPendingOrders())
      }
      else {
        this.pendingOrdersList = [];
        this.inProgressOrdersList = [];
        this.completedOrdersList = [];
      }
    });

    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();



    this.intervalSubscription = timer(10000, 10000).subscribe(x => {
      
      this.subscription = this.restaurantService.ordersRepository.GetAllForKitchen(goDownCode, this.getLastInProgressOrderTime(), this.getLastPendingOrderTime(), timeValue, latestDate, this.ascDesc)
        .subscribe(data => {

          
          if (data._statusCode === 200 && data._obj && data._obj.length > 0) {

            let pendingOrders = data._obj.filter(x => x.Status == 1);

            if (pendingOrders && pendingOrders.length > 0) {

              sessionStorage.setItem(this.commonconstant.Last_Pending_Order_Time_kitchen, findArrayMax(pendingOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
              pendingOrders.forEach(element => {
                let orderIndex = this.pendingOrdersList.findIndex(x => x.OrderCode == element.OrderCode)
                if (orderIndex != -1) {
                  this.pendingOrdersList[orderIndex] = element
                  this.showNotification(element, 'UPDATE')
                }
                else {
                  this.pendingOrdersList.push(element)
                  this.showNotification(element)
                }
              });
            }


            let progressOrders = data._obj.filter(x => x.Status == 2);

            if (progressOrders && progressOrders.length > 0) {

              sessionStorage.setItem(this.commonconstant.Last_Order_Time_Kitchen, findArrayMax(progressOrders, 'OrderRecordTimeStamp').OrderRecordTimeStamp)
              progressOrders.forEach(element => {
                let orderIndex = this.inProgressOrdersList.findIndex(x => x.OrderCode == element.OrderCode)
                if (orderIndex != -1) {
                  this.inProgressOrdersList[orderIndex] = element
                }
                else {
                  this.inProgressOrdersList.push(element)
                }
              });
            }

            this.completedOrdersList = data._obj.filter(x => x.Status == 3)
          }
        });
    });
  }

  showNotification(order: ResOrders, Operation: string = 'INSERT') {

    let orderMsg = this.translate.transform('505') + ': #' + order.OrderCode + '<br/> ' + this.translate.transform('3632') + ': ' + order.TimeRequired;
    this.toastrService.info(orderMsg,
      Operation === 'INSERT' ? this.translate.transform('3692') : this.translate.transform('3693'),
      {
        timeOut: 1000,
        positionClass: 'toast-bottom-right',
        toastClass: 'custom-Toast'
      }
    );

    let newNotification = new Notification();

    newNotification.OrderCode = order.OrderCode;
    newNotification.TimeRequired = order.TimeRequired;
    newNotification.IsInsertNotification = Operation == "INSERT";
    newNotification.RowIndex = this.notifications.length;
    this.notifications.push(newNotification)

    // Delay added so that div load in view
    setTimeout(() => {

      $("#" + newNotification.OrderCode).addClass("hvr-buzz");
      setTimeout(() => {

        $("#" + newNotification.OrderCode).removeClass("hvr-buzz");
      }, 1500)
    }, 200); 

    this.totalUnreadNotifications = this.notifications.filter(x => !x.IsNotificationRead).length;
    this.updateNotificationsInStorage()

  }

  onNotificationClick(clickedNotification: Notification) {

    clickedNotification.IsNotificationRead = true;
    this.totalUnreadNotifications = this.notifications.filter(x => !x.IsNotificationRead).length;

    document.getElementById(clickedNotification.OrderCode).scrollIntoView({ behavior: "smooth" });

    $("#" + clickedNotification.OrderCode).addClass("hvr-buzz");

    setTimeout(() => {
      $("#" + clickedNotification.OrderCode).removeClass("hvr-buzz");
    }, 1500)

    setTimeout(() => {
      $("html, body").animate({ scrollTop: 0 });
    }, 1500);
    this.updateNotificationsInStorage()
  }

  onReadAllNotificationClick() {
    this.notifications.forEach(element => {
      element.IsNotificationRead = true;
    });
    this.updateNotificationsInStorage()
    this.totalUnreadNotifications = 0;
  }

  getLastInProgressOrderTime(): any {
    
    let orderTime: any = sessionStorage.getItem(this.commonconstant.Last_Order_Time_Kitchen)
    
    if (!orderTime) {
      orderTime = new Date();
      orderTime = this.dateFormat.transform(new Date(orderTime.getFullYear(), orderTime.getMonth(), orderTime.getDate(), 0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss');
    }
    return orderTime;
  }
  getLastPendingOrderTime(): any {
    let orderTime: any = sessionStorage.getItem(this.commonconstant.Last_Pending_Order_Time_kitchen)
    if (!orderTime) {
      orderTime = new Date();
      orderTime = this.dateFormat.transform(new Date(orderTime.getFullYear(), orderTime.getMonth(), orderTime.getDate(), 0, 0, 0, 0), 'yyyy-MM-dd hh:mm:ss');
    }
    return orderTime;
  }

  startOrder(orderCode: string, completionTime: string, status: number, timeRequired: number, spliceRecord: boolean = true, updationTime: string = '') {
    this.restaurantService.ordersRepository.UpdateStatus(orderCode, completionTime, updationTime, status, timeRequired).subscribe((data: any) => {
      if (data && data._statusCode == 200) {
        this.toastrService.success(this.translate.transform(data._message));
        if (spliceRecord) {
          if (status == 2) {
            var exist = this.pendingOrdersList.find(x => x.OrderCode === orderCode);
            if (exist) {
              let index = this.pendingOrdersList.indexOf(exist);
              if (index !== -1) {
                this.pendingOrdersList[index].Status = status;
                this.pendingOrdersList[index].CompletionTime = completionTime;
                this.pendingOrdersList[index].RowIndex = this.inProgressOrdersList.length;
                this.inProgressOrdersList.push(this.pendingOrdersList.find(x => x.OrderCode == orderCode));
                this.pendingOrdersList.splice(index, 1);
              }
            }
          }
          else if (status == 3) {
            var exist = this.inProgressOrdersList.find(x => x.OrderCode === orderCode);
            if (exist) {
              let index = this.inProgressOrdersList.indexOf(exist);
              if (index !== -1) {

                this.inProgressOrdersList[index].Status = status;
                this.inProgressOrdersList[index].CompletionTime = completionTime;
                this.inProgressOrdersList[index].RowIndex = this.completedOrdersList.length;
                this.completedOrdersList.push(this.inProgressOrdersList.find(x => x.OrderCode == orderCode));
                this.inProgressOrdersList.splice(index, 1);
              }
            }
            else {
              var existInPending = this.pendingOrdersList.find(x => x.OrderCode === orderCode);
              if (existInPending) {
                let index = this.pendingOrdersList.indexOf(existInPending);
                if (index !== -1) {
                  this.pendingOrdersList[index].Status = status;
                  this.pendingOrdersList[index].CompletionTime = completionTime;
                  this.pendingOrdersList[index].RowIndex = this.completedOrdersList.length;
                  this.completedOrdersList.push(this.pendingOrdersList.find(x => x.OrderCode == orderCode));
                  this.pendingOrdersList.splice(index, 1);
                }
              }
            }
          }
        }
        else {
          if (status == 1) {
            var existInPending = this.pendingOrdersList.find(x => x.OrderCode === orderCode);
            if (existInPending) {
              let indexs = this.pendingOrdersList.indexOf(existInPending);
              if (indexs !== -1) {
                this.pendingOrdersList[indexs].TimeRequired = timeRequired;
              }
            }
          }
          else if (status == 2) {
            var exist = this.inProgressOrdersList.find(x => x.OrderCode === orderCode);
            if (exist) {
              let index = this.inProgressOrdersList.indexOf(exist);
              if (index !== -1) {
                this.inProgressOrdersList[index].TimeRequired = timeRequired;
              }
            }
          }
          this.closePopUp();
          $('.calcplusmodal').hide();
          $('.modal-backdrop').hide();
        }
      }
      else if (data && data._statusCode == 404) {
        this.toastrService.error(this.translate.transform(data._message));
      }
      else {
        this.toastrService.error(this.translate.transform('66'));
      }
    });
  }

  completeOrder(orderCode: string, completionTime: string, status: number, timeRequired: number) {
    var today = new Date();
    completionTime = formatDate(today, 'yyyy-MM-dd hh:mm:ss a', 'en-US');
    this.startOrder(orderCode, completionTime, status, timeRequired);
  }

  IncreaseTime(orderCode: string, completionTime: string, status: number, timeRequired: number) {
    this.OrderCode = orderCode;
    this.CompletionTime = completionTime;
    this.Status = status;
    this.TimeRequired = timeRequired;
  }

  IncreaseOrderTime() {
    this.TimeRequired = this.TimeRequired + this.quantity;
    var today = new Date();
    var updationTime = formatDate(today, 'hh:mm:ss a', 'en-US');
    this.startOrder(this.OrderCode, this.CompletionTime, this.Status, this.TimeRequired, false, updationTime);
  }

  countDownTime() {
    if (this.inProgressOrdersList != null) {
      this.inProgressOrdersList.forEach(element => {
        var currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
        var orderTime = currentDate + ' ' + element.OrderTime;
        var times = new Date(orderTime);
        var tiiimes = times.setMinutes(times.getMinutes() + element.TimeRequired);
        var timeREquired = new Date(tiiimes).getTime();
        var totalTime = Math.floor(timeREquired - new Date().getTime());

        element.Hour = Math.floor((totalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        element.Minutes = Math.floor((totalTime % (1000 * 60 * 60)) / (1000 * 60));
        element.Seconds = Math.floor((totalTime % (1000 * 60)) / (1000));

        if (element.Hour <= 0 && element.Minutes <= 0 && element.Seconds <= 0) {
          element.Hour = 0;
          element.Minutes = 0;
          element.Seconds = 0;
        }

      });
    }
  }

  printReport(order: any) {
    this.model = order;
    this.printTime = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US');
    setTimeout(() => {
      var divToPrint = document.getElementById('divReport');
      var htmlToPrint = '<style>html, body {margin: 0;}</style>' + divToPrint.outerHTML;
      let newWin = window.open("", "Printable Page", "width=360,height=650px");
      newWin.document.write(htmlToPrint);
      setTimeout(() => { // wait until all resources loaded 
        newWin.document.close(); // necessary for IE >= 10
        newWin.focus(); // necessary for IE >= 10
        newWin.print(); // change window to winPrint
      }, 100);
    }, 100);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();
    //   console.log(performance.navigation)
    //   console.log(performance.navigation.type,'navigation Type')
    // // if (performance.navigation.type == 1)
      sessionStorage.removeItem(this.commonconstant.Kitchen_Notifications_List)
  }

  private updateNotificationsInStorage() {
    sessionStorage.removeItem(this.commonconstant.Kitchen_Notifications_List)
    sessionStorage.setItem(this.commonconstant.Kitchen_Notifications_List, this.notifications && this.notifications.length > 0 ? JSON.stringify(this.notifications) : null)
  }

  private updateNotificationsListFromStorage() {
    let obj = sessionStorage.getItem(this.commonconstant.Kitchen_Notifications_List)
    if (obj) {
      this.notifications = JSON.parse(obj)
      this.totalUnreadNotifications = this.notifications.filter(x => !x.IsNotificationRead).length;
    }
  }

  sortPendingOrders() {
    return sortArray(this.pendingOrdersList, 'OrderRecordTimeStamp').reverse();
  }

  sortCompletedOrders() {
    return sortArray(this.completedOrdersList, 'RowIndex').reverse();
  }
  sortInProgressOrders() {
    return sortArray(this.inProgressOrdersList, 'RowIndex')
  }

  sortNotifications() {
    return sortArray(this.notifications, 'RowIndex').reverse();
  }


}
export class Notification {
  constructor() {
    this.IsNotificationRead = false;
  }
  RowIndex:number;
  OrderCode: string;
  TimeRequired: number;
  IsNotificationRead: boolean;
  IsInsertNotification: boolean;
}