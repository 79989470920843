import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { LoyaltyCard } from "src/app/models/LoyaltyManagement/loyalty-card.model";
import { IPaymentMethodRepository } from "./IPaymentMethodRepository";

@Injectable({ providedIn: 'root' })
export class PaymentMethodRepository extends GenericRepository<any> implements IPaymentMethodRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/PaymentMethod', environment.urlAddress);
    }

    SelectAll(currentPage: number, recordsPerPage: number): Observable<ResponseModel> {
        return this.Get('SelectAll/' + currentPage + '/' + recordsPerPage);
    }

    SaveRec(item: LoyaltyCard): Observable<ResponseModel> {
        return this.PostData('/SaveRec/', item);
    }

    DeleteRec(code: number): Observable<ResponseModel> {
        return this.Delete('DeleteRec/' + code);
    }


}