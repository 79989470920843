import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { IPublicHolidayRepository } from "./IPublicHolidayRepository";
import { PublicHoliday } from "../../../models/HR/PublicHoliday/public-holiday.model";

@Injectable()
export class PublicHolidayRepository extends GenericRepository<PublicHoliday> implements IPublicHolidayRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/PublicHoliday', environment.urlAddress);
    }   
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {      
        filterText = filterText.split('/').join('~');   
        return this.Get('/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item: PublicHoliday): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/GetRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
}