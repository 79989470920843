import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { environment } from "../../../../environments/environment";
import { ITaskRatingRepository } from "./ITaskRatingRepository";
import { TaskRating } from "src/app/models/HR/TaskRating/task-rating";

@Injectable()
export class TaskRatingRepository extends GenericRepository<any> implements ITaskRatingRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/TaskRating', environment.urlAddress);
    }

    SaveRec(item: TaskRating): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item);
    }
   
    GetCompanyInfo(url: string, fiscalSpanID: number): Observable<ResponseModel> {
        return this.GetById('GetCompanyInfo/' + url + '/' + fiscalSpanID);
    }

    GetTaskInfo(taskCode: number, fiscalSpanID: number, cultureCode: string, connectionString: string): Observable<ResponseModel> {
        return this.GetById('GetTaskInfo/' + taskCode + '/' + fiscalSpanID + '/' + cultureCode + '/' + connectionString);
    }

}