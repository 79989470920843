import { GenericRepository } from "../generic-repository/GenericRepository";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReceiptSetup } from "../../models/ReceiptSetup/receiptsetup.model";
import { IReceiptSetupRepository } from "./IReceiptSetupRepository";

@Injectable()
export class ReceiptSetupRepository extends GenericRepository<ReceiptSetup> implements IReceiptSetupRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/ReceiptSetup/', environment.urlAddress);
    }
    LoadRec(CounterCode: string): Observable<any> {
        return this.GetById('/GetRec/' + CounterCode).pipe(map(this.extractData), catchError(this.handleError));
    }    
}