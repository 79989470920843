import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader/LoaderService';
import { Globals } from 'src/environments/Globals';


export class HttpRequestInterceptorService implements HttpInterceptor {

  constructor(private globals: Globals, private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // console.log('interceptor server time reset')
    // this.globals.resetServerSessionTimeOut();
    // this.globals.resetSessionTimeOut();
    // const customReq = request.clone({});    
    if (!window.location.href.includes('Procurement/PrintReport')) // load another component and disturb report design.
      this.showLoader();

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.onEnd();
      }
    },
      (err: any) => {
        this.onEnd();
      }));
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
  //   this.showLoader();
  //   return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
  //     if (event instanceof HttpResponse) {
  //       this.onEnd();
  //     }
  //   },
  //     (err: any) => {
  //       this.onEnd();
  //     }));
  // }

  private onEnd(): void {    
    this.loaderService.hide();
    this.globals.resetServerSessionTimeOut();
    this.globals.resetSessionTimeOut();
  }
  private showLoader(): void {  
    this.loaderService.show();
  }
}