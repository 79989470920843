import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { ResponseModel } from "../../../models/SharedModels/response.model";
import { IFundsTransferRepository } from "./IFundsTransferRepository";
import { FundsTransfer } from "src/app/models/Finance/FundsTransfer/funds-transfer.model";


@Injectable()
export class FundsTransferRepository extends GenericRepository<FundsTransfer> implements IFundsTransferRepository {

    url: string = environment.urlAddress;
    constructor(protected _http: HttpClient) {
        super(_http, 'api/fundstransfer', environment.urlAddress);
    }
    GetAll(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/fundstransfer/SelectAll' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    GetAllForApproval(currentPage: number, recordsPerPage: number, filterText: string): Observable<any> {
        filterText = filterText.split('/').join('~');
        if (filterText == "") {
            filterText = "|";
        }
        return this._http
            .get(this.url + 'api/fundstransfer/SelectAllForApproval' + '/' + currentPage + '/' + recordsPerPage + '/' + filterText, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    SaveRec(item : FundsTransfer): Observable<ResponseModel> {
        return this.PostData('/SaveRec', item).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadNavRec(navType: number, currentIndex: number): Observable<ResponseModel> {
        return this.GetById('/NavRec/' + navType + '/' + currentIndex).pipe(map(this.extractData), catchError(this.handleError));
    }
    LoadRec(code: string): Observable<ResponseModel> {
        return this.GetById('/LoadRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    DeleteRec(code: string): Observable<ResponseModel> {
        return this.Delete('/DeleteRec/' + code).pipe(map(this.extractData), catchError(this.handleError));
    }
    ApproveFundsTransfer(PaymentNumbers: string, approvedBy: string): Observable<ResponseModel> {
        return this._http
            .get(this.url + 'api/fundstransfer/ApproveFundsTransfer' + '/' +  PaymentNumbers + '/' + approvedBy, this.httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}