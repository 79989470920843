﻿import { Injectable, Injector } from "@angular/core";

@Injectable()
export class NumericService {
    _isLoadCB: boolean = false;
    constructor(private injector: Injector) { }
    
    get(input, decimalInValue, commaPattern): string {
        input = this.ParseAmount(input);
        input = input.toFixed(decimalInValue);
        const parts: string[] = input.split(".")

        const intPart: string = parts[0];
        const deciPart: string = parts[1];

        let rest: string = intPart;
        let last3: string = '';
        if (intPart.length > 3) {
            last3 = intPart.substr(intPart.length - 3);
            rest = intPart.substr(0, intPart.length - 3);

            if (commaPattern == 2)
                rest = rest.replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1,");
            else if (commaPattern == 3)
                rest = rest.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

            if (commaPattern !== 1 && rest !== '-')
                rest += ",";
        }

        if (decimalInValue == 0)
            return rest + last3;

        return rest + last3 + "." + deciPart;
    };
    ParseAmount(amt): number {

        var newAmount = amt.toString().replace(/,/g, "");
        if (newAmount == '')
            newAmount = '0';
        return parseFloat(newAmount);
    }
}
