﻿/*
 * added by Yameen 19-12-2019
 * For Amount input
 */
import { Directive, OnInit, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValueFormatter } from '../value-formatters/value-formatter';
import { SanitizeValueFormatter } from '../value-formatters/sanitize-value-formatter';

@Directive({
    selector: '[nullableAmountInput]',
    host: {
        '(keydown)': 'onKeyDown($event)',
        '(focus)': 'onFocus($event.target.value,$event)',
        '(blur)': 'onBlur($event.target.value)'
    },
})

export class NullableAmountInputDirective implements OnInit {
    _updateElementValue: boolean = true;
    constructor(private vf: ValueFormatter, private svf: SanitizeValueFormatter,
        private elementRef: ElementRef, private ngControl: NgControl) { }

    ngOnInit(): void {
        this.elementRef.nativeElement.value = this.vf.nullableAmountFormat(this.elementRef.nativeElement.value);
        this.ngControl.control.valueChanges.subscribe(() => {
            const newValue = this.svf.nullableAmountFormat(this.elementRef.nativeElement.value);
            this.ngControl.viewToModelUpdate(newValue);
            if (this._updateElementValue)
                this.elementRef.nativeElement.value = this.vf.nullableAmountFormat(this.elementRef.nativeElement.value);
        });

    }

    onFocus = (value, event) => {
        this._updateElementValue = false;
        this.elementRef.nativeElement.value = this.svf.nullableAmountFormat(value);
        if (event.which == 9) { //tab click
            return false;
        }
        this.elementRef.nativeElement.select();

    }

    onBlur = (value) => {
        this._updateElementValue = true;
        this.elementRef.nativeElement.value = this.vf.nullableAmountFormat(value);
        const newValue = this.elementRef.nativeElement.value.replace(/,/g, ""); // remove commas
        this.ngControl.viewToModelUpdate(newValue);
    }

    onKeyDown = (event) => {
        let e = <KeyboardEvent>event;
        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();

        }
    }
}
