﻿/*
 * added by Yameen 02-01-2020
 * to handle numeric sanitize formats
 */
import { Injectable } from "@angular/core";
import { NumericSanitizerPipe } from "../pipes/numeric-sanitizer.pipe";
import { NullableNumericSanitizerPipe } from "../pipes/nullable-numeric-sanitizer.pipe";

@Injectable()

export class SanitizeValueFormatter {

    constructor(private numericSanitizerPipe: NumericSanitizerPipe, private nullableNumericSanitizerPipe: NullableNumericSanitizerPipe) { }

    priceFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 1);
    amountFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 2);
    quantityFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 3);
    currencyFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 4);
    taxFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 5);
    numericFormat = (value: any): number => this.numericSanitizerPipe.transform(value, 6);

    /*
    * added by Yameen 12-05-2020
    * TODO: handle case where value == "" to return "", i.e used only placeholder intead of label in textbox - reference import modal
    */

    nullablePriceFormat = (value: any): any => this.nullableNumericSanitizerPipe.transform(value, 1);
    nullableAmountFormat = (value: any): any => this.nullableNumericSanitizerPipe.transform(value, 2);
    nullableQuantityFormat = (value: any): any => this.nullableNumericSanitizerPipe.transform(value, 3);
    nullableCurrencyFormat = (value: any): any => this.nullableNumericSanitizerPipe.transform(value, 4);
    nullableTaxFormat = (value: any): any => this.nullableNumericSanitizerPipe.transform(value, 5);

}