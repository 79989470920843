import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericRepository } from "../../generic-repository/GenericRepository";
import { IAccountBookSetupRepository } from "./IAccountBookSetupRepository";
import { AccountBookSetup } from "../../../models/Finance/AccountBookSetup/account-book-setup.model";

@Injectable()
export class AccountBookSetupRepository extends GenericRepository<AccountBookSetup> implements IAccountBookSetupRepository {
 
  url: string = environment.urlAddress;
  constructor(protected _http: HttpClient) {
      super(_http, 'api/accountbooksetup', environment.urlAddress);
  }      

}